import React, { createContext, useMemo, useReducer } from "react";
import {
  DataTableContextState,
  useDataTableContext,
} from "../components/DataTable/DataTableContext/DataTableContextProvider";
import { visitorBookingContextTypes } from "./store/actions/visitorBooking.types";
import visitorBookingReducer, {
  initialState,
  VisitorBookingContextDataGridState,
  VisitorBookingRowValues,
} from "./store/reducers/visitorBooking.reducer";

type VisitorBookingContextType = {
  dataTable: DataTableContextState;
  visitorBookingContextState: VisitorBookingContextDataGridState;
  setPageNumber: (pageNumber: number) => void;
  setPageSize: (pageSize: number) => void;
  setSorting: (sortBy: string) => void;
  resetPageNumber: () => void;
  resetPageSize: () => void;
  toggleCancelVisitorBookingWarning: () => void;
  setSelectedRowValues: (visitorBookingRowValues: VisitorBookingRowValues) => void;
};

export const VisitorBookingContext = createContext<VisitorBookingContextType>(
  {} as VisitorBookingContextType
);

interface IVisitorBookingContextProps {}

const VisitorBookingContextProvider: React.FC<IVisitorBookingContextProps> = ({
  children,
}) => {
  const dataTableContext = useDataTableContext();
  const [visitorBookingContextState, dispatch] = useReducer(
    visitorBookingReducer,
    initialState
  );

  const setSorting = (sortBy: string) => {
    dispatch({
      type: visitorBookingContextTypes.SET_SORTING,
      payload: sortBy,
    });
  };

  const setPageNumber = (pageNumber: number): void =>
    dataTableContext.setPageNumber(pageNumber);
  const setPageSize = (pageSize: number): void =>
    dataTableContext.setPageSize(pageSize);
  const resetPageNumber = (): void => dataTableContext.resetPageNumber();
  const resetPageSize = (): void => dataTableContext.resetPageSize();

  const toggleCancelVisitorBookingWarning = () =>
    dispatch({
      type: visitorBookingContextTypes.TOGGLE_SHOW_CANCEL_VISITORBOOKING,
    });

  const setSelectedRowValues = (visitorBookingRowValues: VisitorBookingRowValues) =>
    dispatch({
      type: visitorBookingContextTypes.SET_SELECTED_ROW_VALUES,
      payload: visitorBookingRowValues,
    });

  const store: VisitorBookingContextType = {
    dataTable: dataTableContext.state,
    visitorBookingContextState,
    setSorting,
    setPageNumber,
    setPageSize,
    resetPageNumber,
    resetPageSize,
    toggleCancelVisitorBookingWarning,
    setSelectedRowValues
  };

  const memoStore = useMemo(
    () => ({
      store,
    }),
    [store]
  );

  return (
    <VisitorBookingContext.Provider value={memoStore.store}>
      {children}
    </VisitorBookingContext.Provider>
  );
};

export default VisitorBookingContextProvider;
