import { VisitorBookingApi } from "./visitorBookingApi.types";

export const visitorBookingApi: VisitorBookingApi = (() => {
  const baseUrl = "/api/VisitorBooking";

  const getProductConfigurationsUrl = (customerId: string) =>
    `${baseUrl}/GetProductConfigurations/${customerId}`;

  const getParkingLocationsUrl = () => `${baseUrl}/GetParkingLocations`;

  const getCreateVisitorBookingUrl = (
    customerId: string,
    referenceNumber: string
  ) => `${baseUrl}/CreateVisitorBooking/${customerId}/${referenceNumber}`;

  const getCreateVisitorBookingsUrl = (
    customerId: string
  ) => `${baseUrl}/CreateVisitorBookings/${customerId}`;

  const getVisitorBookingsUrl = (customerId: string) =>
    `${baseUrl}/GetVisitorBookings/${customerId}`;

  const getCancelVisitorBookingUrl = (
    customerId: string,
    referenceNumber: string
  ) => `${baseUrl}/CancelVisitorBooking/${customerId}/${referenceNumber}`;

  const getParkingFacilityImagesUrl = () => 
    `${baseUrl}/GetFacilityImages`;

  return {
    getProductConfigurationsUrl,
    getParkingLocationsUrl,
    getCreateVisitorBookingUrl,
    getCreateVisitorBookingsUrl,
    getVisitorBookingsUrl,
    getCancelVisitorBookingUrl,
    getParkingFacilityImagesUrl
  };
})();
