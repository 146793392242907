import { TableBody, TableCell, TableRow } from "@material-ui/core";
import React from "react";
import styled from "styled-components";
import { variables } from "../../../theme/variables";
import CustomSpinner from "../../../ui/CustomSpinner/CustomSpinner";

const { colors } = variables;

const LoadingDataContainer = styled.div`
  display: flex;
  min-height: 500px;
  align-items: center;
  justify-content: center;
  background-color: ${colors.mostlyWhite};
`;

const NoPaddingTableCell = styled(TableCell)`
  padding: 0 !important;
`;

interface LoadingDataProps {
  colSpan: number;
}

const LoadingDataBody: React.FC<LoadingDataProps> = ({ colSpan }) => (
  <TableBody>
    <TableRow>
      <NoPaddingTableCell colSpan={colSpan + 2}>
        <LoadingDataContainer>
          <CustomSpinner />
        </LoadingDataContainer>
      </NoPaddingTableCell>
    </TableRow>
  </TableBody>
);

export default LoadingDataBody;
