import React, { createContext, useReducer, useEffect } from "react";
import IChildren from "../interfaces/IChildren";
import appContextReducer, {
  initialState,
  AppContextState,
  UserType,
} from "./store/reducers/appContext.reducer";
import { appContextTypes } from "./store/actions/appContext.types";
import { useTranslation } from "react-i18next";

export type AppContextType = {
  appState: AppContextState;
  setAppUser: (user: UserType) => void;
  disposeUser: () => void;
};

export const AppContext = createContext<AppContextType | undefined>(undefined);

const AppContextProvider = ({ children }: IChildren) => {
  const [appState, dispatch] = useReducer(appContextReducer, initialState);
  const { i18n } = useTranslation();

  useEffect(() => {
    setAppLanguage();

    // eslint-disable-next-line
  }, [i18n.languages]);

  const setAppLanguage = () =>
    dispatch({
      type: appContextTypes.SET_APP_LANGUAGE,
      payload: i18n.languages[0],
    });

  const setAppUser = (user: UserType) =>
    dispatch({
      type: appContextTypes.SET_APP_USER,
      payload: user,
    });

  const disposeUser = () =>
    dispatch({
      type: appContextTypes.DISPOSE_USER,
    });

  const store: AppContextType = {
    appState,
    setAppUser,
    disposeUser,
  };

  return <AppContext.Provider value={store}>{children}</AppContext.Provider>;
};

export default AppContextProvider;
