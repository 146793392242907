import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import environment, { EnvironmentState } from "./environment.reducer";
import parkingProducts, {
  ParkingProductState,
} from "./parkingProducts.reducer";
import toast, { ToastState } from "./toast.reducer";
import loader, { LoaderState } from "./loader.reducer";
import { reducer as oidcReducer, UserState } from "redux-oidc";
import oidcConfig, { OidcState } from "./oidc.reducer";
import ai, { AiState } from "./ai.reducer";
import {
  VisitorBookingState,
  visitorBookingReducer as visitorBooking,
} from "./visitor-booking";
import file, { FileState } from "./file.reducer";

export type RootReducer = {
  readonly router: any;
  readonly environment: EnvironmentState;
  readonly parkingProducts: ParkingProductState;
  readonly toast: ToastState;
  readonly loader: LoaderState;
  readonly oidc: UserState;
  readonly oidcConfig: OidcState;
  readonly ai: AiState;
  readonly visitorBooking: VisitorBookingState;
  readonly file: FileState;
};

const rootReducer = (history: any) => {
  return combineReducers<RootReducer>({
    router: connectRouter(history),

    // reducers
    environment,
    parkingProducts,
    toast,
    loader,
    oidc: oidcReducer,
    oidcConfig,
    ai,
    visitorBooking,
    file,
  });
};

export default rootReducer;
