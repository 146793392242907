import { TFunction } from "i18next";
import * as Yup from "yup";
import { DelegationType } from "../../../context/store/reducers/fleetManagerContext.reducer";
import { format } from "../../../utils";
import useIsValidDelegeeEmail from "../../../hooks/useIsValidDelegeeEmail";

export interface IDelegeeFormValues {
  name?: string;
  email: string;
  rights?: number[];
}

const useValidationSchema = (t: TFunction, isEditMode: boolean, delegationType: DelegationType) => {
  const isDelegeeEmailValid = useIsValidDelegeeEmail();

  const rights = isEditMode
    ? Yup.array(Yup.number()).default([])
    : Yup.array(Yup.number())
        .min(1, format(t("errors:min.field"), t("globals:parkingRights"), 1))
        .required(format(t("errors:required.field"), t("globals:parkingRights").toLowerCase()))
        .default([]);

  const email =
    delegationType == DelegationType.Multiple
      ? Yup.string().default("")
      : Yup.string()
          .email(
            format(t("errors:format.field"), t("globals:email"), t("globals:email").toLowerCase())
          )
          .required(format(t("errors:required.field"), t("globals:email").toLowerCase()))
          .test("email-is-valid", (value, ctx) => {
            if (!value) return true;

            const validValue = isDelegeeEmailValid(value);
            if (!validValue.isValid)
              return ctx.createError({ message: t(validValue.errorMessageKey!) });

            return true;
          })
          .default("");

  const validationSchema = Yup.object({
    name: Yup.string().optional().default(""),
    email,
    rights,
  });

  const initialValues: IDelegeeFormValues = {
    name: "",
    email: "",
    rights: [],
  };

  return {
    validationSchema,
    initialValues,
  };
};

export default useValidationSchema;
