import { isUndefined, without } from "underscore";

export type Position = {
  left?: number;
  right?: number;
  width?: number;
};

type LocationCardBusiness = {
  handleCardClicked: (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    instance: React.MutableRefObject<any>,
    action?: (position?: Position) => void
  ) => void;
};

const locationCardBusiness: LocationCardBusiness = (() => {
  const activeClass = "active";

  const _elementIsActive = (instance: React.MutableRefObject<any>) =>
    instance.current.classList.contains(activeClass);
  const _removeActiveClass = (instance: React.MutableRefObject<any>) =>
    instance.current.classList.remove(activeClass);
  const _addActiveClass = (instance: React.MutableRefObject<any>) =>
    instance.current.classList.add(activeClass);

  const _isOutsideOfElement = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    instance: React.MutableRefObject<any>
  ): boolean => {
    return (
      instance.current &&
      !instance.current.contains(event.target) &&
      instance.current.classList.contains(activeClass)
    );
  };

  const _isInsideOfElement = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    instance: React.MutableRefObject<any>
  ): boolean => {
    return instance.current && instance.current.contains(event.target);
  };

  const _excludeOtherElements = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    instance: React.MutableRefObject<any>
  ): void => {
    event.preventDefault();
    const elements = document.querySelectorAll("[data-card-type]");
    const toDeactivate = without(elements, instance.current);
    toDeactivate.forEach((el: Element) => el.classList.remove(activeClass));
  };

  const handleCardClicked = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    instance: React.MutableRefObject<any>,
    action?: (position?: Position) => void
  ) => {
    _excludeOtherElements(event, instance);

    const { left, right, width }: Position = instance.current.getBoundingClientRect();

    if (!isUndefined(action)) {
      action({ left, right, width });
    }

    if (_isInsideOfElement(event, instance)) {
      if (_elementIsActive(instance)) {
        _removeActiveClass(instance);

        return;
      }

      _addActiveClass(instance);
    }

    if (_isOutsideOfElement(event, instance)) {
      _removeActiveClass(instance);
    }
  };

  return {
    handleCardClicked,
  };
})();

export default locationCardBusiness;
