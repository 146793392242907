import { Dispatch } from "react";
import { ToastActionType, ToastTranslationModel } from "../reducers/toast.reducer";
import { toastTypes } from "../action-types/toast.types";
import { ToastType } from "../../ui/CustomToast/CustomToast";

export type ToastActions = {
  success: (translationModel: ToastTranslationModel) => (dispatch: Dispatch<ToastActionType>) => void;
  error: (translationModel: ToastTranslationModel) => (dispatch: Dispatch<ToastActionType>) => void;
  warning: (translationModel: ToastTranslationModel) => (dispatch: Dispatch<ToastActionType>) => void;
  info: (translationModel: ToastTranslationModel) => (dispatch: Dispatch<ToastActionType>) => void;
  hideToast: () => (dispatch: Dispatch<ToastActionType>) => void;
  dispose: () => (dispatch: Dispatch<ToastActionType>) => void;
};

const showToast = (severity: ToastType, translationModel: ToastTranslationModel) => {
  return (dispatch: Dispatch<ToastActionType>) => {
    dispatch({
      type: toastTypes.SHOW_TOAST,
      severity,
      translationModelPayload: translationModel,
    });
  };
};

const success = (translationModel: ToastTranslationModel) => showToast("success", translationModel);
const error = (translationModel: ToastTranslationModel) => showToast("error", translationModel);
const warning = (translationModel: ToastTranslationModel) => showToast("warning", translationModel);
const info = (translationModel: ToastTranslationModel) => showToast("info", translationModel);

const hideToast = () => {
  return (dispatch: Dispatch<ToastActionType>) => {
    dispatch({
      type: toastTypes.HIDE_TOAST,
    });
  };
};

const dispose = () => {
  return (dispatch: Dispatch<ToastActionType>) => {
    dispatch({
      type: toastTypes.DISPOSE_TOAST,
    });
  };
};

export const toastActions: ToastActions = {
  success,
  error,
  warning,
  info,
  hideToast,
  dispose,
};
