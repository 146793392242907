import React from "react";
import { isEmpty } from "underscore";
import { MuiHelperTextProps } from "./MuiHelperText.types";

const MuiHelperText = (props: MuiHelperTextProps) => {
  if (!props.error || isEmpty(props.error)) {
    return null;
  }

  if (!Boolean(props.error)) {
    return null;
  }

  return (
    <p className={`MuiFormHelperText-root MuiFormHelperText-contained Mui-error text-red ${props.className} no-margin-important`}>
      {props.error}
    </p>
  );
};

export default MuiHelperText;
