import moment from "moment-timezone";
import React, { useEffect, Dispatch, useCallback } from "react";
import { connect } from "react-redux";
import { RootReducer } from "../../../../store/reducers";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { isEmpty, isUndefined } from "underscore";
import { v4 as uuidV4 } from "uuid";
import { IDataTableRow } from "../../../../components/DataTable/DataTableBody/DataTableBody";
import {
  FilterType,
  IFilteredColumn,
} from "../../../../components/DataTable/DataTableFilters/DataTableFilters";
import { QParkChip } from "../../../../components/QParkChip";
import VisitorStatus from "../../../../components/VisitorStatus/VisitorStatus";
import { VisitorBookingConstants } from "../../../../constants";
import {
  ProductType,
  VisitorBookingDetails,
  VisitorBookingsList,
  VisitorBookingStatus,
} from "../../../../models/visitor-booking";
import routes from "../../../../router/routes";
import filteredColumnObjectBuilder from "../../../FleetManager/MyProducts/ObjectBuilders/FilteredColumnObjectBuilder";
import {
  useDataTableContext,
  DataTableContextFilters,
} from "../../../../components/DataTable/DataTableContext/DataTableContextProvider";
import { visitorBookingActions } from "../../../../store/actions/visitor-booking";
import { VisitorBookingsRequest } from "../../../../models/visitor-booking/VisitorBookingsRequest";
import {
  FmpFilters,
} from "../../../../constants/filtering.constants";
import useAppContext from "../../../../context/hooks/useAppContext";
import { WithFiltersAndPaging } from "../../../../models/filters/WithFiltersAndPaging";
import useVisitorBookingContext from "../../../../context/hooks/useVisitorBookingContext";
import {
  getFacilityOptions,
  getProductConfigurationOptions,
} from "../../../../helpers/selectOptionHelper";
import { NoPaddingGrid } from "../../../FleetManager/FleetManagerDataTable/StyledComponents";
import DataTable from "../../../../components/DataTable/DataTable";
import { Tooltip } from "@material-ui/core";
import {
  ActionsIconHoverButton,
  CancelIcon,
} from "../../../../components/DataTable/DataTableStyledComponents";

export interface VisitorBookingTableModel extends IDataTableRow {
  customerId?: string;
  referenceNumber?: string;
  customerName: string;
  customerEmailAddress: string;
  facility: string;
  creationDate: string;
  product: JSX.Element;
  startDateTime: string;
  endDateTime: string;
  status: JSX.Element | null;
  visitorStatus: VisitorBookingStatus;
}

const _createData = (
  visitorBookingDetails: VisitorBookingDetails
): VisitorBookingTableModel => ({
  rowKey: visitorBookingDetails.referenceNumber,
  disabled: visitorBookingDetails.status == VisitorBookingStatus.Cancelled,
  customerName: visitorBookingDetails.customerName,
  customerEmailAddress: visitorBookingDetails.customerEmailAddress,
  facility: visitorBookingDetails.facility,
  product: <QParkChip.Labeled label={visitorBookingDetails.product} />,
  creationDate: moment(visitorBookingDetails.creationDate).local()
    .format("DD/MM/YYYY HH:mm"),
  startDateTime:
    visitorBookingDetails.productType === ProductType.FixedRate
      ? moment(visitorBookingDetails.startDateTime).format("DD/MM/YYYY")
      : FormatDateTimeForFacilityTimeZone(visitorBookingDetails.startDateTime, visitorBookingDetails.physicalZoneUid),
  endDateTime:
    visitorBookingDetails.productType === ProductType.HourlyRate
      ? FormatDateTimeForFacilityTimeZone(visitorBookingDetails.endDateTime, visitorBookingDetails.physicalZoneUid)
      : "-",
  status: <VisitorStatus type={visitorBookingDetails.status} />,
  visitorStatus: visitorBookingDetails.status
});


const FormatDateTimeForFacilityTimeZone = function (dateTime: Date, physicalZoneUid: string): string {
    return moment(dateTime).tz(moment.tz.zonesForCountry(physicalZoneUid.split(".")[0])[0]).format("DD/MM/YYYY HH:mm");
};

const VisitorBookingDataTable = (props: VisitorBookingDataTableProps) => {
  const {
    hasProductsConfigured,
    isLoading,
    parkingLocations,
    productConfigurations,
    visitorBookingsList: { visitorBookings, totalPages },
    getVisitorBookings,
  } = props;
  const {
    appState: { user, selectedLanguage },
  } = useAppContext();
  const { push } = useHistory();

  const dataTableContext = useDataTableContext();
  const {
    visitorBookingContextState,
    setSorting,
    setSelectedRowValues,
    toggleCancelVisitorBookingWarning,
  } = useVisitorBookingContext();
  const { t } = useTranslation(["visitors"]);

  const memoizedFetch = useCallback(
    (): void => fetchVisitorBookings(),
    // eslint-disable-next-line
    [
      dataTableContext.state.pageSize,
      dataTableContext.state.pageNumber,
      visitorBookingContextState.orderDesc,
      visitorBookingContextState.sortBy,
      user.seasonTicketOwnerCrmId,
      selectedLanguage,
    ]
  );

  useEffect(() => {
    memoizedFetch();
  }, [memoizedFetch]);

  const getRequestModel = (): WithFiltersAndPaging<VisitorBookingsRequest> => {
    const filters = JSON.parse(
      JSON.stringify(dataTableContext.state.filters ?? [])
    ) as DataTableContextFilters[];

    const request: WithFiltersAndPaging<VisitorBookingsRequest> = {
      entity: {
        customerId: user.seasonTicketOwnerCrmId!,
      },
      pagination: {
        pageNumber: dataTableContext.state.pageNumber!,
        pageSize: dataTableContext.state.pageSize!,
      },
      filters: [
        {
          key: FmpFilters.sortBy,
          type: FilterType.QueryParam,
          value: visitorBookingContextState.sortBy,
        },
        {
          key: FmpFilters.orderDesc,
          type: FilterType.QueryParam,
          value: visitorBookingContextState.orderDesc?.toString(),
        },
        ...filters,
      ],
    };

    return request;
  };

  const redirectToNewBooking = () => {
    if (isUndefined(routes.visitors.subRoutes)) {
      return;
    }

    const newBookingPath = routes.visitors.subRoutes.find(
      (p) => p.name === "newBooking"
    );

    if (isUndefined(newBookingPath)) {
      return;
    }

    push({
      pathname: newBookingPath.path as string,
      search: `${VisitorBookingConstants.ReferenceNumber}=${uuidV4()}`,
    });
  };

    const clickCancelVisitorBooking = (dataRow: IDataTableRow) => {
    setSelectedRowValues({
      customerName: dataRow.customerName,
      referenceNumber: dataRow.rowKey,
    });
    toggleCancelVisitorBookingWarning();
  };

  const columns: IFilteredColumn<VisitorBookingTableModel>[] =
    filteredColumnObjectBuilder.buildVisitorBookingsColumns(
      t,
      getProductConfigurationOptions(productConfigurations?.data ?? []),
      getFacilityOptions(parkingLocations?.data ?? [])
    );

  const getTableRows = (): VisitorBookingTableModel[] => {
    if (isEmpty(visitorBookings)) {
      return [];
    }

    return visitorBookings.map(_createData);
  };
  const rows: VisitorBookingTableModel[] = getTableRows();

  const fetchVisitorBookings = () => {
    getVisitorBookings(getRequestModel());
  };

  const onSort = (
    _: React.MouseEvent<unknown>,
    property: keyof VisitorBookingTableModel
  ) => {
    setSorting(property.toString());
  };

  const renderCancelBtn = (focusedRow: IDataTableRow): JSX.Element => {
    if (focusedRow?.disabled || focusedRow?.visitorStatus === VisitorBookingStatus.Draft) {
      return <></>;
    }
    return (
      <Tooltip title={t("cancelBooking").toString()} placement="top">
        <ActionsIconHoverButton
          disableRipple
          className={"cancelButton"}
          onClick={() => clickCancelVisitorBooking(focusedRow)}
        >
          <CancelIcon />
        </ActionsIconHoverButton>
      </Tooltip>
    );
  };

  return (
    <NoPaddingGrid container item direction="row" xs={12}>
      <NoPaddingGrid container item direction="column" xs={12}>
        <DataTable
          filtersRef={undefined}
          columns={columns}
          rows={rows}
          renderActionMenu={() => []}
          actionBtn={renderCancelBtn}
          loading={isLoading}
          heading={{
            options: {
              useHeading: true,
              showHeadingCount: false,
            },
            title: t("bookingOverview"),
          }}
          filtering={{
            options: {
              useFilters: true,
            },
          }}
          onFilterClicked={fetchVisitorBookings}
          onResetFiltersClicked={fetchVisitorBookings}
          actions={{
            options: {
              useActions: true,
            },
            add: {
              useAdd: true,
              showAdd: hasProductsConfigured,
              addLabel: t("VisitorBookingDataTable.newBooking"),
              onAddClicked: redirectToNewBooking,
            },
          }}
          orderBy={visitorBookingContextState.sortBy ?? ""}
          order={visitorBookingContextState.orderDesc ? "desc" : "asc"}
          onRequestSort={onSort}
          pages={totalPages}
          selectedRowKeys={[]}
          setSelectedRowKeys={() => {}}
          hideWhenNoData={false}
          hideActionsMenu={false}
          translationOverrideKey={"visitorBookings"}
          hideCheckBoxes={true}
          hiddenKeys={["visitorStatus"]}
        />
      </NoPaddingGrid>
    </NoPaddingGrid>
  );
};

interface VisitorBookingDataTableProps extends StateProps, DispatchProps {
  hasProductsConfigured: boolean;
  isLoading: boolean;
  visitorBookingsList: VisitorBookingsList;
}

const mapStateToProps = (state: RootReducer) => {
  const { visitorBooking } = state;
  const { parkingLocations, productConfigurations } = visitorBooking;

  return {
    parkingLocations,
    productConfigurations,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  getVisitorBookings: (body: WithFiltersAndPaging<VisitorBookingsRequest>) =>
    dispatch(visitorBookingActions.getVisitorBookings(body)),
});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VisitorBookingDataTable);
