import { useContext } from "react";
import { AppContext } from "../AppContextProvider";

const useAppContext = () => {
  const ctxValue = useContext(AppContext);

  if (ctxValue === undefined)
    throw new Error("AppContext is not setup properly");

  return ctxValue;
};

export default useAppContext;
