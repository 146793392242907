import React from "react";
import { SvgIcon } from "@material-ui/core";

const VehicleIcon = () => (
  <SvgIcon className="vehicle-icon">
    <g transform="translate(0 0.001)">
      <path
        className="a"
        d="M15.121,19.09V17.44h3.241v1.65a1.62,1.62,0,0,1-3.241,0Zm-14.041,0V17.44H4.32v1.65a1.62,1.62,0,0,1-3.24,0ZM1,17.439a1,1,0,0,1-1-1V8.629a1,1,0,0,1,1-1H2.161V3a3,3,0,0,1,3-3h9.122a3,3,0,0,1,3,3V7.629h1.16a1,1,0,0,1,1,1v7.81a1,1,0,0,1-1,1Zm11.961-5.45a2.161,2.161,0,1,0,2.16-2.18A2.173,2.173,0,0,0,12.961,11.989Zm-10.8,0a2.161,2.161,0,1,0,2.16-2.18A2.173,2.173,0,0,0,2.16,11.989Zm2.161-8.81V7.629h10.8V3.179a1,1,0,0,0-1-1h-8.8A1,1,0,0,0,4.321,3.179Z"
        transform="translate(1.455 0)"
      />
      <rect
        className="a"
        width="2.909"
        height="1.474"
        rx="0.737"
        transform="translate(19.441 7.921)"
      />
      <rect className="a" width="2.909" height="1.474" rx="0.737" transform="translate(0 7.921)" />
    </g>
  </SvgIcon>
);

export default VehicleIcon;
