import { AxiosResponse } from "axios";
import { Dispatch } from "react";
import { isUndefined } from "underscore";
import { success } from "../helpers/action.helper";
import { aiTypes } from "../action-types/ai.types";
import aiService from "../../services/ai.service";

export type AiDispatchType = {
  getInstrumentationKey: (callback?: Function) => void;
};

export type AiActionType = {
  type: string;
  payload: string;
};

const getInstrumentationKey = (callback?: Function) => {
  return (dispatch: Dispatch<AiActionType>) => {
    aiService
      .getInstrumentationKey()
      .then((response: AxiosResponse<string>) => {
        dispatch({
          type: success(aiTypes.GET_INSTRUMENTATIONKEY),
          payload: response.data,
        });

        if (!isUndefined(callback)) {
          callback();
        }
      });
  };
};

export default {
  getInstrumentationKey,
} as AiDispatchType;
