import React, { PropsWithChildren, useRef } from "react";
import CustomCheckbox from "../../../ui/CustomCheckbox/CustomCheckbox";
import {
  TableSortLabel,
  TableRow,
  TableHead,
  TableCell,
  makeStyles,
} from "@material-ui/core";
import { isUndefined } from "underscore";
import {
  KeyboardArrowDownRounded,
  KeyboardArrowUpRounded,
  UnfoldMoreRounded,
} from "@material-ui/icons";
import { variables } from "../../../theme/variables";

const { colors } = variables;

const useIconStyle = makeStyles({
  root: {
    width: "0.7em",
    height: "0.7em",
    color: colors.lightGray,
  },
});

export type Order = "asc" | "desc";

export type MultiSelectOption = {
  label: string;
  value: string | number;
};

export interface Column<T> {
  id: keyof T;
  label: string;
  numeric?: boolean;
  disablePadding?: boolean;
  align?: "inherit" | "left" | "center" | "right" | "justify";
  width?: number;
  disableSorting?: boolean;
}

interface ITableHeaderProps<T> {
  columns: Column<T>[];
  numSelected: number;
  onRequestSort?: (event: React.MouseEvent<unknown>, property: keyof T) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order?: Order;
  orderBy: string;
  rowCount: number;
  hideActions?: boolean;
  hideCheckboxes?: boolean;
}

const DataTableHead = <T,>(props: PropsWithChildren<ITableHeaderProps<T>>) => {
  const classes = useIconStyle();

  const {
    columns,
    order,
    orderBy,
    numSelected,
    rowCount,
    hideActions,
    onSelectAllClick,
    onRequestSort,
    hideCheckboxes
  } = props;
  const orderedBy = useRef<string>("");

  const createSortHandler =
    (property: keyof T) => (event: React.MouseEvent<unknown>) => {
      orderedBy.current = property.toString();

      if (!isUndefined(onRequestSort)) {
        onRequestSort(event, property);
      }
    };

  const getSortingIcon = (columnId: keyof T) => {
    if (columnId !== orderBy) {
      return <UnfoldMoreRounded classes={classes} />;
    }

    return order == "asc" ? (
      <KeyboardArrowUpRounded classes={classes} />
    ) : (
      <KeyboardArrowDownRounded classes={classes} />
    );
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell
          padding="checkbox"
          style={{ display: (hideCheckboxes ? "none" : "table-cell") }}
        >
          <CustomCheckbox
            checkboxProps={{
              checked: rowCount > 0 && numSelected === rowCount,
              onChange: onSelectAllClick,
            }}
          />
        </TableCell>
        {columns.map((column: Column<T>) => (
          <TableCell
            key={`${column.id as number | string}_${order}`}
            align={column.align}
            padding={column.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === column.id ? order : false}
            width={column.width}
          >
            <TableSortLabel
              active={orderedBy.current === column.id}
              hideSortIcon={column.disableSorting}
              disabled={column.disableSorting }
              onClick={createSortHandler(column.id)}
              IconComponent={() => getSortingIcon(column.id)}
            >
              {column.label}
            </TableSortLabel>
          </TableCell>
        ))}
        {!hideActions && <TableCell width={15} />}
      </TableRow>
    </TableHead>
  );
};

DataTableHead.defaultProps = {
  order: "desc",
};

export default DataTableHead;
