import { FormControl, Grid, MenuItem, Select } from "@material-ui/core";
import React, { memo, useMemo } from "react";
import { isUndefined, findWhere, isEmpty } from "underscore";
import RequiredInputLabel from "../../../../../../../../ui/RequiredInputLabel/RequiredInputLabel";
import { Fields, ParkingLocationFormProps } from "./ParkingLocationForm.types";
import { MuiHelperText } from "../../../../../../../../components/MuiHelperText";
import { useTranslation } from "react-i18next";
import useAppContext from "../../../../../../../../context/hooks/useAppContext";

const ParkingLocationForm = memo((props: ParkingLocationFormProps) => {
  const { t } = useTranslation(["visitors", "countries", "globals"]);
  const {
    country,
    city,
    physicalZone,
    onChange,
    setFieldValue,
    setFieldError,
    onPhysicalZoneChanged,
    parkingLocations,
  } = props;

  const { appState } = useAppContext();

  const countries = useMemo(() => {
    return parkingLocations
      ?.map((d) => ({
        countryCode: d.countryCode,
        countryName: t(`countries:${d.countryCode}`),
      }))
      .sort((a, b) => (a.countryName < b.countryName ? -1 : 1));
  }, [parkingLocations, appState.selectedLanguage]);

  const cities = useMemo(() => {
    if (isUndefined(parkingLocations)) {
      return [];
    }

    const result =
      findWhere(parkingLocations ?? [], {
        countryCode: country.value,
      })
        ?.cities.map((c) => c.name)
        .sort((a, b) => (a < b ? -1 : 1)) ?? [];

    if (result.length === 1) {
      city.value = result[0];
      setFieldValue(Fields.City, city.value);
      setFieldError(Fields.City, undefined);
    }

    return result;
  }, [parkingLocations, country.value]);

  const physicalZones = useMemo(() => {
    if (isUndefined(parkingLocations)) {
      return [];
    }

    if (isEmpty(city.value)) {
      return [];
    }

    const citiesForCountry = findWhere(parkingLocations ?? [], {
      countryCode: country.value,
    })?.cities;

    if (isUndefined(cities)) {
      return [];
    }

    const result =
      findWhere(citiesForCountry ?? [], {
        name: city.value,
      })?.physicalZones.sort((a, b) => (a.name < b.name ? -1 : 1)) ?? [];

    if (result.length === 1) {
      physicalZone.value = result[0].physicalZoneId;
      setFieldValue(Fields.PhysicalZone, physicalZone.value);
      setFieldError(Fields.PhysicalZone, undefined);
    }

    return result;
  }, [parkingLocations, country.value, city.value]);

  const onCountryChanged = (
    e: React.ChangeEvent<{
      name?: string;
      value: unknown;
    }>
  ) => {
    setFieldValue(Fields.City, "");
    setFieldValue(Fields.PhysicalZone, "");
    onChange(Fields.Country, e);
    if (onPhysicalZoneChanged) {
      onPhysicalZoneChanged();
    }
  };

  const onCityChanged = (
    e: React.ChangeEvent<{
      name?: string;
      value: unknown;
    }>
  ) => {
    setFieldValue(Fields.PhysicalZone, "");
    onChange(Fields.City, e);
    if (onPhysicalZoneChanged) {
      onPhysicalZoneChanged();
    }
  };

  const onInternalPhysicalZoneChanged = (
    e: React.ChangeEvent<{
      name?: string;
      value: unknown;
    }>
  ) => {
    onChange(Fields.PhysicalZone, e);
    if (onPhysicalZoneChanged) {
      onPhysicalZoneChanged();
    }
  };

  return (
    <Grid
      container
      item
      direction="row"
      alignItems="center"
      xs={12}
      id={"visitor-booking-location"}
    >
      <Grid
        container
        direction="column"
        item
        xs={12}
        xl={3}
        lg={3}
        md={3}
        sm={12}
      >
        <RequiredInputLabel htmlFor={Fields.Country}>
          {t("globals:country")}
        </RequiredInputLabel>
      </Grid>
      <Grid
        item
        container
        direction="column"
        xs={12}
        xl={9}
        lg={9}
        md={9}
        sm={12}
      >
        <FormControl
          variant="outlined"
          error={country.hasError}
          disabled={countries?.length === 0}
        >
          <Select
            id={Fields.Country}
            name={Fields.Country}
            autoComplete="nope"
            value={country.value}
            onChange={onCountryChanged}
            displayEmpty
          >
            <MenuItem value="" disabled>
              {t("parkingLocationForm.countryPlaceholder")}
            </MenuItem>
            {!isUndefined(countries) &&
              countries.map((c) => (
                <MenuItem
                  key={`${c.countryCode}_${c.countryName}`}
                  value={c.countryCode}
                >
                  {c.countryName}
                </MenuItem>
              ))}
          </Select>
          <MuiHelperText error={country.error} />
        </FormControl>
      </Grid>
      <Grid
        container
        direction="column"
        item
        xs={12}
        xl={3}
        lg={3}
        md={3}
        sm={12}
      >
        <RequiredInputLabel htmlFor={Fields.City}>
          {t("globals:city")}
        </RequiredInputLabel>
      </Grid>
      <Grid
        item
        container
        direction="column"
        xs={12}
        xl={9}
        lg={9}
        md={9}
        sm={12}
      >
        <FormControl
          variant="outlined"
          error={city.hasError}
          disabled={isEmpty(country.value)}
        >
          <Select
            id={Fields.City}
            name={Fields.City}
            autoComplete="nope"
            value={city.value}
            onChange={onCityChanged}
            displayEmpty
          >
            <MenuItem value="" disabled>
              {t("parkingLocationForm.cityPlaceholder")}
            </MenuItem>
            {!isEmpty(cities) &&
              cities.map((c) => (
                <MenuItem key={c} value={c}>
                  {c}
                </MenuItem>
              ))}
          </Select>
          <MuiHelperText error={city.error} />
        </FormControl>
      </Grid>
      <Grid
        container
        direction="column"
        item
        xs={12}
        xl={3}
        lg={3}
        md={3}
        sm={12}
      >
        <RequiredInputLabel htmlFor={Fields.PhysicalZone}>
          {t("globals:facility")}
        </RequiredInputLabel>
      </Grid>
      <Grid
        item
        container
        direction="column"
        xs={12}
        xl={9}
        lg={9}
        md={9}
        sm={12}
        id={"visitor-booking-facility"}
      >
        <FormControl
          variant="outlined"
          error={physicalZone.hasError}
          disabled={isEmpty(city.value)}
        >
          <Select
            id={Fields.PhysicalZone}
            name={Fields.PhysicalZone}
            autoComplete="nope"
            value={physicalZone.value}
            onChange={onInternalPhysicalZoneChanged}
            displayEmpty
          >
            <MenuItem value="" disabled>
              {t("parkingLocationForm.facilityPlaceholder")}
            </MenuItem>
            {!isEmpty(physicalZones) &&
              physicalZones.map((z) => (
                <MenuItem key={z.physicalZoneId} value={z.physicalZoneId}>
                  {z.name}
                </MenuItem>
              ))}
          </Select>
          <MuiHelperText error={physicalZone.error} />
        </FormControl>
      </Grid>
    </Grid>
  );
});

export default ParkingLocationForm;
