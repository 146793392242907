import { MenuItem } from "@material-ui/core";
import React, { Dispatch, ReactNode, useCallback, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { IDataTableRow } from "../../../components/DataTable/DataTableBody/DataTableBody";
import {
  EditIcon,
  ResendInvitationIcon,
  DeleteDelegeeMenuItem,
  DeleteDelegeeIcon,
  NoPaddingGrid,
} from "./StyledComponents";
import useAppContext from "../../../context/hooks/useAppContext";
import parkingProductsActions from "../../../store/actions/parkingProducts.actions";
import useFleetManagerContext from "../../../context/hooks/useFleetManagerContext";
import DataTable from "../../../components/DataTable/DataTable";
import "./_fleetManagerTable.scss";
import {
  DataTableActions,
  DataTableFiltering,
  DataTableHeadingProps,
} from "../../../components/DataTable/DataTableHeading/DataTableHeading";
import { Column, Order } from "../../../components/DataTable/DataTableHead/DataTableHead";
import "./_fleetManagerTable.scss";
import { useDataTableContext } from "../../../components/DataTable/DataTableContext/DataTableContextProvider";
import { isEmpty } from "underscore";
import { FilterRef } from "../../../components/DataTable/DataTableFilters/DataTableFilters";
import { ForbiddenIconMenu } from "../../../ui/Icons/ForbiddenIcon";

export interface IFleetManagerDataTableModel extends IDataTableRow {
  name: string;
  email: string;
  invitationDate: string;
  status: JSX.Element;
}

interface IFleetManagerDataTable extends DispatchProps {
  columns: Column<IFleetManagerDataTableModel>[];
  rows: IFleetManagerDataTableModel[];
  heading: DataTableHeadingProps;
  actions: DataTableActions;
  filtering: DataTableFiltering;
  totalPages: number;
  isLoading: boolean;
  orderBy: string;
  order: Order;
  hideWhenNoData?: boolean;
  hideActionsMenu?: boolean;
  onSort?: (
    event: React.MouseEvent<unknown>,
    property: keyof IFleetManagerDataTableModel
  ) => void;
  getRegistrationIdFromTableRow: (
    focusedRow: IDataTableRow | null
  ) => string | null;
  isResendInviteDisabled: (focusedRow: IDataTableRow | null) => boolean;
  onFilterClicked?: () => void;
  onResetFiltersClicked?: () => void;
  translationOverrideKey: string;
  subHeading?: ReactNode
}

const FleetManagerDataTable = (props: IFleetManagerDataTable) => {
  const {
    columns,
    rows,
    heading,
    filtering,
    actions,
    totalPages,
    isLoading,
    orderBy,
    order,
    onSort,
    getRegistrationIdFromTableRow,
    isResendInviteDisabled,
    onFilterClicked,
    onResetFiltersClicked,
    hideWhenNoData,
    hideActionsMenu,
    translationOverrideKey,
    subHeading
  } = props;
  const { t } = useTranslation(["fleetManagerTable", "globals", "dataTable"]);
  const {
    fleetManagerState,
    toggleEditDelegee,
    toggleRevokeParkingRightsWarning,
    setSelectedRegistrationId,
    setSelectedParkingRightIds,
    setSelectedDelegeeRowKey,
    toggleDeleteDelegeeWarning,
  } = useFleetManagerContext();
  const { appState } = useAppContext();
  const { state } = useDataTableContext();
  const filtersRef = useRef<FilterRef>({
    onFilterClicked: () => setSelectedParkingRightIds([]),
  } as FilterRef);

  const handleEditClick = (focusedRow: IDataTableRow | null) => {
    const registrationId = getRegistrationIdFromTableRow(focusedRow) || "";
    toggleEditDelegee(registrationId);
  };

  const handleDeleteClick = (focusedRow: IDataTableRow | null) => {
    const registrationId = getRegistrationIdFromTableRow(focusedRow) || "";
    setSelectedRegistrationId(registrationId);
    toggleDeleteDelegeeWarning();
  };

  const handleRevokeClick = (focusedRow: IDataTableRow | null) => {
    const registrationId = getRegistrationIdFromTableRow(focusedRow) || "";
    setSelectedRegistrationId(registrationId);
    setSelectedDelegeeRowKey(focusedRow?.rowKey || "");
    toggleRevokeParkingRightsWarning();
  };

  const handleResendInviteClick = (
    focusedRow: IDataTableRow | null,
    handleMenuClose: () => void
  ) => {
    const registrationId = getRegistrationIdFromTableRow(focusedRow) || "";
    props.resendDelegeeInvite(
      registrationId,
      appState.user.seasonTicketOwnerCrmId as string
    );
    handleMenuClose();
  };

  const renderMenuItems = (
    focusedRow: IDataTableRow | null,
    onClose: () => void
  ): JSX.Element[] => {
    const editItem = (
      <MenuItem key="edit" onClick={() => handleEditClick(focusedRow)}>
        <EditIcon />
        {t("contextMenuActions.editDelegee")}
      </MenuItem>
    );
    const resendInviteItem = (
      <MenuItem
        key="resend"
        onClick={() => handleResendInviteClick(focusedRow, onClose)}
        disabled={isResendInviteDisabled(focusedRow)}
      >
        <ResendInvitationIcon />
        {t("contextMenuActions.resendInvite")}
      </MenuItem>
    );
    const revokeItem = (
      <MenuItem
        key="revoke"
        onClick={() => handleRevokeClick(focusedRow)}
        disabled={!focusedRow?.products}
      >
        <ForbiddenIconMenu />
        {t("contextMenuActions.revokeDelegee")}
      </MenuItem>  
    );
    const deleteItem = (
      <DeleteDelegeeMenuItem
        key="delete"
        onClick={() => handleDeleteClick(focusedRow)}
      >
        <DeleteDelegeeIcon />
        {t("contextMenuActions.deleteDelegee")}
      </DeleteDelegeeMenuItem>
    );

    const menuItems = [editItem, resendInviteItem, revokeItem, deleteItem];

    return menuItems;
  };

  const filtersHaveChanged = useCallback(() => {
    state.filters?.forEach((filter) => {
      if (!isEmpty(filter.value)) {
        setSelectedParkingRightIds([]);
      }
    });
  }, [state.filters]);

  useEffect(() => {
    filtersHaveChanged();
  }, [filtersHaveChanged]);

  return (
    <NoPaddingGrid container item direction="row" xs={12}>
      <NoPaddingGrid container item direction="column" xs={12}>
        <DataTable
            filtersRef={filtersRef}
            columns={columns}
            rows={rows}
            renderActionMenu={renderMenuItems}
            loading={isLoading}
            heading={heading}
            filtering={filtering}
            onFilterClicked={onFilterClicked}
            onResetFiltersClicked={onResetFiltersClicked}
            actions={actions}
            orderBy={orderBy}
            order={order}
            onRequestSort={onSort}
            pages={totalPages}
            selectedRowKeys={fleetManagerState.selectedRowKeys}
            setSelectedRowKeys={setSelectedParkingRightIds}
            hideWhenNoData={hideWhenNoData}
            hideActionsMenu={hideActionsMenu}
            translationOverrideKey={translationOverrideKey}
            subHeading={subHeading}
        />
      </NoPaddingGrid>
    </NoPaddingGrid>
  );
};

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  resendDelegeeInvite: (registrationId: string, seasonTicketOwnerCrmId: string) =>
    dispatch(parkingProductsActions.resendDelegeeInvite(registrationId, seasonTicketOwnerCrmId)),
});

type DispatchProps = ReturnType<typeof mapDispatchToProps>;

export default connect(null, mapDispatchToProps)(FleetManagerDataTable);
