import { isNull } from "underscore";

type StorageService = {
  setTokenToStorage: (value: string) => void;
  getTokenFromStorage: () => string;
  removeTokenFromStorage: () => void;
};

const storageService: StorageService = (() => {
  const $key = "token";

  const _setToken = (value: string) => localStorage.setItem($key, value);

  const _getToken = () => {
    const token = localStorage.getItem($key);

    if (isNull(token)) {
      return "";
    }

    return token;
  };

  const _removeToken = () => localStorage.removeItem($key);

  return {
    setTokenToStorage: _setToken,
    getTokenFromStorage: _getToken,
    removeTokenFromStorage: _removeToken,
  };
})();

export default storageService;
