import { findWhere, isNull, isUndefined } from "underscore";
import errorConstants from "../constants/error.constants";
import { ErrorContextModel, ErrorDetailsModel } from "../models/ErrorDetailsModel";
import { isNil } from "lodash";

type ErrorUtils = {
  checkValidationError: (error: ErrorDetailsModel | null, key: string, value: string) => boolean;
  checkHasNoCapacity: (error: ErrorDetailsModel | null) => boolean;
  checkEmailExists: (error: ErrorDetailsModel | null) => boolean;
  checkForSeasonTicketCrmIdNotAvailableError: (error: ErrorDetailsModel | null) => boolean;
  checkForParkingRightsAlreadyLinkedToALicensePlate: (error: ErrorDetailsModel | null) => boolean;
  checkRequestError: (error: ErrorContextModel | null, key: string, value: string) => boolean;
};

export const checkValidationError = (
  error: ErrorDetailsModel | null,
  key: string,
  value: string
) => {
  if (isNil(error)) {
    return false;
  }

  const { errors } = error;
  const property = {
    [key]: value,
  };

  const found = findWhere(errors, property);

  return !isUndefined(found);
};

export const checkRequestError = (
  error: ErrorContextModel | null,
  key: string,
  value: string
) => {
  if (isNull(error) || error.context.length <= 0) {
    return false;
  }

  return error.context[0].key === key && error.context[0].value === value;
}

export const checkEmailExists = (error: ErrorDetailsModel | null) =>
  checkValidationError(error, errorConstants.keys.delegee, errorConstants.codes.alreadyExists);

export const checkHasNoCapacity = (error: ErrorDetailsModel | null) =>
  checkValidationError(error, errorConstants.keys.parkingRights, errorConstants.codes.notAvailable);

export const checkNumberPlateAlreadyAssigned = (error: ErrorDetailsModel | null) =>
    checkValidationError(error, errorConstants.keys.numberPlate, errorConstants.codes.alreadyLinkedToAnotherUser);

export const checkLicensePlateAlreadyLinked = (error: ErrorDetailsModel | null) =>
    checkValidationError(error, errorConstants.keys.numberPlate, errorConstants.codes.licensePlateAlreadyLinked);

export const checkForSeasonTicketCrmIdNotAvailableError = (error: ErrorDetailsModel | null) =>
  checkValidationError(
    error,
    errorConstants.keys.seasonTicketOwnerCrmId,
    errorConstants.codes.notAvailable
  );

export const checkForParkingRightsAlreadyLinkedToALicensePlate = (
  error: ErrorDetailsModel | null
) =>
  checkValidationError(
    error,
    errorConstants.keys.parkingRights,
    errorConstants.codes.licensePlateAlreadyLinked
  );

const errorUtils: ErrorUtils = {
  checkValidationError,
  checkEmailExists,
  checkHasNoCapacity,
  checkForSeasonTicketCrmIdNotAvailableError,
  checkForParkingRightsAlreadyLinkedToALicensePlate,
  checkRequestError
};

export default errorUtils;
