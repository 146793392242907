import { MenuItem, Select } from "@material-ui/core";
import React, { useState } from "react";
import { NoPaddingGrid } from "../../../../containers/FleetManager/FleetManagerDataTable/StyledComponents";
import CustomCheckbox from "../../../../ui/CustomCheckbox/CustomCheckbox";
import {
  DataTableContextFilters,
  useDataTableContext,
} from "../../DataTableContext/DataTableContextProvider";
import { contains, filter as $filter } from "underscore";
import { FilterType, IFilter } from "../DataTableFilters";
import {
  GrayUpArrow,
  GrayDownArrow,
  PaddingFormControl,
  LightGraySpan,
  CenterdSpan,
  FilterNumberBubble,
  AbsoluteClearInputIcon,
} from "../CommonStyledComponents";
import styled from "styled-components";

export interface ISelectOption {
  label: string;
  value: any;
}

interface ISelectFilter {
  filter: IFilter;
  entity?: DataTableContextFilters;
}

const EllipsedGridItem = styled(NoPaddingGrid)`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const EllipsedSpan = styled.span`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const NoDecorationAbbr = styled.abbr`
  text-decoration: none;
`;

const SelectFilter: React.FC<ISelectFilter> = ({ filter, entity }) => {
  const { setFilter, resetFilter } = useDataTableContext();
  const [opened, setOpened] = useState<boolean>(false);

  const CustomInputIcon = (count: number) => {
    if (count) {
      return null;
    }

    if (opened) {
      return <GrayUpArrow />;
    }

    return <GrayDownArrow />;
  };

  return (
    <NoPaddingGrid item container direction="column" justifyContent="center" xs={2}>
      <PaddingFormControl size="small" variant="outlined">
        <Select
          multiple={filter.type === FilterType.Multiselect}
          displayEmpty
          defaultValue={"placeholder"}
          name={entity?.key}
          value={entity?.value as string[]}
          onChange={(e) =>
            setFilter(entity?.key as string, entity?.type as FilterType, e.target.value as string[])
          }
          renderValue={(selected) => {
            if ((selected as string[]).length === 0) {
              return <LightGraySpan>{filter.placeholder}</LightGraySpan>;
            }

            const selectedValues = $filter(filter.options!, (option) =>
              contains(selected as string[], option.value)
            ).map((o) => o.label);

            return (
              <CenterdSpan>
                <EllipsedSpan>
                  {selectedValues.length > 1 ? filter.placeholder : selectedValues[0]}
                  {selectedValues.length > 1 && (
                    <FilterNumberBubble>{selectedValues?.length}</FilterNumberBubble>
                  )}
                </EllipsedSpan>
              </CenterdSpan>
            );
          }}
          IconComponent={() => CustomInputIcon((entity?.value as string[]).length)}
          onOpen={() => setOpened(true)}
          onClose={() => setOpened(false)}
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left",
            },
            getContentAnchorEl: null,
          }}
        >
          {filter.options?.map((o, index) => (
            <MenuItem key={index} value={o.value as number}>
              <NoPaddingGrid container>
                <NoPaddingGrid container item xs={2} alignItems="center">
                  <CustomCheckbox
                    checkboxProps={{
                      checked:
                        (entity?.value as string[])?.some((value) => value === o.value) || false,
                    }}
                  />
                </NoPaddingGrid>
                <EllipsedGridItem item xs={10}>
                  <span>
                    <NoDecorationAbbr title={o.label}>{o.label}</NoDecorationAbbr>
                  </span>
                </EllipsedGridItem>
              </NoPaddingGrid>
            </MenuItem>
          ))}
        </Select>
        {(entity?.value as string[]).length > 0 && (
          <AbsoluteClearInputIcon onClick={() => resetFilter(entity?.key as string)} />
        )}
      </PaddingFormControl>
    </NoPaddingGrid>
  );
};

export default SelectFilter;
