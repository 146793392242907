import axios from "../axios";
import { AxiosResponse } from "axios";
import { OidcConfigurationResponseModel } from "../models/OidcConfigurationResponseModel";
import oidcApi from "../api/oidc.api";

type OidcService = {
  getConfiguration: () => Promise<AxiosResponse<OidcConfigurationResponseModel>>;
};

const getConfiguration = (): Promise<AxiosResponse<OidcConfigurationResponseModel>> =>
  axios.get(oidcApi.getConfigurationUrl());

export default {
  getConfiguration,
} as OidcService;
