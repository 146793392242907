import React from "react";
import { TextFieldProps, TextField, InputAdornment, Tooltip } from "@material-ui/core";
import { ErrorOutline } from "@material-ui/icons";
import styled from "styled-components";
import { variables } from "../../theme/variables";

const { colors } = variables;

const StyledErrorOutline = styled(ErrorOutline)`
  fill: ${colors.red.hex};
  width: 18px;
`;

const CustomInput = (props: TextFieldProps) => <TextField {...props} />;

const Required = (props: TextFieldProps) => (
  <TextField
    {...props}
    InputProps={{
      endAdornment: props.error && (
        <InputAdornment position="end">
          <StyledErrorOutline />
        </InputAdornment>
      ),
    }}
  />
);

const RequiredWithTooltip = (props: CustomInputProps) => (
  <TextField
    {...props}
    InputProps={{
      endAdornment: props.error && (
  <Tooltip
    arrow
    title={props.tooltip}
    placement="top"
    className="d-flex align-center"
  >
    <InputAdornment position="end">
      <StyledErrorOutline />
    </InputAdornment>
  </Tooltip>        
      ),
    }}
  />
);

CustomInput.Required = Required;
CustomInput.RequiredWithTooltip = RequiredWithTooltip;

export type CustomInputProps = TextFieldProps & {
  tooltip: string;
}

export default CustomInput;
