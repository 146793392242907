import React, { useCallback, useContext, useEffect, useState } from "react";
import { DayPickerRangeController } from "react-dates";
import CustomizableCalendarDay from "react-dates/lib/components/CustomizableCalendarDay.js";
import moment, { Moment } from "moment";
import { NavigateBeforeRounded, NavigateNextRounded } from "@material-ui/icons";
import { START_DATE } from "./DatePicker.constants";
import { customDayStyles } from "./DatePicker.styles";
import {
  ClearIcon,
  DatePickerContainer,
  DatePickerFieldSet,
  DatePickerInput,
  DatePickerWrapper,
  DateRange,
  DateRangeIcon,
  Placeholder,
  StyledPopper,
} from "./DatePicker.styledComponents";
import { isNull, isUndefined } from "underscore";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import "./_datepicker-override.scss";

type FocusType = "startDate" | "endDate" | null;

export interface IDatePickerProps {
  onDateSelected?: (startDate: Date | null, endDate: Date | null) => void;
  onDateReset?: () => void;
  size?: "medium" | "small";
  placeholder?: string;
  resetRef?: React.MutableRefObject<() => void>;
}

type DatePickerState = {
  startDate: Moment | null;
  endDate: Moment | null;
};

const initialState: DatePickerState = {
  startDate: null,
  endDate: null,
};

const DatePicker: React.FC<IDatePickerProps> = ({
  onDateSelected,
  size,
  placeholder,
  onDateReset,
  resetRef,
}) => {
  const [dateRange, setDateRange] = useState<DatePickerState>(initialState);
  const [focus, setFocus] = useState<FocusType>(START_DATE);
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

  const onDateSelectedAction = useCallback(() => {
    if (isUndefined(onDateSelected)) {
      return;
    }

    const { startDateJsDate, endDateJsDate } = getStartAndEndDateJsDate();

    onDateSelected(startDateJsDate, endDateJsDate);
  }, [dateRange.startDate, dateRange.endDate]);

  useEffect(() => {
    onDateSelectedAction();
  }, [onDateSelectedAction]);

  useEffect(() => {
    if (isUndefined(resetRef)) {
      return;
    }

    resetRef.current = () => setDateRange(initialState);
  }, []);

  const getStartAndEndDateJsDate = (): {
    startDateJsDate: Date | null;
    endDateJsDate: Date | null;
  } => {
    if (isUndefined(dateRange.startDate)) {
      setDateRange({
        ...dateRange,
        startDate: null,
      });
    }

    if (isUndefined(dateRange.endDate)) {
      setDateRange({
        ...dateRange,
        endDate: null,
      });
    }

    return {
      startDateJsDate: !isNull(dateRange.startDate) ? dateRange.startDate.toDate() : null,
      endDateJsDate: !isNull(dateRange.endDate) ? dateRange.endDate.toDate() : null,
    };
  };

  const onDatesChange = ({ startDate, endDate }: DatePickerState) =>
    setDateRange({
      ...dateRange,
      startDate: startDate,
      endDate: endDate,
    });

  const onFocusChange = (focusedInput: FocusType) => {
    const focus = !focusedInput ? START_DATE : focusedInput;

    setFocus(focus);
  };

  const clearDateSelection = (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    event.stopPropagation();
    setDateRange(initialState);

    if (!isUndefined(onDateReset)) {
      onDateReset();
    }
  };

  const { startDate, endDate } = dateRange;
  const startDateString = startDate && moment(startDate).format("DD/MM/YYYY");
  const endDateString = endDate && moment(endDate).format("DD/MM/YYYY");

  const DateRangeValue = () => (
    <>
      {startDate && endDate ? (
        <DateRange>{`${startDateString} - ${endDateString}`}</DateRange>
      ) : (
        <Placeholder>{placeholder}</Placeholder>
      )}
    </>
  );

  const InputIcon = ({ size }: IDatePickerProps) => (
    <>
      {dateRange.startDate && dateRange.endDate ? (
        <ClearIcon size={size} onClick={clearDateSelection} />
      ) : (
        <DateRangeIcon size={size} />
      )}
    </>
  );

  const onInputClicked = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) =>
    setAnchorEl(e.currentTarget);
  const onOutsideClicked = () => setAnchorEl(null);

  const open = Boolean(anchorEl);

  return (
    <DatePickerContainer>
      <DatePickerFieldSet tabIndex={0} onClick={onInputClicked}>
        <DatePickerInput size={size}>
          <DateRangeValue />
        </DatePickerInput>
        <InputIcon size={size} />
      </DatePickerFieldSet>

      <StyledPopper open={open} anchorEl={anchorEl} placement="bottom-start">
        <DatePickerWrapper size={size} className="fmp-date-picker">
          <DayPickerRangeController
            onFocusChange={onFocusChange}
            onDatesChange={onDatesChange}
            focusedInput={focus}
            startDate={startDate}
            endDate={endDate}
            keepOpenOnDateSelect={false}
            enableOutsideDays={true}
            hideKeyboardShortcutsPanel={true}
            numberOfMonths={1}
            minimumNights={0}
            initialVisibleMonth={null}
            onOutsideClick={onOutsideClicked}
            renderCalendarDay={(props) => (
              <CustomizableCalendarDay {...props} {...customDayStyles} />
            )}
            navNext={<NavigateNextRounded />}
            navPrev={<NavigateBeforeRounded />}
          />
        </DatePickerWrapper>
      </StyledPopper>
    </DatePickerContainer>
  );
};

export default DatePicker;
