import React, { useEffect, useState } from "react";
import { Drawer, List } from "@material-ui/core";
import FleetManagerSidebar from "./FleetManagerSidebar/FleetManagerSidebar";
import "./_sidebar.scss";

interface IProps {
  openSidebar: boolean;
  onCloseSidebar: () => void;
}

const SideBar = ({ openSidebar, onCloseSidebar }: IProps) => {
  const [width, setWidth] = useState(window.innerWidth);
  const [mobile, setMobile] = useState(false);

  useEffect(() => {
    if (width < 600) {
      setMobile(true);
    } else {
      setMobile(false);
    }
  }, [width]);

  useEffect(() => {
    window.onresize = () => {
      setWidth(window.innerWidth);
    };
  }, []);

  return (
    <Drawer
      variant={mobile ? "temporary" : "permanent"}
      open={mobile && openSidebar}
      onClose={onCloseSidebar}
      anchor="left"
      classes={{
        root: "nav-sidebar",
        paper: "nav-paper",
      }}
    >
      <List component="nav" classes={{ root: "sidebar-menu" }}>
        <FleetManagerSidebar />
      </List>
    </Drawer>
  );
};

export default SideBar;
