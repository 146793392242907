export const variables = {
  colors: {
    lime: {
      hex: "#8bb611",
      rgb: "139, 182, 17",
    },
    black: "#1c1c1c",
    white: "#fff",
    gray: {
      hex: "#bbb",
      rgb: "187, 187, 187",
    },
    darkGray: "#5b5d61",
    darkGraySecondary: "#777777",
    red: {
      hex: "#D90327",
      rgb: "217, 3, 39",
    },
    yellow: {
      hex: "#E88F00",
      rgb: "232, 143, 0"
    },
    darkRed: "#ac001d",
    veryLightGray: "#ededed",
    lightGray: "#CECECE",
    veryLightGraySecondary: "#ececec",
    silver: "#bcbcbc",
    darkerLime: "#799f0f",
    mostlyWhite: "#f9f9f9",
    mostlyBlack: "#23262c",
    lightGrayishBlue: {
      hex: "#DDDEE2",
      rgb: "221, 222, 226",
    },
    grayishBlue: {
      hex: "#cbcbd1",
      rgb: "203, 203, 209",
    },
    brightOrange: {
      hex: "#EF9A26",
      rgb: "239, 154, 38",
    },
    darkModerateCyan: {
      rgb: "66, 143, 164",
      hex: "#428FA4",
    },
  },
  typography: {
    fontSizeBase: "18px",
    fontSizeLarge: "20px",
    fontSizeLarger: "24px",
    fontSizeExtraLarge: "28px",
    fontSizeSmaller: "16px",
    fontSizeSmall: "14px",
    fontSizeExtraSmall: "12px",
  },
  whiteSpaces: {
    ["2xs"]: "8px",
    xs: "18px",
    sm: "36px",
    md: "45px",
    lg: "54px",
    xl: "90px",
  },
  breakpoints: {
    screenXs: "0px",
    screenSm: "600px",
    screenMd: "960px",
    screenLg: "1280px",
    screenXl: "1920px",
  },
  shared: {
    boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.1)",
  },
};
