const baseUrl = "/api/environment";

type EnvironmentApiType = {
  getEnvironmentUrl: () => string;
  getVersionUrl: () => string;
  getPortalStatusUrl: () => string;
  getFeatureFlagsAndDynamicConfigurationUrl: (loggedInCrmId: string) => string;
  getAllowImpersonationUrl: (loggedInCrmId: string) => string;
};

const getEnvironmentUrl = () => `${baseUrl}/getenvironment`;

const getVersionUrl = () => `${baseUrl}/getversion`;

const getPortalStatusUrl = () => `${baseUrl}/getportalstatus`;

// General feature flag and configuration call, do not clean this up when removing the FF
const getFeatureFlagsAndDynamicConfigurationUrl = (loggedInCrmId: string) => `${baseUrl}/GetFeatureFlagsAndDynamicConfiguration/${loggedInCrmId}`;

const getAllowImpersonationUrl = (loggedInCrmId: string) =>
  `${baseUrl}/getallowimpersonation/${loggedInCrmId}`;

export default {
  getEnvironmentUrl,
  getVersionUrl,
  getPortalStatusUrl,
  getFeatureFlagsAndDynamicConfigurationUrl,
  getAllowImpersonationUrl,
} as EnvironmentApiType;
