import React, { useState } from "react";
import NavigationBar from "../../NavigationBar/NavigationBar";
import SideBar from "../../SideBar/SideBar";
import { Container } from "@material-ui/core";
import "./_flex-layout.scss";

interface IProps {
  children: React.ReactNode;
}

const FlexLayout = ({ children }: IProps) => {
  const [openSidebar, setShowSidebar] = useState(false);

  const toggleSidebar = () => setShowSidebar((s) => (s = !s));

  return (
    <React.Fragment>
      <section className="flex-header">
        <NavigationBar
          onToggleSidebar={toggleSidebar}
          sidebarOpened={openSidebar}
        />
      </section>
      <section className="flex-main">
        <nav className="flex-sidebar">
          <SideBar openSidebar={openSidebar} onCloseSidebar={toggleSidebar} />
        </nav>
        <Container classes={{ root: "container" }} className="relative">
          <>{children}</>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default FlexLayout;
