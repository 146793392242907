import { Card, Grid, makeStyles } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import styled from "styled-components";
import { variables } from "../../theme/variables";

const { colors, typography, breakpoints } = variables;

interface InlineProps {
  justifyContent: string;
}

export const StyledCard = styled(Card)`
  @media screen and (min-width: ${breakpoints.screenLg}) {
    width: 308px;
  }

  @media screen and (max-width: ${breakpoints.screenMd}) {
    width: 100%;
  }
`;

export const Inline = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: ${(props: InlineProps) => props.justifyContent};
  width: 100%;
`;

export const StyledSkeleton = styled(Skeleton)`
  margin: 0 0 0 5px;
`;

export const LocationCapacity = styled.div`
  overflow: hidden;
  width: auto;
`;

export const NoPaddingCol = styled(Grid)`
  padding-bottom: 0;
`;

export const useStyles = makeStyles({
  root: {
    border: `1px solid ${colors.veryLightGray}`,
    borderRadius: "6px",
    boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.1)",
    margin: "0.25rem 0.5rem 0.25rem 0",
    transition: "all .2s ease-in-out",

    "&:hover": {
      cursor: "pointer",
    },

    "&.active": {
      margin: "0 0.5rem 0.5rem 0",
      boxShadow: "0px 5px 6px rgba(0, 0, 0, 0.1)",
    },
  },
  media: {
    height: 115,
    width: "100%",
  },
  gridRoot: {
    paddingBottom: 0,
  },
  colRoot: {
    color: colors.darkGraySecondary,
    fontSize: typography.fontSizeSmaller,
  },
  cardContentRoot: {
    "&:last-child": {
      padding: "20px 18px",
    },
  },
});
