import { isEmpty, isNull, isUndefined } from "underscore";
import {
  DataTableContextFilters,
  DateFilter,
} from "../components/DataTable/DataTableContext/DataTableContextProvider";
import { FilterType } from "../components/DataTable/DataTableFilters/DataTableFilters";
import { FmpFilters } from "../constants/filtering.constants";
import { Paging } from "../models/pagination/Paging";
import moment from "moment";

const isEmptyOrUndefined = (value: any): boolean => {
  if (isEmpty(value)) {
    return true;
  }

  if (isUndefined(value)) {
    return true;
  }

  return false;
};

type QueryParam = {
  buildQueryParam: (
    model: DataTableContextFilters[],
    pagination: Paging
  ) => URLSearchParams;
};

const queryParam: QueryParam = (() => {
  const startDateKey = "startDate";
  const endDateKey = "endDate";

  const getStringDate = (date: Date): string => {
    const stringDateFormat = "YYYY/MM/DD";

    return moment(date).format(stringDateFormat);
  };

  const enrichParamWithFilters = (
    filters: DataTableContextFilters[],
    param: URLSearchParams
  ): void => {
    filters?.forEach((f) => {
      if (isEmptyOrUndefined(f.value)) {
        return;
      }

      if (f.type === FilterType.QueryParam && !isEmptyOrUndefined(f.value)) {
        param.append(f.key, f.value as string);
      }

      if (f.type === FilterType.Multiselect && !isEmptyOrUndefined(f.value)) {
        (f.value as string[] | number[]).forEach((value: any) => {
          param.append(f.key, value as string);
        });
      }

      if (f.type === FilterType.Text && !isEmptyOrUndefined(f.value)) {
        param.append(f.key, f.value as string);
      }

      if (f.type === FilterType.Date && !isEmptyOrUndefined(f.value)) {
        const { startDate, endDate, customKeys } = f.value as DateFilter;

        if (isNull(startDate) || isNull(endDate)) {
          return;
        }

        param.append(
          customKeys && customKeys.startKey
            ? customKeys.startKey
            : startDateKey,
          getStringDate(startDate)
        );
        param.append(
          customKeys && customKeys.endKey ? customKeys.endKey : endDateKey,
          getStringDate(endDate)
        );
      }
    });
  };

  const buildQueryParam = (
    model: DataTableContextFilters[],
    pagination: Paging
  ): URLSearchParams => {
    const param: URLSearchParams = new URLSearchParams();

    param.append(FmpFilters.pageSize, pagination.pageSize.toString());
    param.append(FmpFilters.pageNumber, pagination.pageNumber.toString());

    enrichParamWithFilters(model, param);

    return param;
  };

  return {
    buildQueryParam,
  };
})();

export default queryParam;
