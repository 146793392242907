const KEY_SEPARATOR = "/";

export function parseAssignedParkingRowKey(key: string): {
  vehicleAssignmentId: string;
  pmcId: number;
} {
  const vehicleAssignmentDetails = key.split(KEY_SEPARATOR);

  return {
    vehicleAssignmentId: vehicleAssignmentDetails[0],
    pmcId: parseInt(vehicleAssignmentDetails[1]),
  };
}

export type DVARowKey = {
  vehicleAssignmentId: string;
  pmcIds: number[];
};

export function parseDVARowKey(s: string): DVARowKey {
  const [vehicleAssignmentId, ...pmcStrings] = s.split(KEY_SEPARATOR);
  const pmcIds = pmcStrings.map(x => parseInt(x));
  return { vehicleAssignmentId, pmcIds };
}

export function stringifyDVARowKey(key: DVARowKey): string {
  return [key.vehicleAssignmentId, ...key.pmcIds].join(KEY_SEPARATOR);
}

export function parseVehicleAssignmentId(key: string): string {
  const vehicleAssignmentDetails = key.split(KEY_SEPARATOR);

  return vehicleAssignmentDetails[0];
}
