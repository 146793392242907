import { ISelectOption } from "../components/DataTable/DataTableFilters/DataTableFilterTypes/SelectFilter";
import { ParkingProductModel } from "../models/ParkingProductModel";
import { VisitorBookingProductConfiguration } from "../models/visitor-booking";
import { ParkingLocationCountry } from "../models/visitor-booking/ParkingLocationCountry";

export const getParkingProductOptions = (
  parkingProducts: ParkingProductModel[] 
): ISelectOption[] => {
  const productOptions: ISelectOption[] = [];

  parkingProducts?.forEach(product => {
    productOptions.push({
      label: `${product.pmcName} - ${product.location}`,
      value: product.pmcId,
    })
  });

  return productOptions;
};

export const getProductConfigurationOptions = (
  productConfigurations : VisitorBookingProductConfiguration[]
): ISelectOption[] => {
  const productOptions: ISelectOption[] = [];

  productConfigurations?.forEach(product => {
    productOptions.push({
      label: product.name,
      value: product.id,
    })
  });

  return productOptions;
};

export const getFacilityOptions = (
  parkingLocations: ParkingLocationCountry[] //Data<ParkingLocationCountry[]>
): ISelectOption[] => {
  const facilityOptions: ISelectOption[] = [];

  parkingLocations?.forEach((countryLoc) => {
    countryLoc?.cities?.forEach((city) => {
      city.physicalZones?.map((location) =>
        facilityOptions.push({
          label: location.name,
          value: location.physicalZoneId,
        })
      );
    });
  });

  return facilityOptions;
};
