import React from "react";
import { InputLabel, InputLabelProps } from "@material-ui/core";
import { variables } from "../../theme/variables";
import styled from "styled-components";

const { colors, typography } = variables;

interface IRequiredLabelProps extends InputLabelProps {
  fontSize?: string;
}

const RequiredSpan = styled.span`
  font-size: ${(props: IRequiredLabelProps) => props.fontSize || typography.fontSizeBase};
  color: ${colors.darkRed};
`;

const StyledInputLabel = styled(InputLabel)`
  font-size: ${(props: IRequiredLabelProps) => props.fontSize || typography.fontSizeBase};
`;

const RequiredInputLabel = (props: IRequiredLabelProps) => (
  <StyledInputLabel {...props}>
    {props.children}
    <RequiredSpan {...props}>*</RequiredSpan>
  </StyledInputLabel>
);

export default RequiredInputLabel;
