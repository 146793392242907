import React from "react";
import styled from "styled-components";
import { variables } from "../../theme/variables";
import { useTranslation } from "react-i18next";
import { Fonts } from "../../typography";

const { colors, typography } = variables;
const { FuturaD } = Fonts;

export enum Statuses {
  Pending = 0,
  Rejected = 1,
  Accepted = 2,
  Revoked = 3,
}

export type Status = 0 | 1 | 2 | 3;

interface IDelegeeStatusProps {
  type?: Status;
}

interface IStatus {
  variant: string;
}

const StyledStatus = styled.div`
  background-color: ${({ variant }: IStatus) => `rgba(${variant}, .1)`};
  color: ${({ variant }: IStatus) => `rgb(${variant})`};
  font-size: ${typography.fontSizeSmaller};
  font-family: ${FuturaD.fontFamily};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3px;
  border-radius: 500px;
`;

const DelegeeStatus = (props: IDelegeeStatusProps) => {
  const { type } = props;

  if (type === Statuses.Accepted) {
    return <Active />;
  }
  if (type === Statuses.Pending) {
    return <Pending />;
  }

  return null;
};

const Active = () => {
  const { t } = useTranslation("globals");

  return <StyledStatus variant={colors.lime.rgb}>{t("active")}</StyledStatus>;
};

const Pending = () => {
  const { t } = useTranslation("globals");

  return (
    <StyledStatus variant={colors.brightOrange.rgb}>
      {t("pending")}
    </StyledStatus>
  );
};

export default DelegeeStatus;
