import { visitorBookingContextTypes } from "../actions/visitorBooking.types";

export type VisitorBookingRowValues = {
  customerName: string;
  referenceNumber: string;
};

export type VisitorBookingContextDataGridState = {
  sortBy?: string;
  orderDesc?: boolean;
  showCancelBookingWarning: boolean;
  selectedRowValues?: VisitorBookingRowValues;
};

export type VisitorBookingContextAction = {
  type: string;
  payload?: string | VisitorBookingRowValues;
};

export const initialState: VisitorBookingContextDataGridState = {
  sortBy: "creationDate",
  orderDesc: true,
  showCancelBookingWarning: false,
};

export default (
  state: VisitorBookingContextDataGridState = initialState,
  action: VisitorBookingContextAction
): VisitorBookingContextDataGridState => {
  switch (action.type) {
    case visitorBookingContextTypes.SET_SORTING:
      return {
        ...state,
        sortBy: action.payload as string,
        orderDesc: state.orderDesc != null ? !state.orderDesc : false,
      };
    case visitorBookingContextTypes.TOGGLE_SHOW_CANCEL_VISITORBOOKING:
      return {
        ...state,
        showCancelBookingWarning: !state.showCancelBookingWarning,
      };
    case visitorBookingContextTypes.SET_SELECTED_ROW_VALUES:
      return {
        ...state,
        selectedRowValues: action.payload as VisitorBookingRowValues,
      };
  }

  return state;
};
