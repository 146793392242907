import React from "react";
import { SvgIcon } from "@material-ui/core";
import styled from "styled-components";
import { variables } from "../../theme/variables";

const { colors } = variables;

const Svg = styled(SvgIcon)`
  color: ${colors.red.hex};
`;

const DeleteIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" width="12.25" height="14" viewBox="0 0 12.25 14">
    <path
      id="Path_15761"
      data-name="Path 15761"
      d="M7.328-.875h.656A.328.328,0,0,0,8.312-1.2V-7.109a.328.328,0,0,0-.328-.328H7.328A.328.328,0,0,0,7-7.109V-1.2A.328.328,0,0,0,7.328-.875Zm4.484-9.187H9.559l-.93-1.55A1.455,1.455,0,0,0,7.5-12.25H4.747a1.455,1.455,0,0,0-1.125.637l-.93,1.55H.437A.438.438,0,0,0,0-9.625v.437a.438.438,0,0,0,.437.437H.875V.437A1.313,1.313,0,0,0,2.187,1.75h7.875A1.313,1.313,0,0,0,11.375.437V-8.75h.437a.438.438,0,0,0,.437-.437v-.437A.438.438,0,0,0,11.812-10.062Zm-7.114-.8a.182.182,0,0,1,.141-.08H7.41a.182.182,0,0,1,.141.08l.477.8H4.222ZM10.062.437H2.187V-8.75h7.875ZM4.266-.875h.656A.328.328,0,0,0,5.25-1.2V-7.109a.328.328,0,0,0-.328-.328H4.266a.328.328,0,0,0-.328.328V-1.2A.328.328,0,0,0,4.266-.875Z"
      transform="translate(0 12.25)"
      fill="#d90327"
    />
  </Svg>
);

export default DeleteIcon;
