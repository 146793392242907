import React from "react";
import { Grid, Box } from "@material-ui/core";
import IChildren from "../../../interfaces/IChildren";

interface IDataColProps extends IChildren {
  xs: any;
}

interface IDataRowProps extends IChildren {
  disabled?: boolean;
}

const Data = () => {};

const Row = ({ disabled, children }: IDataRowProps) => (
  <Grid
    container
    direction="row"
    alignItems="center"
    justifyContent="space-between"
    classes={{ root: `data-row ${disabled && "disabled"}` }}
  >
    {disabled && <Box component="div" className="disabled-row" />}
    {children}
  </Grid>
);

const Col = ({ xs, children }: IDataColProps) => (
  <Grid container item direction="column" xs={xs} classes={{ root: "data-col" }}>
    {children}
  </Grid>
);

Data.Row = Row;
Data.Col = Col;

export default Data;
