import { Theme, Tooltip, withStyles } from "@material-ui/core";
import React from "react";
import { ParkingProductModel } from "../../../models/ParkingProductModel";
import { variables } from "../../../theme/variables";
import "./_productsTooltip.scss";
import { useGetOccupiedParkingRightsCount } from "../../../containers/FleetManager/MyProducts/ParkingRightsDataTable/common";

const { colors, typography } = variables;

const HtmlTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: colors.white,
    color: colors.black,
    fontSize: typography.fontSizeBase,
    border: `1px solid ${colors.veryLightGray}`,
    borderRadius: 6,
    boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.1)",
    maxWidth: "none",
  },
}))(Tooltip);

interface ProductsTooltipProps {
  children: React.ReactElement<any, any>;
  parkingProducts: ParkingProductModel[];
  displayLocation?: boolean;
  displayAmount?: boolean;
}

const ProductsTooltip = ({
  children,
  parkingProducts,
  displayLocation,
  displayAmount,
}: ProductsTooltipProps) => {
  const getTotalOccupiedParkingRights = useGetOccupiedParkingRightsCount();

  return (
    <HtmlTooltip
      title={
        <div className="products-container">
          <ul>
            {parkingProducts?.map((pp: ParkingProductModel, index: number) => (
              <li key={`${pp.pmcName}_${index}`}>
                {displayAmount && (
                  <div className="amount">
                    {getTotalOccupiedParkingRights(pp)}/{pp.totalParkingRights}
                  </div>
                )}
                <span className="product">
                  {pp.pmcName}
                  {displayLocation && ` - ${pp.location}`}
                </span>
              </li>
            ))}
          </ul>
        </div>
      }
    >
      {children}
    </HtmlTooltip>
  );
};

ProductsTooltip.defaultProps = {
  children: null,
  products: [],
  displayLocation: false,
  displayAmount: false,
};

export default ProductsTooltip;
