import { isEmpty, partition } from "underscore";
import { CountriesNumberPlates } from "../../../../../../../../../lib/countriesNumberPlates";
import { VisitorDetailValidationModel } from "../../../../../../../../../models/visitor-booking/VisitorDetailModel";
import emailValidator from "../../../../../../../../../validators/email.validator";
import numberPlateValidator from "../../../../../../../../../validators/numberplate.validator";
import * as XLSX from "xlsx";

const sheetName = "Sheet1";
const visitorName = "Visitor Name";
const visitorEmail = "Visitor e-mail";
const countryCode = "Vehicle registration code";
const numberPlate = "Number plate";

const isLicensePlateValid = (countryCode?: string, licensePlate?: string) => {
  if (!licensePlate) {
    return true;
  }

  return countryCode === "D"
    ? numberPlateValidator.isValidGermanNumberPlate(licensePlate.toString())
    : numberPlateValidator.isValidNumberPlateWithoutDash(licensePlate.toString());
};

const isCountryCodeValid = (
  countriesNumberPlates: CountriesNumberPlates,
  countryCode?: string,
  licensePlate?: string
) =>
  (!countryCode && !licensePlate) ||
  countriesNumberPlates.isValidCountryCode(countryCode?.toString());

const isVisitorValid = (visitor: VisitorDetailValidationModel) =>
  visitor.name.isValid &&
  visitor.emailAddress.isValid &&
  visitor.numberPlateCountryCode.isValid &&
  visitor.numberPlateNumber.isValid;

const isVisitorRowEmpty = (visitor: VisitorDetailValidationModel) =>
  isEmpty(visitor.name.value) &&
  isEmpty(visitor.emailAddress.value) &&
  isEmpty(visitor.numberPlateCountryCode.value) &&
  isEmpty(visitor.numberPlateNumber.value);

export const parseUploadedData = (e: ProgressEvent<FileReader>, maxRecordsNumber?: number) => {
  const visitorsToImport: VisitorDetailValidationModel[] = [];

  const data = e.target?.result;
  const wb = XLSX.read(data, { type: "binary" });

  if (wb.SheetNames?.includes(sheetName)) {
    const rows: any[] = XLSX.utils.sheet_to_json(wb.Sheets[sheetName]);

    const hasRows = rows.length > 0;
    if (!hasRows) {
      return "error";
    }

    if (rows.length > Number(maxRecordsNumber)) {
      return "maxRecordsError";
    }

    const countriesNumberPlates = new CountriesNumberPlates();

    rows.forEach((row) => {
      let visitorCountryCode = row[countryCode];
      let visitorNumberPlate = row[numberPlate]?.toString().replaceAll(" ", "");
      if (visitorCountryCode !== "D") {
        visitorNumberPlate = visitorNumberPlate?.replaceAll("-", "");
      }

      if (isEmpty(visitorNumberPlate)) {
        visitorCountryCode = "";
      }

      const visitor: VisitorDetailValidationModel = {
        name: {
          value: row[visitorName],
          isValid: true,
        },
        emailAddress: {
          value: row[visitorEmail],
          isValid: row[visitorEmail] && emailValidator.isValidEmail(row[visitorEmail].toString()),
        },
        numberPlateCountryCode: {
          value: visitorCountryCode,
          isValid: isCountryCodeValid(
            countriesNumberPlates,
            visitorCountryCode,
            visitorNumberPlate
          ),
        },
        numberPlateNumber: {
          value: visitorNumberPlate,
          isValid: isLicensePlateValid(visitorCountryCode, visitorNumberPlate),
        },
      };

      if (!isVisitorRowEmpty(visitor)) {
        visitorsToImport.push(visitor);
      }
    });

    return partition(visitorsToImport, isVisitorValid);
  }

  return "error";
};
