import { aiTypes } from "../action-types/ai.types";
import { AiActionType } from "../actions/ai.actions";
import { success } from "../helpers/action.helper";

export type AiState = {
  instrumentationKey: string;
};

const initialState: AiState = {
  instrumentationKey: "",
};

export default (state: AiState = initialState, action: AiActionType): AiState => {
  switch (action.type) {
    case success(aiTypes.GET_INSTRUMENTATIONKEY):
      return {
        ...state,
        instrumentationKey: action.payload,
      };
    default:
      return state;
  }
};
