import { OidcConfigurationResponseModel } from "../../models/OidcConfigurationResponseModel";
import { oidcTypes } from "../action-types/oidc.types";
import { OidcActionType } from "../actions/oidc.actions";
import { success } from "../helpers/action.helper";

export type OidcState = {
  oidcConfig: OidcConfigurationResponseModel;
};

const initialState: OidcState = {
  oidcConfig: {
    serverUrl: "",
    clientId: "",
    clientSecret: "",
  },
};

export default (state: OidcState = initialState, action: OidcActionType): OidcState => {
  switch (action.type) {
    case success(oidcTypes.GET_CONFIGURATION):
      return {
        ...state,
        oidcConfig: action.payload,
      };
    default:
      return state;
  }
};
