import React, { useEffect, useRef, useState } from "react";
import { findWhere, isNull, isUndefined } from "underscore";
import { NoPaddingGrid } from "../../../../containers/FleetManager/FleetManagerDataTable/StyledComponents";
import {
  DataTableContextFilters,
  DateFilter as DateFilterType,
  useDataTableContext,
} from "../../DataTableContext/DataTableContextProvider";
import { FilterType, IFilter } from "../DataTableFilters";
import DatePicker from "./DatePicker/DatePicker";

interface IDateFilter {
  filter: IFilter;
  entity?: DataTableContextFilters;
}

const DateFilter: React.FC<IDateFilter> = ({ filter, entity }) => {
  const { state, setFilter, resetFilter } = useDataTableContext();

  const resetInternalRef = useRef<() => void>(() => {});

  useEffect(() => {
    if (!isUndefined(state.filters)) {
      const found = findWhere(state.filters as DataTableContextFilters[], {
        key: entity?.key as string,
      });

      const { startDate, endDate } = found?.value as DateFilterType;
      if (isNull(startDate) && isNull(endDate)) {
        resetInternalRef.current();
      }
    }
  }, [state.filters]);

  const setStartEndDate = (startDate: Date | null, endDate: Date | null) => {
    if (startDate && !endDate) {
      return;
    }

    setFilter(entity?.key as string, entity?.type as FilterType, {
      startDate,
      endDate,
      customKeys: filter.customDateKeys,
    });
  };

  return (
    <NoPaddingGrid
      item
      container
      direction="column"
      justifyContent="center"
      xs={2}
    >
      <DatePicker
        resetRef={resetInternalRef}
        size="small"
        placeholder={filter.placeholder}
        onDateSelected={setStartEndDate}
        onDateReset={() => resetFilter(entity?.key as string)}
      />
    </NoPaddingGrid>
  );
};

export default DateFilter;
