import React from "react";
import styled from "styled-components";
import { variables } from "../../theme/variables";
import { useTranslation } from "react-i18next";
import { Fonts } from "../../typography";
import { VisitorBookingStatus } from "../../models/visitor-booking";

const { colors, typography } = variables;
const { FuturaD } = Fonts;

interface IStatus {
  variant: string;
}

interface IVisitorBookingStatusProps {
  type?: VisitorBookingStatus;
}

const StyledStatus = styled.div`
  background-color: ${({ variant }: IStatus) => `rgba(${variant}, .1)`};
  color: ${({ variant }: IStatus) => `rgb(${variant})`};
  font-size: ${typography.fontSizeSmaller};
  font-family: ${FuturaD.fontFamily};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3px 15px;
  border-radius: 500px;
`;

const VisitorStatus = (props: IVisitorBookingStatusProps) => {
  const { type } = props;

  if (type === VisitorBookingStatus.Draft) {
    return <Draft />;
  }
  if (type === VisitorBookingStatus.Booked) {
    return <Booked />;
  }
  if (type === VisitorBookingStatus.Cancelled) {
    return <Cancelled />;
  }

  return null;
};

const Draft = () => {
  const { t } = useTranslation("globals");

  return (
    <StyledStatus variant={colors.yellow.rgb}>
      {t("draft")}
    </StyledStatus>
  );
};

const Booked = () => {
  const { t } = useTranslation("globals");

  return <StyledStatus variant={colors.darkModerateCyan.rgb}>{t("booked")}</StyledStatus>;
};

const Cancelled = () => {
  const { t } = useTranslation("globals");

  return (
    <StyledStatus variant={colors.red.rgb}>
      {t("cancelled")}
    </StyledStatus>
  );
};

export default VisitorStatus;