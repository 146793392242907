import React, { memo, useEffect, useRef, useState } from "react";
import { NoPaddingBottomGrid } from "../DataTable/DataTableStyledComponents";
import CircleImage from "../../ui/CircleImage/CircleImage";
import XlsFileAdd from "../../assets/images/xls-file-add.svg";
import XlsFileDropping from "../../assets/images/xls-file-dropping.svg";
import XlsFileEmpty from "../../assets/images/xls-file-empty.svg";
import XlsFileChecked from "../../assets/images/xls-file-checked.svg";
import { variables } from "../../theme/variables";
import Heading from "../../ui/Heading/Heading";
import ImgBg from "../../ui/ImgBg/ImgBg.";
import { contains, first } from "underscore";
import { useTranslation } from "react-i18next";
import Spacer from "../../ui/Spacer/Spacer";
import CustomButton from "../../ui/CustomButton/CustomButton";
import { ReactComponent as RefreshIcon } from "../../assets/images/refresh-icon.svg";
import "./_dragAndDrop.scss";
import DownloadDelegeeFile from "../DownloadFile/DownloadDelegeeFile";
import useAppContext from "../../context/hooks/useAppContext";
import { GeneralValidationError } from "../../models/bulkImport/ValidateDelegeesResponseModel";
import { DelegeeFileType } from "../DownloadFile/DelegeeFileType.enum";

const { typography } = variables;

const DragAndDrop: React.FC<DragAndDropProps> = (props) => {
  const { t } = useTranslation(["dragAndDrop"]);
  const [state, setState] = useState<DndState>(props.state);
  const { appState } = useAppContext();

  const fileInput = useRef<HTMLInputElement>(null);
  const maxRecordsNumberFallback = 200;
  const maxFileSizeInMBFallBack = 2;
  const bytesPerMB = 1000000;

  useEffect(() => {
    if (props.state !== state) {
      setState(props.state);
    }
    if (props.state === "default" && fileInput.current) {
      fileInput.current.files = null;
      fileInput.current.value = "";
    }
  }, [props.state]);

  const handleFileSelected = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isValid = isValidDrop(e.target.files as FileList);

    if (props.onFileDropped) {
      props.onFileDropped(isValid);
    }

    if (!isValid) {
      setState("error");
      return;
    }

    const file = first(e.target.files as FileList) as File;
    setState("processing");

    props.onFileUploaded(file);

    if (fileInput.current) {
      fileInput.current.value = "";
    }
  };

  const handleDragOver = (e: React.DragEvent<HTMLLabelElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragEnter = (e: React.DragEvent<HTMLLabelElement>) => {
    e.preventDefault();
    e.stopPropagation();

    if (state === "processing") return;

    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
      setState("dragging");
    }
  };

  const handleDragLeave = (e: React.DragEvent<HTMLLabelElement>) => {
    e.preventDefault();
    e.stopPropagation();

    setState("default");
  };

  const handleDrop = (e: React.DragEvent<HTMLLabelElement>) => {
    e.preventDefault();
    e.stopPropagation();

    if (state === "processing") return;

    const isValid = isValidDrop(e.dataTransfer.files);
    if (props.onFileDropped) {
      props.onFileDropped(isValid);
    }

    if (!isValid) {
      setState("error");
      return;
    }

    const file = first(e.dataTransfer.files) as File;
    setState("processing");

    props.onFileUploaded(file);
  };

  const isValidDrop = (files: FileList): boolean => {
    if (files.length > 1) {
      return false;
    }

    const file = first(files);
    if (!isSupportedFileType((file as File)?.type)) {
      return false;
    }

    if (file!.size > (props.maxFileSizeInMB ?? maxFileSizeInMBFallBack) * bytesPerMB) {
      return false;
    }

    return true;
  };

  const isSupportedFileType = (fileType: string): boolean =>
    contains(props.supportedFileTypes, fileType);

  const getImgWrapperSrc = () => {
    switch (state) {
      case "error":
        return XlsFileEmpty;
      case "success":
        return XlsFileChecked;
      default:
        return XlsFileAdd;
    }
  };

  const hasValidateDelegeeErrors = () => {
    return (
      props.error === "invalid" &&
      (props.invalidDelegeesCount !== undefined ||
        props.validateDelegeesErrorCode !== undefined ||
        props.generalValidationErrors !== undefined)
    );
  };

  const getErrorHeading = () => {
    if (props.error === "empty") {
      return t("emptyFileDetected");
    }
    if (props.error === "maxRecords") {
      return t("tooManyRecords");
    }
    return hasValidateDelegeeErrors() ? t("validatedelegeeError") : t("dragAndDropHere");
  };

  const getErrorContent = () => {
    if (props.error === "empty") {
      return (
        <div className="d-flex flex-col align-center">
          <Spacer size="2xs" />
          <span className="text-dark-gray-2nd">{t("emptyFileUploaded")}</span>
          <Spacer size="2xs" />
          <div className="w-half">
            <CustomButton.Light
              onClick={() => fileInput.current?.click()}
              startIcon={<RefreshIcon />}
              text={t("globals:tryAgain")}
            />
          </div>
        </div>
      );
    }
    if (props.error === "maxRecords") {
      return t("tooManyRecordsContent", {
        maxRecords: props.maxRecordsNumber ?? maxRecordsNumberFallback,
      });
    }
    if (hasValidateDelegeeErrors()) {
      const parkingRightsExceededErrors = props.generalValidationErrors?.filter(
        (e) => e.errorCode == "NotEnoughParkingRights"
      );
      const noDelegeesError = props.generalValidationErrors?.filter(
        (e) => e.errorCode == "NoDelegees"
      )[0];
      const fleetManagerInfoMissingError = props.generalValidationErrors?.filter(
        (e) => e.errorCode == "FleetManagerInfoMissing"
      )[0];
      return (
        <>
          {parkingRightsExceededErrors?.map((e) => (
            <span key={e.additionalInformation["PmcId"]}>
              {t("parkingRightsExceededError", {
                numberOfMissingParkingRights:
                  e.additionalInformation["NumberOfMissingParkingRights"],
              })}
              <br />
            </span>
          ))}
          {noDelegeesError !== undefined && (
            <span>
              {t("emptyFileDetected")}
              <br />
            </span>
          )}
          {fleetManagerInfoMissingError !== undefined && (
            <span>
              {t("fleetManagerInfoMissing")}
              <br />
            </span>
          )}
          {props.invalidDelegeesCount !== undefined && props.invalidDelegeesCount > 0 && (
            <div>
              <span className="text-dark-gray-2nd">
                {t("validatedelegeeDetailsError", {
                  errorsCount: props.invalidDelegeesCount,
                })}
              </span>
              <span className="text-dark-gray-2nd">
                <DownloadDelegeeFile
                  delegeeFileType={DelegeeFileType.Validation}
                  seasonTicketOwnerCrmId={props.seasonTicketOwnerCrmId!}
                  fileReference={props.fileReference!}
                  language={appState.selectedLanguage}
                />
              </span>
            </div>
          )}
        </>
      );
    }
    return (
      <span className="text-dark-gray-2nd">
        {t("or")}&nbsp;
        <span className="dnd-link">{t("browseFiles")}</span>
      </span>
    );
  };

  return (
    <div className={`drag-and-drop ${state}`}>
      <div
        className={`dnd-box ${state === "dragging" && "on-dragging"} ${
          state === "processing" && "on-processing"
        }`}
      >
        {state !== "success" &&
          props.error !== "invalid" && (
            <label
              className="drop-zone"
              htmlFor={`${state !== "dragging" && "file"}`}
              onDrop={handleDrop}
              onDragOver={handleDragOver}
              onDragEnter={handleDragEnter}
              onDragLeave={handleDragLeave}
            />
          )}
        <div className="dnd-box-input">
          <input
            ref={fileInput}
            type="file"
            name="file"
            id="file"
            multiple={false}
            accept={props.supportedFileTypes.join(",")}
            onChange={handleFileSelected}
            disabled={state === "processing"}
          />
          <NoPaddingBottomGrid
            container
            item
            direction="row"
            alignItems="center"
            justifyContent="center"
          >
            <NoPaddingBottomGrid
              container
              item
              direction="column"
              xs={12}
              alignItems="center"
              justifyContent="center"
            >
              <div className="img-wrapper">
                <CircleImage src={getImgWrapperSrc()} />
              </div>
              <div className="dragging-img-wrapper">
                <ImgBg src={XlsFileDropping} />
                <span className="sonar-wave" />
              </div>
            </NoPaddingBottomGrid>
            <NoPaddingBottomGrid
              container
              item
              direction="column"
              xs={12}
              alignItems="center"
              justifyContent="center"
            >
              <div className="dnd-file-choose-label">
                <Heading width="auto" justifyContent="center" fontSize={typography.fontSizeLarge}>
                  {hasValidateDelegeeErrors() ? t("validatedelegeeError") : t("dragAndDropHere")}
                </Heading>
                <div className="input-label">
                  {
                    <span className="text-dark-gray-2nd">
                      {t("or")}&nbsp;
                      <span className="dnd-link">{t("browseFiles")}</span>
                    </span>
                  }
                </div>
              </div>
              <div className="dnd-error-label">
                <Heading width="auto" justifyContent="center" fontSize={typography.fontSizeLarge}>
                  {getErrorHeading()}
                </Heading>
                <span className="text-dark-gray-2nd">{getErrorContent()}</span>
              </div>
              <div className="dnd-success-label">
                <span className="text-dark-gray-2nd">{t("successFile")}</span>
                {props.extraSuccessMsg && (
                  <>
                    <br />
                    <span className="text-dark-gray-2nd">{props.extraSuccessMsg}</span>
                  </>
                )}
              </div>
              <div className="dnd-file-dropping-label">
                <Heading width="auto" justifyContent="center" fontSize={typography.fontSizeLarge}>
                  {t("releaseFile")}
                </Heading>
                <span className="text-dark-gray-2nd">{t("releaseFileSubtitle")}</span>
              </div>
              <div className="dnd-file-processing-label">
                <Heading width="auto" justifyContent="center" fontSize={typography.fontSizeLarge}>
                  {t("processingFile")}
                </Heading>
                <span className="text-dark-gray-2nd">{t("processingFileSubtitle")}</span>
              </div>
            </NoPaddingBottomGrid>
          </NoPaddingBottomGrid>
        </div>
      </div>
      {hasValidateDelegeeErrors() ? (
        <></>
      ) : (
        <div className="dnd-accepted-files">
          <NoPaddingBottomGrid container item direction="row">
            <NoPaddingBottomGrid container item direction="column" xs={12}>
              {props.maxRecordsNumber ? (
                <span>
                  {t("acceptedFileTypesWithMaximumRecords", { maxRecords: props.maxRecordsNumber })}
                </span>
              ) : (
                <span>{t("acceptedFileTypes")}</span>
              )}
            </NoPaddingBottomGrid>
            <NoPaddingBottomGrid container item direction="column" xs={12}>
              <span>{t("multipleFilesNotSupported")}</span>
            </NoPaddingBottomGrid>
            {props.showMaxFileSize && (
              <NoPaddingBottomGrid container item direction="column" xs={12}>
                <span>
                  {t("maxFileSize", {
                    maxFileSize: props.maxFileSizeInMB ?? maxFileSizeInMBFallBack,
                  })}
                </span>
              </NoPaddingBottomGrid>
            )}
          </NoPaddingBottomGrid>
        </div>
      )}
    </div>
  );
};

export type DndState = "default" | "dragging" | "error" | "processing" | "success";

export type DndError = "empty" | "invalid" | "maxRecords" | "moreThanAvailable";

interface DragAndDropProps {
  onFileUploaded: (file: File) => void;
  onFileDropped?: (valid: boolean) => void;
  state: DndState;
  error?: DndError;
  supportedFileTypes: string[];
  extraSuccessMsg?: string;
  maxRecordsNumber?: string;
  maxFileSizeInMB?: number;
  seasonTicketOwnerCrmId?: string;
  fileReference?: string;
  invalidDelegeesCount?: number;
  validateDelegeesErrorCode?: string;
  generalValidationErrors?: GeneralValidationError[];
  showMaxFileSize: boolean;
}

export default memo(DragAndDrop);
