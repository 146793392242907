import { CircularProgress } from "@material-ui/core";
import React from "react";
import styled from "styled-components";
import { variables } from "../../theme/variables";
import "./customSpinner.scss";

const { colors } = variables;

const RedCircularProgress = styled(CircularProgress)`
  color: ${colors.red.hex};
`;

const LightGrayCircularProgress = styled(CircularProgress)`
  color: ${colors.veryLightGraySecondary};
`;

interface ICustomSpinnerProps {
  size?: string | number;
}

const CustomSpinner = ({ size }: ICustomSpinnerProps) => <RedCircularProgress size={size} />;

const LightGray = ({ size }: ICustomSpinnerProps) => <LightGrayCircularProgress size={size} />;

const DotPulse = () => (
  <div className="spinner">
    <div className="bounce1"></div>
    <div className="bounce2"></div>
    <div className="bounce3"></div>
  </div>
);

CustomSpinner.LightGray = LightGray;
CustomSpinner.DotPulse = DotPulse;

export default CustomSpinner;
