import React from "react";
import { variables } from "../../theme/variables";
import styled from "styled-components";
import { isUndefined } from "underscore";

const { typography, whiteSpaces } = variables;

const Wrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
  align-items: center;
  padding: 0 1.5rem;
`;

const CounterSpan = styled.span`
  font-size: ${typography.fontSizeLarge};
  margin-left: ${whiteSpaces.xs};
  &:before {
    content: "(";
  }
  &:after {
    content: ")";
  }
`;

type HeadingOptions = {
  showCounter?: boolean;
};

interface IHeadingProps {
  title: string;
  count?: number;
  className?: string;
  options?: HeadingOptions;
}

const CustomHeading: React.FC<IHeadingProps> = ({ className, title, count, options }) => (
  <Wrapper className={className}>
    <h2>{title}</h2>
    {!isUndefined(options) && options.showCounter && <CounterSpan>{count}</CounterSpan>}
  </Wrapper>
);

export default CustomHeading;
