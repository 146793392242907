import React from "react";
import DataTableContextProvider from "../../components/DataTable/DataTableContext/DataTableContextProvider";
import MyProducts from "../../containers/FleetManager/MyProducts/MyProducts";
import MyProductsContextProvider from "../../context/MyProductsContextProvider";
import ImportDelegationsContextProvider from "../../context/ImportDelegationsContext";

const MyProductsPage = () => (
  <DataTableContextProvider>
    <MyProductsContextProvider>
      <ImportDelegationsContextProvider>
        <MyProducts />
      </ImportDelegationsContextProvider>
    </MyProductsContextProvider>
  </DataTableContextProvider>
);

export default MyProductsPage;
