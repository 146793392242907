const errorConstants = {
  keys: {
    delegeeEmail: "delegeeEmail",
    delegee: "delegee",
    accessDeviceRight: "accessDeviceRight",
    delegationId: "delegationId",
    parkingRights: "parkingRights",
    pmc: "pmc",
    seasonTicket: "seasonTicket",
    seasonTicketOwnerCrmId: "seasonTicketOwnerCrmId",
    userId: "userId",
    serverError: "serverError",
    numberPlate: 'numberPlate',
  },
  codes: {
    alreadyExists: "alreadyExists",
    notFound: "notFound",
    notAllowed: "notAllowed",
    notAvailable: "notAvailable",
    notComplete: "notComplete",
    notPending: "notPending",
    notValid: "notValid",
    technicalSupport: "technicalSupport",
    isRequired: "isRequired",
    generalError: "generalError",
    licensePlateAlreadyLinked: "licensePlateAlreadyLinked",
    alreadyLinkedToAnotherUser: "alreadyLinkedToAnotherUser"
  },
};

type ErrorConstants = Readonly<typeof errorConstants>;

export default errorConstants as ErrorConstants;
