import React from "react";
import { Container, Grid, Typography } from "@material-ui/core";
import WarningFleet from "../../assets/images/warning-fleet.svg";
import CircleImage from "../../ui/CircleImage/CircleImage";
import styled from "styled-components";
import Spacer from "../../ui/Spacer/Spacer";

const FullHeightContainer = styled(Container)`
  height: 100%;
`;

const FullHeightRow = styled(Grid)`
  height: 100%;
`;

interface IPortalDeactivated {}

const PortalDeactivated: React.FC<IPortalDeactivated> = () => {
  return (
    <FullHeightContainer>
      <FullHeightRow container item direction="row" xs={12} alignItems="center" justifyContent="center">
        <Container>
          <Grid container item direction="column" xs={12} alignItems="center" justifyContent="center">
            <CircleImage src={WarningFleet} />
          </Grid>
          <Spacer size="sm" />
          <Grid container item direction="column" xs={12} alignItems="center" justifyContent="center">
            <Typography variant="h6">
              Sorry! The service you requested is not available at this time.
            </Typography>
          </Grid>
        </Container>
      </FullHeightRow>
    </FullHeightContainer>
  );
};

export default PortalDeactivated;
