import { fileTypes } from "../action-types/file.types";
import { FileActionType } from "../actions/file.actions";

export type FileState = {
  fileUploaded: boolean;
};

const initialState: FileState = {
  fileUploaded: false,
};

export default (
  state: FileState = initialState,
  action: FileActionType
): FileState => {
  switch (action.type) {
    case fileTypes.FILE_UPLOADED:
      return {
        ...state,
        fileUploaded: true,
      };
    case fileTypes.CANCEL_UPLOAD:
      return {
        ...state,
        fileUploaded: false,
      };
    default:
      return state;
  }
};
