import React, { useState } from "react";
import QParkLogo from "../../assets/images/logo.png";
import LanguageSelector from "../../components/LanguageSelector/LanguageSelector";
import { AppBar, Toolbar, Button, TextField, Divider } from "@material-ui/core";
import NavLinkItem from "../NavLinkItem/NavLinkItem";
import { Translation } from "react-i18next";
import { Menu, Close } from "@material-ui/icons";
import useAppContext from "../../context/hooks/useAppContext";
import { RootReducer } from "../../store/reducers";
import { connect } from "react-redux";
import EnvironmentInfo from "./EnvironmentInfo/EnvironmentInfo";
import UserCard from "../../components/UserCard/UserCard";
import styled from "styled-components";
import routes from "../../router/routes";
import "./_navigation-bar.scss";

const StyledDivider = styled(Divider)`
  margin: 0.5rem 0;
  max-height: 30px;
`;

interface IProps extends StateProps {
    onToggleSidebar: () => void;
    sidebarOpened: boolean;
}

const NavigationBar = (props: IProps) => {
    const { onToggleSidebar, sidebarOpened, environment } = props;
    const { appState, setAppUser } = useAppContext();

    const [crmId, setCrmId] = useState(appState.user.seasonTicketOwnerCrmId);

    const onCrmIdInputBlur = () =>
        setAppUser({
            seasonTicketOwnerCrmId: crmId,
        });

    return (
        <Translation ns="topMenu">
            {(t: Function) => (
                <AppBar position="fixed" className="nav-bar-wrapper">
                    <Toolbar className="nav-toolbar">
                        <div className="logo-wrapper">
                            <img src={QParkLogo} alt="QPark Logo" className="qpark-logo" />
                        </div>
                        <div className="nav-menu">
                            <NavLinkItem.NavLink
                                to={routes.fleetManager.subRoutes![0].path as string}
                            >
                                {t("fleetManager")}
                            </NavLinkItem.NavLink>
                            <NavLinkItem.NavLink to={routes.visitors.path as string}>
                                {t("visitors")}
                            </NavLinkItem.NavLink>
                            <NavLinkItem.NavLink to={routes.faq.path as string}>
                                {t("faq")}
                            </NavLinkItem.NavLink>
                            {environment.allowImpersonation && (
                                <TextField
                                    id="outlined-basic"
                                    variant="outlined"
                                    placeholder="Customer id"
                                    multiline={false}
                                    value={crmId}
                                    onChange={(e) => setCrmId(e.target.value)}
                                    onBlur={onCrmIdInputBlur}
                                />
                            )}
                        </div>
                        <div className="nav-aside">
                            <EnvironmentInfo
                                environment={environment.environment}
                                version={environment.version}
                            />
                            <LanguageSelector />
                            <StyledDivider orientation="vertical" flexItem />
                            <UserCard email={appState.user.email as string} />
                            <Button
                                disableRipple
                                onClick={onToggleSidebar}
                                className="mobile-menu-btn"
                            >
                                {sidebarOpened ? <Close /> : <Menu />}
                            </Button>
                        </div>
                    </Toolbar>
                </AppBar>
            )}
        </Translation>
    );
};

const mapStateToProps = (state: RootReducer) => {
    const { environment } = state;

    return {
        environment,
    };
};

type StateProps = ReturnType<typeof mapStateToProps>;

export default connect(mapStateToProps)(NavigationBar);