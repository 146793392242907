import MyProductsIcon from "../ui/Icons/MyProductsIcon";
import AppRoute, { IRouteProps } from "./AppRoute";
import MyProductsPage from "../pages/FleetManager/MyProductsPage";
import MyFleetPage from "../pages/FleetManager/MyFleetPage";
import VehicleIcon from "../ui/Icons/Vehicle";
import FaqPage from "../pages/FAQ/FaqPage";
import BookingOverviewPage from "../pages/Visitors/BookingOverviewPage";
import NewBookingPage from "../pages/Visitors/NewBookingPage";
import BookingOverviewIcon from "../ui/Icons/BookingOverviewIcon";

type RoutesType = {
  [key: string]: IRouteProps;
};

const fleetManager = new AppRoute("/fleet-manager", undefined, "fleetManager")
  .addSubroute("my-products", MyProductsPage, "myProducts", MyProductsIcon)
  .addSubroute("my-fleet", MyFleetPage, "myFleet", VehicleIcon)
  .getRoute();

const visitors = new AppRoute("/visitors", undefined, "visitors")
  .addSubroute(
    "booking-overview",
    BookingOverviewPage,
    "bookingOverview",
    BookingOverviewIcon
  )
  .addSubroute(
    "booking-overview/new-booking",
    NewBookingPage,
    "newBooking",
    undefined,
    true
  )
  .getRoute();

const oidcCallbackRoute = new AppRoute(
  "/signin-oidc",
  undefined,
  "oidc_signin"
).getRoute();

const faqRoute = new AppRoute("/faq", FaqPage, "faq").getRoute();

const routes: RoutesType = {
  fleetManager,
  visitors,
  oidcCallbackRoute,
  faq: faqRoute,
};

export const appRoutesArray: IRouteProps[] = Object.keys(routes).map(
  (key) => routes[key]
);

export default routes;
