import { Dispatch } from "react";
import { fileTypes } from "../action-types/file.types";

export type FileActions = {
  setFileUploaded: () => (dispatch: Dispatch<FileActionType>) => void;
  cancelFileUpload: () => (dispatch: Dispatch<FileActionType>) => void;
};

export type FileActionType = {
  type: string;
};

const setFileUploaded = () => {
  return (dispatch: Dispatch<FileActionType>) => {
    dispatch({
      type: fileTypes.FILE_UPLOADED,
    });
  };
};

const cancelFileUpload = () => {
  return (dispatch: Dispatch<FileActionType>) => {
    dispatch({
      type: fileTypes.CANCEL_UPLOAD,
    });
  };
};

export const fileActions: FileActions = {
  setFileUploaded,
  cancelFileUpload,
};
