import React, { Dispatch } from "react";
import { connect } from "react-redux";
import Warning from "./Warning";
import useAppContext from "../../context/hooks/useAppContext";
import { useTranslation } from "react-i18next";
import useVisitorBookingContext from "../../context/hooks/useVisitorBookingContext";
import { visitorBookingActions } from "../../store/actions/visitor-booking";

interface IFormProps extends DispatchProps {
  customerName: string;
  referenceNumber: string;
}

const CancelVisitorBookingWarning = (props: IFormProps) => {
  const { customerName, referenceNumber, cancelVisitorBooking } = props;
  const { t } = useTranslation(["visitors"]);

  const { toggleCancelVisitorBookingWarning } = useVisitorBookingContext();
  const {
    appState: { user },
  } = useAppContext();

  const handleDeleteCancelation = () => {
    toggleCancelVisitorBookingWarning();
  };

  const handleDeleteConfirmation = () => {
    cancelVisitorBooking(user.seasonTicketOwnerCrmId!, referenceNumber);
    toggleCancelVisitorBookingWarning();
  };

  return (
    <Warning
      warning={t("cancelBooking")}
      details={t("cancelBookingWarning", { name: customerName })}
      onCancel={handleDeleteCancelation}
      onConfirm={handleDeleteConfirmation}
      confirmText={t("cancelBooking")}
    />
  );
};

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  cancelVisitorBooking: (customerId: string, referenceNumber: string) =>
    dispatch(
      visitorBookingActions.cancelVisitorBooking(customerId, referenceNumber)
    ),
});

type DispatchProps = ReturnType<typeof mapDispatchToProps>;

export default connect(null, mapDispatchToProps)(CancelVisitorBookingWarning);
