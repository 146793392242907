import { useState } from "react";

type SmartState<TState> = {
  state: TState;
  updateState: (newState: TState) => void;
  resetState: () => void;
};

const useSmartState = <TState>(initialState: TState): SmartState<TState> => {
  const [state, setState] = useState<TState>(initialState);

  const updateState = (newState: TState): void =>
    setState({
      ...state,
      ...newState,
    });

  const resetState = (): void => setState(initialState);

  return {
    state,
    updateState,
    resetState,
  };
};

export default useSmartState;
