import React from "react";
import { useTranslation } from "react-i18next";
import ContainerArrowUp from "../../ui/ContainerArrowUp/ContainerArrowUp";
import CustomButton from "../../ui/CustomButton/CustomButton";
import Spacer from "../../ui/Spacer/Spacer";
import {
  NoPaddingGrid,
  StyledFullName,
  DetailsContainer,
  SignOutIcon,
  AbstractCollapse,
} from "./UserCardStyledComponents";

interface IUserCardDetailsProps {
  email: string;
  show: boolean;
  onSignOut?: () => void;
}

const UserCardDetails: React.FC<IUserCardDetailsProps> = (props) => {
  const { email, show, onSignOut } = props;
  const { t } = useTranslation("topMenu");

  return (
    <AbstractCollapse in={show}>
      <ContainerArrowUp rounded right={35}>
        <DetailsContainer>
          <NoPaddingGrid container item direction="row">
            <NoPaddingGrid container item direction="column" xs={12} alignItems="center">
              <StyledFullName>{email}</StyledFullName>
            </NoPaddingGrid>
            <Spacer size="xs" />
            <NoPaddingGrid container item direction="column" xs={12} alignItems="center">
              <CustomButton.Light
                startIcon={<SignOutIcon />}
                text={t("signOut")}
                onClick={onSignOut}
              />
            </NoPaddingGrid>
          </NoPaddingGrid>
        </DetailsContainer>
      </ContainerArrowUp>
    </AbstractCollapse>
  );
};

export default UserCardDetails;
