import { RouteProps, RouteComponentProps } from "react-router";

export interface IRouteProps extends RouteProps {
  subRoutes?: IRouteProps[];
  name?: string;
  Icon?: React.ComponentType<any>;
  hidden?: boolean;
}

class AppRoute {
  private route: IRouteProps = {};

  constructor(
    path?: string,
    component?:
      | React.ComponentType<RouteComponentProps<any>>
      | React.ComponentType<any>,
    name?: string
  ) {
    this.route.path = path;
    this.route.component = component;
    this.route.exact = true;
    this.route.name = name;
    this.route.subRoutes = [];
  }

  public addSubroute = (
    path?: string,
    component?:
      | React.ComponentType<RouteComponentProps<any>>
      | React.ComponentType<any>,
    name?: string,
    Icon?: React.ComponentType<any>,
    hidden?: boolean
  ): AppRoute => {
    this.route.subRoutes?.push({
      path: `${this.route.path}/${path}`,
      exact: true,
      name,
      component,
      Icon,
      hidden,
    });

    return this;
  };

  public getRoute = (): IRouteProps => this.route;
}

export default AppRoute;
