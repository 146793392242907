import React from "react";
import { SvgIcon } from "@material-ui/core";
import styled from "styled-components";
import { variables } from "../../theme/variables";

const { colors } = variables;

const Svg = styled(SvgIcon)`
  color: ${colors.grayishBlue.hex};
`;

const FilterIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" >
    <path
      className="a"
      d="M13.592-13.125H1.408a1.407,1.407,0,0,0-.994,2.4L5.156-5.98V-.937A1.57,1.57,0,0,0,5.719.234L7.594,1.613A1.42,1.42,0,0,0,9.844.488V-5.98l4.743-4.744A1.407,1.407,0,0,0,13.592-13.125ZM8.437-6.562V.469L6.562-.937V-6.562L1.406-11.719H13.594Z"
      transform="translate(0 13.125)"
    />
  </Svg>
);

export default FilterIcon;
