import { useContext } from "react";
import { FleetManagerContext } from "../FleetManagerContextProvider";

const useFleetManagerContext = () => {
    const fleetManagerContext = useContext(FleetManagerContext);

    return fleetManagerContext;
};

export default useFleetManagerContext;
