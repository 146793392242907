export const format = (value: string, ...args: any) => {
  if (!value.match(/^(?:(?:(?:[^{}]|(?:\{\{)|(?:\}\}))+)|(?:\{[0-9]+\}))+$/)) {
    throw new Error("invalid format string.");
  }
  return value.replace(
    /((?:[^{}]|(?:\{\{)|(?:\}\}))+)|(?:\{([0-9]+)\})/g,
    (m: string, str: string, index: number) => {
      if (str) {
        return str.replace(/(?:{{)|(?:}})/g, (m) => m[0]);
      } else {
        if (index >= args.length) {
          throw new Error("argument index is out of range in format");
        }
        return args[index];
      }
    }
  );
};

export const equalsIgnoreCase = (a: string, b: string) => a?.localeCompare(b, undefined, { sensitivity: 'accent' }) === 0;

export const stringUtils = {
  format,
};
