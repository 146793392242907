import { isEmpty, isUndefined } from "underscore";
import faqs from "./faq.json";

export type FaqCountryModel = {
  DisplayName: string;
  CountryCode: string;
  Url: string;
};

export const getFaqCountries = (): FaqCountryModel[] => {
  const results: FaqCountryModel[] = faqs
    .filter((f) => !isEmpty(f.url) && !isUndefined(f.url))
    .map(
      (f) =>
        ({
          DisplayName: f.displayName,
          CountryCode: f.countryCode,
          Url: f.url,
        } as FaqCountryModel)
    );

  return results;
};
