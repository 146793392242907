import { Container, Grid } from "@material-ui/core";
import * as React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { isEmpty } from "underscore";
import FaqButton from "../../components/FaqButton/FaqButton";
import { getFaqCountries } from "../../pages/FAQ/data";
import { variables } from "../../theme/variables";
import Spacer from "../../ui/Spacer/Spacer";

const { colors, typography, breakpoints } = variables;

interface IFaqProps {}

const FAQ: React.FC<IFaqProps> = () => {
  const countries = getFaqCountries();

  const { t } = useTranslation("faq");

  return (
    <FlexContainer>
      <Spacer size="xl" />
      <PaddingRow item container direction="row" xs={12}>
        <Spacer size="lg" />
        <Grid item container direction="column" xs={12}>
          <Grid item container direction="row" xs={12} alignItems="center" justifyContent="center">
            <Grid item container direction="column" xs={12} alignItems="center" justifyContent="center">
              <CenteredTextH1>{t("selectCountry")}</CenteredTextH1>
            </Grid>
            <Grid item container direction="column" xs={12} alignItems="center" justifyContent="center">
              <StyledSpanContainer>
                <span>{t("heading")}</span>
              </StyledSpanContainer>
            </Grid>
          </Grid>
        </Grid>
        <Spacer size="sm" />

        {!isEmpty(countries) && countries.map((c) => (
          <PaddingCol key={c.CountryCode} item container direction="column" xs={12} md={6}>
            <FaqButton countryCode={c.CountryCode} countryName={c.DisplayName} url={c.Url} />
          </PaddingCol>
        ))}
        <Spacer size="lg" />
      </PaddingRow>
    </FlexContainer>
  );
};

const FlexContainer = styled(Container)`
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
`;

const PaddingRow = styled(Grid)`
  padding: 0 9em;
  background-color: ${colors.white};
  box-shadow: 0px 1px 3px ${colors.veryLightGraySecondary};
  border-radius: 6px;
  max-width: 75%;

  @media screen and (max-width: ${breakpoints.screenMd}) {
    max-width: 95%;
    padding: 0 0.5em;
  }

  @media screen and (max-width: ${breakpoints.screenLg}) {
    max-width: 85%;
    padding: 0 1.5em;
  }
`;

const PaddingCol = styled(Grid)`
  padding: 0 0 0.7em 0;

  &:nth-child(even) {
    padding: 0 1.2em 0.7em 0;
  }

  @media screen and (max-width: ${breakpoints.screenMd}) {
    padding: 0.25em 0;

    &:nth-child(even) {
      padding: 0.25em 0;
    }
  }

  @media screen and (max-width: ${breakpoints.screenLg}) {
    padding: 0.25em;

    &:nth-child(even) {
      padding: 0.25em;
    }
  }
`;

const StyledSpanContainer = styled.div`
  max-width: 25em;
  text-align: center;
  font-size: ${typography.fontSizeBase};
  color: ${colors.darkGraySecondary};
`;

const CenteredTextH1 = styled.h1`
  text-align: center;
`;

export default FAQ;
