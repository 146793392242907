import { Container, Fade, IconButton, makeStyles } from "@material-ui/core";
import { ArrowBackIosRounded, ArrowForwardIosRounded } from "@material-ui/icons";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { isUndefined } from "underscore";
import { useDataTableContext } from "../../../../components/DataTable/DataTableContext/DataTableContextProvider";
import LocationCard from "../../../../components/LocationCard/LocationCard";
import { Position } from "../../../../components/LocationCard/locationCard.business";
import useMyProductsContext from "../../../../context/hooks/useMyProductsContext";
import { LocatedParkingProduct } from "../../../../models/LocatedParkingProduct";
import { variables } from "../../../../theme/variables";
import "./_locationsCarousel.scss";

const { colors } = variables;

const CarouselContainer = styled(Container)`
  padding: 0 1.5rem;
  max-width: 100%;
`;

const useStyles = makeStyles({
  root: {
    backgroundColor: colors.white,
    boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.1)",
    border: `1px solid ${colors.veryLightGray}`,
    "&:hover": {
      backgroundColor: colors.white,
    },
  },
});

interface ILocationsCarousel {
  elements: LocatedParkingProduct[];
  loading?: boolean;
  onCardClicked?: (position: Position) => void;
}

type StateType = {
  selectedIndex: number;
  carousel?: boolean;
};

const initialState: StateType = {
  selectedIndex: 0,
  carousel: false,
};

const btnInitialState = {
  next: true,
  previous: false,
};

type BtnStateType = Readonly<typeof btnInitialState>;

const LocationsCarousel: React.FC<ILocationsCarousel> = ({ elements, loading, onCardClicked }) => {
  const classes = useStyles();
  const { setSelectedLocation } = useMyProductsContext();
  const { resetPageNumber } = useDataTableContext();

  const selectLocation = (placeId: number) => {
    resetPageNumber();
    setSelectedLocation(placeId);
  };

  const [state, setState] = useState<StateType>(initialState);
  const [btnState, setBtnState] = useState<BtnStateType>(btnInitialState);
  const card = useRef<number>();

  useEffect(() => {
    toggleButtons();
    // eslint-disable-next-line
  }, [state.selectedIndex]);

  const isLast = state.selectedIndex === elements.length - 1;
  const isFirst = state.selectedIndex === 0;

  const updateState = (newState: StateType) =>
    setState({
      ...state,
      ...newState,
    });

  const updateBtnState = (newState: BtnStateType) =>
    setBtnState({
      ...btnState,
      ...newState,
    });

  const toggleButtons = () => {
    if (state.selectedIndex > 0 && state.selectedIndex < elements.length - 1) {
      updateBtnState({
        next: true,
        previous: true,
      });

      return;
    }

    if (isLast) {
      updateBtnState({
        next: false,
        previous: true,
      });

      return;
    }

    if (isFirst) {
      updateBtnState({
        previous: false,
        next: true,
      });

      return;
    }
  };

  const onNext = () => {
    if (isLast) {
      return;
    }

    updateState({
      selectedIndex: state.selectedIndex + 1,
    });
  };

  const onPrev = () => {
    if (isFirst) {
      return;
    }

    updateState({
      selectedIndex: state.selectedIndex - 1,
    });
  };

  const updateCarouselState = (index: number) => {
    if (card.current === index && state.carousel) {
      updateState({
        carousel: false,
        selectedIndex: 0,
      });

      card.current = 0;

      return;
    }

    if (!state.carousel) {
      updateState({
        carousel: true,
        selectedIndex: index,
      });
    }

    card.current = index;
  };

  const onCardSelect = (index: number, placeId: number, position?: Position) => {
    updateCarouselState(index);
    selectLocation(placeId);

    if (!isUndefined(onCardClicked)) {
      if (index > 0 && !state.carousel) {
        position = {
          ...position,
          left: 289,
        };
      }

      onCardClicked(position as Position);
    }
  };

  if (!elements?.length) {
    return null;
  }

  return (
    <Fade in={Boolean(elements.length)}>
      <CarouselContainer>
        <div className="carousel-slider">
          {btnState.previous && state.carousel && (
            <IconButton
              aria-label="previous"
              className="btn-previous"
              onClick={onPrev}
              classes={{
                root: classes.root,
              }}
            >
              <ArrowBackIosRounded />
            </IconButton>
          )}

          <div
            className={`carousel-slider-wrapper ${state.carousel && "slide"}`}
            style={{
              transform: `translateX(-${state.selectedIndex * (100 / elements.length)}%)`,
            }}
          >
            {elements.map((pp: LocatedParkingProduct, index: number) => (
              <LocationCard
                key={`${pp.locationName}_${index}`}
                model={pp}
                loading={loading}
                onClick={(position) => onCardSelect(index, pp.placeId, position)}
              />
            ))}
          </div>

          {btnState.next && state.carousel && (
            <IconButton
              aria-label="next"
              className="btn-next"
              onClick={onNext}
              classes={{
                root: classes.root,
              }}
            >
              <ArrowForwardIosRounded />
            </IconButton>
          )}
        </div>
      </CarouselContainer>
    </Fade>
  );
};

export default LocationsCarousel;
