import React from "react";
import { ProductTimeframeFormBulk } from "../forms";
import { ProductTimeframeProps } from "./ProductTimeframe.types";

const ProductTimeframeBulk = (props: ProductTimeframeProps) => {
  const {
    products,
    setFieldValue,
    setFieldError,
    values,
    errors,
    titlePrefixProduct,
    titlePrefixTime,
  } = props;

  return (
    <ProductTimeframeFormBulk
      products={products}
      setFieldValue={setFieldValue}
      setFieldError={setFieldError}
      titlePrefixProduct={titlePrefixProduct}
      titlePrefixTime={titlePrefixTime}
      productId={{
        value: values.productId,
        hasError: Boolean(errors.productId),
        error: errors.productId,
      }}
      startDate={{
        value: values.startDate,
        hasError: Boolean(errors.startDate),
        error: errors.startDate,
      }}
      startTime={{
        value: values.startTime,
        hasError: Boolean(errors.startTime),
        error: errors.startTime,
      }}
      endDate={{
        value: values.endDate,
        hasError: Boolean(errors.endDate),
        error: errors.endDate,
      }}
      endTime={{
        value: values.endTime,
        hasError: Boolean(errors.endTime),
        error: errors.endTime,
      }}
      physicalZone={{
        value: values.physicalZone,
        hasError: Boolean(errors.physicalZone),
        error: errors.physicalZone,
      }}
      parkingTime={{
        value: values.parkingTime ?? null,
        hasError: Boolean(errors.parkingTime),
        error: errors.parkingTime,
      }}
    />
  );
};

export default ProductTimeframeBulk;
