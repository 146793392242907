import { success } from "../helpers/action.helper";
import { environmentTypes } from "../action-types/environment.types";
import { EnvironmentActionType } from "../actions/environment.actions";
import { FeatureFlag } from "../../types/FeatureFlag";
import { DynamicConfiguration } from "../../types/DynamicConfiguration";
import { FeatureFlagsAndDynamicConfiguration } from "../../types/FeatureFlagsAndDynamicConfiguration";

export type EnvironmentState = {
  environment: string;
  version: string;
  portalDeactivated: boolean;
  featureFlags: FeatureFlag[];
  dynamicConfigurations: DynamicConfiguration[];
  allowImpersonation: boolean;
};

const initialState: EnvironmentState = {
  environment: "",
  version: "",
  portalDeactivated: false,
  featureFlags: [],
  dynamicConfigurations: [],
  allowImpersonation: false,
};

export default (
  state: EnvironmentState = initialState,
  action: EnvironmentActionType
): EnvironmentState => {
  switch (action.type) {
    case success(environmentTypes.GET_ENVIRONMENT):
      return {
        ...state,
        environment: action.payload as string,
      };
    case success(environmentTypes.GET_VERSION):
      return {
        ...state,
        version: action.payload as string,
      };
    case success(environmentTypes.GET_PORTAL_STATUS):
      return {
        ...state,
        portalDeactivated: action.payload as boolean,
      };
    case success(environmentTypes.GET_FEATURE_FLAGS_AND_DYNAMIC_CONFIGURATIONS):
      {        
        const featureFlagsAndDynamicConfiguration = action.payload as FeatureFlagsAndDynamicConfiguration;
        return {
          ...state,
          featureFlags: featureFlagsAndDynamicConfiguration.featureFlags,
          dynamicConfigurations: featureFlagsAndDynamicConfiguration.dynamicConfigurations,
        }
      }
    case success(environmentTypes.GET_ALLOW_IMPERSONATION):
      return {
        ...state,
        allowImpersonation: action.payload as boolean,
      };
    default:
      return state;
  }
};
