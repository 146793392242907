import React, { memo, PropsWithChildren } from "react";
import { isEmpty, isUndefined } from "underscore";
import { ContentCardProps } from "./ContentCard.types";
import "./_contentCard.scss";
import CloseVisitorIcon from "../Icons/CloseVisitorIcon";

const ContentCard = memo((props: PropsWithChildren<ContentCardProps>) => {
  const { children, removePadding, title, handleDelete } = props;

  if (isUndefined(children)) {
    return null;
  }

  return (
    <div className="content-card">
      {!isUndefined(handleDelete) &&
        <CloseVisitorIcon handleDelete={handleDelete} />
      }
      <div
        className={`content-card-wrapper ${
          removePadding ? "no-padding" : ""
        }`.trim()}
      >
        {!isEmpty(title) && <span className="content-card-title">{title}</span>}
        <div className="content-card-body">{children}</div>
      </div>
    </div>
  );
});

export default ContentCard;
