import React from "react";
import styled from "styled-components";
import { FormControl, Select, MenuItem } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { variables } from "../../../theme/variables";
import { isNaN } from "underscore";
import { useDataTableContext } from "../DataTableContext/DataTableContextProvider";

const { typography } = variables;

const StyledWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const StyledFormControl = styled(FormControl)`
  margin-left: 5px;
  font-size: ${typography.fontSizeSmaller};
`;

interface IDataTablePageSize {
  pageSizes?: number[];
  defaultPageSize?: number;
  disabled?: boolean;
}

const DataTablePageSize = (props: IDataTablePageSize) => {
  const { pageSizes, defaultPageSize } = props;
  const { t } = useTranslation("dataTable");
  const { setPageSize, state } = useDataTableContext();

  const onPageSizeChanged = (event: React.ChangeEvent<any>) => {
    event.preventDefault();

    const { value } = event.target;

    const pageSize = Number(value);
    if (!isNaN(pageSize)) {
      setPageSize(pageSize);
    } else {
      setPageSize(props.defaultPageSize!);
    }
  };

  return (
    <StyledWrapper>
      {t("pageSize")}
      <StyledFormControl variant="outlined">
        <Select
          id="data-page-size"
          onChange={onPageSizeChanged}
          defaultValue={defaultPageSize}
          value={state.pageSize}
          inputProps={{ MenuProps: { disableScrollLock: true } }}
        >
          {pageSizes?.map((pageSize: number, index: number) => (
            <MenuItem key={`${pageSize}_${index}`} value={pageSize} disabled={props.disabled}>
              {pageSize}
            </MenuItem>
          ))}
        </Select>
      </StyledFormControl>
    </StyledWrapper>
  );
};

DataTablePageSize.defaultProps = {
  pageSizes: [10, 25, 50, 100],
  defaultPageSize: 25,
  disabled: false,
};

export default DataTablePageSize;
