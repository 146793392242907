import { Optional, Required } from "../../../../NewBooking.types";

export type VisitorDetailsFormProps = {
  name: Optional<string>;
  emailAddress: Required<string>;
  numberPlatePrefix: Optional<string>;
  numberPlate: Optional<string>;
  onChange: (field: string, e?: React.ChangeEvent<any>) => void;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => any;
  setFieldError: (field: string, value: string | undefined) => void;
};

export enum Fields {
  Name = "name",
  EmailAddress = "emailAddress",
  NumberPlate = "numberPlate",
  NumberPlatePrefix = "numberPlatePrefix",
}
