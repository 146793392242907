import React from "react";
import Pagination from "@material-ui/lab/Pagination";
import styled from "styled-components";
import { useDataTableContext } from "../DataTableContext/DataTableContextProvider";
import "./_dataTablePagination.scss";

const StyledPagination = styled(Pagination)`
  ul {
    li {
      &:first-child {
        button {
          &:after {
            content: "${({ previouslabel }: IDataTablePaginationProps) => previouslabel}";
            margin-left: 5px;
          }
        }
      }

      &:last-child {
        button {
          &:before {
            content: "${({ nextlabel }: IDataTablePaginationProps) => nextlabel}";
            margin-right: 5px;
          }
        }
      }
    }
  }
`;

interface IDataTablePaginationProps {
  count?: number;
  previouslabel?: string;
  nextlabel?: string;
  disabled?: boolean;
}

const DataTablePagination = (props: IDataTablePaginationProps) => {
  const { state, setPageNumber } = useDataTableContext();

  const onPageChange = (event: React.ChangeEvent<unknown>, page: number) => {
    event.preventDefault();

    setPageNumber(page);
  };

  return (
    <StyledPagination
      previouslabel={props.previouslabel}
      nextlabel={props.nextlabel}
      count={props.count}
      defaultPage={1}
      page={state.pageNumber}
      siblingCount={0}
      disabled={props.disabled}
      size="large"
      shape="rounded"
      className="data-table-pagination"
      variant="outlined"
      onChange={onPageChange}
    />
  );
};

DataTablePagination.defaultProps = {
  previouslabel: "Previous",
  nextlabel: "Next",
  disabled: false,
};

export default DataTablePagination;
