import { CustomLocale } from "flatpickr/dist/types/locale";
import { languages } from "../../translations";
import { english } from "flatpickr/dist/l10n/default";
import { Dutch } from "flatpickr/dist/l10n/nl";
import { German } from "flatpickr/dist/l10n/de";
import { French } from "flatpickr/dist/l10n/fr";

export const qparkDatePickerV2Locale = new Map<string, CustomLocale>();
qparkDatePickerV2Locale.set(languages.en, english);
qparkDatePickerV2Locale.set(languages.nl, Dutch);
qparkDatePickerV2Locale.set(languages.de, German);
qparkDatePickerV2Locale.set(languages.fr, French);
