import { Container, ButtonGroup, Button } from "@material-ui/core";
import React, { PropsWithChildren } from "react";
import { NoPaddingBottomGrid } from "../../../../../../../../../components/DataTable/DataTableStyledComponents";
import { useTranslation } from "react-i18next";

export type VisitorDetailHeaderTab = "manual" | "file";

export type VisitorDetailsHeaderProps = {
  isDirty: boolean;
  currentTab: VisitorDetailHeaderTab;
  openModalDialog: (onClose: () => void) => void;
  onTabSwitch: (newTab: VisitorDetailHeaderTab) => void;
};

const VisitorDetailsHeader = (props: PropsWithChildren<VisitorDetailsHeaderProps>) => {
  const {
    isDirty,
    currentTab,
    openModalDialog,
    onTabSwitch,
    children,
  } = props;

  const { t } = useTranslation(["globals", "visitors"]);

  const askConfirmationAndSwitchInput = (tab: VisitorDetailHeaderTab) => {
    if (currentTab === tab) {
      return;
    }

    if (isDirty) {
      openModalDialog(() => {
        onTabSwitch(tab);
      });
    } else {
      onTabSwitch(tab);
    }
  };

  const component = (
    <React.Fragment>
      <Container className="no-padding-important">
        <NoPaddingBottomGrid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
        >
          <NoPaddingBottomGrid
            container
            item
            direction="column"
            xs={12}
            className="padding-bottom-18"
          >
            <ButtonGroup variant="contained" fullWidth disableRipple>
              <Button
                color={currentTab === "manual" ? "primary" : "default"}
                onClick={() => askConfirmationAndSwitchInput("manual")}
                disableElevation
              >
                {t("visitors:visitorBookingFileUpload.manualInput")}
              </Button>
              <Button
                color={currentTab === "file" ? "primary" : "default"}
                onClick={() => askConfirmationAndSwitchInput("file")}
                disableElevation
              >
                {t("visitors:visitorBookingFileUpload.fileUpload")}
              </Button>
            </ButtonGroup>
          </NoPaddingBottomGrid>
        </NoPaddingBottomGrid>
      </Container>

      {children}
    </React.Fragment>
  );

  return component;
};

export default VisitorDetailsHeader;
