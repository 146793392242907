import { Box, Button } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";

export enum TabOption {
  FleetMembers = "fleetMembers",
  Vehicles = "vehicles",
}

export type TableSwitcherProps = Readonly<{
  tab: TabOption;
  onChange: (tabOption: TabOption) => void;
  getCount: (tabOption: TabOption) => number;
}>;

const options = [TabOption.FleetMembers, TabOption.Vehicles];

function TableSwitcher(props: TableSwitcherProps) {
  const { t } = useTranslation(["myProducts"]);

  return (
    <Box sx={{ p: 0.5, borderRadius: "16px", width: "100%", display: "flex", bgcolor: "#efefef", gridGap: "0.5rem" }}>
      {options.map((option) => (
        <Button
          key={option}
          style={{
            backgroundColor: props.tab === option ? "#fff" : undefined,
            borderRadius: "12px",
            flexGrow: 1,
          }}
          onClick={() => props.onChange(option)}
        >
          {t(`tableSwitcher.${option}`, { count: props.getCount(option) })}
        </Button>
      ))}
    </Box>
  );
}

export default TableSwitcher;
