import React from "react";
import DataTableContextProvider from "../../components/DataTable/DataTableContext/DataTableContextProvider";
import BookingOverview from "../../containers/Visitors/BookingOverview/BookingOverview";
import VisitorBookingContextProvider from "../../context/VisitorBookingContextProvider";

const BookingOverviewPage = () => (
  <DataTableContextProvider>
    <VisitorBookingContextProvider>
      <BookingOverview />
    </VisitorBookingContextProvider>
  </DataTableContextProvider>
);

export default BookingOverviewPage;
