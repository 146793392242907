import * as React from "react";
import { Dialog, DialogContent, Grid } from "@material-ui/core";
import WarningColumn from "../../../../../../../../../ui/CenteredWarning/CenteredWarning";
import CustomButton from "../../../../../../../../../ui/CustomButton/CustomButton";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import "../../../../../../../../../ui/Icons/_visitorBookingCreation.scss";
import WarningFleetOrange from "../../../../../../../../../assets/images/warning-fleet-orange.svg";

export interface VisitorBookingModalDialog {
  open: boolean;
  onAccept: () => void;
  onDismiss: () => void;
}

const WarningRow = styled(Grid)`
  padding: 0;
  position: relative;
`;

export default function ModalDialog(props: Readonly<VisitorBookingModalDialog>) {
  const { open, onAccept, onDismiss } = props;
  const { t } = useTranslation("globals");

  // Pre-load the image to avoid showing the popup without the image
  new Image().src = WarningFleetOrange;

  return (
    <Dialog
      open={open}
      onClose={onDismiss}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        <WarningRow container item direction="row" alignItems="center" justifyContent="center">
          <WarningColumn
            title={t("warning")}
            details={t("visitors:closeVisitorImportWarning.details")}
            orangeWarning={true}
          />
          <div className="visitor-dialog-buttons">
            <CustomButton.Light text={t("cancel")} onClick={onDismiss} />
            <CustomButton.Primary
              text={t("visitors:closeVisitorImportWarning.continue")}
              onClick={onAccept}
              disabled={false}
              loading={false}
            />
          </div>
        </WarningRow>
      </DialogContent>
    </Dialog>
  );
}
