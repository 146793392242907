import { TFunction } from "i18next";
import { isUndefined } from "underscore";
import { DataTableContextState } from "../../../components/DataTable/DataTableContext/DataTableContextProvider";
import { FleetManagerContextState } from "../../../context/store/reducers/fleetManagerContext.reducer";

export const getSelectedParkingRightIds = (
  fleetManagerState: FleetManagerContextState,
  dataTableState: DataTableContextState,
  delegees: any
) => {
  return fleetManagerState.selectedRowKeys.flatMap((id) =>
    getParkingRightIdsByRegistrationId(id, delegees, dataTableState)
  );
};

export const getSelectedParkingRightIdSingle = (
  fleetManagerState: FleetManagerContextState,
  dataTableState: DataTableContextState,
  delegees: any
) => {
  return getParkingRightIdsByRegistrationId(fleetManagerState.selectedRegistrationId, delegees, dataTableState);
};

const getParkingRightIdsByRegistrationId = (
  registrationId: string,
  delegees: any,
  dataTableState: DataTableContextState
) => {
  if (
    delegees.pages == null ||
    !delegees.pages[dataTableState.pageNumber!] ||
    delegees.pages[dataTableState.pageNumber!].data == null
  ) {
    return [];
  }

  const delegate = delegees.pages[dataTableState.pageNumber!].data?.find(
    (delegate: { registrationId: string }) =>
      delegate.registrationId === registrationId
  );
  if (delegate == null) {
    return [];
  }

  return delegate.products.map(
    (product: { parkingRightId: any }) => product.parkingRightId
  );
};

export const getMessage = (
  t: TFunction,
  TKey: string,
  count?: number,
  TKeyCount?: string
): string => {
  if (!isUndefined(count) && !isUndefined(TKeyCount) && count > 1) {
    return t(TKeyCount, { count });
  }

  return t(TKey);
};
