import React from "react";
import DataTableContextProvider from "../../components/DataTable/DataTableContext/DataTableContextProvider";
import MyFleet from "../../containers/FleetManager/MyFleet/MyFleet";
import MyFleetContextProvider from "../../context/MyFleetContextProvider";
import ImportDelegationsContextProvider from "../../context/ImportDelegationsContext";

const MyFleetPage = () => (
  <DataTableContextProvider>
    <MyFleetContextProvider>
      <ImportDelegationsContextProvider>
        <MyFleet />
      </ImportDelegationsContextProvider>
    </MyFleetContextProvider>
  </DataTableContextProvider>
);

export default MyFleetPage;
