import { Grid } from "@material-ui/core";
import { ErrorOutline } from "@material-ui/icons";
import React from "react";
import styled from "styled-components";
import { variables } from "../../theme/variables";

const { colors, typography } = variables;

const Container = styled.div`
  display: flex;
  border-top: 1px solid ${colors.veryLightGraySecondary};
  padding: 0.7rem 1.5rem;
  background-color: ${colors.white};
`;

const MessageWrapper = styled(Grid)`
  display: flex;
  background-color: rgba(${colors.red.rgb}, 0.1);
  width: 100%;
  border-radius: 6px;
  padding: 0;
`;

const StyledCol = styled(Grid)`
  padding: 0.5rem;
`;

const StyledErrorOutline = styled(ErrorOutline)`
  fill: ${colors.red.hex};
  width: 18px;
`;

const Span = styled.span`
  color: ${colors.red.hex};
  font-size: ${typography.fontSizeSmaller};
`;

interface ICustomErrorProps {
  show?: boolean;
  message: string;
}

const CustomError: React.FC<ICustomErrorProps> = ({ message, show }) => {
  if (!show) {
    return null;
  }

  return (
    <Container>
      <MessageWrapper container item direction="row" xs={12} alignItems="center" justifyContent="center">
        <StyledCol container item direction="column" xs={2} alignItems="flex-end" justifyContent="center">
          <StyledErrorOutline />
        </StyledCol>
        <StyledCol
          container
          item
          direction="column"
          xs={10}
          alignItems="flex-start"
          justifyContent="center"
        >
          <Span>{message}</Span>
        </StyledCol>
      </MessageWrapper>
    </Container>
  );
};

export default CustomError;
