import React, { useEffect, Dispatch } from "react";
import { connect } from "react-redux";
import store from "./store";
import { RootReducer } from "./store/reducers";
import getUserManager from "./utils/userManager";
import { OidcProvider } from "redux-oidc";
import { isEmpty, isUndefined } from "underscore";
import IChildren from "./interfaces/IChildren";
import useAppContext from "./context/hooks/useAppContext";
import storageService from "./services/storage.service";
import { useHistory, useLocation } from "react-router";
import { User } from "oidc-client";
import environmentActions from "./store/actions/environment.actions";
import moment from "moment-timezone";

interface IProps extends IChildren, StateProps, DispatchProps {}

const CustomOidcProvider = ({
  children,
  oidcConfig,
  getAllowImpersonation,
  getFeatureFlagsAndDynamicConfigurations,
}: IProps) => {
  const { setAppUser, appState } = useAppContext();
  const history = useHistory();
  const location = useLocation();

  const isCallbackPage = history.location.pathname === "/signin-oidc";

  const setUser = (user: User) => {
    setAppUser({
      seasonTicketOwnerCrmId: user.profile.crmid,
      email: user.profile.email as string,
      country: user.profile.country as string,
    });
    storageService.setTokenToStorage(user.access_token);
    getAllowImpersonation(user.profile.crmid);
    getFeatureFlagsAndDynamicConfigurations(user.profile.crmid);
  };

  const setTimezone = (user: User) => {
    const zones = moment.tz.zonesForCountry(user.profile.country);
    if (zones && zones.length > 0) {
      moment.tz.setDefault(zones[0]);
    }
  };
  
  useEffect(() => {
    if (appState.user.seasonTicketOwnerCrmId) {
      getFeatureFlagsAndDynamicConfigurations(appState.user.seasonTicketOwnerCrmId);
    }
  }, [appState.user.seasonTicketOwnerCrmId]);

  useEffect(() => {
    const userManager = getUserManager(oidcConfig.oidcConfig);

    if (isCallbackPage) {
      userManager
        .signinRedirectCallback()
        .then((user) => {
          setUser(user);
          setTimezone(user);

          history.push("/");
        })
        .catch(() => userManager.signinRedirect()); //When state invalid (bookmarked login page), redirect to login to set new state
    } else {
      userManager.getUser().then((user) => {
        if (!user || user.expired) {
          userManager.signinRedirect();
        } else {
          setUser(user);
          setTimezone(user);
        }
      });
    }

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    return () => {
      const hasOwnerCrmId =
        !isUndefined(appState.user.seasonTicketOwnerCrmId) &&
        !isEmpty(appState.user.seasonTicketOwnerCrmId);

      if (!hasOwnerCrmId) {
        const userManager = getUserManager(oidcConfig.oidcConfig);
        userManager.getUser();
        }

      // Skip the "/visitors" pathname since it immediately goes to "/visitors/booking-overview"
      if (hasOwnerCrmId && location.pathname !== "/visitors") {
        getFeatureFlagsAndDynamicConfigurations(appState.user.seasonTicketOwnerCrmId!);
      }
    };
  }, [location]);

  const oidcConfigIsFulfilled =
    !isEmpty(oidcConfig.oidcConfig.serverUrl) &&
    !isEmpty(oidcConfig.oidcConfig.clientId) &&
    !isEmpty(oidcConfig.oidcConfig.clientSecret);

  const loadChildren = appState.user.seasonTicketOwnerCrmId !== "";

  return (
    <React.Fragment>
      {oidcConfigIsFulfilled && (
        <OidcProvider userManager={getUserManager(oidcConfig.oidcConfig)} store={store}>
          <React.Fragment>{loadChildren && children}</React.Fragment>
        </OidcProvider>
      )}
    </React.Fragment>
  );
};

const mapStateToProps = (state: RootReducer) => {
  const { oidcConfig } = state;

  return {
    oidcConfig,
  };
};
const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  getAllowImpersonation: (loggedInCrmId: string) =>
    dispatch(environmentActions.getAllowImpersonation(loggedInCrmId)),
  getFeatureFlagsAndDynamicConfigurations: (loggedInCrmId: string) =>
    dispatch(environmentActions.getFeatureFlagsAndDynamicConfigurations(loggedInCrmId)),
});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;
export default connect(mapStateToProps, mapDispatchToProps)(CustomOidcProvider);
