import { Grid, InputLabel } from "@material-ui/core";
import React, { useMemo, memo } from "react";
import { useTranslation } from "react-i18next";
import { CountriesNumberPlates } from "../../../../../../../../lib/countriesNumberPlates";
import { variables } from "../../../../../../../../theme/variables";
import CustomInput from "../../../../../../../../ui/CustomInput/CustomInput";
import { NumberPlateDeSupport } from "../../../../../../../../ui/NumberPlate";
import RequiredInputLabel from "../../../../../../../../ui/RequiredInputLabel/RequiredInputLabel";
import { Fields, VisitorDetailsFormProps } from "./VisitorDetailsForm.types";

const { typography } = variables;

const VisitorDetailsForm = memo((props: VisitorDetailsFormProps) => {
  const {
    name,
    emailAddress,
    numberPlatePrefix,
    numberPlate,
    onChange,
    setFieldValue,
    setFieldError,
  } = props;
  const { t } = useTranslation(["visitors", "globals"]);

  const handleNumberPlatePrefixChange = (npPrefix: string) => {
    onChange(Fields.NumberPlatePrefix);
    return setFieldValue(Fields.NumberPlatePrefix, npPrefix);
  };

  const oldNpValue = React.useRef("");
  const handleNumberPlateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const np = e.target.value;
    const field = Fields.NumberPlate;
    if (oldNpValue.current !== np) {
      setFieldValue(field, np);
      onChange(field, e);
      oldNpValue.current = np;
    }
  };

  const countriesNumberPlates = new CountriesNumberPlates();

  const supportedNumberPlates = useMemo(() => {
    return countriesNumberPlates.getCountriesNumberPlates();
  }, [countriesNumberPlates]);

  const onInputChange = (field: Fields) => (e: React.ChangeEvent<any>) => onChange(field, e);

  return (
    <Grid item container direction="row" xs={12} alignItems="center">
      <Grid item container direction="column" xs={12} xl={3} lg={3} md={3} sm={12}>
        <InputLabel htmlFor={Fields.Name}>{t("globals:name")}</InputLabel>
      </Grid>
      <Grid item container direction="column" xs={12} xl={9} lg={9} md={9} sm={12}>
        <CustomInput
          id={Fields.Name}
          name={Fields.Name}
          autoComplete="given-name"
          variant="outlined"
          placeholder={t("visitorDetailsForm.namePlaceholder")}
          value={name.value}
          onChange={onInputChange(Fields.Name)}
          InputProps={{
            autoComplete: "given-name",
          }}
        />
      </Grid>
      <Grid item container direction="column" xs={12} xl={3} lg={3} md={3} sm={12}>
        <RequiredInputLabel fontSize={typography.fontSizeSmaller} htmlFor={Fields.EmailAddress}>
          {t("globals:email")}
        </RequiredInputLabel>
      </Grid>
      <Grid item container direction="column" xs={12} xl={9} lg={9} md={9} sm={12}>
        <CustomInput.Required
          id={Fields.EmailAddress}
          name={Fields.EmailAddress}
          autoComplete="email"
          variant="outlined"
          placeholder={t("visitorDetailsForm.emailPlaceholder")}
          value={emailAddress.value}
          error={emailAddress.hasError}
          helperText={emailAddress.error}
          onChange={onInputChange(Fields.EmailAddress)}
          type="email"
          InputProps={{
            autoComplete: "email",
          }}
        />
      </Grid>
      <Grid item container direction="column" xs={12} xl={3} lg={3} md={3} sm={12}>
        <InputLabel htmlFor={Fields.NumberPlate}>{t("globals:numberPlate")}</InputLabel>
      </Grid>
      <Grid item container direction="column" xs={12} xl={6} lg={6} md={9} sm={12}>
        <NumberPlateDeSupport
          id={Fields.NumberPlate}
          name={Fields.NumberPlate}
          placeholder={t("visitorDetailsForm.numberPlatePlaceholder")}
          regionPlaceholder={t("visitorDetailsForm.numberPlateRegionPlaceholder")}
          onValueChange={handleNumberPlateChange}
          onPrefixSelect={handleNumberPlatePrefixChange}
          value={numberPlate.value ?? ""}
          error={numberPlate.error ?? numberPlatePrefix.error}
          prefix={numberPlatePrefix.value ?? ""}
          supportedNumberPlates={supportedNumberPlates.map((s) => ({
            numberPlatePrefix: s.numberPlateCode,
            countryCode: s.countryCode,
          }))}
          setFieldError={setFieldError}
          setFieldValue={setFieldValue}
        />
      </Grid>
    </Grid>
  );
});

export default VisitorDetailsForm;
