import { findWhere, indexOf, isEmpty, isEqual, isUndefined } from "underscore";
import { LocatedParkingProduct } from "../../models/LocatedParkingProduct";
import { ParkingProductsActionType } from "../actions/parkingProducts.actions";
import { ParkingProductState } from "../reducers/parkingProducts.reducer";

type ParkingProductsReducerHelper = {
  getLocatedParkingProductsState: (
    state: ParkingProductState,
    action: ParkingProductsActionType
  ) => ParkingProductState;
  getLocatedParkingProductsData: (state: ParkingProductState) => LocatedParkingProduct[];
};

const parkingProductsReducerHelper: ParkingProductsReducerHelper = (() => {
  const _getRefreshedLocatedParkingProducts = (
    currentState: LocatedParkingProduct[],
    newState: LocatedParkingProduct[]
  ) => {
    const updated = newState;
    const current = currentState;

    updated?.forEach((entity) => {
      let found = findWhere(current, { placeId: entity.placeId });

      if (!isUndefined(found) && !isEqual(entity, found)) {
        const index = indexOf(current, found);
        current[index] = entity;
      }
    });

    return updated;
  };

  const getLocatedParkingProductsState = (
    state: ParkingProductState,
    action: ParkingProductsActionType
  ): ParkingProductState => {
    if (!isEmpty(state.locatedParkingProducts.data)) {
      const updated = _getRefreshedLocatedParkingProducts(
        state.locatedParkingProducts.data!,
        action.locatedParkingProductsPayload!
      );

      return {
        ...state,
        locatedParkingProducts: {
          ...state.locatedParkingProducts,
          data: updated,
          loading: false,
        },
      };
    }

    return {
      ...state,
      locatedParkingProducts: {
        ...state.locatedParkingProducts,
        data: action.locatedParkingProductsPayload,
        loading: false,
      },
    };
  };

  const getLocatedParkingProductsData = (state: ParkingProductState): LocatedParkingProduct[] => {
    if (isEmpty(state.locatedParkingProducts.data)) {
      return [];
    }

    return state.locatedParkingProducts.data as LocatedParkingProduct[];
  };

  return {
    getLocatedParkingProductsState,
    getLocatedParkingProductsData,
  };
})();

export default parkingProductsReducerHelper;
