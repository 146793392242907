import React, { Dispatch, useState } from "react";
import { Container } from "@material-ui/core";
import { KeyboardArrowDown } from "@material-ui/icons";
import { NoPaddingBottomGrid } from "../../DataTable/DataTableStyledComponents";
import Heading from "../../../ui/Heading/Heading";
import { variables } from "../../../theme/variables";
import { useTranslation } from "react-i18next";
import DragAndDrop, {
  DndError,
  DndState,
} from "../../DragAndDropV2/DragAndDrop";
import * as XLSX from "xlsx";
import { DelegeeData } from "../../../context/store/reducers/importDelegations.reducer";
import useImportDelegationsContext from "../../../context/hooks/useImportDelegationsContext";
import { isEmpty } from "underscore";
import { ICheckParkingRight } from "../../DelegeeForm/ParkingRightsGrid/ParkingRightsGrid";
import { connect } from "react-redux";
import parkingProductsActions from "../../../store/actions/parkingProducts.actions";
import useAppContext from "../../../context/hooks/useAppContext";
import { ValidateDelegeesRequestModel } from "../../../models/bulkImport/ValidateDelegeesRequestModel";
import { GeneralValidationError, ValidateDelegeesResponseModel } from "../../../models/bulkImport/ValidateDelegeesResponseModel";

const { typography } = variables;

const dprSheetName = "Import Delegees";
const delegeeEmail = "Delegee Email Address";
const delegeeName = "Delegee Name";
const rowNumber = "__rowNum__";

const DelegeesImportDragAndDrop: React.FC<DelegeesImportDragAndDropProps> = (
  props
) => {
  const [state, setState] = useState<DndState>("default");
  const [error, setError] = useState<DndError | undefined>(undefined);
  const [fileReference, setFileReference] = useState<string>();
  const [generalValidationErrors, setGeneralValidationErrors] = useState<GeneralValidationError[]>();
  const [invalidDelegeesCount, setInvalidDelegeesCount] = useState<number>();
  const [validateDelegeesErrorCode, setValidateDelegeesErrorCode] = useState<string>();
  const { appState } = useAppContext();

  const { importDelegationsState } = useImportDelegationsContext();

  const { t } = useTranslation(["delegeeForm"]);
  const supportedFileTypes = [
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "application/vnd.ms-excel",
  ];

  const handleFileUpload = (file: File) => {
    setState("processing");
    props.onProcessing(true);
    readDelegeesFromXls(file);
  };

  const readDelegeesFromXls = (file: File) => {
    const reader = new FileReader();
    reader.readAsBinaryString(file);
    reader.onload = handleUploadedData;
  };

  const handleUploadedData = (e: ProgressEvent<FileReader>) => {
    const delegeesToImport: DelegeeData[] = [];

    const data = e.target?.result;
    const wb = XLSX.read(data, { type: "binary" });

    if (wb.SheetNames && wb.SheetNames.includes(dprSheetName)) {
      readDataFromExcel(wb, delegeesToImport);

      const fileIsEmpty = isEmpty(delegeesToImport);
      if (fileIsEmpty) {
        setState("error");
        setError("empty");
        props.onProcessing(false);
        return;
      }

      const checkParkingRights = [...importDelegationsState.checkParkingRights];
      checkParkingRights.forEach((checkParkingRight) => {
        checkParkingRight.right!.totalDelegatedParkingRights! +=
          delegeesToImport.length;
      });

      props.onCheckedParkingRights(checkParkingRights);
      const req: ValidateDelegeesRequestModel = {
        delegees: delegeesToImport.map(d => ({ name: d.name.value, emailAddress: d.emailAddress.value, lineNumber: d.lineNumber ?? 0 })),
        pmcIds: checkParkingRights.map(pr => pr.right?.pmcId as number),
        userEmail: appState.user.email
      };
      props.validateDelegees(
        appState.user.seasonTicketOwnerCrmId as string,
        req,
        (data) => {
          setState("success");
          if (!data.hasErrors) {
            props.onProcessing(false);
            props.onFileProcessed(delegeesToImport);
          }
          else {
            setState("error");
            setError("invalid");

            if (data.fileReference) {
              setFileReference(data.fileReference);
            }
            setGeneralValidationErrors(data.generalErrors);
            setInvalidDelegeesCount(data.numberOfErrors);
          }
        },
        (errorCode) => {
          setState("error");
          setError("invalid");

          setValidateDelegeesErrorCode(errorCode)
        });
      
    } else {
      setState("error");
      setError("invalid");
    }
  };

  const readDataFromExcel = (wb: XLSX.WorkBook, delegeesToImport: DelegeeData[]) => {
    const rows: any[] = XLSX.utils.sheet_to_json(wb.Sheets[dprSheetName]);
    rows.forEach((row) => {
      if (row[delegeeName] || row[delegeeEmail]) {
        let delegee: DelegeeData;
        delegee = {
          name: { value: row[delegeeName], isValid: true },
          emailAddress: { value: row[delegeeEmail], isValid: true },
          lineNumber: row[rowNumber] + 1
        };
        delegeesToImport.push(delegee);
      }
    });
  }


  if (!props.show) {
    return null;
  }

  return (
    <React.Fragment>
      {error === undefined && (<Container>
        <NoPaddingBottomGrid container item direction="row" alignItems="center">
          <NoPaddingBottomGrid container item direction="column" xs={6}>
            <Heading fontSize={typography.fontSizeBase}>
              <strong>{t("uploadFile")}</strong>
            </Heading>
          </NoPaddingBottomGrid>
          <NoPaddingBottomGrid container item direction="column" xs={6}>
            <div className="download-template">
              <div>
                <a
                  href="/files/DelegeeImportTemplate.xlsx"
                  target="_blank"
                  download
                >
                  {t("downloadTemplate")}
                </a>
                <KeyboardArrowDown />
              </div>
            </div>
          </NoPaddingBottomGrid>
        </NoPaddingBottomGrid>
      </Container>)}
      <Container className="h-full">
        <DragAndDrop
          onFileUploaded={handleFileUpload}
          supportedFileTypes={supportedFileTypes}
          state={state}
          error={error}
          seasonTicketOwnerCrmId={appState.user.seasonTicketOwnerCrmId as string}
          fileReference={fileReference}
          invalidDelegeesCount={invalidDelegeesCount}
          validateDelegeesErrorCode={validateDelegeesErrorCode}
          generalValidationErrors={generalValidationErrors}
          showMaxFileSize
        />
      </Container>
    </React.Fragment>
  );
};

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  validateDelegees: (
    seasonTicketOwnerCrmId: string,
    req: ValidateDelegeesRequestModel,
    onSuccess: (response: ValidateDelegeesResponseModel) => void,
    onError: (errorCode: string) => void) =>
    dispatch(parkingProductsActions.validateDelegees(
      seasonTicketOwnerCrmId,
      req,
      onSuccess,
      onError))
});

interface DelegeesImportDragAndDropProps extends DispatchProps {
  show: boolean;
  onFileProcessed: (data: DelegeeData[]) => void;
  onCheckedParkingRights: (pr: ICheckParkingRight[]) => void;
  onProcessing: (processing: boolean) => void;
}

type DispatchProps = ReturnType<typeof mapDispatchToProps>;

export default connect(null, mapDispatchToProps)(DelegeesImportDragAndDrop);
