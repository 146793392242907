import React from "react";
import styled from "styled-components";
import { variables } from "../../theme/variables";

const { whiteSpaces } = variables;

type Size = "2xs" | "xs" | "sm" | "md" | "lg" | "xl";

interface ISpacerProps {
  size?: Size;
}

const SpacerSpan = styled.span`
  display: block;
  padding-top: ${(props: ISpacerProps) => whiteSpaces[props.size!] || whiteSpaces.xs};
  width: 100%;
`;

const Spacer = ({ size }: ISpacerProps) => <SpacerSpan size={size} />;

export default Spacer;
