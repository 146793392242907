import { createStore, applyMiddleware, compose } from "redux";
import rootReducer from "./reducers";
import { history, middlewares } from "./buildEnv";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";

const composeEnhancers : typeof compose = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(rootReducer(history), composeEnhancers(applyMiddleware(...middlewares)));

export default store;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<typeof store.dispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;