import React from "react";

interface IProps {
  environment: string;
  version: string;
}

const EnvironmentInfo = (props: IProps) => {
  const { environment, version } = props;

  return (
    <>
      {environment.toLowerCase() !== "production" && (
        <div className="environment-info">
          {environment} - {version}
        </div>
      )}
    </>
  );
};

export default EnvironmentInfo;
