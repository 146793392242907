import Grid from "@material-ui/core/Grid";
import React from "react";
import styled from "styled-components";
import WarningFleet from "../../assets/images/warning-fleet.svg";
import WarningFleetOrange from "../../assets/images/warning-fleet-orange.svg";
import { variables } from "../../theme/variables";
import CircleImage from "../CircleImage/CircleImage";
import Spacer from "../Spacer/Spacer";


const { colors } = variables;

const ContentWrapper = styled.div`
  text-align: center;
`;

const TextWrapper = styled.div`
  max-width: 20rem;
`;

const Details = styled.span`
  color: ${colors.darkGray};
`;

const NoPaddingCol = styled(Grid)`
  padding: 0;
`;

interface IProps {
    title: string,
    details: string,
    extraRows?: React.ReactNode[],
    orangeWarning?: boolean
}


const WarningColumn = (props : IProps) => {
    
    const renderExtraRows = () => 
        props.extraRows?.map(child => (
            <Grid item xs={12} alignContent="center">
                {child}
            </Grid>
            ))    
    
  return (
        <NoPaddingCol container justifyContent="center" alignItems="center" direction="column">
          <ContentWrapper>
            <Grid container item direction="row" xs={12} justifyContent="center">
              <CircleImage src={props.orangeWarning ? WarningFleetOrange : WarningFleet} />
            </Grid>
            <Grid item xs={12}>
              <Spacer size="sm"/>
              <TextWrapper>
                <h2>{props.title}</h2>
                <Spacer size = "xs"/>
                <Details>{props.details}</Details>
              </TextWrapper>
            </Grid>
            {renderExtraRows()} 
          </ContentWrapper>
        </NoPaddingCol>
  );
};

export default WarningColumn;
