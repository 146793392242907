import * as React from "react";
import "../../../../../../../../../ui/Icons/_visitorBookingCreation.scss";

export default function VisitorBookingErrorMessage(props: Readonly<{ children?: React.ReactNode }>) {
  const { children } = props;

  return (
    <div className="warning-message">
      <div className="icon-column">
        {/* inline image for performances, same image as /assets/images/exclamation-mark-red-circle.svg*/}
        <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M12.9992 25.7992C20.0685 25.7992 25.7992 20.0685 25.7992 12.9992C25.7992 5.92997 20.0685 0.199219 12.9992 0.199219C5.92997 0.199219 0.199219 5.92997 0.199219 12.9992C0.199219 20.0685 5.92997 25.7992 12.9992 25.7992Z" fill="#D01535" />
          <path d="M13.072 20.1101C11.9002 20.1101 10.9468 19.1567 10.9468 17.9848C10.9468 16.813 11.9002 15.8596 13.072 15.8596C14.2439 15.8596 15.1972 16.813 15.1972 17.9848C15.1972 19.1567 14.2439 20.1101 13.072 20.1101ZM13.5293 15.4798H12.6129C11.926 15.4798 11.3344 14.9444 11.2661 14.2608L10.6487 8.08724C10.6447 8.04746 10.642 7.99329 10.642 7.95267C10.642 7.20634 11.2492 6.59915 11.9955 6.59915H14.1486C14.8945 6.60105 15.5001 7.20823 15.5001 7.95267C15.5001 7.99329 15.4974 8.04746 15.4934 8.08746L14.8761 14.2609C14.8077 14.9444 14.2162 15.4798 13.5293 15.4798Z" fill="white" />
          <path d="M11.9955 7.18097C11.5695 7.18097 11.2238 7.52669 11.2238 7.95267C11.2238 7.97386 11.2255 8.00826 11.2276 8.02935L11.845 14.203C11.8833 14.5866 12.2273 14.898 12.6129 14.898H13.5293C13.9148 14.898 14.2588 14.5866 14.2971 14.203L14.9145 8.02935C14.9166 8.00826 14.9183 7.97386 14.9183 7.95267C14.9183 7.52777 14.5735 7.18205 14.1486 7.18097H11.9955ZM13.072 16.4414C12.2201 16.4414 11.5286 17.1329 11.5286 17.9848C11.5286 18.8368 12.2201 19.5282 13.072 19.5282C13.924 19.5282 14.6154 18.8368 14.6154 17.9848C14.6154 17.1329 13.924 16.4414 13.072 16.4414ZM11.9955 6.01733H14.1486C15.2159 6.02005 16.0819 6.88824 16.0819 7.95267C16.0819 8.01253 16.0782 8.08644 16.0723 8.14558L15.455 14.3188C15.3956 14.9126 15.0601 15.4282 14.5856 15.7415C15.3052 16.2285 15.7791 17.0524 15.7791 17.9848C15.7791 19.4775 14.5647 20.6919 13.072 20.6919C11.5794 20.6919 10.365 19.4775 10.365 17.9848C10.365 17.0527 10.8384 16.2292 11.5575 15.7421C11.0825 15.4289 10.7465 14.913 10.6871 14.3187L10.0698 8.14513C10.0639 8.08644 10.0602 8.01253 10.0602 7.95267C10.0602 6.88552 10.9284 6.01733 11.9955 6.01733Z" fill="#BD1330" />
        </svg>
      </div>
      <div className="message-column">
        {children}
      </div>
    </div>
  );
}
