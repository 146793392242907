import useAppContext from "../context/hooks/useAppContext";
import { EnsureValidValue } from "../context/store/reducers/importDelegations.reducer";
import { equalsIgnoreCase } from "../utils";
import emailValidator from "../validators/email.validator";

const isValidEmail = (
  emailAddress: string,
  allEmails?: string[],
  currentUserEmail?: string
): EnsureValidValue<string> => {
  if (currentUserEmail) {
    if (equalsIgnoreCase(emailAddress, currentUserEmail))
      return {
        isValid: false,
        value: emailAddress,
        errorMessageKey: "errors:emailSelf",
      };
  }

  if (!emailValidator.isValidEmail(emailAddress)) {
    return { isValid: false, value: emailAddress, errorMessageKey: "errors:format.email" };
  }

  if (allEmails) {
    let count = 0;
    for (const e of allEmails) {
      if (equalsIgnoreCase(e, emailAddress)) {
        count++;
      }

      if (count > 1) {
        return { isValid: false, value: emailAddress, errorMessageKey: "errors:emailExists" };
      }
    }
  }

  return {
    isValid: true,
    value: emailAddress,
  };
};

const useIsValidDelegeeEmail = () => {
  const appCtx = useAppContext();
  const currentUserEmail = appCtx.appState.user.email;
  return (emailAddress: string, allEmails?: string[]) =>
    isValidEmail(emailAddress, allEmails, currentUserEmail);
};

export default useIsValidDelegeeEmail;
