import { Grid } from "@material-ui/core";
import moment from "moment";
import React, { memo, useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { filter, isEmpty, isNull, isUndefined } from "underscore";
import { QParkDatePickerV2 } from "../../../../../../../../components/QParkDatePickerV2";
import { QParkTimePicker } from "../../../../../../../../components/QParkTimePicker";
import { VisitorBookingProductConfiguration } from "../../../../../../../../models/visitor-booking";
import { variables } from "../../../../../../../../theme/variables";
import RequiredInputLabel from "../../../../../../../../ui/RequiredInputLabel/RequiredInputLabel";
import Spacer from "../../../../../../../../ui/Spacer/Spacer";
import { Fields, ProductTimeframeFormProps } from "./ProductTimeframeForm.types";
import "./_product-timeframe-form.scss";
import { ContentCard } from "../../../../../../../../ui/ContentCard";
import NoFacilitySelected from "../ui/NoFacilitySelected";
import { useProductTimeframeFormBulk } from "./hooks/useProductTimeframeFormBulk";
import QParkSelectCardsBulk from "../../../../../../../../components/QParkSelectCards/QParkSelectCardsBulk";

const { typography } = variables;

const ProductTimeframeFormBulk = memo((props: ProductTimeframeFormProps) => {
  const {
    products,
    productId,
    startDate,
    startTime,
    endDate,
    endTime,
    physicalZone,
    setFieldValue,
    setFieldError,
    titlePrefixProduct,
    titlePrefixTime,
  } = props;
  const { t } = useTranslation(["visitors"]);

  const {
    jsStartDate,
    jsMaxEndDate,
    isHourlyRateProduct,
    minEndTime,
    minStartTime,
    onStartDateChange,
    onEndDateChange,
    onStartTimeChange,
    onEndTimeChange,
    onProductSelect,
    resetTimeframeOnProductChange,
  } = useProductTimeframeFormBulk(
    products,
    startDate.value,
    startTime.value,
    endDate.value,
    endTime.value,
    productId.value,
    setFieldValue,
    physicalZone.value
  );

  const today = useMemo(() => new Date(), []);
  const todayNextYear = useMemo(() => moment(today).add(1, "year").toDate(), [today]);
  let oldSelectedProduct: string | undefined = productId.value;

  const showWhenHourlyProductSelected = useMemo(
    () => ({
      display: !isHourlyRateProduct ? "none" : "inherit",
    }),
    [isHourlyRateProduct]
  );

  const isPhysicalZoneSelected = useMemo(
    () => !physicalZone.hasError && !isEmpty(physicalZone.value) && !isNull(physicalZone.value),
    [physicalZone]
  );

  const productsByPhysicalZone = useMemo((): VisitorBookingProductConfiguration[] => {
    if (!isPhysicalZoneSelected) {
      return [];
    }

    const found = filter(
      products,
      (product) =>
        product.allowedPhysicalZoneIds.find((pz) =>
          pz.toLocaleLowerCase().includes(physicalZone.value?.toLocaleLowerCase() as string)
        ) !== undefined
    );

    if (isUndefined(found)) {
      return [];
    }

    return found;
  }, [physicalZone, isPhysicalZoneSelected]);

  const productOptions = useMemo(
    () =>
      productsByPhysicalZone.map((p) => ({
        option: p.name,
        value: p.id,
      })),
    [productsByPhysicalZone]
  );

  const clearDateErrorOnChange =
    (field: Fields, action: (date: Date | null) => void) => (date: Date | null) => {
      setFieldError(field, undefined);
      action(date);
    };

  const clearTimeErrorOnChange =
    (field: Fields, action: (time: string) => void) => (time: string) => {
      setFieldError(field, undefined);
      action(time);
    };

  const clearAllFieldsOnMount = useCallback(() => {
    if (!isPhysicalZoneSelected) {
      return;
    }

    setFieldError(Fields.StartDate, undefined);
    setFieldError(Fields.StartTime, undefined);
    setFieldError(Fields.EndDate, undefined);
    setFieldError(Fields.EndTime, undefined);
  }, [isPhysicalZoneSelected]);

  useEffect(() => clearAllFieldsOnMount(), [clearAllFieldsOnMount]);

  const OnBulkProductSelect = (value?: string | number) => {
    if (oldSelectedProduct === value) {
      return;
    }

    oldSelectedProduct = value?.toString();
    onProductSelect(value);
    resetTimeframeOnProductChange();

    const element = document.getElementById("vb-time-select");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <React.Fragment>
      <Grid item container direction="row" xs={12}>
        <ContentCard
          title={`${titlePrefixProduct ?? ""}${t("productTimeframeForm.productSelectionTitle")}`}
        >
          <Spacer size="xs" />
          <Grid item container direction="row" className="no-padding-important" xs={12}>
            <Grid item container direction="column" className="no-padding-important" xs={12}>
              {!isPhysicalZoneSelected ? (
                <NoFacilitySelected />
              ) : (
                <QParkSelectCardsBulk
                  options={productOptions}
                  onChange={OnBulkProductSelect}
                  initSelected={productId.value}
                />
              )}
            </Grid>
          </Grid>
        </ContentCard>
      </Grid>

      <Grid item container direction="row" xs={12} style={{ paddingTop: 0 }}>
        <ContentCard
          title={`${titlePrefixTime ?? ""}${t("productTimeframeForm.timeframeSelectionTitle")}`}
        >
          <Spacer size="xs" />
          {!isPhysicalZoneSelected ? (
            <Grid item container direction="row" className="no-padding-important" xs={12}>
              <Grid item container direction="column" className="no-padding-important" xs={12}>
                <NoFacilitySelected />
              </Grid>
            </Grid>
          ) : (
            <Grid
              item
              container
              direction="row"
              id="vb-time-select"
              className="no-padding-important"
              xs={12}
            >
              <Grid item container direction="column" xs={12}>
                <Grid
                  item
                  container
                  direction="row"
                  xs={12}
                  className="no-padding-important"
                  spacing={2}
                >
                  <Grid
                    item
                    container
                    direction="column"
                    justifyContent="center"
                    xs={12}
                    xl={3}
                    lg={3}
                    md={3}
                    sm={12}
                  >
                    <RequiredInputLabel
                      fontSize={typography.fontSizeSmaller}
                      htmlFor={Fields.StartDate}
                    >
                      {t(
                        isHourlyRateProduct
                          ? "productTimeframeForm.startDateAndTime"
                          : "productTimeframeForm.startDate"
                      )}
                    </RequiredInputLabel>
                  </Grid>
                  <Grid item container direction="column" xs={8} xl={3} lg={4} md={5} sm={8}>
                    <QParkDatePickerV2
                      value={startDate.value}
                      error={startDate.hasError}
                      helperText={startDate.error}
                      minDate={today}
                      maxDate={todayNextYear}
                      onChange={clearDateErrorOnChange(Fields.StartDate, onStartDateChange)}
                    />
                  </Grid>
                  <Grid
                    item
                    container
                    direction="column"
                    xs={4}
                    xl={2}
                    lg={3}
                    md={4}
                    sm={4}
                    style={showWhenHourlyProductSelected}
                  >
                    <QParkTimePicker
                      minTime={minStartTime}
                      value={startTime.value}
                      onChange={clearTimeErrorOnChange(Fields.StartTime, onStartTimeChange)}
                      error={startTime.hasError}
                      helperText={startTime.error}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item container direction="column" xs={12} style={showWhenHourlyProductSelected}>
                <Grid
                  item
                  container
                  direction="row"
                  xs={12}
                  className="no-padding-important"
                  spacing={2}
                >
                  <Grid
                    item
                    container
                    direction="column"
                    justifyContent="center"
                    xs={12}
                    xl={3}
                    lg={3}
                    md={3}
                    sm={12}
                  >
                    <RequiredInputLabel
                      fontSize={typography.fontSizeSmaller}
                      htmlFor={Fields.EndDate}
                    >
                      {t("productTimeframeForm.endDateAndTime")}
                    </RequiredInputLabel>
                  </Grid>
                  <Grid item container direction="column" xs={8} xl={3} lg={4} md={5} sm={8}>
                    <QParkDatePickerV2
                      minDate={jsStartDate}
                      maxDate={jsMaxEndDate}
                      value={endDate.value}
                      error={endDate.hasError}
                      helperText={endDate.error}
                      onChange={clearDateErrorOnChange(Fields.EndDate, onEndDateChange)}
                    />
                  </Grid>
                  <Grid item container direction="column" xs={4} xl={2} lg={3} md={4} sm={4}>
                    <QParkTimePicker
                      minTime={minEndTime}
                      value={endTime.value}
                      onChange={clearTimeErrorOnChange(Fields.EndTime, onEndTimeChange)}
                      error={endTime.hasError}
                      helperText={endTime.error}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
        </ContentCard>
      </Grid>
    </React.Fragment>
  );
});

export default ProductTimeframeFormBulk;
