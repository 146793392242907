import * as React from "react";
import Flag from "react-world-flags";
import styled from "styled-components";
import { variables } from "../../theme/variables";
import { ReactComponent as ArrowRightIcon } from "../../assets/images/arrow-right.svg";

const { colors, typography } = variables;

interface IFaqButtonProps {
  countryName: string;
  countryCode: string;
  url: string;
}

const FaqButton: React.FC<IFaqButtonProps> = ({ countryCode, countryName, url }) => (
  <ButtonContainer target="_blank" href={url}>
    <FlagAndCountryContainer>
      <FlagContainer>
        <Flag code={countryCode} height="100%" />
      </FlagContainer>
      <CountryNameContainer>
        <span>{countryName}</span>
      </CountryNameContainer>
    </FlagAndCountryContainer>
    <IconContainer>
      <ArrowRightIcon />
    </IconContainer>
  </ButtonContainer>
);

const ButtonContainer = styled.a`
  background-color: ${colors.white};
  border: 1px solid ${colors.veryLightGray};
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5em 1em;
  height: 50px;
  border-radius: 6px;
  text-decoration: none;
  width: 100%;
  color: ${colors.black};

  &:hover {
    cursor: pointer;
  }
`;

const FlagAndCountryContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  width: 100%;
`;

const FlagContainer = styled.div`
  width: 26px;
  height: 21px;
  border-radius: 6px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CountryNameContainer = styled.div`
  padding: 0 1em;
  font-size: ${typography.fontSizeBase};
`;

const IconContainer = styled.div`
  color: ${colors.black};
`;

export default FaqButton;
