import { Backdrop } from "@material-ui/core";
import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { RootReducer } from "../../store/reducers";
import CustomSpinner from "../../ui/CustomSpinner/CustomSpinner";

const CustomBackdrop = styled(Backdrop)`
  z-index: 3020;
`;

interface IAppLoaderState extends StateProps {}

const AppLoader: React.FC<IAppLoaderState> = ({ loader }) => (
  <CustomBackdrop open={loader.showLoader}>
    <CustomSpinner.LightGray />
  </CustomBackdrop>
);

const mapStateToProps = (state: RootReducer) => {
  const { loader } = state;

  return {
    loader,
  };
};

type StateProps = ReturnType<typeof mapStateToProps>;

export default connect(mapStateToProps)(AppLoader);
