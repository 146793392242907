import React, { useCallback, useEffect, useState } from "react";
import {
  every,
  findWhere,
  isEmpty,
  isNull,
  isUndefined,
  where,
} from "underscore";
import { NoPaddingGrid } from "../../UserCard/UserCardStyledComponents";
import {
  DataTableContextFilters,
  useDataTableContext,
  DateFilter as DateFilterType,
} from "../DataTableContext/DataTableContextProvider";
import { Column, MultiSelectOption } from "../DataTableHead/DataTableHead";
import { HeaderPaddedGrid } from "../DataTableStyledComponents";
import RefreshIcon from "@material-ui/icons/Refresh";
import CustomButton from "../../../ui/CustomButton/CustomButton";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import SelectFilter from "./DataTableFilterTypes/SelectFilter";
import InputFilter from "./DataTableFilterTypes/InputFilter";
import { variables } from "../../../theme/variables";
import DateFilter from "./DataTableFilterTypes/DateFilter";
import { GrayFilterArrow } from "./CommonStyledComponents";

const { colors } = variables;

const StyledRefreshIcon = styled(RefreshIcon)`
  height: 20px;
  width: 20px;
  color: ${colors.darkGraySecondary};
  cursor: pointer;
`;

export type FilterRef = {
  onFilterClicked: () => void;
};

export enum FilterType {
  Text,
  Multiselect,
  Date,
  QueryParam,
}

export interface IFilter {
  key: string;
  type?: FilterType;
  value?: string;
  options?: MultiSelectOption[];
  placeholder?: string;
  customDateKeys?: { startKey: string; endKey: string };
}

export interface IFilteredColumn<T> extends Column<T> {
  enableFiltering?: boolean;
  filter?: IFilter;
}

export interface IDataTableFiltersProps<T> {
  columns: IFilteredColumn<T>[];
  showFilters: boolean;
  onFilterClicked: () => void;
  onResetFiltersClicked?: () => void;
  filtersRef?: React.MutableRefObject<FilterRef>;
}

const DataTableFilters = <T,>({
  columns,
  showFilters,
  onFilterClicked,
  onResetFiltersClicked,
  filtersRef,
}: IDataTableFiltersProps<T>) => {
  const filteredColumns = where(columns, { enableFiltering: true });
  const { state, setFilter, resetFiltersAsync, resetPageNumber } =
    useDataTableContext();
  const { t } = useTranslation("dataTable");

  const [filtered, setFiltered] = useState<boolean>(false);

  const onFiltering = () => {
    resetPageNumber();
    onFilterClicked();
    setFiltered(true);

    if (!isUndefined(filtersRef)) {
      filtersRef.current.onFilterClicked();
    }
  };

  const onResetFilters = () => {
    resetFiltersAsync().then((result) => {
      setFiltered(false);

      if (result && !isUndefined(onResetFiltersClicked)) {
        onResetFiltersClicked();
      }
    });
  };

  const resetWhenInputsAreEmpty = useCallback(() => {
    let inputsEmpty = every(
      state.filters as DataTableContextFilters[],
      (filter) =>
        isEmpty(filter.value) ||
        isNull((filter.value as DateFilterType).startDate) ||
        isNull((filter.value as DateFilterType).endDate)
    );

    if (filtered && inputsEmpty && !isUndefined(onResetFiltersClicked)) {
      onResetFiltersClicked();
    }

    // eslint-disable-next-line
  }, [state.filters]);

  const initFilter = (column: IFilteredColumn<T>) => {
    if (isUndefined(column.filter)) {
      return;
    }

    const { filter } = column;
    if (filter.type === FilterType.Text) {
      setFilter(filter.key, FilterType.Text, "");
    }

    if (filter.type === FilterType.Multiselect) {
      setFilter(filter.key, FilterType.Multiselect, [] as string[]);
    }

    if (filter.type === FilterType.Date) {
      setFilter(filter.key, FilterType.Date, {
        startDate: null,
        endDate: null,
        customKeys: filter.customDateKeys,
      });
    }
  };

  const determineArrowVisibility = () => {
    const filtersBar = document.getElementById("filters-bar");
    if (filtersBar) {
      const leftArrow = document.getElementById("filters-left-arrow");
      const rightArrow = document.getElementById("filters-right-arrow");
      const arrowsVisible = filtersBar.scrollWidth > filtersBar.clientWidth;
      const leftArrowVisible = arrowsVisible && filtersBar.scrollLeft > 0;
      const rightArrowVisible =
        arrowsVisible && filtersBar.scrollWidth > filtersBar.clientWidth;

      leftArrow?.style.setProperty(
        "display",
        leftArrowVisible ? "block" : "none"
      );
      rightArrow?.style.setProperty(
        "display",
        rightArrowVisible ? "block" : "none"
      );
    }
  };

  useEffect(() => {
    if (isEmpty(state.filters)) {
      filteredColumns.forEach(initFilter);
    }

    if (state.showFilters) {
      determineArrowVisibility();
      window.addEventListener("resize", determineArrowVisibility);

      return () => {
        window.removeEventListener("resize", determineArrowVisibility);
      };
    }

    // eslint-disable-next-line
  }, [state.showFilters]);

  useEffect(() => {
    resetWhenInputsAreEmpty();
  }, [resetWhenInputsAreEmpty]);

  if (!showFilters) {
    return null;
  }

  const Filters = (column: IFilteredColumn<T>, index: number) => {
    if (isUndefined(column.filter)) {
      return null;
    }

    const { filter } = column;
    const found = findWhere(state.filters as DataTableContextFilters[], {
      key: filter.key,
    });

    if (filter.type === FilterType.Text) {
      return <InputFilter key={index} filter={filter} entity={found} />;
    }

    if (filter.type === FilterType.Multiselect) {
      return <SelectFilter key={index} filter={filter} entity={found} />;
    }

    if (filter.type === FilterType.Date) {
      return <DateFilter key={index} filter={filter} entity={found} />;
    }

    return null;
  };

  const hasAnyValue = (): boolean => {
    let result = false;

    if (!isUndefined(state.filters)) {
      state.filters.forEach((filter) => {
        if (
          (filter.type === FilterType.Text ||
            filter.type === FilterType.Multiselect) &&
          !isEmpty(filter.value)
        ) {
          result = true;

          return;
        }

        if (filter.type === FilterType.Date) {
          const { startDate, endDate } = filter.value as DateFilterType;
          if (!isNull(startDate) && !isNull(endDate)) {
            result = true;

            return;
          }
        }
      });
    }

    return result;
  };

  const handleArrowClick = (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    const filtersBar = document.getElementById("filters-bar");
    if (filtersBar) {
      const arrowId = (e.target as HTMLInputElement).id;

      const isLeftArrow = arrowId === "filters-left-arrow";
      filtersBar.scrollLeft += isLeftArrow ? -150 : 150;

      const leftArrow = document.getElementById("filters-left-arrow");
      const rightArrow = document.getElementById("filters-right-arrow");
      if (isLeftArrow && leftArrow) {
        rightArrow?.style.setProperty("display", "block");

        if (filtersBar.scrollLeft <= 0) {
          leftArrow.style.setProperty("display", "none");
        }
      } else if (!isLeftArrow && rightArrow) {
        leftArrow?.style.setProperty("display", "block");

        const maxScrollLeft = filtersBar.scrollWidth - filtersBar.clientWidth;
        if (filtersBar.scrollLeft >= maxScrollLeft) {
          rightArrow.style.setProperty("display", "none");
        }
      }
    }
  };

  const filterBtnDisabled = !hasAnyValue();

  return (
    <HeaderPaddedGrid
      className="table-filters"
      item
      container
      direction="row"
      xs={12}
    >
      <NoPaddingGrid
        container
        item
        direction="column"
        alignItems="flex-start"
        md={10}
      >
        <NoPaddingGrid
          id="filter-bar-area"
          item
          container
          direction="row"
          alignItems="center"
          xs={12}
        >
          <GrayFilterArrow
            id="filters-left-arrow"
            onClick={(e) => handleArrowClick(e)}
          ></GrayFilterArrow>

          <div className="horizontal-scroll-wrapper" id="filters-bar">
            {filteredColumns.map((col, index) => Filters(col, index))}
          </div>

          <GrayFilterArrow
            id="filters-right-arrow"
            onClick={(e) => handleArrowClick(e)}
          ></GrayFilterArrow>
        </NoPaddingGrid>
      </NoPaddingGrid>

      <NoPaddingGrid
        item
        container
        direction="row"
        justifyContent="flex-end"
        alignItems="flex-end"
        md={2}
      >
        <div className="vb-filter-button">
          <NoPaddingGrid
            item
            container
            direction="row"
            justifyContent="flex-end"
            xs={12}
            lg={9}
          >
            <NoPaddingGrid
              item
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="flex-start"
              md={10}
              lg={9}
            >
              <CustomButton.Primary
                disabled={filterBtnDisabled}
                text={t("filter")}
                onClick={onFiltering}
              />
            </NoPaddingGrid>
            {!filterBtnDisabled && (
              <NoPaddingGrid
                item
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                md={2}
                lg={3}
              >
                <StyledRefreshIcon onClick={onResetFilters} />
              </NoPaddingGrid>
            )}
          </NoPaddingGrid>
        </div>
      </NoPaddingGrid>
    </HeaderPaddedGrid>
  );
};

export default DataTableFilters;
