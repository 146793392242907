import React, { memo, useEffect, useState } from "react";
import { isEmpty, isUndefined } from "underscore";
import QParkSelectCardItem from "./components/ui/QParkSelectCardItem";
import { QParkSelectCardsProps } from "./QParkSelectCards.types";
import "./_qpark-select-cards.scss";

const QParkSelectCardsBulk = memo((props: QParkSelectCardsProps) => {
  const { onChange, options = [], initSelected } = props;

  const defaultVal = isEmpty(options) ? "" : options[0].value;
  const initVal = initSelected ? initSelected : defaultVal;

  const [selected, setSelected] = useState<string | number | undefined>(
    initVal
  );

  useEffect(() => {
    setSelected((c) => (c = initVal));

    if (!isUndefined(onChange) && !initSelected) {
      onChange(initVal);
    }
  }, [options]);

  const onClick = (value?: string | number) => {
    setSelected((c) => (c = value));

    if (!isUndefined(onChange)) {
      onChange(value);
    }
  };

  return (
    <ul>
      {options.map((p) => (
        <QParkSelectCardItem
          key={p.value}
          value={p.value}
          selected={selected}
          onClick={onClick}
        >
          {p.option}
        </QParkSelectCardItem>
      ))}
    </ul>
  );
});

export default QParkSelectCardsBulk;
