const baseUrl = "/api/ai";

type AiApiType = {
  getInstrumentationKeyUrl: () => string;
};

const getInstrumentationKeyUrl = () => `${baseUrl}/getInstrumentationKey`;

export default {
  getInstrumentationKeyUrl,
} as AiApiType;
