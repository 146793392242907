import React from "react";
import AppLoader from "../../components/AppLoader/AppLoader";
import FlexLayout from "./Flex-Layout/Flex-Layout";

interface IProps {
  children: React.ReactNode;
}

const Layout = ({ children }: IProps) => (
  <React.Fragment>
    <AppLoader />
    <FlexLayout>{children}</FlexLayout>
  </React.Fragment>
);

export default Layout;
