import React from "react";
import { Tooltip } from "@material-ui/core";
import { CheckCircleOutline, RemoveCircleOutline } from "@material-ui/icons";

interface IProps {
  showSelectAll: boolean;
  selectAllTooltipText: string;
  deselectAllTooltipText: string;
  selectAllText: string;
  deselectAllText: string;
  onSelectAll: () => void;
  onDeselectAll: () => void;
}

const ToggleSelectAll = (props: IProps) => (
  <Tooltip
    arrow
    title={props.showSelectAll ? props.deselectAllTooltipText : props.selectAllTooltipText}
    placement="top"
    className="d-flex align-center"
  >
    <div>
      {props.showSelectAll ? (
        <RemoveCircleOutline classes={{ root: "dark-gray smaller-important" }} />
      ) : (
        <CheckCircleOutline classes={{ root: "dark-gray smaller-important" }} />
      )}
      &nbsp;
      <span
        className="dark-gray font-size-smaller hoverable"
        onClick={() => (props.showSelectAll ? props.onDeselectAll() : props.onSelectAll())}
        role="button"
      >
        {props.showSelectAll ? props.deselectAllText : props.selectAllText}
      </span>
    </div>
  </Tooltip>
);

export default ToggleSelectAll;
