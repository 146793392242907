import { isEmpty, isNull } from "underscore";
import { VisitorBookingNewBookingBulkDetails } from "../containers/Visitors/BookingOverview/NewBooking/models/VisitorBookingNewBookingBulk";
import { ProductType } from "../models/visitor-booking/ProductType";

export const getParametersForGetProductSalesAvailability = (
  data: VisitorBookingNewBookingBulkDetails
) => {
  const isHourlyRateProduct = data.productType === ProductType.HourlyRate;
  const startDateTime = isHourlyRateProduct
    ? `${data.startDate} ${data.startTime}`
    : `${data.startDate} 00:00`;
  const endDateTime = isHourlyRateProduct ? `${data.endDate} ${data.endTime}` : startDateTime;

  return [startDateTime, endDateTime];
};

export const isTimeframeCorrectlySet = (data: VisitorBookingNewBookingBulkDetails) => {
  const isHourlyRateProduct = data.productType === ProductType.HourlyRate;
  const startDate = data.startDate;
  const endDate = data.endDate;
  const startTime = data.startTime;
  const endTime = data.endTime;

  const isFixedRateTimeProperlySelected =
    !isHourlyRateProduct && !isEmpty(startDate) && !isNull(startDate);
  const isHourlyRateTimeProperlySelected =
    isHourlyRateProduct &&
    !isEmpty(startDate) &&
    !isNull(startDate) &&
    !isEmpty(endDate) &&
    !isNull(endDate) &&
    !isEmpty(startTime) &&
    !isNull(startTime) &&
    !isEmpty(endTime) &&
    !isNull(endTime);

  return isHourlyRateTimeProperlySelected || isFixedRateTimeProperlySelected;
};

export const isParkingLocationSet = (physicalZone?: string) =>
  !isNull(physicalZone) &&
  !isEmpty(physicalZone);

export const performOnChange = (data: VisitorBookingNewBookingBulkDetails) =>
  !isNull(data.productId) &&
  !isEmpty(data.productId) &&
  isParkingLocationSet(data.physicalZone);