import React, { memo, useEffect, useState, useCallback } from "react";
import { isEmpty, isEqual, isUndefined } from "underscore";
import QParkSelectCardItem from "./components/ui/QParkSelectCardItem";
import { QParkSelectCardOption, QParkSelectCardsProps } from "./QParkSelectCards.types";
import "./_qpark-select-cards.scss";

const QParkSelectCards = memo((props: QParkSelectCardsProps) => {
  const { onChange, options = [] } = props;
  const [selected, setSelected] = useState<string | number | undefined>(
    isEmpty(options) ? "" : options[0].value
  );

  const [existingOptions, setExistingOptions] = useState<QParkSelectCardOption[]>(
    options
  );

  useEffect(() => {
    if (!isEqual(existingOptions, options)) {
      setSelected((c) => {
        const newSelected = !isEmpty(options) ? options[0].value : "";
        if (!isUndefined(onChange)) {
          onChange(newSelected);
        }
        c = newSelected;
        return c;
      });

      setExistingOptions((c) => (c = options));
    }
  }, [options]);  

  useEffect(() => {
    if (!isEmpty(selected) && !isUndefined(onChange)) {
      onChange(selected);
    }
  } ,[]);

  const onClick = (value?: string | number) => {
    setSelected((c) => (c = value));

    if (!isUndefined(onChange)) {
      onChange(value);
    }
  };

  return (
    <ul>
      {options.map((p) => (
        <QParkSelectCardItem
          key={p.value}
          value={p.value}
          selected={selected}
          onClick={onClick}
        >
          {p.option}
        </QParkSelectCardItem>
      ))}
    </ul>
  );
});

export default QParkSelectCards;
