export const visitorBookingTypes = {
  GET_PRODUCT_CONFIGURATIONS: "visitorBooking/GET_PRODUCT_CONFIGURATIONS",
  GET_PARKING_LOCATIONS: "visitorBooking/GET_PARKING_LOCATIONS",
  CREATE_VISITOR_BOOKING: "visitorBooking/CREATE_VISITOR_BOOKING",
  CREATE_VISITOR_BOOKINGS: "visitorBooking/CREATE_VISITOR_BOOKINGS",
  GET_VISITOR_BOOKINGS: "visitorBooking/GET_VISITOR_BOOKINGS",
  CANCEL_VISITOR_BOOKING: "visitorBooking/CANCEL_VISITOR_BOOKING",
  HANDLE_VISITOR_BOOKING_VALIDATION: "visitorBooking/HANDLE_VISITOR_BOOKING_VALIDATION",
  GET_PRODUCT_SALES_AVAILABILITY: "visitorBooking/GET_PRODUCT_SALES_AVAILABILITY",
  GET_PARKING_FACILITY_IMAGES: "visitorBooking/GET_PARKING_FACILITY_IMAGES"
};
