import store from "../../store";
import { toastActions } from "../../store/actions/toast.actions";
import { ToastTranslationModel } from "../../store/reducers/toast.reducer";

const success = (translationModel : ToastTranslationModel) => store.dispatch(toastActions.success(translationModel) as any);
const error = (translationModel : ToastTranslationModel) => store.dispatch(toastActions.error( translationModel) as any);
const warning = (translationModel: ToastTranslationModel) => store.dispatch(toastActions.warning(translationModel) as any);
const info = (translationModel : ToastTranslationModel) => store.dispatch(toastActions.info( translationModel ) as any);
const hideToast = () => store.dispatch(toastActions.hideToast() as any);

export const toastTriggers = {
  success,
  error,
  warning,
  info,
  hideToast,
};
