type EmailValidator = {
  isValidEmail: (value: string) => boolean;
};

const emailValidator: EmailValidator = (() => {
  const emailPattern =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  const isValidEmail = (value?: string): boolean => {
    return emailPattern.test(value?.toLowerCase()!);
  };

  return {
    isValidEmail,
  };
})();

export default emailValidator;
