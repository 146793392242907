import { createTheme } from "@material-ui/core/styles";
import { Fonts } from "../typography";
import { variables } from "./variables";

const { FuturaD, FuturaT } = Fonts;
const { colors, typography } = variables;

export default createTheme({
  typography: {
    h1: {
      fontFamily: FuturaD.fontFamily,
    },
    h2: {
      fontFamily: FuturaD.fontFamily,
    },
    h3: {
      fontFamily: FuturaD.fontFamily,
    },
    h4: {
      fontFamily: FuturaD.fontFamily,
    },
    h5: {
      fontFamily: FuturaD.fontFamily,
    },
    h6: {
      fontFamily: FuturaD.fontFamily,
    },
    fontFamily: FuturaT.fontFamily,
    fontWeightBold: FuturaD.fontWeight,
    fontWeightRegular: FuturaT.fontWeight,
  },
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
  },
  palette: {
    primary: {
      main: colors.lime.hex,
    },
  },
  overrides: {
    // Font family
    MuiCssBaseline: {
      "@global": {
        "@font-face": [FuturaT, FuturaD],
      },
    },

    // Paper
    MuiPaper: {
      root: {
        boxShadow: "0px 3px 6px #44580A26",
        border: `1px solid ${colors.veryLightGray}`,
      },
      elevation1: {
        boxShadow: "0px 3px 6px #00000008;",
      },
    },

    // Grid
    MuiGrid: {
      root: {
        paddingBottom: "12px",
      },
    },

    // Inputs
    MuiInputBase: {
      root: {
        backgroundColor: colors.white,
      },
      marginDense: {
        fontSize: "15px",
      },
      input: {
        padding: "10px 14px",
      },
    },
    MuiOutlinedInput: {
      input: {
        padding: "10px 14px",
        fontSize: typography.fontSizeSmaller,
      },
      inputMarginDense: {
        padding: "7px 4px",
        fontSize: "15px",
      },
      adornedStart: {
        paddingLeft: "4px",
      },
      adornedEnd: {
        paddingRight: "4px",
      },
      root: {
        "& $notchedOutline": {
          borderColor: colors.lightGrayishBlue.hex,
        },
        "&$focused $notchedOutline": {
          borderColor: colors.lime.hex,
        },
        "&:hover $notchedOutline": {
          borderColor: colors.gray.hex,
        },
        "&$error $notchedOutline": {
          backgroundColor: `rgba(${colors.red.rgb}, .05)`,
          borderColor: colors.red.hex,
        },
      },
    },

    // Select
    MuiSelect: {
      select: {
        padding: "10px 14px",
        "&:focus": {
          backgroundColor: colors.white,
        },
      },
      outlined: {
        paddingLeft: "10px",
      },
    },

    // Helper text
    MuiFormHelperText: {
      root: {
        "&$contained": {
          color: colors.red.hex,
          fontSize: typography.fontSizeSmaller,
          margin: "3px 0",
        },
        "&.Mui-error": {
          color: colors.red.hex,
          fontSize: typography.fontSizeSmaller,
        },
      },
    },

    // Form Control Label
    MuiFormControlLabel: {
      label: {
        fontSize: typography.fontSizeSmaller,
      },
    },

    // Label
    MuiFormLabel: {
      root: {
        color: colors.black,
        fontSize: typography.fontSizeSmaller,
      },
    },

    // Buttons
    MuiButton: {
      contained: {
        minWidth: "84px",
        backgroundColor: colors.white,
        color: colors.black,
        textTransform: "none",
        fontFamily: FuturaT.fontFamily,
        fontSize: typography.fontSizeSmaller,
        boxShadow: "0px 3px 6px #44580A26",
        borderRadius: "4px",
        border: `1px solid ${colors.veryLightGray}`,
        "&:hover": {
          backgroundColor: colors.mostlyWhite,
          boxShadow: "0px 3px 6px #44580A26",
        },
        "&.Mui-disabled": {
          color: colors.white,
          backgroundColor: colors.grayishBlue.hex,
          borderColor: colors.grayishBlue.hex,
          boxShadow: "0px 3px 6px #44580A26",
        },
      },
      containedPrimary: {
        backgroundColor: colors.lime.hex,
        color: colors.white,
        fontFamily: FuturaD.fontFamily,
        border: "1px solid #0000000F",
        "&:hover": {
          backgroundColor: `${colors.darkerLime} !important`,
        },
        "&:active": {
          backgroundColor: `${colors.darkerLime} !important`,
        },
        "&:focus": {
          backgroundColor: `${colors.darkerLime} !important`,
        },
      },
      containedSecondary: {
        backgroundColor: colors.red.hex,
        color: colors.white,
        fontFamily: FuturaD.fontFamily,
        border: "1px solid #0000000F",
        "&:hover": {
          backgroundColor: colors.darkRed,
        },
        "&:active": {
          backgroundColor: colors.darkRed,
        },
        "&:focus": {
          backgroundColor: colors.darkRed,
        },
      },
      textPrimary: {
        color: colors.lime.hex,
        fontFamily: FuturaT.fontFamily,
        textTransform: "none",
      },
    },
    MuiIconButton: {
      root: {
        "&:hover": {
          backgroundColor: "none",
        },
      },
      colorSecondary: {
        color: colors.red.hex,
        "&:hover": {
          backgroundColor: `rgba(${colors.red.rgb}, 0.1)`,
        },
      },
    },
    MuiButtonGroup: {
      contained: {
        boxShadow: "0px 3px 6px #44580A26",
        "&:hover": {
          boxShadow: "0px 3px 6px #44580A26",
        },
      },
    },

    // Stepper
    MuiStepper: {
      root: {
        border: "none",
        padding: 0,
      },
    },
    MuiStepIcon: {
      root: {
        color: colors.veryLightGray,
        "&.MuiStepIcon-active": {
          color: colors.lime.hex,
        },
        "&.MuiStepIcon-completed": {
          color: colors.lime.hex,
        },
      },
      active: {
        color: colors.lime.hex,
      },
      completed: {
        color: colors.lime.hex,
      },
    },
    MuiStepConnector: {
      root: {
        "& $line": {
          borderColor: colors.veryLightGray,
        },
      },
      active: {
        "& $line": {
          borderColor: colors.lime.hex,
        },
      },
      completed: {
        "& $line": {
          borderColor: colors.lime.hex,
        },
      },
      line: {
        borderColor: colors.veryLightGray,
      },
    },

    // Tooltip
    MuiTooltip: {
      tooltip: {
        fontSize: typography.fontSizeSmaller,
        textAlign: "center",
      },
    },

    // MuiCard
    MuiCardMedia: {
      root: {
        backgroundColor: colors.mostlyBlack,
      },
    },

    // Table
    MuiTableContainer: {
      root: {
        borderRadius: "4px",
        border: `1px solid ${colors.lightGrayishBlue.hex}`,
        backgroundColor: colors.white,
        zIndex: 0,
      },
    },
    MuiTableHead: {
      root: {
        backgroundColor: colors.veryLightGraySecondary,
        borderBottom: `1px solid ${colors.lightGrayishBlue.hex}`,
      },
    },
    MuiTableSortLabel: {
      root: {
        display: "flex",
        justifyContent: "space-between",
        color: colors.black,
      },
    },
    MuiTableRow: {
      root: {
        fontSize: typography.fontSizeSmaller,
        "&.MuiTableRow-hover": {
          "&:hover": {
            backgroundColor: "rgba(0, 0, 0, .04)",
          },
        },
        "&.Mui-selected": {
          backgroundColor: "inherit",
          "&:hover": {
            backgroundColor: "rgba(0, 0, 0, .04)",
          },
        },
        "&:last-child": {
          "& > .MuiTableCell-root": {
            borderBottom: "none",
          },
        },
        "&:nth-child(even)": {
          backgroundColor: colors.mostlyWhite,
        },
      },
    },
    MuiTableCell: {
      root: {
        borderBottom: `1px solid ${colors.lightGrayishBlue.hex}`,
      },
      body: {
        color: colors.black,
      },
      sizeSmall: {
        padding: "10px 12px",
        "&.MuiTableCell-paddingCheckbox": {
          padding: "0 10px",
        },
        "&.MuiTableCell-head": {
          padding: "4px 12px",
          textTransform: "uppercase",
          fontSize: typography.fontSizeSmall,
          borderBottom: `1px solid ${colors.lightGrayishBlue.hex} !important`,
          "&.MuiTableCell-paddingCheckbox": {
            padding: "0 10px",
          },
          "&:not(:first-child)": {
            borderRight: `1px solid ${colors.lightGrayishBlue.hex}`,
          },
          "&:last-child": {
            borderRight: "none",
          },
          "&:nth-last-child(2)": {
            borderRight: "none",
          },
        },
      },
    },

    // List
    MuiList: {
      root: {
        maxHeight: 480
      }
    },

    MuiListItem: {
      root: {
        "&.Mui-disabled": {
          backgroundColor: colors.white,
        }
      }
    },

    // Menu
    MuiMenuItem: {
      root: {
        "&.action-menu svg": {
           color: colors.lightGrayishBlue.hex,
           marginRight: ".5rem"
        }
      }
    },

    // Date Picker
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: colors.lime.hex,
      },
    },
    MuiPickersCalendarHeader: {
      switchHeader: {
        backgroundColor: colors.white,
        color: colors.black,
        "& p": {
          fontFamily: FuturaD.fontFamily,
        },
      },
      dayLabel: {
        textTransform: "uppercase",
      },
    },
    MuiPickersDay: {
      day: {
        margin: 2,
        color: colors.black,
        backgroundColor: colors.mostlyWhite,
        borderRadius: 2,
        transition: "all .2s ease",

        "&:hover": {
          backgroundColor: colors.lime.hex,
          color: colors.white,
        },
      },
      daySelected: {
        backgroundColor: colors.lime.hex,
        color: colors.white,
      },
      dayDisabled: {
        color: colors.gray.hex,
      },
      current: {
        color: colors.black,
        "& p": {
          fontFamily: FuturaD.fontFamily,
        },
      },
    },
  },
});
