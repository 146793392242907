import React from "react";
import { MenuItem, Box } from "@material-ui/core";
import { Link, LinkProps, NavLink, NavLinkProps } from "react-router-dom";
import IChildren from "../../interfaces/IChildren";
import "./_nav-link-item.scss";

interface IExternalLinkProps extends IChildren {
  to: string;
}

const MenuNavItem = ({ children }: IChildren) => (
  <MenuItem className="nav-menu-item" disableTouchRipple>
    {children}
  </MenuItem>
);

const NavLinkItem = (props: NavLinkProps) => (
  <MenuNavItem>
    <NavLink {...props} className="nav-link-item">
      <Box component="span">{props.children}</Box>
    </NavLink>
  </MenuNavItem>
);

const LinkItem = (props: LinkProps) => (
  <MenuNavItem>
    <Link {...props} className="nav-link-item">
      <Box component="span">{props.children}</Box>
    </Link>
  </MenuNavItem>
);

const ExternalLinkItem = (props: IExternalLinkProps) => (
  <MenuNavItem>
    <a className="nav-link-item" href={props.to} target="_blank">
      {props.children}
    </a>
  </MenuNavItem>
);

MenuNavItem.NavLink = NavLinkItem;
MenuNavItem.Link = LinkItem;
MenuNavItem.ExternalLink = ExternalLinkItem;

export default MenuNavItem;
