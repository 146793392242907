import styled from "styled-components";
import { variables } from "../../theme/variables";

const { colors, typography } = variables;

export const StyledChip = styled.div`
  border: 1px solid ${colors.grayishBlue.hex};
  background-color: ${colors.mostlyWhite};
  font-size: ${typography.fontSizeSmaller};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3px 10px;
  border-radius: 500px;
  max-width: 70%;
  margin: 0 3px;
`;

export const StyledSpan = styled.span`
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;