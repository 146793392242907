export type Required<T> = {
  value: T;
  hasError: boolean;
  error?: string;
};

export type Optional<T> = {
  value?: T;
  hasError?: boolean;
  error?: string;
};

export enum Fields {
  ProductId = "productId"
}