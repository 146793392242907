import React from "react";
import IChildren from "./interfaces/IChildren";
import { connect } from "react-redux";
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin, withAITracking } from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory } from "history";

interface IProps extends IChildren {
  instrumentationKey: string;
}

const reactPlugin = new ReactPlugin();

const TelemetryProvider = ({ children, instrumentationKey }: IProps) => {
    const browserHistory = createBrowserHistory({ basename: '' });
    const appInsights = new ApplicationInsights({
        config: {
            instrumentationKey: `${instrumentationKey}`,
            extensions: [reactPlugin],
            extensionConfig: {
              [reactPlugin.identifier]: { history: browserHistory }
            }
        }
    });
    appInsights.loadAppInsights();

    return (
        <React.Fragment>
            {children}
        </React.Fragment>
    );
}

export default connect()(withAITracking(reactPlugin, TelemetryProvider));