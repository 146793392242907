import { Grid, TextField } from "@material-ui/core";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { filter, isEmpty, isNull, uniqueId } from "underscore";
import { useTranslation } from "react-i18next";
import VisitorDetailsWrapper, { VisitorDetailsWrapperHandle } from "./VisitorDetailsWrapper";
import CustomButton from "../../../../../../../../../ui/CustomButton/CustomButton";
import "../../../../../_newBooking.scss";
import { VisitorDetailModel } from "../../../../../../../../../models/visitor-booking/VisitorDetailModel";
import VisitorBookingErrorMessage from "./VisitorBookingErrorMessage";

export type VisitorDetailsListProps = {
  visitorDetails?: any[];
  numberPlateCountryCode?: string;
  initialValues?: VisitorDetailModel[] | null;
  inValidationMode?: boolean;
  productSalesAvailability?: number;
  setNumberOfVisitorDetails?: (number: number) => void;
  setIsDirty?: () => void;
};

export type VisitorDetailsListHandle = {
  handleSubmit: () => Promise<{
    isValid: boolean;
    data: VisitorDetailModel[] | null;
  }>;
  getValues: () => VisitorDetailModel[];
  isDirty: () => boolean;
};

type VisitorDetailsWithId = {
  id: string;
  value: VisitorDetailModel | null;
};

const maximumAdditionalVisitorsAddedInOneClick = 20;
const maximumAllowedVisitorsToBeCreatedInBulk = 50;

const VisitorDetailsList = forwardRef<VisitorDetailsListHandle, VisitorDetailsListProps>(
  (
    {
      numberPlateCountryCode,
      initialValues,
      inValidationMode,
      productSalesAvailability,
      setNumberOfVisitorDetails,
      setIsDirty,
    }: VisitorDetailsListProps,
    ref
  ) => {
    const { t } = useTranslation(["visitors"]);

    const refs = useRef<VisitorDetailsWrapperHandle[]>([]);

    const initialValuesMapped: VisitorDetailsWithId[] = initialValues?.map((el) => ({
      id: uniqueId(),
      value: el,
    })) ?? [{ id: uniqueId(), value: null }];

    const [visitorDetails, setVisitorDetails] = useState(initialValuesMapped);

    const [addSize, setAddSize] = useState(1);
    const [areThereSpotsLeft, setAreThereSpotsLeft] = useState((productSalesAvailability ?? 0) > 0);
    const [shouldShowNoSpotsAvailableMessage, setShouldShowNoSpotsAvailableMessage] =
      useState(false);
    const [
      showMaxAllowedVisitorsCanBeCreatedMessage,
      setShowMaxAllowedVisitorsCanBeCreatedMessage,
    ] = useState(false);
    const [
      isAvailabilityLessThanMaxVisitorsThatCanBeAdded,
      setIsAvailabilityLessThanMaxVisitorsThatCanBeAdded,
    ] = useState((productSalesAvailability ?? 0) < maximumAdditionalVisitorsAddedInOneClick);

    const maximumAllowedVisitorsToBeCreatedInBulkReached = useMemo(
      () => visitorDetails.length >= maximumAllowedVisitorsToBeCreatedInBulk,
      [visitorDetails]
    );

    const handleAddVisitorDetails = () => {
      let cannotAddOneMoreVisitor = (productSalesAvailability ?? 0) < visitorDetails.length + 1;
      if (cannotAddOneMoreVisitor) {
        setShouldShowNoSpotsAvailableMessage(true);
        return;
      }

      cannotAddOneMoreVisitor =
        addSize + visitorDetails.length > maximumAllowedVisitorsToBeCreatedInBulk;
      if (cannotAddOneMoreVisitor) {
        setShowMaxAllowedVisitorsCanBeCreatedMessage(true);
        return;
      }

      const addDetails = [];
      for (let i = 0; i < addSize; i++) {
        addDetails.push({ id: uniqueId(), value: null });
      }

      setVisitorDetails([...visitorDetails, ...addDetails]);
      setAddSize(1);
    };

    const [maxVisitorsThatCanBeAdded, setMaxVisitorsThatCanBeAdded] = useState(
      Math.min(
        (productSalesAvailability ?? 0) - visitorDetails.length,
        maximumAllowedVisitorsToBeCreatedInBulk - visitorDetails.length,
        maximumAdditionalVisitorsAddedInOneClick
      )
    );

    useEffect(() => {
      let remainingSpots: number = maximumAllowedVisitorsToBeCreatedInBulk - visitorDetails.length;

      if (productSalesAvailability) {
        remainingSpots =
          productSalesAvailability > maximumAllowedVisitorsToBeCreatedInBulk
            ? maximumAllowedVisitorsToBeCreatedInBulk - visitorDetails.length
            : productSalesAvailability - visitorDetails.length;
      }

      if (
        remainingSpots !== undefined &&
        remainingSpots < maximumAdditionalVisitorsAddedInOneClick
      ) {
        setMaxVisitorsThatCanBeAdded(remainingSpots);
        setAreThereSpotsLeft(remainingSpots > 0);
        setIsAvailabilityLessThanMaxVisitorsThatCanBeAdded(true);
      } else {
        setMaxVisitorsThatCanBeAdded(maximumAdditionalVisitorsAddedInOneClick);
        setAreThereSpotsLeft(true);
        setIsAvailabilityLessThanMaxVisitorsThatCanBeAdded(false);
      }

      if (setNumberOfVisitorDetails) {
        setNumberOfVisitorDetails(visitorDetails.length);
      }
    }, [visitorDetails, productSalesAvailability]);

    const handleRemoveVisitorDetails = (idToRemove: string) => {
      if (visitorDetails.length > 1) {
        setVisitorDetails(filter(visitorDetails, (el) => el.id !== idToRemove));
        setShouldShowNoSpotsAvailableMessage(false);
        setShowMaxAllowedVisitorsCanBeCreatedMessage(false);
      }
    };

    const handleAddVisitorDetailsInput = (event: React.ChangeEvent<any>) => {
      event.preventDefault();

      const { value } = event.target;

      let newValue = parseInt(value.replace(/\D/g, ""));

      if (!isEmpty(newValue) && !isNaN(newValue) && newValue < 1) {
        newValue = 1;
      }

      if (newValue > maxVisitorsThatCanBeAdded) {
        newValue = maxVisitorsThatCanBeAdded;
      }

      if (addSize !== newValue) {
        setAddSize(newValue);
      }
    };

    const handleAddVisitorDetailsBlur = (event: React.ChangeEvent<any>) => {
      event.preventDefault();

      const { value } = event.target;

      let newValue = parseInt(value);

      if (isNaN(newValue)) {
        newValue = 1;
      }

      if (addSize !== newValue) {
        setAddSize(newValue);
      }
    };

    useImperativeHandle(ref, () => ({
      async handleSubmit() {
        let isValid = true;
        let data: VisitorDetailModel[] = [];
        await Promise.all(
          refs.current?.map(async (el) => {
            if (isNull(el)) {
              return;
            }

            const res = await el.handleSubmit();
            if (res.isValid && !isNull(res.data)) {
              data.push(res.data);
            } else {
              isValid = false;
            }
          })
        );

        // Jump to the first error if any
        const elements = document.getElementsByClassName("Mui-error");
        if (elements && elements.length > 0) {
          const dims = elements[0].getBoundingClientRect();
          window.scrollTo({
            left: window.scrollX,
            top: dims.top - 135 + window.scrollY,
            behavior: "smooth",
          });
        }

        return {
          isValid: isValid,
          data: isValid ? data : null,
        };
      },

      getValues() {
        const res: VisitorDetailModel[] = [];
        refs.current?.forEach((el) => {
          if (el) {
            res.push(el.getValue());
          }
        });

        return res;
      },

      isDirty() {
        return refs.current?.some((x) => x.isDirty());
      },
    }));

    const addMoreInfoLabelMessage = () => {
      if (!areThereSpotsLeft) {
        if (maximumAllowedVisitorsToBeCreatedInBulkReached) {
          return t("maxVisitorsInOneOperation", {
            maxNumber: maximumAllowedVisitorsToBeCreatedInBulk,
          });
        }

        return t("noMoreSpotsAvailable");
      }

      if (isAvailabilityLessThanMaxVisitorsThatCanBeAdded) {
        return t("currentMaxVisitors", {
          maxNumber: maxVisitorsThatCanBeAdded,
        });
      }

      return t("additionalVisitorsInfo", {
        maxNumber: maximumAdditionalVisitorsAddedInOneClick,
      });
    };

    return (
      <React.Fragment>
        {productSalesAvailability &&
          productSalesAvailability > 0 &&
          visitorDetails.length > productSalesAvailability && (
            <VisitorBookingErrorMessage>
              <span>
                {t("visitors:availableSpotsNotEnough")}
                <br />
                {t("visitors:removeVisitors", {
                  number: visitorDetails.length - productSalesAvailability,
                })}
              </span>
            </VisitorBookingErrorMessage>
          )}

        {!inValidationMode && (
          <Grid item container direction="row" xs={12} className="visitor-details-title">
            <h3>
              {t("visitorDetailsTitle")} ({visitorDetails.length})
            </h3>
          </Grid>
        )}

        {visitorDetails.map((vd, index) => (
          <VisitorDetailsWrapper
            key={vd.id}
            index={index}
            ref={(el) => (refs.current!![vd.id as any] = el!!)}
            numberPlateCountryCode={numberPlateCountryCode}
            initialValues={vd.value ?? undefined}
            handleDelete={
              visitorDetails.length > 1 && !inValidationMode
                ? () => handleRemoveVisitorDetails(vd.id)
                : undefined
            }
            setIsDirty={setIsDirty}
          />
        ))}
        {!inValidationMode && (
          <Grid
            item
            container
            direction="row"
            xs={12}
            spacing={0}
            className={`${
              (!areThereSpotsLeft && shouldShowNoSpotsAvailableMessage) ||
              showMaxAllowedVisitorsCanBeCreatedMessage
                ? "no-spots-available"
                : ""
            }`}
          >
            <Grid item xs={12} style={{ paddingBottom: 0 }}>
              {t("additionalVisitors")}
            </Grid>
            <Grid item xs={12}>
              <div className={"new-booking-add-wrapper"}>
                <TextField
                  id="standard-number"
                  className="new-booking-add-input"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={isNaN(addSize) ? "" : addSize}
                  onChange={handleAddVisitorDetailsInput}
                  onBlur={handleAddVisitorDetailsBlur}
                />
                <CustomButton.Light
                  text={"+"}
                  onClick={handleAddVisitorDetails}
                  className="new-booking-add-btn"
                  disabled={!areThereSpotsLeft && shouldShowNoSpotsAvailableMessage}
                />
              </div>
            </Grid>
            <Grid item xs={12} className="new-booking-info-label">
              {addMoreInfoLabelMessage()}
            </Grid>
          </Grid>
        )}
      </React.Fragment>
    );
  }
);

export default VisitorDetailsList;
