import { useState } from "react";

const initialState = {
  showDetails: false,
};

export type UserCardState = Readonly<typeof initialState>;

const useUserCardState = () => {
  const [state, setState] = useState<UserCardState>(initialState);

  const updateState = (newState: UserCardState): void => {
    setState({
      ...state,
      ...newState,
    });
  };

  const resetState = (): void => setState(initialState);

  const showDetails = () => updateState({ showDetails: true });

  const hideDetails = () => updateState({ showDetails: false });

  const toggleDetails = () => updateState({ showDetails: !state.showDetails });

  return { state, showDetails, hideDetails, toggleDetails, resetState, updateState };
};

export default useUserCardState;
