import React, { memo, useEffect } from "react";
import NewBookingBulk from "../../containers/Visitors/BookingOverview/NewBooking/NewBookingBulk";
import XlsFileAdd from "../../assets/images/xls-file-add.svg";
import XlsFileDropping from "../../assets/images/xls-file-dropping.svg";
import XlsFileEmpty from "../../assets/images/xls-file-empty.svg";
import XlsFileChecked from "../../assets/images/xls-file-checked.svg";

const VisitorDetailsForm = memo(() => {

  // Pre-load the images
  const preloadSrcList: string[] = [XlsFileAdd, XlsFileDropping, XlsFileEmpty, XlsFileChecked];

  useEffect(() => {
    preloadSrcList.forEach((face) => {
      const img = new Image();
      img.src = face;
    });
  }, []);

  return <NewBookingBulk />;
});

export default VisitorDetailsForm;
