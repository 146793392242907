import React, {
  forwardRef,
  Dispatch,
  useEffect,
  useRef,
  useState,
  useImperativeHandle,
} from "react";
import { Container, Grid } from "@material-ui/core";
import { Trans, useTranslation } from "react-i18next";
import { NoPaddingBottomGrid } from "../../../../../../../../../components/DataTable/DataTableStyledComponents";
import DragAndDrop, {
  DndError,
  DndState,
} from "../../../../../../../../../components/DragAndDropV2/DragAndDrop";
import { connect } from "react-redux";
import { RootReducer } from "../../../../../../../../../store/reducers";
import { fileActions } from "../../../../../../../../../store/actions/file.actions";
import { VisitorDetailModel } from "../../../../../../../../../models/visitor-booking/VisitorDetailModel";
import {
  mapToVisitorDetailModel,
  mapToVisitorDetailModelArray,
} from "../../../../../mappers/visitorBookings.mapper";
import { isEmpty } from "underscore";
import VisitorDetailsList, { VisitorDetailsListHandle } from "../VisitorDetails/VisitorDetailsList";
import { Link } from "react-router-dom";
import { parseUploadedData } from "./VisitorBulkUploadParser";
import { VisitorBulkUploadHandle, VisitorBulkUploadProps } from ".";
import VisitorBookingErrorMessage from "../VisitorDetails/VisitorBookingErrorMessage";

interface IProps extends VisitorBulkUploadProps, StateProps, DispatchProps { }

const VisitorBulkUpload = forwardRef<VisitorBulkUploadHandle, IProps>((props: IProps, ref) => {
  const [state, setState] = useState<DndState>("default");
  const [error, setError] = useState<DndError | undefined>(undefined);
  const { t } = useTranslation(["globals"]);
  const supportedFileTypes = [
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "application/vnd.ms-excel",
  ];

  const visitorListRef = useRef<VisitorDetailsListHandle>(null);

  const [validVisitorDetails, setValidVisitorDetails] = useState<VisitorDetailModel[]>([]);
  const [invalidVisitorDetails, setInvalidVisitorDetails] = useState<VisitorDetailModel[]>([]);

  const [numberOfVisitorsToBeRemoved, setNumberOfVisitorsToBeRemoved] = useState(0);

  const handleFileUpload = (file: File) => {
    setValidVisitorDetails([]);
    setInvalidVisitorDetails([]);
    setState("processing");
    props.setHasTooManyVisitorsUploadedFromFile(false);
    props.onProcessing(true);

    readVisitorsFromFile(file);
  };

  const readVisitorsFromFile = (file: File) => {
    const reader = new FileReader();
    reader.readAsBinaryString(file);
    reader.onload = handleUploadedData;
  };

  const handleUploadedData = (e: ProgressEvent<FileReader>) => {
    const res = parseUploadedData(e, props.maxRecordsNumber, props.germanLicencePlatesEnabled);
    if (res === "error") {
      setState("error");
      setError("empty");
      props.onProcessing(false);
      return;
    }

    if (res === "maxRecordsError") {
      setState("error");
      setError("maxRecords");
      props.onProcessing(false);
      return;
    }

    const [valid, invalid] = [res[0], res[1]];
    const mappedValidVisitorDetails = mapToVisitorDetailModelArray(valid);

    setValidVisitorDetails(mappedValidVisitorDetails);
    setInvalidVisitorDetails(
      invalid.map((visitor) => mapToVisitorDetailModel(visitor, props.numberPlateCountryCode))
    );

    props.onFileProcessed(mappedValidVisitorDetails);
    props.onProcessing(false);

    setState(valid.length > 0 && invalid.length <= 0 ? "success" : "error");
  };

  const handleFileDropped = (valid: boolean) => {
    if (!valid) {
      setState("error");
    }
    props.setFileUploaded();
  };

  const cancelFileUpload = () => {
    props.cancelFileUpload();
    setState("default");
    setError(undefined);
    setValidVisitorDetails([]);
    setInvalidVisitorDetails([]);
  }

  useEffect(() => {
    if (!props.fileUploaded) {
      // Cancel
      setState("default");
      setError(undefined);
      setValidVisitorDetails([]);
      setInvalidVisitorDetails([]);
    }
  }, [props.fileUploaded]);

  useEffect(() => {
    props.setHasTooManyVisitorsUploadedFromFile(error === "maxRecords");
  }, [error]);

  useEffect(() => {
    if (invalidVisitorDetails.length > 0) {
      // Show validation errors
      visitorListRef.current?.handleSubmit();
    }
  }, [invalidVisitorDetails]);

  useEffect(() => {
    const totalNumberOfVisitors = validVisitorDetails.length + invalidVisitorDetails.length;
    props.setNumberOfVisitorDetailsUploadedFromFile(totalNumberOfVisitors);

    if (props.salesAvailability) {
      setNumberOfVisitorsToBeRemoved(totalNumberOfVisitors - props.salesAvailability);
      
      if (props.salesAvailability > 0 && totalNumberOfVisitors > props.salesAvailability) {
        setState("error");
        setError("moreThanAvailable");
      }
    }    
  }, [validVisitorDetails, invalidVisitorDetails, props.salesAvailability]);

  const renderTitle = () => {
    const successFullLines = validVisitorDetails.length;
    const unSuccessFullLines = invalidVisitorDetails.length;

    if (successFullLines <= 0 && unSuccessFullLines <= 0) {
      return <h3>{t("visitors:visitorBookingFileUpload.uploadFile")}</h3>;
    }

    if (unSuccessFullLines > 0) {
      return unSuccessFullLines <= 50 ? (
        <>
          <h3>
            {t("visitors:visitorBookingFileUpload.uploadUnSuccessful", {
              invalidCount: successFullLines,
              count: successFullLines + unSuccessFullLines,
            })}
          </h3>
          <div>{t("visitors:visitorBookingFileUpload.errorCorrection")}</div>
        </>
      ) : (
        <h5 style={{ marginTop: 10 }}>
          <Trans
            i18nKey="visitors:visitorBookingFileUpload.errorCorrectionTooMany"
            t={t}
            components={[
              <Link onClick={() => props.cancelFileUpload()} to="#" className="link-lime" />,
            ]}
          />
        </h5>
      );
    }

    return (
      <h3>
        {successFullLines === 1
          ? t("visitors:visitorBookingFileUpload.uploadSuccessful", {
            count: successFullLines,
          })
          : t("visitors:visitorBookingFileUpload.uploadsSuccessful", {
            count: successFullLines,
          })}
      </h3>
    );
  };

  useImperativeHandle(ref, () => ({
    async handleSubmit() {
      if (visitorListRef.current) {
        const submit = await visitorListRef.current.handleSubmit();

        return {
          isValid: submit?.isValid ?? false,
          data: submit?.data ?? [],
        };
      }

      return {
        isValid: invalidVisitorDetails.length <= 0,
        data: [],
      };
    },
  }));

  return (
    <React.Fragment>
      {numberOfVisitorsToBeRemoved > 0 ?
        (
          <VisitorBookingErrorMessage>
            <span>
              {t("visitors:availableSpotsNotEnough")}
              <br />
              <Trans
                i18nKey="visitors:removeVisitorsFromFile"
                t={t}
                components={[
                  <Link onClick={() => cancelFileUpload()} key="cancelFileUploadLink" to="#" />,
                ]}
                values={{ number: numberOfVisitorsToBeRemoved }}
              />
            </span>
          </VisitorBookingErrorMessage>
        ) : (
          <>
            <Container className="lateral-padding-16 margin-bottom-neg-16">
              <NoPaddingBottomGrid
                container
                item
                direction="row"
                alignItems="center"
                className="padding-0"
              >
                <NoPaddingBottomGrid
                  container
                  item
                  direction="column"
                  xs={isEmpty(invalidVisitorDetails) ? 6 : 12}
                  className="padding-0"
                >
                  {renderTitle()}
                </NoPaddingBottomGrid>
                <NoPaddingBottomGrid container item direction="column" xs={6} className="padding-0">
                  {isEmpty(invalidVisitorDetails) && (
                    <div className="download-template">
                      <div>
                        <a href="/files/VisitorImportTemplate.xlsx" target="_blank" download>
                          {t("visitors:visitorBookingFileUpload:downloadTemplate")}
                        </a>
                      </div>
                    </div>
                  )}
                </NoPaddingBottomGrid>
              </NoPaddingBottomGrid>
            </Container>
            <Container className="h-full lateral-padding-16">
              {isEmpty(invalidVisitorDetails) ? (
                <DragAndDrop
                  onFileUploaded={handleFileUpload}
                  onFileDropped={handleFileDropped}
                  supportedFileTypes={supportedFileTypes}
                  state={state}
                  error={error}
                  extraSuccessMsg={t("visitors:visitorBookingFileUpload.successMessage")}
                  maxRecordsNumber={props.maxRecordsNumber?.toString()}
                  showMaxFileSize={false}
                />
              ) : (
                invalidVisitorDetails.length <= 50 && (
                  <Grid item container direction="row" xs={12}>
                    <VisitorDetailsList
                      numberPlateCountryCode={props.numberPlateCountryCode}
                      ref={visitorListRef}
                      initialValues={invalidVisitorDetails}
                      inValidationMode={true}
                    />
                  </Grid>
                )
              )}
            </Container>
          </>
        )}
    </React.Fragment>
  );
});

const mapStateToProps = (state: RootReducer) => {
  const { file } = state;

  return {
    fileUploaded: file.fileUploaded
  };
};
const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  setFileUploaded: () => dispatch(fileActions.setFileUploaded()),
  cancelFileUpload: () => dispatch(fileActions.cancelFileUpload()),
});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
  VisitorBulkUpload
);
