import { TableBody, TableCell, TableRow } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { variables } from "../../../theme/variables";
import CircleImage from "../../../ui/CircleImage/CircleImage";
import Heading from "../../../ui/Heading/Heading";
import Spacer from "../../../ui/Spacer/Spacer";
import emptyStateCar from "../../../assets/images/empty-state-car.svg";

const { colors, typography, breakpoints } = variables;

const NoDataContainer = styled.div`
  display: flex;
  min-height: 500px;
  align-items: center;
  justify-content: center;
  background-color: ${colors.mostlyWhite};
`;

const NoDataWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 30%;

  @media screen and (max-width: ${breakpoints.screenMd}) {
    max-width: 95%;
  }
`;

const Span = styled.span`
  color: ${colors.darkGraySecondary};
  font-size: ${typography.fontSizeBase};
  text-align: center;
`;

const NoPaddingTableCell = styled(TableCell)`
  padding: 0 !important;
`;

interface NoDataProps {
  colSpan: number;
  translationKey: string;
}

const NoDataTableBody: React.FC<NoDataProps> = ({ colSpan, translationKey }) => {
    const { t } = useTranslation(["dataTable"]);

  return (
    <TableBody>
      <TableRow>
        <NoPaddingTableCell colSpan={colSpan + 2}>
          <NoDataContainer>
            <NoDataWrapper>
              <CircleImage height={180} src={emptyStateCar} />
              <Spacer size="sm" />
                <Heading justifyContent="center" fontSize={typography.fontSizeLarge}>
                    {t(translationKey + ".noRecords")}
                </Heading>
              <Spacer />
              <Span>{t(translationKey + ".noRecordsHelp")}</Span>
            </NoDataWrapper>
          </NoDataContainer>
        </NoPaddingTableCell>
      </TableRow>
    </TableBody>
  );
};

export default NoDataTableBody;
