import React from "react";
import styled from "styled-components";
import { variables } from "../../theme/variables";

const { colors } = variables;

interface ICircleImageProps {
  src?: string;
  height?: number;
  children?: React.ReactNode;
}

const Wrapper = styled.div`
  background-color: ${colors.white};
  box-shadow: 0px 10px 24px ${colors.lightGrayishBlue.hex};
  padding: 0.5rem;
  border-radius: 100%;
  width: ${({ height }: ICircleImageProps) => height}px;
  height: ${({ height }: ICircleImageProps) => height}px;
  overflow: hidden;
`;

const ImageContainer = styled.div`
  background-image: ${({ src }: ICircleImageProps) => `url(${src})`};
  border: 1px solid ${colors.lightGrayishBlue.hex};
  background-color: ${colors.veryLightGraySecondary};
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 100%;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CircleImage = (props: ICircleImageProps) => (
  <Wrapper {...props}>
    <ImageContainer {...props}>{props.children}</ImageContainer>
  </Wrapper>
);

CircleImage.defaultProps = {
  height: 208,
  children: <></>,
};

export default CircleImage;
