import React, { Dispatch, memo, useCallback, useEffect, useRef, useState } from "react";
import { visitorBookingActions } from "../../../../store/actions/visitor-booking";
import { connect } from "react-redux";
import { Container, Grid } from "@material-ui/core";
import ActionFooter from "../../../../components/ActionFooter/ActionFooter";
import CustomButton from "../../../../ui/CustomButton/CustomButton";
import { useHistory } from "react-router";
import routes from "../../../../router/routes";
import { isUndefined, isEmpty, findWhere, isNull, isEqual } from "underscore";
import { useTranslation } from "react-i18next";
import { ArrowBackIosRounded } from "@material-ui/icons";
import { useNewBookingBulkStepOneFormik } from "./hooks/useNewBookingFormik";
import useAppContext from "../../../../context/hooks/useAppContext";
import { ParkingLocation } from "./features/parking-location";
import Spacer from "../../../../ui/Spacer/Spacer";
import { RootReducer } from "../../../../store/reducers";
import "./_newBooking.scss";
import ProductTimeframeBulk from "./features/product-timeframe/components/ui/ProductTimeframeBulk";
import { Fields } from "./NewBooking.types";
import VisitorDetailsList, {
  VisitorDetailsListHandle,
} from "./features/visitor-details/components/ui/VisitorDetails/VisitorDetailsList";
import { VisitorBookingNewBookingBulkRequest } from "./models/VisitorBookingNewBookingBulk";
import {
  mapToCreateVisitorBookingsBulkRequest,
  mapToCreateVisitorBookingsRequest,
} from "./mappers/visitorBookings.mapper";
import VisitorDetailsHeader, {
  VisitorDetailHeaderTab,
} from "./features/visitor-details/components/ui/VisitorDetailsHeader/VisitorDetailsHeader";
import { fileActions } from "../../../../store/actions/file.actions";
import { VisitorDetailModel } from "../../../../models/visitor-booking/VisitorDetailModel";
import VisitorBulkUpload, {
  VisitorBulkUploadHandle,
} from "./features/visitor-details/components/ui/VisitorBulkUpload";
import StickySidebar from "./features/visitor-details/components/ui/StickySidebar/StickySidebar";
import { GetFacilityImagesRequest } from "../../../../models/visitor-booking/GetFacilityImagesRequest";
import {
  getParametersForGetProductSalesAvailability,
  isParkingLocationSet,
  isTimeframeCorrectlySet,
  performOnChange,
} from "../../../../helpers/capacityHelper";
import ModalDialog from "../NewBooking/features/visitor-details/components/ui/VisitorDetails/VisitorBookingModalDialog";
import VisitorBookingErrorMessage from "./features/visitor-details/components/ui/VisitorDetails/VisitorBookingErrorMessage";
import { prebookingProductsService } from "../../../../services/prebookingProducts.service";
import { AxiosResponse } from "axios";
import { ProductsSalesAvailability } from "../../../../models/visitor-booking/ProductsSalesAvailability";
import { failure, request, success } from "../../../../store/helpers/action.helper";
import { visitorBookingTypes } from "../../../../store/action-types";
import { loaderActions } from "../../../../store/actions/loader.actions";

const NewBookingBulk = memo((props: NewBookingProps) => {
  const { push } = useHistory();

  const {
    productConfigurations,
    parkingLocations,
    loading,
    fileUploaded,
    productSalesAvailability,
    facilityImages,
    getParkingLocations,
    createVisitorBookings,
    visitorBookingValidationLoading,
    getProductSalesAvailability,
    getParkingFacilityImages,
    cancelFileUpload,
    startSalesAvailabilityCheck,
    updateSalesAvailabilityState,
    salesAvailabilityCheckError,
    salesAvailabilityCheckRemoveLoader
  } = props;
  const { t } = useTranslation(["globals", "visitors"]);
  const {
    appState: { user, selectedLanguage },
  } = useAppContext();

  const [isOnStepTwo, setIsOnStepTwo] = useState(false);
  const [hasConfiguredCountry, setHasConfiguredCountry] = useState(false);
  const [visitorDetails, setVisitorDetails] = useState<VisitorDetailModel[] | undefined>(undefined);
  const [isDirty, setIsDirty] = useState(false);
  const [currentTab, setCurrentTab] = useState<VisitorDetailHeaderTab>("manual");
  const [imagesRequests, setImagesRequests] = useState<GetFacilityImagesRequest[] | undefined>(
    undefined
  );
  const [showSidebar, setShowSidebar] = useState(false);
  const [isModalDialogOpen, setIsModalDialogOpen] = useState(false);
  const [onModalDialogAccept, setOnModalDialogAccept] = useState<() => void>(() => () => {
    setIsModalDialogOpen(false);
  });
  const [numberOfVisitorDetails, setNumberOfVisitorDetails] = useState(0);
  const [numberOfVisitorDetailsUploadedFromFile, setNumberOfVisitorDetailsUploadedFromFile] = useState(0);
  const [hasTooManyVisitorsUploadedFromFile, setHasTooManyVisitorsUploadedFromFile] = useState(false);

  useEffect(() => {
    if (isUndefined(parkingLocations.data) || isNull(parkingLocations.data)) {
      return;
    }

    const country = findWhere(parkingLocations.data, {
      countryCode: user.country?.toLowerCase(),
    });

    setHasConfiguredCountry((s) => (s = !isUndefined(country)));

    const requests = parkingLocations.data.map(
      (x) =>
      ({
        countryCode: x.countryCode,
        physicalZoneUids: Array.from(
          new Set(x.cities?.flatMap((y) => y.physicalZones).map((y) => y.physicalZoneId.split('.')[1]))
        ),
      } as GetFacilityImagesRequest)
    );

    if (requests.length > 0 && !isEqual(imagesRequests, requests)) {
      getParkingFacilityImages(requests);
      setImagesRequests(requests);
    }

    return () => { };
  }, [parkingLocations.data, user.country]);

  const handleOnTabsSwitch = (requestedTab: VisitorDetailHeaderTab) => {
    setVisitorDetails(undefined);
    setCurrentTab(requestedTab);
    setIsDirty(false);
    setHasTooManyVisitorsUploadedFromFile(false);
  };

  const requestParkingLocations = useCallback(() => {
    const uniquePhysicalZonesUids = Array.from(
      new Set(productConfigurations.data?.flatMap((p) => p.allowedPhysicalZoneIds))
    );
    if (uniquePhysicalZonesUids.length > 0) {
      getParkingLocations(uniquePhysicalZonesUids);
    }
  }, [productConfigurations.data]);

  useEffect(() => requestParkingLocations(), [requestParkingLocations]);

  const redirectToBookingOverview = () => {
    if (isUndefined(routes.visitors.subRoutes)) {
      return;
    }

    push(routes.visitors.subRoutes[0].path as string);
  };

  const { formikStepOne } = useNewBookingBulkStepOneFormik({
    t,
    onSubmit: (_) => {
      setIsOnStepTwo(true);
    },
    numberPlateCountryCode: user.country,
    countryCode: hasConfiguredCountry ? user.country : "",
    receptionistCountryCode: user.country,
  });

  useEffect(() => {
    const values = formikStepOne.values;

    if (performOnChange(values)) {
      if (values.physicalZone && isTimeframeCorrectlySet(values)) {
        const [startDateTime, endDateTime] = getParametersForGetProductSalesAvailability(
          values
        );

        getProductSalesAvailability(
          values.physicalZone,
          startDateTime,
          endDateTime,
          user.seasonTicketOwnerCrmId?.toString() ?? "",
          values.productId?.toString()
        );

        setShowSidebar(true);
      } else {
        setShowSidebar(false);
      }
    }

    if (!isParkingLocationSet(values.physicalZone)) {
      setShowSidebar(false);
    }
  }, [
    formikStepOne.values.startDate,
    formikStepOne.values.endDate,
    formikStepOne.values.startTime,
    formikStepOne.values.endTime,
    formikStepOne.values.country,
    formikStepOne.values.city,
    formikStepOne.values.physicalZone,
    isOnStepTwo
  ]);

  const onParkingLocationChange = (field: string, e: React.ChangeEvent<any>) => {
    if (!isUndefined(formikStepOne.errors[field as keyof typeof formikStepOne.errors])) {
      formikStepOne.setFieldError(field, undefined);
    }

    if (e?.target) {
      formikStepOne.handleChange(e);
    }
  };

  const visitorListRef = useRef<VisitorDetailsListHandle>(null);
  const visitorDragAndDropRef = useRef<VisitorBulkUploadHandle>(null);

  let isSubmitting = loading;

  const handleSubmit = async () => {
    isSubmitting = true;
    visitorBookingValidationLoading(true);

    if (!isOnStepTwo) {
      await handleStepOneSubmit();
      isSubmitting = false;
      visitorBookingValidationLoading(false);
    } else {

      const [startDateTime, endDateTime] = getParametersForGetProductSalesAvailability(
        formikStepOne.values
      );

      startSalesAvailabilityCheck();
      prebookingProductsService.getProductSalesAvailability(
        formikStepOne.values.physicalZone,
        startDateTime,
        endDateTime,
        user.seasonTicketOwnerCrmId?.toString() ?? "",
        formikStepOne.values.productId?.toString()
      ).then(async (response: AxiosResponse<ProductsSalesAvailability>) => {
        updateSalesAvailabilityState(response.data)
        if (response.data?.salesAvailability !== undefined && response.data?.salesAvailability !== 0) {
          const isThereAnErrorInManualInput = numberOfVisitorDetails > response.data?.salesAvailability;
          const isThereAnErrorInFileUpload = currentTab === "file" && (numberOfVisitorDetailsUploadedFromFile > response.data?.salesAvailability || !fileUploaded);
          
          if (isThereAnErrorInManualInput || isThereAnErrorInFileUpload) {
            window.scrollTo({
              top: 0,
              behavior: "smooth",
            });
          }
          else if (currentTab === "manual") {
              await handleStepTwoSubmit();
          } else {
            await handleStepTwoFileUploadSubmit();
          }
        }
        isSubmitting = false;
        visitorBookingValidationLoading(false);
      })
        .catch(() => salesAvailabilityCheckError())
        .finally(() => salesAvailabilityCheckRemoveLoader());
    }
  };

  const handleStepOneSubmit = async () => {
    const errors = await formikStepOne.validateForm();
    if (isEmpty(errors)) {
      formikStepOne.handleSubmit();
    } else {
      // Jump to the first error
      const elements = document.getElementsByClassName("Mui-error text-red");
      if (elements && elements.length > 0) {
        const dims = elements[0].getBoundingClientRect();
        window.scrollTo({
          left: window.scrollX,
          top: dims.top - 135 + window.scrollY,
          behavior: "smooth",
        });
      }
    }
  };

  const handleStepTwoSubmit = async () => {
    const res = await visitorListRef.current?.handleSubmit();
    if (res?.isValid && !isNull(res.data) && res.data.length >= 1) {
      createVisitorBookings(
        user.seasonTicketOwnerCrmId as string,
        selectedLanguage,
        mapToCreateVisitorBookingsBulkRequest(formikStepOne.values, res.data),
        redirectToBookingOverview
      );
    }
  };

  const handleBackToStepOne = () => {
    const values = visitorListRef.current?.getValues();

    if (isDirty) {
      openModalDialog(() => {
        setIsModalDialogOpen(false);
        setVisitorDetails(values);
        setIsOnStepTwo(false);
      });
    } else {
      setVisitorDetails(values);
      setIsOnStepTwo(false);
    }
  };

  const onPhysicalZoneChanged = () => {
    formikStepOne.setFieldValue(Fields.ProductId, undefined);
  };

  const handleStepTwoFileUploadSubmit = async () => {
    const dragAndDropSubmitResult = await visitorDragAndDropRef.current?.handleSubmit();
    if (
      dragAndDropSubmitResult?.isValid &&
      visitorDetails &&
      !isNull(visitorDetails) &&
      (!isEmpty(visitorDetails) || !isEmpty(dragAndDropSubmitResult.data))
    ) {
      createVisitorBookings(
        user.seasonTicketOwnerCrmId as string,
        selectedLanguage,
        mapToCreateVisitorBookingsBulkRequest(formikStepOne.values, [
          ...visitorDetails,
          ...dragAndDropSubmitResult.data,
        ]),
        redirectToBookingOverview
      );
    }
  };

  const handleOnProcessing = (isProcessing: boolean) => {
    if (isProcessing) {
      setVisitorDetails(undefined);
    }

    setIsDirty(!isProcessing);
  };

  const onFileProcessedHandler = (visitors: VisitorDetailModel[]) => {
    setVisitorDetails(visitors);
  };

  const handleManualIsDirty = () => {
    if (!isDirty) {
      setIsDirty(true);
    }
  };

  const handleCancelFileUpload = () => {
    cancelFileUpload();
    setIsDirty(false);
    setVisitorDetails(undefined);
  };

  const openModalDialog = (onAccept: () => void) => {
    setIsModalDialogOpen(true);

    setOnModalDialogAccept(() => () => {
      setIsModalDialogOpen(false);
      cancelFileUpload();
      onAccept();
    });
  };

  return (
    <div className="new-booking bulk-import-wrapper">
      <Container maxWidth={false} className="new-booking-container">
        <Grid item container direction="row" xs={12} style={{ paddingTop: 0 }}>
          <div className="new-booking-header">
            <CustomButton.Icon
              onClick={() => (!isOnStepTwo ? redirectToBookingOverview() : handleBackToStepOne())}
            >
              <ArrowBackIosRounded fontSize="small" />
            </CustomButton.Icon>
            <h2>{t("visitors:newBooking")}</h2>
          </div>
        </Grid>
        <form noValidate onSubmit={handleSubmit}>
          <Grid item container direction="row" xs={12} spacing={4} alignItems="flex-start">
            <Grid
              item
              container
              direction="column"
              xs={9}
              className="max-width-800"
            >
              {!isOnStepTwo ? (
                <React.Fragment>
                  <Grid item container direction="row" xs={12} style={{ paddingBottom: 0 }}>
                    <ParkingLocation
                      values={formikStepOne.values}
                      errors={formikStepOne.errors}
                      handleChange={onParkingLocationChange}
                      setFieldValue={formikStepOne.setFieldValue}
                      setFieldError={formikStepOne.setFieldError}
                      parkingLocations={parkingLocations.data}
                      onPhysicalZoneChanged={onPhysicalZoneChanged}
                    />
                  </Grid>
                  <ProductTimeframeBulk
                    products={productConfigurations.data ?? []}
                    values={formikStepOne.values}
                    errors={formikStepOne.errors}
                    setFieldValue={formikStepOne.setFieldValue}
                    setFieldError={formikStepOne.setFieldError}
                  />
                </React.Fragment>
              ) : (
                <VisitorDetailsHeader
                  isDirty={isDirty}
                  onTabSwitch={handleOnTabsSwitch}
                  currentTab={currentTab}
                  openModalDialog={openModalDialog}
                >
                  {(productSalesAvailability.data?.salesAvailability === 0) ?
                    (
                      <VisitorBookingErrorMessage>
                        <span>
                          {t("visitors:availableSpotsEnded")}
                        </span>
                      </VisitorBookingErrorMessage>
                    ) : (
                      currentTab === "file" ?
                        (
                          <VisitorBulkUpload
                            numberPlateCountryCode={user.country}
                            ref={visitorDragAndDropRef}
                            onFileProcessed={onFileProcessedHandler}
                            onProcessing={handleOnProcessing}
                            maxRecordsNumber={props.maxNumberOfBookings}
                            salesAvailability={productSalesAvailability.data?.salesAvailability}
                            setNumberOfVisitorDetailsUploadedFromFile={setNumberOfVisitorDetailsUploadedFromFile}
                            setHasTooManyVisitorsUploadedFromFile={setHasTooManyVisitorsUploadedFromFile}
                          ></VisitorBulkUpload>
                        ) : (
                          <VisitorDetailsList
                            numberPlateCountryCode={user.country}
                            ref={visitorListRef}
                            initialValues={visitorDetails}
                            setIsDirty={handleManualIsDirty}
                            productSalesAvailability={productSalesAvailability.data?.salesAvailability}
                            setNumberOfVisitorDetails={setNumberOfVisitorDetails}
                          />
                        )
                    )}
                </VisitorDetailsHeader>
              )}
            </Grid>
            <Grid
              item
              container
              direction="column"
              xs={3}
              style={{ alignSelf: "stretch", paddingRight: 0 }}
            >
              <div style={{ height: "100%", paddingTop: "12px" }}>
                <StickySidebar
                  showSidebar={showSidebar}
                  values={formikStepOne.values}
                  parkingLocations={parkingLocations.data}
                  availableSpots={productSalesAvailability.data?.salesAvailability}
                  parkingFacilityImages={facilityImages.data ?? []}
                ></StickySidebar>
              </div>
            </Grid>
          </Grid>
        </form>
        <Spacer size="md" />
      </Container>
      <ActionFooter justifyContent="space-between" className="new-booking-actions">
        {!isOnStepTwo ? (
          <React.Fragment>
            <CustomButton.Light text={t("cancel")} onClick={redirectToBookingOverview} />
            <CustomButton.Primary
              onClick={handleSubmit}
              text={productSalesAvailability.data?.salesAvailability === 0 ? 
                t("visitors:noAvailability") : t("visitors:goToStepTwo")
              }
              loading={isSubmitting}
              disabled={productSalesAvailability.data?.salesAvailability == 0}
            />
          </React.Fragment>
        ) : (
          <React.Fragment>
            {fileUploaded ? (
              <CustomButton.Light
                text={t("visitors:visitorBookingFileUpload:cancelUpload")}
                onClick={handleCancelFileUpload}
              />
            ) : (
              <CustomButton.Light text={t("visitors:backStepOne")} onClick={handleBackToStepOne} />
            )}
            <CustomButton.Primary
              onClick={handleSubmit}
              text={productSalesAvailability.data?.salesAvailability !== 0 ?
                 t("createBooking"): t("visitors:noAvailability")
              }
              loading={isSubmitting}
              disabled={productSalesAvailability.data?.salesAvailability === 0 || hasTooManyVisitorsUploadedFromFile || 
                (productSalesAvailability?.data?.salesAvailability !== undefined &&
                  (productSalesAvailability.data?.salesAvailability < numberOfVisitorDetails ||
                    productSalesAvailability.data?.salesAvailability < numberOfVisitorDetailsUploadedFromFile))}
            />
          </React.Fragment>
        )}
      </ActionFooter>
      <ModalDialog
        onAccept={onModalDialogAccept}
        onDismiss={() => setIsModalDialogOpen(false)}
        open={isModalDialogOpen}
      />
    </div>
  );
});

interface NewBookingProps extends StateProps, DispatchProps { }

const mapStateToProps = (state: RootReducer) => {
  const {
    productConfigurations,
    parkingLocations,
    productSalesAvailability,
    facilityImages,
    loading,
  } = state.visitorBooking;

  const { environment, file } = state;

  const maxNumberOfBookingsConfig = Number(
    environment.dynamicConfigurations?.find(
      (item) => item.name === "MaxNumberOfVisitorBookingRecords"
    )?.value
  );

  const maxNumberOfBookings = Math.min(maxNumberOfBookingsConfig, productSalesAvailability.data?.salesAvailability ?? maxNumberOfBookingsConfig);
  const fileUploaded = file.fileUploaded;

  return {
    productConfigurations,
    parkingLocations,
    loading,
    fileUploaded,
    maxNumberOfBookings,
    productSalesAvailability,
    facilityImages,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  getParkingLocations: (uids: string[]) =>
    dispatch(visitorBookingActions.getParkingLocations({ physicalZoneUids: uids })),
  createVisitorBookings: (
    customerId: string,
    culture: string,
    data: VisitorBookingNewBookingBulkRequest,
    onSuccess?: VoidFunction
  ) =>
    dispatch(
      visitorBookingActions.createVisitorBookings(
        customerId,
        mapToCreateVisitorBookingsRequest(data, culture),
        onSuccess
      )
    ),
  visitorBookingValidationLoading: (loading: boolean) =>
    dispatch(visitorBookingActions.visitorBookingValidationLoading(loading)),
  cancelFileUpload: () => dispatch(fileActions.cancelFileUpload()),
  getProductSalesAvailability: (
    physicalZoneUid: string,
    startDate: string,
    endDate: string,
    customerId: string,
    productId: string
  ) =>
    dispatch(
      visitorBookingActions.getProductSalesAvailability(
        physicalZoneUid,
        startDate,
        endDate,
        customerId,
        productId
      )
    ),
  getParkingFacilityImages: (body: GetFacilityImagesRequest[]) =>
    dispatch(visitorBookingActions.getParkingFacilityImages(body)),

  startSalesAvailabilityCheck: () => {
    dispatch(loaderActions.loadTheLoader() as any);
    dispatch({
      type: request(visitorBookingTypes.GET_PRODUCT_SALES_AVAILABILITY),
    })
  },

  updateSalesAvailabilityState: (payload: ProductsSalesAvailability) =>
    dispatch({
      type: success(visitorBookingTypes.GET_PRODUCT_SALES_AVAILABILITY),
      productSalesAvailabilityPayload: payload
    }),

  salesAvailabilityCheckError: () => {
    dispatch({
      type: failure(visitorBookingTypes.GET_PRODUCT_SALES_AVAILABILITY),
    })
  },

  salesAvailabilityCheckRemoveLoader: () => {
    dispatch(loaderActions.killTheLoader() as any)
  }

});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(NewBookingBulk);
