import { Grid, MenuItem } from "@material-ui/core";
import { EditOutlined } from "@material-ui/icons";
import styled from "styled-components";
import { variables } from "../../../theme/variables";
import { ReactComponent as PaperPlane } from "../../../assets/images/paper-plane.svg";
import { ReactComponent as DeleteIcon } from "../../../assets/images/delete.svg";

const { colors } = variables;

export const NoPaddingGrid = styled(Grid)`
  padding-bottom: 0;
`;

export const EditIcon = styled(EditOutlined)`
  color: ${colors.lightGrayishBlue.hex};
  margin-right: 0.25em;
  height: 0.75em;
`;

export const ResendInvitationIcon = styled(PaperPlane)`
  color: ${colors.lightGrayishBlue.hex};
  margin-right: 0.8em;
  margin-left: 0.2em;
`;

export const DeleteDelegeeMenuItem = styled(MenuItem)`
  color: #d90327;
`;

export const DeleteDelegeeIcon = styled(DeleteIcon)`
  margin-right: 1em;
  margin-left: 0.2em;
`;
