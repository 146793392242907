import React, { useCallback, useMemo } from "react";
import { QParkChip } from "../../../../components/QParkChip";
import { RootState, useAppSelector } from "../../../../store";
import useMyProductsContext from "../../../../context/hooks/useMyProductsContext";
import { ISelectOption } from "../../../../components/DataTable/DataTableFilters/DataTableFilterTypes/SelectFilter";
import { MenuAction } from "../../../../components/DataTable/DataTableHeading/DataTableHeading";
import { useTranslation } from "react-i18next";

import PersonIcon from "@material-ui/icons/Person";
import VehicleIcon from "../../../../ui/Icons/Vehicle";
import useFleetManagerContext from "../../../../context/hooks/useFleetManagerContext";
import { isNil } from "lodash";
import { NumberPlateDeSupport } from "../../../../ui/NumberPlate";
import { convertIsoCountryCodeToNumberPlatePrefix } from "../../../../lib/countriesNumberPlates";

export type ProductChipProps = Readonly<{
  name: string;
  location: string;
}>;

export function ProductChip(props: ProductChipProps) {
  return <QParkChip.Labeled label={`${props.name} - ${props.location}`} />;
}

export type NumberPlateProps = Readonly<{
  countryCode: string;
  identifier: string;
}>;

export function NumberPlate(props: NumberPlateProps) {
  const numberPlatePrefix = convertIsoCountryCodeToNumberPlatePrefix(props.countryCode);
  return (
    <NumberPlateDeSupport prefix={numberPlatePrefix} value={props.identifier} readOnly compact />
  );
}

export function selectUseSingleDirectVehicleAssignment(state: RootState) {
  return state.environment.featureFlags.find(f => f.name === "UseSingleDirectVehicleAssignment")?.value;
}

export function useGetOccupiedParkingRightsCount() {
  const useSingleDirectVehicleAssignment = useAppSelector(selectUseSingleDirectVehicleAssignment);

  return useCallback((pr: {totalAssignedParkingRights?: number, totalDelegatedParkingRights?: number}) => {
    if (useSingleDirectVehicleAssignment) {
      return (pr.totalAssignedParkingRights ?? 0) + (pr.totalDelegatedParkingRights ?? 0); 
    }

    return pr.totalDelegatedParkingRights ?? 0;
  }, [useSingleDirectVehicleAssignment])
}

export function useSharedProductsMenuAction(): {
  menu: MenuAction;
  onAddDelegee: () => void;
  onAssignVehicle: () => void;
} {
  const { t } = useTranslation();
  const useSingleDirectVehicleAssignment = useAppSelector(selectUseSingleDirectVehicleAssignment);

  const { toggleAssignVehicle, toggleAddDelegee, setSelectedParkingRightIds } =
    useFleetManagerContext();

  return useMemo(() => {
    const onInvite = () => {
      setSelectedParkingRightIds([]);
      toggleAddDelegee();
    };
    const onAssign = () => {
      setSelectedParkingRightIds([]);
      toggleAssignVehicle();
    };

    return {
      menu: {
        useMenu: useSingleDirectVehicleAssignment,
        showMenu: useSingleDirectVehicleAssignment,
        menuItems: [
          {
            label: t("fleetManagerTable:inviteFleetMember"),
            action: onInvite,
            icon: <PersonIcon />,
          },
          {
            label: t("fleetManagerTable:assignVehicle"),
            action: onAssign,
            icon: <VehicleIcon />,
          },
        ],
      },
      onAddDelegee: onInvite,
      onAssignVehicle: onAssign,
    };
  }, [toggleAssignVehicle, toggleAddDelegee, setSelectedParkingRightIds]);
}

export function useProductOptions(): ISelectOption[] {
  const currentLocation = useCurrentlySelectedParkingProduct();

  return useMemo(() => {
    if (isNil(currentLocation)) return [];
    return currentLocation.parkingProducts.map((product) => ({
      label: product.pmcName,
      value: product.pmcId ?? 0,
    }));
  }, [currentLocation]);
}

export function useCurrentlySelectedParkingProduct() {
  const {
    myProductsState: { selectedLocation },
  } = useMyProductsContext();
  const locatedProduct = useAppSelector((state) =>
    state.parkingProducts.locatedParkingProducts.data?.find?.(
      (loc) => loc.placeId === selectedLocation
    )
  );
  return locatedProduct;
}
