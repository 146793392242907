const baseUrl = "/api/oidc";

type OidcApiType = {
  getConfigurationUrl: () => string;
};

const getConfigurationUrl = () => `${baseUrl}/getconfiguration`;

export default {
  getConfigurationUrl,
} as OidcApiType;
