import { isEmpty, isUndefined } from "underscore";

export const hoursGenerator = (() => {
  const formatInterval = (hour: number, minute?: number): string => {
    let formattedHour = hour < 10 ? `0${hour}` : hour;
    let formattedMinute = minute ?? "00";

    return `${formattedHour}:${formattedMinute}`;
  };

  const getIntervalStart = (
    minTime?: string
  ): { start: number; passedHalfAnHour?: boolean } => {
    if (isUndefined(minTime) || isEmpty(minTime)) {
      return {
        start: 0
      }
    }

    const minTimeFormatted = minTime?.split(":");
    const hourStart = !isUndefined(minTimeFormatted)
      ? parseInt(minTimeFormatted[0])
      : 0;
    const passedHalfAnHour =
      !isUndefined(minTimeFormatted) && minTimeFormatted[1] >= "30";

    return {
      start: hourStart + 1,
      passedHalfAnHour,
    };
  };

  const createHalfHourIntervals = (minTime?: string): string[] => {
    const { start, passedHalfAnHour } = getIntervalStart(minTime);

    let hours = [];
    if (!isUndefined(passedHalfAnHour) && !passedHalfAnHour) {
      hours.push(formatInterval(start - 1, 30));
    }

    for (let hour = start; hour < 24; hour++) {
      hours.push(formatInterval(hour));
      hours.push(formatInterval(hour, 30));
    }

    return hours;
  };

  return {
    createHalfHourIntervals,
  };
})();
