import React from "react";
import CustomButton from "../../../ui/CustomButton/CustomButton";
import { useDataTableContext } from "../DataTableContext/DataTableContextProvider";
import { useTranslation } from "react-i18next";
import FilterIcon from "../../../ui/Icons/FilterIcon";
import DeleteIcon from "../../../ui/Icons/DeleteIcon";
import { variables } from "../../../theme/variables";
import ForbiddenIcon from "../../../ui/Icons/ForbiddenIcon";
import {
  NoPaddingBottomGrid,
  HorizontalPaddingGrid,
  PaddingRightGrid,
  PaddingLeftGrid,
} from "../DataTableStyledComponents";
import Heading from "../../../ui/Heading/Heading";
import EllipsedSpan from "../../../ui/EllipsedSpan/EllipsedSpan";
import styled from "styled-components";
import { Menu, MenuItem } from "@material-ui/core";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import AddIcon from '@material-ui/icons/Add';


const { colors, typography } = variables;

interface ToggleButtonProps {
  toggled: boolean;
}

const ToggleButtonFilters = styled(CustomButton.Light)`
  background-color: ${(props: ToggleButtonProps) =>
    props.toggled ? `${colors.mostlyWhite}` : `${colors.white}`};
  box-shadow: ${(props: ToggleButtonProps) => props.toggled && "none"};
`;

type DataTableHeadingOptions = {
  showHeadingCount?: boolean;
  useHeading?: boolean;
};

export type DataTableHeadingProps = {
  title: string;
  count?: number;
  options?: DataTableHeadingOptions;
};

type DataTableFilteringOptions = {
  useFilters?: boolean;
};

export type DataTableFiltering = {
  options?: DataTableFilteringOptions;
};

type DeleteAction = {
  useDelete?: boolean;
  showDelete?: boolean;
  deleteLabel?: string;
  onDeleteClicked?: () => void;
};

type RevokeAction = {
  useRevoke?: boolean;
  showRevoke?: boolean;
  revokeLabel?: string;
  onRevokeClicked?: () => void;
};

type AddAction = {
  useAdd?: boolean;
  showAdd?: boolean;
  addLabel?: string;
  onAddClicked?: () => void;
};

export type MenuAction = {
  useMenu?: boolean;
  showMenu?: boolean;
  menuItems?: MenuActionItem[];
};

type MenuActionItem = {
  label: string;
  icon?: React.ReactNode;
  action?: () => void;
};

type DataTableActionsOptions = {
  useActions?: boolean;
};

export type DataTableActions = {
  options?: DataTableActionsOptions;
  revoke?: RevokeAction;
  delete?: DeleteAction;
  add?: AddAction;
  menu?: MenuAction;
};

interface IDataTableHeadingProps {
  heading: DataTableHeadingProps;
  filtering: DataTableFiltering;
  actions: DataTableActions;
}

const DataTableHeading: React.FC<IDataTableHeadingProps> = ({
  heading,
  filtering,
  actions,
}) => {
  const { state, toggleFilters } = useDataTableContext();
  const { t } = useTranslation("dataTable");

  const elements: JSX.Element[] = [];

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLButtonElement>(null);
  const menuOpen = Boolean(anchorEl);

  const openMenu = (event?: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event!.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const executeMenuAction = (action?: () => void) => {
    if (action) {
      action();
    }
    closeMenu();
  }

  if (heading.options?.useHeading) {
    elements.push(
      <NoPaddingBottomGrid
        item
        container
        direction="column"
        xs={12}
        md={filtering.options?.useFilters ? 3 : 5}
      >
        <HorizontalPaddingGrid
          item
          container
          direction="row"
          xs={12}
          justifyContent="flex-start"
          alignItems="center"
        >
          <Heading
            fontSize={typography.fontSizeLarger}
            count={heading.count}
            showCounter={heading.options?.showHeadingCount}
          >
            <EllipsedSpan>{heading.title}</EllipsedSpan>
          </Heading>
        </HorizontalPaddingGrid>
      </NoPaddingBottomGrid>
    );
  }

  if (filtering.options?.useFilters) {
    elements.push(
      <NoPaddingBottomGrid item container direction="column" xs={12} md={2}>
        <PaddingRightGrid
          item
          container
          direction="row"
          xs={12}
          justifyContent="flex-start"
          alignItems="center"
        >
          <ToggleButtonFilters
            toggled={state.showFilters!}
            text={state.showFilters ? t("hideFilters") : t("showFilters")}
            startIcon={<FilterIcon />}
            onClick={toggleFilters}
          />
        </PaddingRightGrid>
      </NoPaddingBottomGrid>
    );
  }

  if (actions.options?.useActions) {
    elements.push(
      <NoPaddingBottomGrid item container direction="column" xs={12} md={7}>
        <PaddingLeftGrid
          item
          container
          direction="row"
          xs={12}
          md={12}
          justifyContent="flex-end"
          alignItems="center"
        >
          {actions.revoke?.useRevoke && actions.revoke?.showRevoke && (
            <>
              <CustomButton.Light
                text={actions.revoke?.revokeLabel!}
                onClick={actions.revoke?.onRevokeClicked}
                startIcon={<ForbiddenIcon />}
              />
              &nbsp;
            </>
          )}
          {actions.delete?.useDelete && actions.delete?.showDelete && (
            <>
              <CustomButton.Light
                text={actions.delete?.deleteLabel!}
                onClick={actions.delete?.onDeleteClicked}
                startIcon={<DeleteIcon />}
                textColor={colors.red.hex}
              />
              &nbsp;
            </>
          )}
          {actions.add?.useAdd && actions.add?.showAdd && (
            <CustomButton.Primary
              text={actions.add?.addLabel!}
              onClick={actions.add?.onAddClicked}
            />
          )}
          {actions.menu?.useMenu && actions.menu?.showMenu && (
            <div>
              <CustomButton.Primary
                onClick={openMenu}
                endIcon={<KeyboardArrowDownIcon />}
                startIcon={<AddIcon/>}
                className="menu-action-button"
              />
              <Menu
                onClose={closeMenu}
                open={menuOpen}
                getContentAnchorEl={null}
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}>
                {actions.menu.menuItems?.map(menuItem => (                
                  <MenuItem
                    className="action-menu"
                    onClick={() => executeMenuAction(menuItem.action)}
                    key={menuItem.label}>
                      {menuItem.icon}
                      {menuItem.label}                    
                  </MenuItem>))}
              </Menu>
            </div>
          )}
        </PaddingLeftGrid>
      </NoPaddingBottomGrid>
    );
  }

  return (
    <NoPaddingBottomGrid
      item
      container
      direction="row"
      xs={12}
      justifyContent="center"
    >
      {elements.map((el, index) => (
        <React.Fragment key={index}>{el}</React.Fragment>
      ))}
    </NoPaddingBottomGrid>
  );
};

export default DataTableHeading;
