import { AxiosResponse } from "axios";
import axios from "../axios";
import { ProductsSalesAvailability } from "../models/visitor-booking/ProductsSalesAvailability";
import { prebookingProductsApi } from "../api/prebookingProducts.api";

export type PrebookingProductsService = {
  getProductSalesAvailability: (
    physicalZoneUid: string,
    startDate: string,
    endDate: string,
    customerId: string,
    productId: string
  ) => Promise<AxiosResponse<ProductsSalesAvailability>>;
};

export const prebookingProductsService: PrebookingProductsService = (() => {
  const getProductSalesAvailability = (
    physicalZoneUid: string,
    startDate: string,
    endDate: string,
    customerId: string,
    productId: string
  ) =>
    axios.get<ProductsSalesAvailability>(
      prebookingProductsApi.getProductSalesAvailabilityUrl(
        physicalZoneUid,
        startDate,
        endDate,
        customerId,
        productId
      )
    );

  return {
    getProductSalesAvailability,
  };
})();
