import moment from "moment-timezone";
import { useCallback, useEffect, useMemo, useState } from "react";
import { findWhere, isEmpty, isNull, isUndefined } from "underscore";
import {
    ProductType,
    VisitorBookingProductConfiguration,
} from "../../../../../../../../../models/visitor-booking";
import { Fields } from "../ProductTimeframeForm.types";

export const useProductTimeframeForm = (
    products: VisitorBookingProductConfiguration[],
    startDate: string,
    startTime: string,
    endDate: string,
    endTime: string,
    productId: string,
    setFieldValue: (
        field: string,
        value: any,
        shouldValidate?: boolean | undefined
    ) => any,
    physicalZone: string | null
) => {
    const [selectedProductId, setSelectedProductId] = useState("");
    const onProductSelect = (id?: string | number | undefined) => {
        setSelectedProductId((id as string) ?? "");
        setFieldValue(Fields.ProductId, id);
    };

    const selectedProduct = useMemo(() => {
        const found = findWhere(products, { id: selectedProductId });
        if (isUndefined(found)) {
            return null;
        }

        return found;
    }, [selectedProductId]);

    const isHourlyRateProduct = useMemo(
        () =>
            !isNull(selectedProduct) &&
            selectedProduct.productType === ProductType.HourlyRate,
        [selectedProduct]
    );

    const setProductTypeAndParkingTime = useCallback(() => {
        if (isNull(selectedProduct)) {
            return;
        }

        setFieldValue(Fields.ProductType, selectedProduct?.productType);
        setFieldValue(Fields.ParkingTime, selectedProduct.parkingTime);
        setFieldValue(Fields.ProductName, selectedProduct.name);
    }, [selectedProduct]);

    useEffect(
        () => setProductTypeAndParkingTime(),
        [setProductTypeAndParkingTime]
    );

    const updateTime = (field: Fields, time?: string) => {
        if (isUndefined(time)) {
            setFieldValue(field, "");
        } else {
            setFieldValue(field, time);
        }
    };

    const onStartTimeChange = (time: string) => {
        updateTime(Fields.StartTime, time);
    };

    const onEndTimeChange = (time: string) => {
        updateTime(Fields.EndTime, time);
    };

    const updateDate = (field: Fields, date: Date | null) => {
        if (isNull(date)) {
            setFieldValue(field, "");
        } else {
            setFieldValue(field, moment(date).format("DD/MM/YYYY"));
        }
    };

    const onStartDateChange = (date: Date | null) => {
        updateDate(Fields.StartDate, date);

        if (startDateAfterEndDate(date)) {
            updateDate(Fields.EndDate, null);
            updateTime(Fields.EndTime);
        }
    };

    const onEndDateChange = (date: Date | null) => {
        updateDate(Fields.EndDate, date);
    };

    const startDateAfterEndDate = (selectedStartDate: Date | null) => {
        if (isNull(startDate)) {
            return false;
        }

        if (isEmpty(endDate)) {
            return false;
        }

        const momentStartDate = moment(selectedStartDate);
        const momentEndDate = moment(endDate, "DD/MM/YYYY");

        return momentStartDate.isAfter(momentEndDate);
    };

    const jsStartDate = useMemo(() => {
        if (isEmpty(startDate)) {
            return null;
        }

        return moment(startDate, "DD/MM/YYYY").toDate();
    }, [startDate]);

    const jsEndDate = useMemo(() => {
        if (isEmpty(endDate)) {
            return null;
        }

        return moment(endDate, "DD/MM/YYYY").toDate();
    }, [endDate]);

    const jsMaxEndDate = useMemo(() => {
        if (isEmpty(startDate)) {
            return null;
        }

        const maxEndDate = moment(startDate, "DD/MM/YYYY").add(1, "year");

        return maxEndDate.toDate();
    }, [startDate]);

    const minStartTime = useMemo(() => {

        let result = "";
        if (isEmpty(startDate)) {
            return result;
        }

        let momentNow = moment(new Date());
        const countryCode = physicalZone?.split(".")[0];
        if (countryCode) {
            const zones = moment.tz.zonesForCountry(countryCode);
            momentNow = momentNow.tz(zones[0]);
        }
        const momentStartDate = moment(startDate, "DD/MM/YYYY");

        if (momentStartDate.isSameOrBefore(momentNow)) {
            updateTime(Fields.StartTime);
            result = momentNow.format("HH:mm");
        }

        return result;
    }, [startDate, physicalZone]);

    const minEndTime = useMemo(() => {
        if (isEmpty(startDate) || isEmpty(endDate) || isEmpty(startTime)) {
            return "";
        }

        const momentStartDate = moment(startDate, "DD/MM/YYYY");
        const momentStartTime = moment(startTime, "HH:mm");

        const momentEndDate = moment(endDate, "DD/MM/YYYY");
        const momentEndTime = moment(endTime, "HH:mm");

        if (
            (momentStartDate.isSameOrAfter(momentEndDate) && isEmpty(endTime)) ||
            (momentStartDate.isSameOrAfter(momentEndDate) &&
                momentStartTime.isSameOrAfter(momentEndTime))
        ) {
            updateTime(Fields.EndTime);
            return startTime;
        }

        return "";
    }, [startDate, endDate, startTime]);

    const resetTimeframeOnProductChange = useCallback(() => {
        updateDate(Fields.StartDate, null);
        updateTime(Fields.StartTime);
        updateDate(Fields.EndDate, null);
        updateTime(Fields.EndTime);
    }, [productId]);

    useEffect(
        () => resetTimeframeOnProductChange(),
        [resetTimeframeOnProductChange]
    );

    return {
        jsStartDate,
        jsEndDate,
        jsMaxEndDate,
        isHourlyRateProduct,
        minEndTime,
        minStartTime,
        onStartDateChange,
        onEndDateChange,
        onStartTimeChange,
        onEndTimeChange,
        onProductSelect,
    };
};
