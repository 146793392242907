import React from "react";
import { SvgIcon } from "@material-ui/core";

const BookingOverviewIcon = () => (
  <SvgIcon
    xmlns="http://www.w3.org/2000/svg"
    width="21.001"
    height="14.001"
    viewBox="0 0 21.001 14.001"
  >
    <path
      id="Subtraction_51"
      data-name="Subtraction 51"
      d="M7289.5,8.3a11.2,11.2,0,0,1-3.781-.58l-.028-.011-.02-.007-.013-.005-.013-.005-.01,0-.009,0-.018-.007,0,0-.013-.005-.007,0-.013-.005-.025-.01a3.681,3.681,0,0,1-.94-.541v0a1.953,1.953,0,0,1-.361-.384h0a1.9,1.9,0,0,0,.162.2,1.81,1.81,0,0,1-.166-.2h0l-.027-.041h0l.009.012a1.407,1.407,0,0,1-.112-.2A1.237,1.237,0,0,1,7284,5.98a5.653,5.653,0,0,1,2.28-4.539A4.758,4.758,0,0,0,7289.5,2.7a4.756,4.756,0,0,0,3.221-1.26A5.649,5.649,0,0,1,7295,5.98c0,.717-.595,1.318-1.719,1.74A11.2,11.2,0,0,1,7289.5,8.3Zm-6.779-2h-7.22a1.5,1.5,0,0,1-1.5-1.5V-1.588a.188.188,0,0,1,.188-.187.187.187,0,0,1,.116.041c.684.532,1.579,1.2,4.814,3.549.1.071.207.155.322.244l.011.009a4.672,4.672,0,0,0,2.54,1.236h.022a2.882,2.882,0,0,0,1.435-.461,7.016,7.016,0,0,0-.747,3.139,2.491,2.491,0,0,0,.021.317Zm-.707-4h-.03a4.262,4.262,0,0,1-1.971-1.06c-.112-.087-.22-.17-.308-.234-3.824-2.776-4.374-3.2-5.205-3.855l-.213-.166a.747.747,0,0,1-.287-.59v-.6a1.5,1.5,0,0,1,1.5-1.5h10.759a4.85,4.85,0,0,0-1.5,3.5A4.837,4.837,0,0,0,7285.4.2l-.433.315-.673.489c-.081.059-.176.133-.277.21l-.031.024A4.264,4.264,0,0,1,7282.015,2.3Zm7.485-1A3.375,3.375,0,0,1,7287,.2a3.565,3.565,0,0,1-.3-.368,3.509,3.509,0,0,1-.64-2.033,3.474,3.474,0,0,1,3.437-3.5,3.474,3.474,0,0,1,3.437,3.5A3.473,3.473,0,0,1,7289.5,1.3Z"
      transform="translate(-7274 5.7)"
      fill="#ed1c2e"
    />
  </SvgIcon>
);

export default BookingOverviewIcon;
