import React from "react";
import { StyledChip, StyledSpan } from "./QParkChip.styledcomponents";
import { QParkChipProps, QParkNumberedChipProps } from "./QParkChip.types";

const Labeled = ({ label }: QParkChipProps) => (
  <StyledChip>
    <StyledSpan title={label}>{label}</StyledSpan>   
  </StyledChip>
);

const Numbered = ({ count }: QParkNumberedChipProps) => (
  <StyledChip>
    <span>{`+${count}`}</span>
  </StyledChip>
);

export const QParkChip = {
  Labeled,
  Numbered,
};
