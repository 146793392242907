import React from "react";
import { SvgIcon } from "@material-ui/core";
import "./_visitorBookingCreation.scss"
import { CloseVisitorIconProps } from "./CloseVisitorIconProps.types";

const CloseVisitorIcon = (props: CloseVisitorIconProps) => {
  const { handleDelete } = props;

  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="35"
      height="35"
      viewBox="-9 -9 35 35"
      className="closeVisitorIcon"
      onClick={handleDelete}
    >
      <path 
          d="M4.98301 3.4747L8 6.49169L11.017 3.4747C11.217 3.27466 11.4883 3.16235 11.7712 3.16235C12.0541 3.16235 12.3254 3.27466 12.5255 3.4747C12.7255 3.67474 12.8378 3.9461 12.8378 4.229C12.8378 4.51189 12.7255 4.78316 12.5255 4.9832L9.50849 8.00019L12.5255 11.0172C12.7255 11.2172 12.8378 11.4886 12.8378 11.7715C12.8378 12.0544 12.7255 12.3256 12.5255 12.5257C12.3254 12.7257 12.0542 12.838 11.7713 12.838C11.4884 12.838 11.217 12.7257 11.017 12.5257L8 9.50868L4.98301 12.5257C4.78297 12.7257 4.51171 12.838 4.22881 12.838C3.94591 12.838 3.67456 12.7257 3.47452 12.5257C3.27448 12.3256 3.16217 12.0543 3.16217 11.7714C3.16217 11.4885 3.27448 11.2172 3.47452 11.0172L6.49151 8.00019L3.47452 4.9832C3.27448 4.78316 3.16217 4.5118 3.16217 4.2289C3.16217 3.94601 3.27448 3.67474 3.47452 3.4747C3.67456 3.27466 3.94582 3.16235 4.22872 3.16235C4.51162 3.16235 4.78297 3.27466 4.98301 3.4747Z" 
          fill="#C0C0CB"
      />
    </SvgIcon>
  );
};

export default CloseVisitorIcon;
