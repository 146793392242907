import { Grid } from "@material-ui/core";
import React, { Dispatch, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { isEmpty, isUndefined } from "underscore";
import useAppContext from "../../../../context/hooks/useAppContext";
import { LocatedParkingProduct } from "../../../../models/LocatedParkingProduct";
import parkingProductsActions from "../../../../store/actions/parkingProducts.actions";
import { RootReducer } from "../../../../store/reducers";
import LocationsCarousel from "../LocationsCarousel/LocationsCarousel";
import CustomHeading from "../../../../ui/CustomHeading/CustomHeading";
import { Position } from "../../../../components/LocationCard/locationCard.business";
import "./_locationCards.scss";

interface ILocationCardsProps extends StateProps, DispatchProps {
  onCardSelected?: (position?: Position) => void;
}

const LocationCards = ({
  locatedParkingProducts,
  getLocations,
  onCardSelected,
}: ILocationCardsProps) => {
  const { appState } = useAppContext();

  const { t } = useTranslation(["myProducts", "globals"]);

  const fetchLocations = useCallback(() => {
    if (
      !isEmpty(appState.user.seasonTicketOwnerCrmId) ||
      !isUndefined(appState.user.seasonTicketOwnerCrmId)
    ) {
      getLocations(appState.user.seasonTicketOwnerCrmId as string, appState.selectedLanguage);
    }
    // eslint-disable-next-line
  }, [appState.user.seasonTicketOwnerCrmId, appState.selectedLanguage]);

  useEffect(() => {
    fetchLocations();
  }, [fetchLocations]);

  const onClick = (position?: Position) => {
    if (!isUndefined(onCardSelected)) {
      onCardSelected(position);
    }
  };

  return (
    <React.Fragment>
      <Grid container direction="row" alignItems="center" className="header-wrapper">
        <CustomHeading
          title={t("myProducts")}
          count={locatedParkingProducts.data?.length}
          options={{
            showCounter: true,
          }}
        />
      </Grid>

      <LocationsCarousel
        loading={locatedParkingProducts.loading}
        elements={locatedParkingProducts.data as LocatedParkingProduct[]}
        onCardClicked={onClick}
      />
    </React.Fragment>
  );
};

const mapStateToProps = (state: RootReducer) => {
  const { locatedParkingProducts } = state.parkingProducts;

  return {
    locatedParkingProducts,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  getLocations: (seasonTicketOwnerCrmId: string, language: string) =>
    dispatch(parkingProductsActions.getLocatedParkingProducts(seasonTicketOwnerCrmId, language)),
});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(LocationCards);
