import React from "react";
import styled from "styled-components";
import IChildren from "../../interfaces/IChildren";

const Ellipsed = styled.span`
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const EllipsedSpan = ({ children }: IChildren) => <Ellipsed>{children}</Ellipsed>;

EllipsedSpan.defaultProps = {
  children: "Your text you want to be ellipsed",
};

export default EllipsedSpan;
