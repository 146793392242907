import { FormikErrors } from "formik";
import { VisitorBookingProductConfiguration } from "../../../../../../../../models/visitor-booking";
import { Required } from "../../../../NewBooking.types";
import { VisitorBookingNewBooking } from "../../../../models/VisitorBookingNewBooking";

export type ProductTimeframeFormProps = {
  products: VisitorBookingProductConfiguration[];
  productId: Required<string>;
  startDate: Required<string>;
  endDate: Required<string>;
  startTime: Required<string>;
  endTime: Required<string>;
  physicalZone: Required<string | null>;
  parkingTime: Required<number | null>;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => Promise<void> | Promise<FormikErrors<VisitorBookingNewBooking>>;
  setFieldError: (field: string, value: string | undefined) => void;
  titlePrefixProduct?: string | null;
  titlePrefixTime?: string | null;
};

export enum Fields {
  ProductId = "productId",
  ParkingTime = "parkingTime",
  StartDate = "startDate",
  StartTime = "startTime",
  EndDate = "endDate",
  EndTime = "endTime",
  ProductType = "productType",
  ProductName = "productName",
}
