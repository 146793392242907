import FutT from "./fonts/Futura-Book.woff2";
import FutD from "./fonts/Futura-Demi.woff2";

const FuturaT = {
  fontFamily: "FuturaT",
  fontStyle: "normal",
  fontWeight: 400,
  src: `
      local('FuturaT'),
      local('FutT'),
      url(${FutT}) format('woff2')
    `,
};

const FuturaD = {
  fontFamily: "FuturaD",
  fontStyle: "normal",
  fontWeight: 400,
  src: `
      local('FuturaD'),
      local('FutD'),
      url(${FutD}) format('woff2')
    `,
};

export const Fonts = {
  FuturaT,
  FuturaD,
};
