import { isEmpty, isUndefined } from "underscore";
import {
  CreateVisitorBookingRequest,
  VisitorBookingsList,
} from "./../../../models/visitor-booking";
import { AxiosError, AxiosResponse } from "axios";
import { Dispatch } from "react";
import { VisitorBookingProductConfiguration } from "../../../models/visitor-booking";
import { visitorBookingService } from "../../../services/visitor-booking";
import { visitorBookingTypes } from "../../action-types";
import { request, success, failure } from "../../helpers/action.helper";
import { VisitorBookingAction, VisitorBookingActions } from "./visitorBookingActions.types";
import { loaderActions } from "../loader.actions";
import { ParkingLocationCountry } from "../../../models/visitor-booking/ParkingLocationCountry";
import { ParkingLocationsRequestModel } from "../../../models/visitor-booking/ParkingLocationsRequestModel";
import { toastTriggers } from "../../../ui/CustomToast/toastTriggers";
import { VisitorBookingsRequest } from "../../../models/visitor-booking/VisitorBookingsRequest";
import { WithFiltersAndPaging } from "../../../models/filters/WithFiltersAndPaging";
import { RootReducer } from "../../reducers";
import { ErrorDetailsModel } from "../../../models/ErrorDetailsModel";
import { checkRequestError } from "../../../utils/errors.utils";
import { CreateVisitorBookingsRequest } from "../../../models/visitor-booking/CreateVisitorBookingsRequest";
import { ProductsSalesAvailability } from "../../../models/visitor-booking/ProductsSalesAvailability";
import { GetFacilityImagesRequest } from "../../../models/visitor-booking/GetFacilityImagesRequest";
import { ParkingFacilityImageModel } from "../../../models/visitor-booking/ParkingFacilityImageModel";
import { prebookingProductsService } from "../../../services/prebookingProducts.service";

export const visitorBookingActions: VisitorBookingActions = (() => {
  const getProductConfigurations = (customerId: string) => {
    return (dispatch: Dispatch<VisitorBookingAction>) => {
      dispatch(loaderActions.loadTheLoader() as any);
      dispatch({
        type: request(visitorBookingTypes.GET_PRODUCT_CONFIGURATIONS),
      });

      visitorBookingService
        .getProductsConfigurations(customerId)
        .then((response: AxiosResponse<VisitorBookingProductConfiguration[]>) => {
          dispatch({
            type: success(visitorBookingTypes.GET_PRODUCT_CONFIGURATIONS),
            productConfigurationsPayload: response.data,
          });
        })
        .catch((error: AxiosError) => {
          dispatch({
            type: failure(visitorBookingTypes.GET_PRODUCT_CONFIGURATIONS),
          });
        })
        .finally(() => {
          dispatch(loaderActions.killTheLoader() as any);
        });
    };
  };

  const getParkingLocations = (model: ParkingLocationsRequestModel) => {
    return (dispatch: Dispatch<VisitorBookingAction>) => {
      dispatch(loaderActions.loadTheLoader() as any);
      dispatch({
        type: request(visitorBookingTypes.GET_PARKING_LOCATIONS),
      });

      visitorBookingService
        .getParkingLocations(model)
        .then((response: AxiosResponse<ParkingLocationCountry[]>) => {
          dispatch({
            type: success(visitorBookingTypes.GET_PARKING_LOCATIONS),
            parkingLocationsPayload: response.data,
          });
        })
        .catch((error: AxiosError) => {
          dispatch({
            type: failure(visitorBookingTypes.GET_PARKING_LOCATIONS),
          });
        })
        .finally(() => {
          dispatch(loaderActions.killTheLoader() as any);
        });
    };
  };

  const createVisitorBooking = (
    customerId: string,
    referenceNumber: string,
    body: CreateVisitorBookingRequest,
    onSuccess?: VoidFunction
  ) => {
    return (dispatch: Dispatch<VisitorBookingAction>) => {
      dispatch(loaderActions.loadTheLoader() as any);
      dispatch({
        type: request(visitorBookingTypes.CREATE_VISITOR_BOOKING),
      });

      visitorBookingService
        .createVisitorBooking(customerId, referenceNumber, body)
        .then(() => {
          dispatch({
            type: success(visitorBookingTypes.CREATE_VISITOR_BOOKING),
          });

          toastTriggers.success({
            content: {
              key: "visitorBooking.createVisitorBooking.success",
            },
          });

          if (!isUndefined(onSuccess)) {
            onSuccess();
          }
        })
        .catch((error: AxiosError) => {
          dispatch({
            type: failure(visitorBookingTypes.CREATE_VISITOR_BOOKING),
          });

          const errorDetails = (error.response?.data as any)?.error;
          let toastKey = "visitorBooking.createVisitorBooking.error";

          if (checkRequestError(errorDetails, "Price", "NotFound")) {
            toastKey = "visitorBooking.createVisitorBooking.priceSetupNotFound";
          }

          if (checkRequestError(errorDetails, "NumberPlate", "NotValid")) {
            toastKey = "visitorBooking.createVisitorBooking.numberPlateNotValid";
          }

          toastTriggers.error({
            content: {
              key: toastKey,
            },
          });
        })
        .finally(() => {
          dispatch(loaderActions.killTheLoader() as any);
        });
    };
  };

  const createVisitorBookings = (
    customerId: string,
    body: CreateVisitorBookingsRequest,
    onSuccess?: VoidFunction
  ) => {
    return (dispatch: Dispatch<VisitorBookingAction>) => {
      dispatch(loaderActions.loadTheLoader() as any);
      dispatch({
        type: request(visitorBookingTypes.CREATE_VISITOR_BOOKINGS),
      });

      visitorBookingService
        .createVisitorBookings(customerId, body)
        .then(() => {
          dispatch({
            type: success(visitorBookingTypes.CREATE_VISITOR_BOOKINGS),
          });

          toastTriggers.success({
            content: {
              key: "visitorBooking.createVisitorBookings.success",
            },
          });

          if (!isUndefined(onSuccess)) {
            onSuccess();
          }
        })
        .catch((error: AxiosError) => {
          dispatch({
            type: failure(visitorBookingTypes.CREATE_VISITOR_BOOKINGS),
          });

          const errorDetails = (error.response?.data as any)?.error;
          let toastKey = "visitorBooking.createVisitorBookings.error";

          if (checkRequestError(errorDetails, "Price", "NotFound")) {
            toastKey = "visitorBooking.createVisitorBookings.priceSetupNotFound";
          }

          if (checkRequestError(errorDetails, "NumberPlate", "NotValid")) {
            toastKey = "visitorBooking.createVisitorBookings.numberPlateNotValid";
          }

          if (checkRequestError(errorDetails, "VisitorBookings", "IsRequired")) {
            toastKey = "visitorBooking.createVisitorBookings.visitorBookingsMissing";
          }

          toastTriggers.error({
            content: {
              key: toastKey,
            },
          });
        })
        .finally(() => {
          dispatch(loaderActions.killTheLoader() as any);
        });
    };
  };

  const getVisitorBookings = (req: WithFiltersAndPaging<VisitorBookingsRequest>) => {
    return (dispatch: Dispatch<VisitorBookingAction>, getState: () => RootReducer) => {
      const { parkingProducts } = getState().parkingProducts;
      const { parkingLocations } = getState().visitorBooking;

      if (isEmpty(parkingProducts.data) || isEmpty(parkingLocations.data)) {
        dispatch(loaderActions.loadTheLoader() as any);
      }

      dispatch({
        type: request(visitorBookingTypes.GET_VISITOR_BOOKINGS),
      });

      visitorBookingService
        .getVisitorBookings(req)
        .then((response: AxiosResponse<VisitorBookingsList>) => {
          dispatch({
            type: success(visitorBookingTypes.GET_VISITOR_BOOKINGS),
            visitorBookingsPayload: response.data,
          });
        })
        .catch(() => {
          dispatch({
            type: failure(visitorBookingTypes.GET_VISITOR_BOOKINGS),
          });
        })
        .finally(() => {
          dispatch(loaderActions.killTheLoader() as any);
        });
    };
  };

  const cancelVisitorBooking = (
    customerId: string,
    referenceNumber: string,
    onSuccess?: VoidFunction
  ) => {
    return (dispatch: Dispatch<VisitorBookingAction>) => {
      dispatch(loaderActions.loadTheLoader() as any);
      dispatch({
        type: request(visitorBookingTypes.CANCEL_VISITOR_BOOKING),
      });

      visitorBookingService
        .cancelVisitorBooking(customerId, referenceNumber)
        .then(() => {
          dispatch({
            type: success(visitorBookingTypes.CANCEL_VISITOR_BOOKING),
            cancelledVisitorBookingReference: referenceNumber,
          });

          toastTriggers.success({
            content: {
              key: "visitorBooking.cancelVisitorBooking.success",
            },
          });

          if (!isUndefined(onSuccess)) {
            onSuccess();
          }
        })
        .catch((error: AxiosError<ErrorDetailsModel>) => {
          dispatch({
            type: failure(visitorBookingTypes.CANCEL_VISITOR_BOOKING),
          });

          const details = error.response?.data as ErrorDetailsModel;
          let toastKey = "visitorBooking.cancelVisitorBooking.error";

          let detailsError = details.errors[0];
          if (detailsError) {
            if (detailsError.visitorBookingStartTime === "notBeforeNow") {
              toastKey = "visitorBooking.cancelVisitorBooking.pastBookingWarning";
            }

            if (detailsError.session === "usedSession") {
              toastKey = "visitorBooking.cancelVisitorBooking.usedSession";
            }
          }

          toastTriggers.error({
            content: {
              key: toastKey,
            },
          });
        })
        .finally(() => {
          dispatch(loaderActions.killTheLoader() as any);
        });
    };
  };

  const visitorBookingValidationLoading = (loading: boolean) => {
    return (dispatch: Dispatch<VisitorBookingAction>) => {
      dispatch({
        type: success(visitorBookingTypes.HANDLE_VISITOR_BOOKING_VALIDATION),
        loading: loading,
      });
    };
  };

  const getProductSalesAvailability = (
    physicalZoneUid: string,
    startDate: string,
    endDate: string,
    customerId: string,
    productId: string
  ) => {
    return (dispatch: Dispatch<VisitorBookingAction>) => {
      dispatch(loaderActions.loadTheLoader() as any);
      dispatch({
        type: request(visitorBookingTypes.GET_PRODUCT_SALES_AVAILABILITY),
      });

      prebookingProductsService
        .getProductSalesAvailability(
          physicalZoneUid,
          startDate,
          endDate,
          customerId,
          productId
        )
        .then((response: AxiosResponse<ProductsSalesAvailability>) => {
          dispatch({
            type: success(visitorBookingTypes.GET_PRODUCT_SALES_AVAILABILITY),
            productSalesAvailabilityPayload: response.data,
          });
        })
        .catch((error: AxiosError) => {
          dispatch({
            type: failure(visitorBookingTypes.GET_PRODUCT_SALES_AVAILABILITY),
          });
        })
        .finally(() => {
          dispatch(loaderActions.killTheLoader() as any);
        });
    };
  };

  const getParkingFacilityImages = (body: GetFacilityImagesRequest[]) => {
    return (dispatch: Dispatch<VisitorBookingAction>) => {
      dispatch(loaderActions.loadTheLoader() as any);
      dispatch({
        type: request(visitorBookingTypes.GET_PARKING_FACILITY_IMAGES),
      });

      visitorBookingService
        .getParkingFacilityImages(body)
        .then((response: AxiosResponse<ParkingFacilityImageModel[]>) => {
          dispatch({
            type: success(visitorBookingTypes.GET_PARKING_FACILITY_IMAGES),
            facilityImagesPayload: response.data,
          });
        })
        .catch((error: AxiosError) => {
          dispatch({
            type: failure(visitorBookingTypes.GET_PARKING_FACILITY_IMAGES),
          });
        })
        .finally(() => {
          dispatch(loaderActions.killTheLoader() as any);
        });
    };
  };

  return {
    getProductConfigurations,
    getParkingLocations,
    createVisitorBooking,
    createVisitorBookings,
    getVisitorBookings,
    cancelVisitorBooking,
    visitorBookingValidationLoading,
    getProductSalesAvailability,
    getParkingFacilityImages,
  };
})();
