import React from "react";
import { ICheckParkingRight } from "../ParkingRightsGrid";
import { isEmpty } from "underscore";
import CircleImage from "../../../../ui/CircleImage/CircleImage";
import { Grid, Box } from "@material-ui/core";
import GridBody from "../GridBody/GridBody";
import ticket from "../../../../assets/images/ticket.svg";
import Spacer from "../../../../ui/Spacer/Spacer";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { variables } from "../../../../theme/variables";

const { typography, colors } = variables;

const StyledStrong = styled.strong`
  font-size: ${typography.fontSizeLarge};
`;

const StyledSpan = styled.span`
  color: ${colors.gray.hex};
  font-size: ${typography.fontSizeBase};
  text-align: center;
`;

const NoResultsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 70%;
`;

interface IResultsGridProps {
  loading?: boolean;
  state?: ICheckParkingRight[];
  onChecked?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  disableEditing?: boolean;
}

const ResultsGrid = (props: IResultsGridProps) => {
  const { t } = useTranslation("delegeeForm");

  if (!props.loading && isEmpty(props.state)) {
    return (
      <Grid container item direction="row" alignItems="center" justifyContent="center" xs={12}>
        <Spacer size="sm" />
        <Grid container item direction="column" alignItems="center" justifyContent="center" xs={12}>
          <CircleImage>
            <embed src={ticket} width={130} height={130} />
          </CircleImage>
        </Grid>
        <Spacer size="xs" />
        <Grid container item direction="column" alignItems="center" justifyContent="center" xs={12}>
          <NoResultsWrapper>
            <StyledStrong>{t("noProductsTitle")}</StyledStrong>
            <Spacer size="xs" />
            <StyledSpan>{t("noProductsBody")}</StyledSpan>
          </NoResultsWrapper>
        </Grid>
      </Grid>
    );
  }

  return (
    <Box component="div" className="parking-rights-container">
      <Grid container item direction="column" xs={12} classes={{ root: "data-table" }}>
        <GridBody
          loading={props.loading as boolean}
          state={props.state as ICheckParkingRight[]}
          onChecked={props.onChecked}
          disableEditing={props.disableEditing}
        />
      </Grid>
    </Box>
  );
};

export default ResultsGrid;
