import React from "react";
import styled from "styled-components";

const ImgBg: React.FC<ImgBgProps> = (props) => {
  return <ImgBgContainer {...props} />;
};

const ImgBgContainer = styled.div`
  background-image: ${({ src }: ImgBgProps) => `url(${src})`};
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

interface ImgBgProps {
  src?: string;
}

export default ImgBg;
