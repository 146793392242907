import React from "react";
import { Select, MenuItem, FormControl } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { ExpandMoreOutlined } from "@material-ui/icons";
import useAppContext from "../../context/hooks/useAppContext";
import styled from "styled-components";
import "./_language-selector.scss";
import { languages } from "../../translations";

const LanguageContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  padding: 0 0.5rem;
`;

const LanguageSelector = () => {
  const { i18n } = useTranslation();
  const { appState } = useAppContext();

  const changeLanguage = (
    event: React.ChangeEvent<{ name?: string | undefined; value: any }>
  ) => {
    const { value } = event.target;
    i18n.changeLanguage(value!);
  };

  return (
    <LanguageContainer>
      <FormControl
        classes={{
          root: "language-form-control",
        }}
      >
        <Select
          id="select-language"
          classes={{
            root: "language-select-root",
            selectMenu: "language-select-menu",
            icon: "language-select-icon",
          }}
          value={appState.selectedLanguage}
          onChange={changeLanguage}
          IconComponent={ExpandMoreOutlined}
          inputProps={{ MenuProps: { disableScrollLock: true } }}
        >
          <MenuItem value={languages.en}>EN</MenuItem>
          <MenuItem value={languages.nl}>NL</MenuItem>
          <MenuItem value={languages.de}>DE</MenuItem>
          <MenuItem value={languages.fr}>FR</MenuItem>
        </Select>
      </FormControl>
    </LanguageContainer>
  );
};

export default LanguageSelector;
