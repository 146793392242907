import { success } from "../helpers/action.helper";
import { environmentTypes } from "../action-types/environment.types";
import environmentService from "../../services/environment.service";
import { Dispatch } from "react";
import { AxiosResponse } from "axios";
import { isUndefined } from "underscore";
import { FeatureFlagsAndDynamicConfiguration } from "../../types/FeatureFlagsAndDynamicConfiguration";

export type EnvironmentDispatchType = {
  getEnvironment: () => void;
  getVersion: () => void;
  getPortalStatus: (callback?: () => void) => void;
  getFeatureFlagsAndDynamicConfigurations: (loggedInCrmId: string) => void;
  getAllowImpersonation: (loggedInCrmId: string) => void;
};

export type EnvironmentActionType = {
  type: string;
  payload?: string | boolean | FeatureFlagsAndDynamicConfiguration;
};

const getEnvironment = () => {
  return (dispatch: Dispatch<EnvironmentActionType>) => {
    environmentService
      .getEnvironment()
      .then((response: AxiosResponse<string>) => {
        dispatch({
          type: success(environmentTypes.GET_ENVIRONMENT),
          payload: response.data!,
        });
      });
  };
};

const getVersion = () => {
  return (dispatch: Dispatch<EnvironmentActionType>) => {
    environmentService.getVersion().then((response: AxiosResponse<string>) => {
      dispatch({
        type: success(environmentTypes.GET_VERSION),
        payload: response.data!,
      });
    });
  };
};

const getPortalStatus = (callback?: () => void) => {
  return (dispatch: Dispatch<EnvironmentActionType>) => {
    environmentService
      .getPortalStatus()
      .then((response: AxiosResponse<boolean>) => {
        dispatch({
          type: success(environmentTypes.GET_PORTAL_STATUS),
          payload: response.data as boolean,
        });

        if (!isUndefined(callback)) {
          callback();
        }
      });
  };
};

const getFeatureFlagsAndDynamicConfigurations = (loggedInCrmId: string) => {
  return (dispatch: Dispatch<EnvironmentActionType>) => {
      environmentService
      .getFeatureFlagsAndDynamicConfiguration(loggedInCrmId)
      .then((response: AxiosResponse<FeatureFlagsAndDynamicConfiguration>) => {
        dispatch({
          type: success(environmentTypes.GET_FEATURE_FLAGS_AND_DYNAMIC_CONFIGURATIONS),
          payload: response.data as FeatureFlagsAndDynamicConfiguration,
        });
      });
  };
};

const getAllowImpersonation = (loggedInCrmId: string) => {
  return (dispatch: Dispatch<EnvironmentActionType>) => {
    environmentService
      .getAllowImpersonation(loggedInCrmId)
      .then((response: AxiosResponse<boolean>) => {
        dispatch({
          type: success(environmentTypes.GET_ALLOW_IMPERSONATION),
          payload: response.data!,
        });
      });
  };
};

export default {
  getEnvironment,
  getVersion,
  getPortalStatus,
  getFeatureFlagsAndDynamicConfigurations,
  getAllowImpersonation,
} as EnvironmentDispatchType;
