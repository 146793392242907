import React from "react";
import { createContext, useReducer } from "react";
import { ICheckParkingRight } from "../components/DelegeeForm/ParkingRightsGrid/ParkingRightsGrid";
import { importDelegationsTypes } from "./store/actions/importDelegations.types";
import reducer, {
  initialState,
  ImportDelegationsState,
  DelegeeData,
} from "./store/reducers/importDelegations.reducer";

interface ImportDelegationsContext {}

export interface ImportDelegationsContextState {
  importDelegationsState: ImportDelegationsState;
  setDelegeesData: (data: DelegeeData[],) => void;
  setCheckParkingRights: (checkParkingRights: ICheckParkingRight[]) => void;
  setProcessing: (isProcessing: boolean) => void;
}

export const ImportDelegationsCtx =
  createContext<ImportDelegationsContextState>(
    {} as ImportDelegationsContextState
  );

const ImportDelegationsContextProvider: React.FC<ImportDelegationsContext> = ({
  children,
}) => {
  const [importDelegationsState, dispatch] = useReducer(reducer, initialState);

  const setDelegeesData = (data: DelegeeData[]) =>
    dispatch({
      type: importDelegationsTypes.SET_DELEGEES_DATA,
      payload: data,
    });

  const setCheckParkingRights = (parkingRights: ICheckParkingRight[]) =>
    dispatch({
      type: importDelegationsTypes.SET_SELECTED_PARKING_RIGHTS,
      payload: parkingRights,
    });

  const setProcessing = (isProcessing: boolean) =>
    dispatch({
      type: importDelegationsTypes.SET_PROCESSING,
      payload: isProcessing,
    });

  const value: ImportDelegationsContextState = {
    importDelegationsState,
    setDelegeesData,
    setCheckParkingRights,
    setProcessing,
  };

  return (
    <ImportDelegationsCtx.Provider value={value}>
      {children}
    </ImportDelegationsCtx.Provider>
  );
};

export default ImportDelegationsContextProvider;
