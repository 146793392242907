import styled from "styled-components";
import { ExitToAppRounded, ExpandLessRounded, ExpandMoreRounded } from "@material-ui/icons";
import { variables } from "../../theme/variables";
import { Collapse, Grid, IconButton } from "@material-ui/core";

const { colors } = variables;

export const UserInfoCard = styled.div``;

export const ExpandMore = styled(ExpandMoreRounded)`
  color: ${colors.grayishBlue.hex};
`;

export const ExpandLess = styled(ExpandLessRounded)`
  color: ${colors.grayishBlue.hex};
`;

export const StyledFullName = styled.strong`
  color: ${colors.black};
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
`;

export const NoPaddingGrid = styled(Grid)`
  padding-bottom: 0;
  overflow: hidden;
  position: relative;
`;

export const NoPaddingWithOverflowGrid = styled(Grid)`
padding-bottom: 0;
position: relative;
`;

export const PointerContainerGrid = styled(Grid)`
  padding-bottom: 0;
  overflow: hidden;
  position: relative;

  &:hover {
    cursor: pointer;
  }
`;

export const StyledIconButton = styled(IconButton)`
  padding: 0;
`;

export const AbstractCollapse = styled(Collapse)`
  position: absolute;
  z-index: 30;
  top: 65px;
  right: 25px;
`;

export const DetailsContainer = styled.div`
  background-color: ${colors.white};
  border-radius: 6px;
  min-width: 250px;
  padding: 1rem 0.5rem;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
`;

export const SignOutIcon = styled(ExitToAppRounded)`
  color: ${colors.lightGrayishBlue.hex};
`;

export const UserCardWrapper = styled.div`
  padding: 0.5rem;
`;
