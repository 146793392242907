import { loaderTypes } from "../action-types/loader.types";

export type LoaderActionType = {
  type: string;
};

export type LoaderState = {
  showLoader: boolean;
};

const initialState: LoaderState = {
  showLoader: false,
};

export default (state: LoaderState = initialState, action: LoaderActionType): LoaderState => {
  switch (action.type) {
    case loaderTypes.LOAD_THE_LOADER:
      return {
        ...state,
        showLoader: true,
      };
    case loaderTypes.KILL_THE_LOADER:
      return {
        ...state,
        showLoader: false,
      };
    default:
      return state;
  }
};
