import { variables } from "../../../../../theme/variables";

const { colors } = variables;

const hoveredStyles = {
  background: `rgba(${colors.lime.rgb}, 0.5)`,
  border: "none",
  color: colors.black,
};

const selectedStyles = {
  background: colors.lime.hex,
  border: "none",
  color: colors.white,

  hover: {
    background: colors.lime.hex,
    border: "none",
    color: colors.white,
  },
};

const selectedSpanStyles = {
  background: colors.lime.hex,
  border: "none",
  color: colors.white,

  hover: {
    background: colors.lime.hex,
    border: "none",
    color: colors.white,
  },
};

const defaultStyles = {
  background: colors.mostlyWhite,
  border: "none",
  color: colors.black,

  hover: {
    background: colors.veryLightGray,
    border: "none",
    color: colors.black,
  },
};

const outsideStyles = {
  background: colors.white,
  border: 0,
  color: colors.lightGrayishBlue.hex,
};

export const customDayStyles = {
  defaultStyles: defaultStyles,
  outsideStyles: outsideStyles,
  selectedStartStyles: selectedStyles,
  selectedEndStyles: selectedStyles,
  hoveredSpanStyles: hoveredStyles,
  selectedSpanStyles,
  selectedStyles: {
    ...selectedStyles,
    ...{
      background: `rgba(${colors.lime.rgb}, 0.5)`,
      border: "none",
      color: colors.black,
    },
  },
};
