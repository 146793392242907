import React from "react";
import IChildren from "../interfaces/IChildren";
import AppContextProvider from "./AppContextProvider";
import FleetManagerContextProvider from "./FleetManagerContextProvider";

const ContextProvider = ({ children }: IChildren) => {
  return (
    <AppContextProvider>
      <FleetManagerContextProvider>{children}</FleetManagerContextProvider>
    </AppContextProvider>
  );
};

export default ContextProvider;
