import React from "react";
import IChildren from "../../interfaces/IChildren";
import styled from "styled-components";
import { variables } from "../../theme/variables";
import { Fonts } from "../../typography";

const { colors, typography, whiteSpaces } = variables;
const { FuturaT } = Fonts;

interface IHeadingProps extends IChildren {
  fontSize?: string;
  justifyContent?: "flex-start" | "flex-end" | "center";
  width?: string;
  required?: boolean;
  showCounter?: boolean;
  count?: number;
}

const StyledStrong = styled.strong`
  font-size: ${(props: IHeadingProps) => props.fontSize || typography.fontSizeBase};
  color: ${colors.black};
  width: ${(props: IHeadingProps) => props.width || "100%"};
  display: flex;
  align-items: center;
  justify-content: ${(props: IHeadingProps) => props.justifyContent};
`;

const RequiredSpan = styled.span`
  font-size: ${(props: IHeadingProps) => props.fontSize || typography.fontSizeBase};
  color: ${colors.darkRed};
`;

const Counter = styled.span`
  font-size: ${(props: IHeadingProps) => props.fontSize || typography.fontSizeBase};
  font-family: ${FuturaT.fontFamily};
  color: ${colors.black};
  margin-left: ${whiteSpaces.xs};
  &:before {
    content: "(";
  }
  &:after {
    content: ")";
  }
`;

const Heading = (props: IHeadingProps) => (
  <StyledStrong {...props}>
    {props.children}
    {props.required && <RequiredSpan {...props}>*</RequiredSpan>}
    {props.showCounter && <Counter>{props.count}</Counter>}
  </StyledStrong>
);

Heading.defaultProps = {
  justifyContent: "flex-start",
};

export default Heading;
