import { ScheduleRounded } from "@material-ui/icons";
import styled from "styled-components";
import { variables } from "../../theme/variables";

const { colors } = variables;

export const ClockIcon = styled(ScheduleRounded)`
  width: 20px;
  height: 20px;
  position: absolute;
  right: 12px;
  color: ${colors.lightGray};
  top: 10px;

  &:hover {
    cursor: pointer;
  }
`;