import { InputAdornment } from "@material-ui/core";
import React from "react";
import { NoPaddingGrid } from "../../../../containers/FleetManager/FleetManagerDataTable/StyledComponents";
import {
  DataTableContextFilters,
  useDataTableContext,
} from "../../DataTableContext/DataTableContextProvider";
import { FilterType, IFilter } from "../DataTableFilters";
import { ClearInputIcon, SeachIcon } from "../CommonStyledComponents";
import { PaddingCustomInput } from "../CommonStyledComponents";

interface IInputFilter {
  filter: IFilter;
  entity?: DataTableContextFilters;
}

const InputFilter: React.FC<IInputFilter> = ({ filter, entity }) => {
  const { setFilter, resetFilter } = useDataTableContext();

  return (
    <NoPaddingGrid item container direction="column" justifyContent="center" xs={2}>
      <PaddingCustomInput
        size="small"
        fullWidth
        className="padding-right-very-small-important"
        name={entity?.key}
        value={entity?.value}
        onChange={(e) =>
          setFilter(entity?.key as string, entity?.type as FilterType, e.target.value)
        }
        variant="outlined"
        placeholder={filter.placeholder}
        autoComplete="off"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SeachIcon />
            </InputAdornment>
          ),
          endAdornment: (
            <>
              {entity?.value && (
                <InputAdornment position="end">
                  <ClearInputIcon onClick={() => resetFilter(entity?.key)} />
                </InputAdornment>
              )}
            </>
          ),
        }}
      />
    </NoPaddingGrid>
  );
};

export default InputFilter;
