import { QParkDatePickerV2Props } from "./QParkDatePickerV2.types";
import flatpickr from "flatpickr";
import { Instance } from "flatpickr/dist/types/instance";
import { BaseOptions } from "flatpickr/dist/types/options";
import moment, { Moment } from "moment";
import {
  ChangeEvent,
  FocusEvent,
  KeyboardEvent,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { isEmpty, isNull, isUndefined } from "underscore";
import useIsMounted from "../../hooks/useIsMounted";
import useAppContext from "../../context/hooks/useAppContext";
import { qparkDatePickerV2Locale } from "./QParkDatePickerV2.locale";

const momentDateFormat = "DD/MM/YYYY";

const parseDate = (date: Date | string): Moment =>
  moment(date, momentDateFormat);
const formatDate = (date: Date): string =>
  moment(date).format(momentDateFormat);

const useQParkDatePickerV2 = (props: Partial<QParkDatePickerV2Props>) => {
  const { value, minDate, maxDate, onChange } = props;

  const isMounted = useIsMounted();
  const {
    appState: { selectedLanguage },
  } = useAppContext();

  const [inputDateValue, setInputDateValue] = useState<string>("");
  const inputRef = useRef<HTMLInputElement | null>(null);
  const flatpickrInstance = useRef<Instance | null>(null);
  const isManualInput = useRef<boolean>(false);

  const setManualInput = (_: KeyboardEvent<HTMLInputElement>) =>
    (isManualInput.current = true);
  const unSetManualInput = () => (isManualInput.current = false);

  const onManualInput = (event: ChangeEvent<HTMLInputElement>) =>
    setInputDateValue(event.target.value);

  const validateAndSetManualInputDate = (
    event: FocusEvent<HTMLInputElement>
  ) => {
    if (!isManualInput.current) {
      return;
    }

    if (isNull(inputRef.current) || isNull(flatpickrInstance.current)) {
      return;
    }

    const inputValue = event.target.value;
    if (isEmpty(inputValue)) {
      flatpickrInstance.current.clear(true);
      return;
    }

    const formattedDate = parseDate(inputValue);

    const isValidDate = formattedDate.isValid();
    if (!isValidDate) {
      flatpickrInstance.current.clear(true);
      setInputDateValue("");
      return;
    }

    const isBeforeToday = formattedDate.isBefore(new Date(), "day");
    if (isBeforeToday) {
      flatpickrInstance.current.clear(true);
      setInputDateValue("");
      return;
    }

    if (
      !isUndefined(minDate) &&
      !isNull(minDate) &&
      parseDate(minDate).isValid()
    ) {
      const isBeforeMinDate = formattedDate.isBefore(minDate, "day");

      if (isBeforeMinDate) {
        flatpickrInstance.current.clear(true);
        setInputDateValue("");
        return;
      }
    }

    if (
      !isUndefined(maxDate) &&
      !isNull(maxDate) &&
      parseDate(maxDate).isValid()
    ) {
      const isAfterMaxDate = formattedDate.isAfter(maxDate, "day");

      if (isAfterMaxDate) {
        flatpickrInstance.current.clear(true);
        setInputDateValue("");
        return;
      }
    }

    flatpickrInstance.current.setDate(inputValue, true);
  };

  const onDateChange = useCallback(
    (date: Date[], dateStr: string, instance: any) => {
      unSetManualInput();

      if (isUndefined(onChange)) {
        return;
      }

      let jsDateValue: Date | null = null;

      if (!isEmpty(dateStr)) {
        jsDateValue = parseDate(dateStr).toDate();
      }

      onChange(jsDateValue);
    },
    [onChange]
  );

  const getFlatpickrOptions = useCallback(() => {
    const flatpickrOptions: Partial<BaseOptions> = {
      locale: {
        ...qparkDatePickerV2Locale.get(selectedLanguage),
        firstDayOfWeek: 1,
      },
      onChange: onDateChange,
      dateFormat: "d/m/Y",
      defaultDate: !isEmpty(value) ? value : undefined,
      disableMobile: true,
      allowInput: true,
      minDate:
        !isUndefined(minDate) && !isNull(minDate)
          ? formatDate(minDate)
          : "today",
      maxDate:
        !isUndefined(maxDate) && !isNull(maxDate)
          ? formatDate(maxDate)
          : undefined,
      monthSelectorType: "static",
      mode: "single",
      shorthandCurrentMonth: false,
    };

    return flatpickrOptions;
  }, [minDate, maxDate, value, selectedLanguage]);

  const updateDateState = useCallback(
    () => setInputDateValue(inputRef.current?.value ?? ""),
    [inputRef.current?.value]
  );

  useLayoutEffect(() => {
    flatpickrInstance.current = flatpickr(
      inputRef.current as Node,
      getFlatpickrOptions()
    );
  }, [getFlatpickrOptions]);

  useEffect(() => {
    if (!isMounted()) {
      return;
    }

    if (isEmpty(value)) {
      flatpickrInstance.current?.clear(true);
    }

    if (
      parseDate(value!).isValid() &&
      parseDate(value!).isBefore(minDate, "day")
    ) {
      flatpickrInstance.current?.clear(true);
    }
  }, [value, minDate]);

  useEffect(() => {
    updateDateState();
  }, [updateDateState]);

  return {
    inputRef,
    inputDateValue,
    onManualInput,
    validateAndSetManualInputDate,
    setManualInput,
  };
};

export default useQParkDatePickerV2;
