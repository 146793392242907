import React from "react";
import styled from "styled-components";
import { variables } from "../../theme/variables";

const { colors } = variables;

const ArrowUp = styled.div`
  display: inline-block;
  position: relative;
  border: 1px solid ${colors.veryLightGray};
  margin-top: 5px;
  width: 100%;
  box-shadow: 0px 1px 3px ${colors.veryLightGraySecondary};
  ${({ rounded }: ContainerArrowUpProps) => rounded && "border-radius: 6px;"}

  &:before {
    content: "";
    display: block;
    position: absolute;
    bottom: 100%;
    ${({ left }: ContainerArrowUpProps) =>
      left && left > 0 ? `left: ${left - 1}px;` : "left: unset;"}
    ${({ right }: ContainerArrowUpProps) =>
      right && right > 0 ? `right: ${right - 1}px;` : "right: unset;"}
    width: 0;
    height: 0;
    border: 10px solid transparent;
    border-bottom-color: ${colors.veryLightGray};
  }

  &:after {
    content: "";
    display: block;
    position: absolute;
    top: -17px;
    ${({ left }: ContainerArrowUpProps) => (left && left > 0 ? `left: ${left}px;` : "left: unset;")}
    ${({ right }: ContainerArrowUpProps) =>
      right && right > 0 ? `right: ${right}px;` : "right: unset;"}
    bottom: 100%;
    width: 0;
    height: 0;
    border: 9px solid transparent;
    border-bottom-color: ${colors.white};
  }
`;

interface ContainerArrowUpProps {
  left?: number;
  right?: number;
  rounded?: boolean;
}

const ContainerArrowUp: React.FC<ContainerArrowUpProps> = (props) => (
  <ArrowUp {...props}>{props.children}</ArrowUp>
);

export default ContainerArrowUp;
