import React from "react";

export type SidebarInformationProps = {
    imageSrc: string;
    text: string;
    useWarningColor: boolean;
}

export function SidebarInformation(props: Readonly<SidebarInformationProps>) {
    const { imageSrc, text, useWarningColor } = props;

    return (
        <div className="sidebar-info">
            <img src={imageSrc} alt="info"></img>
            <div className={useWarningColor ? "warning-color" : ""}>{text}</div>
        </div>
    );
}