import React, { useState } from "react";
import { useDownloadFile } from "./useDownloadFile";
import MuiAlert from "@material-ui/lab/Alert";
import CustomButton from "../../ui/CustomButton/CustomButton";
import parkingProductsService from "../../services/parkingProducts.service";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import { DelegeeFileType } from "./DelegeeFileType.enum";

const DownloadDelegeeFile: React.FC<IDownloadDelegeeFile> = (props) => {
   
    const [showError, setShowError] = useState<boolean>(false);
    const [error, setError] = useState<string>();
    const { t } = useTranslation(["downloadDelegeeFile"]);

    const onErrorDownloadFile = (message: string) => {
        setShowError(true);
        setError(message);
        setTimeout(() => {
            setShowError(false);
        }, 3000);                
    };
   
    const getFileName = () => props.delegeeFileType === DelegeeFileType.Invitation 
        ? `invitation_errors-${format(new Date(), 'dd-MM-yyyy-HHmm')}.csv` 
        : `validation_errors-${format(new Date(), 'dd-MM-yyyy-HHmm')}.csv`;
    const getSeasonTicketOwnerCrmId = () => props.seasonTicketOwnerCrmId;
    const getFileReference = () => props.fileReference;
    const getLanguage = () => props.language;
    const delegeeFileType: DelegeeFileType = props.delegeeFileType;

    const { download } = useDownloadFile({
        downloadValidationRequest: () => parkingProductsService.getDelegeesValidationResult(props.seasonTicketOwnerCrmId, props.fileReference, props.language!),
        downloadInvitationRequest: () => parkingProductsService.getDelegeesInvitationResult(props.seasonTicketOwnerCrmId, props.fileReference, props.language!),
        onError: onErrorDownloadFile,
        getFileName,
        getSeasonTicketOwnerCrmId,
        getFileReference,
        getLanguage,
        delegeeFileType
    });

    return (
        <div>            
            {showError &&
                <MuiAlert severity="error" >
                    {t("downloadError", {error: error})}
                </MuiAlert>
            }

            <CustomButton.Light
                text={t("downloadErrorDetails")}
                onClick={download}
            />                
            
        </div>
    );
};

interface IDownloadDelegeeFile {
    seasonTicketOwnerCrmId: string;
    fileReference: string;
    language?: string;
    delegeeFileType: DelegeeFileType;
}

export default DownloadDelegeeFile;