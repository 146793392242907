import React, { Dispatch } from "react";
import { connect } from "react-redux";
import Warning from "./Warning";
import parkingProductsActions from "../../store/actions/parkingProducts.actions";
import useFleetManagerContext from "../../context/hooks/useFleetManagerContext";
import { useTranslation } from "react-i18next";
import { DeleteDelegationsRequestModel } from "../../models/delegations/DeleteDelegationsRequestModel";
import { RootReducer } from "../../store/reducers";

type FormOptions = {
  warning: string;
  warningDescription: string;
};

interface IFormProps extends IStateProps, DispatchProps {
  onRefresh: () => void;
  options: FormOptions;
}

const BatchDeleteDelegeeWarning: React.FC<IFormProps> = (props) => {
  const { deleteDelegations, onRefresh, options, loading } = props;
  const { t } = useTranslation("globals");

  const {
    fleetManagerState,
    setSelectedParkingRightIds,
    toggleBatchDeleteDelegationsWarning,
  } = useFleetManagerContext();

  const handleSuccessfulDelete = () => {
    toggleBatchDeleteDelegationsWarning();

    onRefresh();
    setSelectedParkingRightIds([]);
  };

  const handleDeleteConfirmation = () =>
    deleteDelegations(
      { registrationIds: fleetManagerState.selectedRowKeys },
      handleSuccessfulDelete
    );

  return (
    <Warning
      warning={options.warning}
      details={options.warningDescription}
      onCancel={toggleBatchDeleteDelegationsWarning}
      onConfirm={handleDeleteConfirmation}
      confirmText={t("delete")}
      disabled={loading}
    />
  );
};

const mapStateToProps = (state: RootReducer) => {
  const { parkingProducts } = state;
  const { loading } = parkingProducts;

  return { loading };
};

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  deleteDelegations: (request: DeleteDelegationsRequestModel, callback?: () => void) =>
    dispatch(parkingProductsActions.deleteDelegations(request, callback)),
});

type IStateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(BatchDeleteDelegeeWarning);
