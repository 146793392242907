import React, { PropsWithChildren } from "react";
import { isUndefined } from "underscore";
import "./_actionFooter.scss";

const ActionFooter = ({
  children,
  justifyContent,
  className,
}: PropsWithChildren<ActionFooterProps>) => (
  <div
    className={`action-footer ${
      isUndefined(className) ? "" : className
    }`.trim()}
  >
    <div
      className={`action-footer-container ${
        justifyContent ? "space-between" : ""
      }`.trim()}
    >
      {children}
    </div>
  </div>
);

interface ActionFooterProps {
  justifyContent?: "space-between";
  className?: string;
}

export default ActionFooter;
