import { AxiosResponse } from "axios";
import { Dispatch } from "react";
import { isUndefined } from "underscore";
import { OidcConfigurationResponseModel } from "../../models/OidcConfigurationResponseModel";
import oidcService from "../../services/oidc.service";
import { oidcTypes } from "../action-types/oidc.types";
import { success } from "../helpers/action.helper";

export type OidcDispatchType = {
  getConfiguration: (callback?: Function) => void;
};

export type OidcActionType = {
  type: string;
  payload: OidcConfigurationResponseModel;
};

const getConfiguration = (callback?: Function) => {
  return (dispatch: Dispatch<OidcActionType>) => {
    oidcService
      .getConfiguration()
      .then((response: AxiosResponse<OidcConfigurationResponseModel>) => {
        dispatch({
          type: success(oidcTypes.GET_CONFIGURATION),
          payload: response.data,
        });

        if (!isUndefined(callback)) {
          callback();
        }
      });
  };
};

export default {
  getConfiguration,
} as OidcDispatchType;
