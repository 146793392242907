import { Dispatch } from "react";
import { loaderTypes } from "../action-types/loader.types";
import { LoaderActionType } from "../reducers/loader.reducer";

export type LoaderActions = {
  loadTheLoader: () => (dispatch: Dispatch<LoaderActionType>) => void;
  killTheLoader: () => (dispatch: Dispatch<LoaderActionType>) => void;
};

const loadTheLoader = () => {
  return (dispatch: Dispatch<LoaderActionType>) => {
    dispatch({
      type: loaderTypes.LOAD_THE_LOADER,
    });
  };
};

const killTheLoader = () => {
  return (dispatch: Dispatch<LoaderActionType>) => {
    dispatch({
      type: loaderTypes.KILL_THE_LOADER,
    });
  };
};

export const loaderActions: LoaderActions = {
  loadTheLoader,
  killTheLoader,
};
