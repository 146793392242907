import {
  CreateVisitorBookingRequest,
  VisitorBookingProductConfiguration,
  VisitorBookingsList,
} from "../../models/visitor-booking";
import { VisitorBookingService } from "./visitorBookingService.types";
import { visitorBookingApi } from "../../api/visitor-booking";
import { AxiosResponse } from "axios";
import { ParkingLocationCountry } from "../../models/visitor-booking/ParkingLocationCountry";
import { ParkingLocationsRequestModel } from "../../models/visitor-booking/ParkingLocationsRequestModel";
import { VisitorBookingsRequest } from "../../models/visitor-booking/VisitorBookingsRequest";
import axios from "../../axios";
import { WithFiltersAndPaging } from "../../models/filters/WithFiltersAndPaging";
import queryParam from "../../utils/queryParam.utils";
import { CreateVisitorBookingsRequest } from "../../models/visitor-booking/CreateVisitorBookingsRequest";
import { GetFacilityImagesRequest } from "../../models/visitor-booking/GetFacilityImagesRequest";
import { ParkingFacilityImageModel } from "../../models/visitor-booking/ParkingFacilityImageModel";

export const visitorBookingService: VisitorBookingService = (() => {
  const getProductsConfigurations = (
    customerId: string
  ): Promise<AxiosResponse<VisitorBookingProductConfiguration[]>> => {
    return axios.get(visitorBookingApi.getProductConfigurationsUrl(customerId));
  };

  const getParkingLocations = (
    parkingLocationsRequest: ParkingLocationsRequestModel
  ): Promise<AxiosResponse<ParkingLocationCountry[]>> => {
    return axios.post(visitorBookingApi.getParkingLocationsUrl(), parkingLocationsRequest);
  };

  const createVisitorBooking = (
    customerId: string,
    referenceNumber: string,
    body: CreateVisitorBookingRequest
  ) => {
    return axios.post(
      visitorBookingApi.getCreateVisitorBookingUrl(customerId, referenceNumber),
      body
    );
  };

  const createVisitorBookings = (customerId: string, body: CreateVisitorBookingsRequest) => {
    return axios.post(visitorBookingApi.getCreateVisitorBookingsUrl(customerId), body);
  };

  const getVisitorBookings = (
    request: WithFiltersAndPaging<VisitorBookingsRequest>
  ): Promise<AxiosResponse<VisitorBookingsList>> => {
    const params = queryParam.buildQueryParam(request.filters!, request.pagination);

    return axios.get(visitorBookingApi.getVisitorBookingsUrl(request.entity.customerId), {
      params,
    });
  };

  const cancelVisitorBooking = (customerId: string, referenceNumber: string) =>
    axios.post(visitorBookingApi.getCancelVisitorBookingUrl(customerId, referenceNumber));

  const getParkingFacilityImages = (body: GetFacilityImagesRequest[]) =>
    axios.post<ParkingFacilityImageModel[]>(visitorBookingApi.getParkingFacilityImagesUrl(), body);

  return {
    getProductsConfigurations,
    getParkingLocations,
    createVisitorBooking,
    createVisitorBookings,
    getVisitorBookings,
    cancelVisitorBooking,
    getParkingFacilityImages,
  };
})();
