import { ParkingProductModel } from "../../models/ParkingProductModel";
import { request, success, failure } from "../helpers/action.helper";
import { parkingProductsTypes } from "../action-types/parkingProducts.types";
import { ParkingProductsActionType } from "../actions/parkingProducts.actions";
import {
  DelegatedParkingRight,
  DelegeeWithProductsModel,
  AssignedParkingRight,
  VehicleAssignmentModel,
  VehicleAssignment,
} from "../../models/delegations/DelegatedParkingProductsModel";
import { LocatedParkingProduct } from "../../models/LocatedParkingProduct";
import { ErrorDetailsModel } from "../../models/ErrorDetailsModel";
import parkingProductsReducerHelper from "../helpers/parkingProductsReducer.helper";
import { GetVehicleAssignmentsQuery } from "../../api/parkingProducts.api";
import { reduceVehicleAssignmentMutation, reduceRevokeMyFleet } from "../helpers/dva-optimistic-updates";

type Pending = {
  loading: boolean;
};

type DataType<T> = {
  loading: boolean;
  data?: T | null;
  error?: ErrorDetailsModel | null;
};

type PagedDataType<T> = {
  [page: number]: DataType<T>;
};

export type VehiclesMyProductsState = {
  totalPages: number;
  totalRecords: number;
  pages: {
    [page: number]: {
      loading: boolean;
      data?: AssignedParkingRight[] | null;
      error?: ErrorDetailsModel | null;
      query: GetVehicleAssignmentsQuery;
    };
  };
};

export type VehicleAssignmentsMyFleetState = {
  totalPages: number;
  totalRecords: number;
  pages: {
    [page: number]: {
      loading: boolean;
      data?: VehicleAssignment[] | null;
      error?: ErrorDetailsModel | null;
      query: GetVehicleAssignmentsQuery;
    };
  };
};

export type ParkingProductState = {
  loading: boolean;
  parkingProducts: DataType<ParkingProductModel[]>;
  locatedParkingProducts: DataType<LocatedParkingProduct[]>;
  delegatedParkingProducts: {
    totalPages: number;
    totalRecords: number;
    pages: PagedDataType<DelegatedParkingRight[]> | null;
  };
  delegees: {
    totalPages: number;
    totalRecords: number;
    pages: PagedDataType<DelegeeWithProductsModel[]> | null;
  };
  vehiclesMyProducts: VehiclesMyProductsState;
  vehicleAssignmentsMyFleet: VehicleAssignmentsMyFleetState;
  delegeeDetails: DataType<DelegeeWithProductsModel>;
  importDelegees: Pending;
  validateDelegees: Pending & {
    success?: boolean;
    fileReference?: string;
  };
  selectedVehicleAssignment: DataType<VehicleAssignmentModel>
  bulkImportProgress: {
    progress: number;
    hasErrors: boolean;
    numberOfErrors: number;
  };
  isBulkImportCompleted: boolean;
  error: ErrorDetailsModel | null;
};

const initialState: ParkingProductState = {
  loading: false,
  parkingProducts: {
    loading: false,
    data: [],
    error: null,
  },
  locatedParkingProducts: {
    loading: false,
    data: [],
    error: null,
  },
  delegatedParkingProducts: {
    totalPages: 0,
    totalRecords: 0,
    pages: null,
  },
  delegees: {
    totalPages: 0,
    totalRecords: 0,
    pages: null,
  },
  delegeeDetails: {
    loading: false,
    data: null,
    error: null,
  },
  importDelegees: {
    loading: false,
  },
  validateDelegees: {
    loading: false,
  },
  error: null,
  bulkImportProgress: {
    progress: 0,
    hasErrors: false,
    numberOfErrors: 0,
  },
  isBulkImportCompleted: true,
  vehiclesMyProducts: {
    pages: {},
    totalPages: 0,
    totalRecords: 0,
  }, 
  vehicleAssignmentsMyFleet: {
    pages: {},
    totalPages: 0,
    totalRecords: 0,
  },
  selectedVehicleAssignment: {
    loading: false,
  }
};

function reduceVehicleAssignmentUpdates(
  state: ParkingProductState,
  action: ParkingProductsActionType
): ParkingProductState {
  switch (action.type) {
    case request(parkingProductsTypes.ASSIGN_VEHICLE):
      return { ...state, loading: true };

    case request(parkingProductsTypes.UPDATE_VEHICLE_ASSIGNMENT):
      return {
        ...state,
        loading: true,
      };
    case success(parkingProductsTypes.ASSIGN_VEHICLE):
    case success(parkingProductsTypes.UPDATE_VEHICLE_ASSIGNMENT): {
      const newState = reduceVehicleAssignmentMutation(state, action.assignedVehicleMutation!);
      return { ...state, ...newState, loading: false };
    }

    case failure(parkingProductsTypes.UPDATE_VEHICLE_ASSIGNMENT):
    case failure(parkingProductsTypes.ASSIGN_VEHICLE):
      return {
        ...state,
        loading: false,
        error: action.error as ErrorDetailsModel,
      };

    default:
      return state;
  }
}

function reduceVehicles(vehicles: VehiclesMyProductsState, action: ParkingProductsActionType): VehiclesMyProductsState {
  let pageNumber: number;
  switch (action.type) {
    case request(parkingProductsTypes.GET_ASSIGNED_VEHICLES):
      pageNumber = action.assignedVehiclesProductsPayload?.pageNumber!;

      return {
        ...vehicles,
        pages: {
          ...vehicles.pages,
          [pageNumber]: {
            ...vehicles.pages[pageNumber],
            loading: true,
            query: action.assignedVehiclesProductsPayload?.query!,
            data: [],
          },
        },
      };

    case success(parkingProductsTypes.GET_ASSIGNED_VEHICLES):
      pageNumber = action.assignedVehiclesProductsPayload?.pageNumber!;
      return {
        ...vehicles,
        totalPages: action.assignedVehiclesProductsPayload?.totalPages as number,
        totalRecords: action.assignedVehiclesProductsPayload?.totalRecords as number,
        pages: {
          ...vehicles.pages,
          ...{
            [pageNumber]: {
              ...vehicles.pages[pageNumber],
              loading: false,
              data: action.assignedVehiclesProductsPayload?.payload,
            },
          },
        },
      };

    case failure(parkingProductsTypes.GET_ASSIGNED_VEHICLES):
      pageNumber = action.assignedVehiclesProductsPayload?.pageNumber as number;

      return {
        ...vehicles,
        pages: {
          ...vehicles.pages,
          ...{
            [pageNumber]: {
              ...vehicles.pages[pageNumber],
              data: [],
              loading: false,
              error: action.error,
            },
          },
        },
      };

    default:
      return vehicles;
  }
}

function reduceVehicleAssignmentsMyFleet(vehicleAssignments: VehicleAssignmentsMyFleetState, action: ParkingProductsActionType): VehicleAssignmentsMyFleetState {
  let pageNumber: number;
  switch (action.type) {
    case request(parkingProductsTypes.GET_VEHICLE_ASSIGNMENTS_MY_FLEET):
      pageNumber = action.vehicleAssigmentsMyFleetPayload?.pageNumber!;

      return {
        ...vehicleAssignments,
        pages: {
          ...vehicleAssignments.pages,
          [pageNumber]: {
            ...vehicleAssignments.pages[pageNumber],
            loading: true,
            data: [],
            query: action.vehicleAssigmentsMyFleetPayload?.query!,
          },
        },
      };

    case success(parkingProductsTypes.GET_VEHICLE_ASSIGNMENTS_MY_FLEET):
      pageNumber = action.vehicleAssigmentsMyFleetPayload?.pageNumber!;
      return {
        ...vehicleAssignments,
        totalPages: action.vehicleAssigmentsMyFleetPayload?.totalPages as number,
        totalRecords: action.vehicleAssigmentsMyFleetPayload?.totalRecords as number,
        pages: {
          ...vehicleAssignments.pages,
          ...{
            [pageNumber]: {
              ...vehicleAssignments.pages[pageNumber],
              loading: false,
              data: action.vehicleAssigmentsMyFleetPayload?.payload,
            },
          },
        },
      };

    case failure(parkingProductsTypes.GET_VEHICLE_ASSIGNMENTS_MY_FLEET):
      pageNumber = action.vehicleAssigmentsMyFleetPayload?.pageNumber as number;

      return {
        ...vehicleAssignments,
        pages: {
          ...vehicleAssignments.pages,
          ...{
            [pageNumber]: {
              ...vehicleAssignments.pages[pageNumber],
              data: [],
              loading: false,
              error: action.error,
            },
          },
        },
      };

    default:
      return vehicleAssignments;
  }
}

function reduceGlobalLoadingState(state: ParkingProductState, action: ParkingProductsActionType) : ParkingProductState {
  switch (action.type) {
    case request(parkingProductsTypes.DELEGATE_PARKING_RIGHTS):
    case request(parkingProductsTypes.UPDATE_DELEGATION):
    case request(parkingProductsTypes.RESEND_DELEGEE_INVITE):
    case request(parkingProductsTypes.REVOKE_PARKINGRIGHTS):
    case request(parkingProductsTypes.REVOKE_VEHICLE_ASSIGNMENTS):
    case request(parkingProductsTypes.DELETE_VEHICLE_ASSIGNMENTS):
    case request(parkingProductsTypes.DELETE_DELEGEES):
    case request(parkingProductsTypes.GET_BULK_IMPORT_STATUS):
    case request(parkingProductsTypes.GET_ASSIGNED_VEHICLE):
        return {
        ...state,
        selectedVehicleAssignment: {
          loading: true,
        },
        loading: true,
      };
    case failure(parkingProductsTypes.DELEGATE_PARKING_RIGHTS):
    case failure(parkingProductsTypes.UPDATE_DELEGATION):
    case failure(parkingProductsTypes.REVOKE_PARKINGRIGHTS):
    case failure(parkingProductsTypes.REVOKE_VEHICLE_ASSIGNMENTS):
    case failure(parkingProductsTypes.DELETE_VEHICLE_ASSIGNMENTS):
    case failure(parkingProductsTypes.DELETE_DELEGEES):
    case failure(parkingProductsTypes.GET_ASSIGNED_VEHICLE):
        return {
        ...state,
        selectedVehicleAssignment: {
          loading: false,
          error: action.error as ErrorDetailsModel,
        },
        loading: false,
        error: action.error as ErrorDetailsModel,
      };
    case success(parkingProductsTypes.DELEGATE_PARKING_RIGHTS):
    case success(parkingProductsTypes.UPDATE_DELEGATION):
    case success(parkingProductsTypes.REVOKE_PARKINGRIGHTS):
    case success(parkingProductsTypes.DELETE_VEHICLE_ASSIGNMENTS):
    case success(parkingProductsTypes.RESEND_DELEGEE_INVITE):
    case success(parkingProductsTypes.DELETE_DELEGEES):
    case success(parkingProductsTypes.ASSIGN_VEHICLE):
      return {
        ...state,
        loading: false,
      };
    case success(parkingProductsTypes.REVOKE_VEHICLE_ASSIGNMENTS):
      return {
        ...state,
        vehicleAssignmentsMyFleet: reduceRevokeMyFleet(state.vehicleAssignmentsMyFleet, action.revokeProductsModel!),
        loading: false,
      };
    default:
      return state;
  }
}

export default (
  state: ParkingProductState = initialState,
  action: ParkingProductsActionType
): ParkingProductState => {
  let pageNumber: number;

  const newStateMaybe = reduceVehicleAssignmentUpdates(state, action);
  if (newStateMaybe != state) {
    return newStateMaybe;
  }

  const newVehicleState = reduceVehicles(state.vehiclesMyProducts, action);
  if (newVehicleState !== state.vehiclesMyProducts) {
    return {
      ...state,
      vehiclesMyProducts: newVehicleState
    };
  }

  const newVehicleAssignmentsState = reduceVehicleAssignmentsMyFleet(state.vehicleAssignmentsMyFleet, action);
  if (newVehicleAssignmentsState !== state.vehicleAssignmentsMyFleet) {
    return {
      ...state,
      vehicleAssignmentsMyFleet: newVehicleAssignmentsState
    };
  }

  const newLoadingState = reduceGlobalLoadingState(state, action);
  if (newLoadingState !== state) {
    return newLoadingState;
  }

  switch (action.type) {
    case request(parkingProductsTypes.GET_PARKING_PRODUCTS):
      return {
        ...state,
        parkingProducts: {
          data: [],
          loading: true,
        },
      };
    case request(parkingProductsTypes.GET_LOCATED_PARKING_PRODUCTS):
      return {
        ...state,
        locatedParkingProducts: {
          data: parkingProductsReducerHelper.getLocatedParkingProductsData(state),
          loading: true,
        },
      };
    case request(parkingProductsTypes.GET_DELEGATED_PARKING_RIGHTS):
      pageNumber = action.delegatedParkingProductsPayload?.pageNumber as number;

      return {
        ...state,
        delegatedParkingProducts: {
          ...state.delegatedParkingProducts,
          pages: {
            ...state.delegatedParkingProducts.pages,
            [pageNumber]: {
              loading: true,
              data: [],
            },
          },
        },
      };
    case request(parkingProductsTypes.GET_DELEGEE_DETAILS):
      return {
        ...state,
        delegeeDetails: {
          loading: true,
        },
      };
    case request(parkingProductsTypes.GET_DELEGEES):
      pageNumber = action.delegeesPayload?.pageNumber as number;

      return {
        ...state,
        delegees: {
          ...state.delegees,
          pages: {
            ...state.delegees.pages,
            [pageNumber]: {
              loading: true,
              data: [],
            },
          },
        },
      };
    case request(parkingProductsTypes.IMPORT_DELEGEES):
      return {
        ...state,
        importDelegees: {
          ...state.importDelegees,
          loading: true,
        },
      };
    case request(parkingProductsTypes.VALIDATE_DELEGEES):
      return {
        ...state,
        validateDelegees: {
          ...state.validateDelegees,
          loading: true,
        },
      };
    case success(parkingProductsTypes.GET_PARKING_PRODUCTS):
      return {
        ...state,
        parkingProducts: {
          ...state.parkingProducts,
          data: action.parkingProductsPayload,
          loading: false,
        },
      };
    case success(parkingProductsTypes.GET_LOCATED_PARKING_PRODUCTS):
      return parkingProductsReducerHelper.getLocatedParkingProductsState(state, action);
    case success(parkingProductsTypes.GET_DELEGATED_PARKING_RIGHTS):
      pageNumber = action.delegatedParkingProductsPayload?.pageNumber as number;

      return {
        ...state,
        delegatedParkingProducts: {
          ...state.delegatedParkingProducts,
          totalPages: action.delegatedParkingProductsPayload?.totalPages as number,
          totalRecords: action.delegatedParkingProductsPayload?.totalRecords as number,
          pages: {
            ...state.delegatedParkingProducts.pages,
            ...{
              [pageNumber]: {
                loading: false,
                data: action.delegatedParkingProductsPayload?.payload,
              },
            },
          },
        },
      };
    case success(parkingProductsTypes.GET_DELEGEES):
      pageNumber = action.delegeesPayload?.pageNumber as number;

      return {
        ...state,
        delegees: {
          ...state.delegees,
          totalPages: action.delegeesPayload?.totalPages as number,
          totalRecords: action.delegeesPayload?.totalRecords as number,
          pages: {
            ...state.delegees.pages,
            ...{
              [pageNumber]: {
                loading: false,
                data: action.delegeesPayload?.payload,
              },
            },
          },
        },
      };
    case success(parkingProductsTypes.GET_DELEGEE_DETAILS):
      return {
        ...state,
        delegeeDetails: {
          ...state.delegeeDetails,
          loading: false,
          data: action.delegeeDetailsPayload,
        },
      };
    case success(parkingProductsTypes.GET_ASSIGNED_VEHICLE):
      return {
        ...state,
        selectedVehicleAssignment: {
          loading: false,
          data: action.selectedVehicleAssignment
        },
        loading: false
      }
    case success(parkingProductsTypes.GET_BULK_IMPORT_PROGRESS):
      return {
        ...state,
        bulkImportProgress: {
          progress: action.bulkImportProgress?.progress ?? 0,
          hasErrors: action.bulkImportProgress?.hasErrors ?? false,
          numberOfErrors: action.bulkImportProgress?.numberOfErrors ?? 0,
        },
      };
    case success(parkingProductsTypes.GET_BULK_IMPORT_STATUS):
    case failure(parkingProductsTypes.GET_BULK_IMPORT_STATUS):
      return {
        ...state,
        isBulkImportCompleted: action.isBulkImportCompleted ?? true,
        loading: false,
      };
    case success(parkingProductsTypes.IMPORT_DELEGEES):
      return {
        ...state,
        importDelegees: {
          ...state.importDelegees,
          loading: false,
        },
      };
    case success(parkingProductsTypes.VALIDATE_DELEGEES):
      return {
        ...state,
        validateDelegees: {
          ...state.validateDelegees,
          loading: false,
          success: true,
          fileReference: action.validateDelegeesPayload!.fileReference,
        },
      };
    case failure(parkingProductsTypes.VALIDATE_DELEGEES):
      return {
        ...state,
        validateDelegees: {
          ...state.validateDelegees,
          loading: false,
          success: false,
        },
      };
    case failure(parkingProductsTypes.GET_PARKING_PRODUCTS):
      return {
        ...state,
        parkingProducts: {
          ...state.parkingProducts,
          loading: false,
          error: action.error,
        },
      };
    case failure(parkingProductsTypes.GET_LOCATED_PARKING_PRODUCTS):
      return {
        ...state,
        locatedParkingProducts: {
          ...state.locatedParkingProducts,
          loading: false,
          data: [],
          error: action.error,
        },
      };
    case failure(parkingProductsTypes.GET_DELEGATED_PARKING_RIGHTS):
      pageNumber = action.delegatedParkingProductsPayload?.pageNumber as number;

      return {
        ...state,
        delegatedParkingProducts: {
          ...state.delegatedParkingProducts,
          pages: {
            ...state.delegatedParkingProducts.pages!,
            ...{
              [pageNumber]: {
                data: [],
                loading: false,
                error: action.error,
              },
            },
          },
        },
      };
    case failure(parkingProductsTypes.GET_DELEGEES):
      pageNumber = action.delegeesPayload?.pageNumber as number;

      return {
        ...state,
        delegees: {
          ...state.delegees,
          pages: {
            ...state.delegees.pages!,
            ...{
              [pageNumber]: {
                data: [],
                loading: false,
                error: action.error,
              },
            },
          },
        },
      };
    case failure(parkingProductsTypes.GET_DELEGEE_DETAILS):
      return {
        ...state,
        delegeeDetails: {
          ...state.delegeeDetails,
          loading: false,
          error: action.error as ErrorDetailsModel,
        },
      };
    case failure(parkingProductsTypes.RESEND_DELEGEE_INVITE):
      return {
        ...state,
        loading: false,
        error: action.error as ErrorDetailsModel,
      };
    case failure(parkingProductsTypes.IMPORT_DELEGEES):
      return {
        ...state,
        importDelegees: {
          ...state.importDelegees,
          loading: false,
        },
      };
    case parkingProductsTypes.DISPOSE_ERROR:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case parkingProductsTypes.CLEAR_DELEGEE_DETAILS:
      return {
        ...state,
        delegeeDetails: {
          ...initialState.delegeeDetails,
        },
      };
    case parkingProductsTypes.RESET_BULK_UPLOAD_PROGRESS:
      return {
        ...state,
        bulkImportProgress: {
          progress: 0,
          hasErrors: false,
          numberOfErrors: 0,
        },
      };
    default:
      return state;
  }
};
