import { isNil, mapValues, sumBy } from "lodash";
import { GetVehicleAssignmentsQuery } from "../../api/parkingProducts.api";
import { AssignedVehicleSuccessfulMutation } from "../../models/vehicle-assignments/AssignedVehicleSuccessfulMutation";
import { AssignVehicleRequestModel } from "../../models/vehicle-assignments/AssignVehicleRequestModel";
import {
  AssignedParkingRight,
  VehicleAssignment,
  VehicleAssignmentProduct,
} from "../../models/delegations/DelegatedParkingProductsModel";
import { LocatedParkingProduct } from "../../models/LocatedParkingProduct";
import { UpdateVehicleRequestModel } from "../../models/vehicle-assignments/UpdateVehicleRequestModel";
import {
  ParkingProductState,
  VehicleAssignmentsMyFleetState,
  VehiclesMyProductsState as VehicleAssignmentMyProductsState,
} from "../reducers/parkingProducts.reducer";
import { isEmpty } from "underscore";
import { RevokeVehicleAssignmentsModel } from "../../models/vehicle-assignments/RevokeVehicleAssignmentsModel";
import { ParkingRightItemModel } from "../../models/vehicle-assignments/ParkingRightItemModel";

function reduceUpdate(
  state: ParkingProductState,
  aggregateId: string,
  update: UpdateVehicleRequestModel,
  pmcToParkingRightStartDates: Record<number, ParkingRightItemModel>
): ParkingProductState {
  const revokedPmcIds = state.selectedVehicleAssignment
    .data!.assignedProducts.map((x) => x.pmcId)
    .filter((assignedPmc) => !update.pmcIds.includes(assignedPmc));
  const assignedPmcIds = update.pmcIds.filter(
    (pmcId) =>
      !state.selectedVehicleAssignment.data!.assignedProducts.some(
        (assignedProduct) => assignedProduct.pmcId === pmcId
      )
  );

  const newLocatedParkingProducts = incrementTotalAssignedCount(
    state.locatedParkingProducts.data!,
    Object.fromEntries([
      ...revokedPmcIds.map((revokedPmc) => [revokedPmc, -1]),
      ...assignedPmcIds.map((assignedPmcId) => [assignedPmcId, 1]),
    ])
  );

  const newMyProductsVehicles = reduceMyProductsVehiclesState(
    state.vehiclesMyProducts,
    state.locatedParkingProducts.data!,
    aggregateId,
    update,
    pmcToParkingRightStartDates
  );

  const newMyFleetVehicles = reduceMyFleetVehiclesStateUpdate(
    state.vehicleAssignmentsMyFleet,
    state.locatedParkingProducts.data!,
    aggregateId,
    update
  );

  return {
    ...state,
    locatedParkingProducts: {
      ...state.locatedParkingProducts,
      data: newLocatedParkingProducts,
    },
    vehiclesMyProducts: newMyProductsVehicles,
    vehicleAssignmentsMyFleet: newMyFleetVehicles,
  };
}

function incrementTotalAssignedCount(
  locations: LocatedParkingProduct[],
  pmcIdToAmount: { [pmcId: number]: number }
): LocatedParkingProduct[] {
  return locations.map((location): LocatedParkingProduct => {
    const newParkingProducts = location.parkingProducts.map((parkingProduct) => {
      const amount = pmcIdToAmount[parkingProduct.pmcId!];
      if (amount) {
        return {
          ...parkingProduct,
          totalAssignedParkingRights: parkingProduct.totalAssignedParkingRights! + amount,
        };
      }
      return parkingProduct;
    });

    const totalAssignedParkingRights = sumBy(newParkingProducts, x => x.totalAssignedParkingRights!);

    return {
      ...location,
      totalAssignedParkingRights,
      parkingProducts: newParkingProducts,
    };
  });
}

function vehicleAssignmentMatchesQuery(
  vehicleAssignment: VehicleAssignment,
  query: GetVehicleAssignmentsQuery
): boolean {
  return (
    (isNil(query.description) ||
      isEmpty(query.description) ||
      (!isNil(vehicleAssignment.vehicle.description) &&
        vehicleAssignment.vehicle.description
          .toLowerCase()
          .includes(query.description.toLowerCase()))) &&
    (isNil(query.numberPlateValue) ||
      isEmpty(query.numberPlateValue) ||
      vehicleAssignment.vehicle.identifier.includes(query.numberPlateValue.toUpperCase())) &&
    (isNil(query.pmcIds) ||
      isEmpty(query.pmcIds) ||
      vehicleAssignment.products.some((p) => query.pmcIds!.includes(p.pmcId))) &&
    (isNil(query.assignedDateStart) ||
      new Date(query.assignedDateStart).getTime() <= vehicleAssignment.assignedAt.getTime()) &&
    (isNil(query.assignedDateEnd) ||
      vehicleAssignment.assignedAt.getTime() <= new Date(query.assignedDateEnd).getTime())
  );
}

function assignedParkingRightMatchesQuery(
  assignedParkingRight: AssignedParkingRight,
  query: GetVehicleAssignmentsQuery,
  getPlaceId: (pmc: number) => number
): boolean {
  return (
    query.placeId === getPlaceId(assignedParkingRight.parkingRight.pmc) &&
    (isNil(query.description) ||
      isEmpty(query.description) ||
      (!isNil(assignedParkingRight.vehicle.description) &&
        assignedParkingRight.vehicle.description
          .toLowerCase()
          .includes(query.description.toLowerCase()))) &&
    (isNil(query.numberPlateValue) ||
      isEmpty(query.numberPlateValue) ||
      assignedParkingRight.vehicle.identifier.includes(query.numberPlateValue.toUpperCase())) &&
    (isNil(query.pmcIds) ||
      isEmpty(query.pmcIds) ||
      query.pmcIds.includes(assignedParkingRight.parkingRight.pmc)) &&
    (isNil(query.assignedDateStart) ||
      new Date(query.assignedDateStart).getTime() <= assignedParkingRight.assigned.getTime()) &&
    (isNil(query.assignedDateEnd) ||
      assignedParkingRight.assigned.getTime() <= new Date(query.assignedDateEnd).getTime()) &&
    (isNil(query.StartedDateStart) || (!isNil(assignedParkingRight.startedAt) &&
      new Date(query.StartedDateStart).getTime() <= assignedParkingRight.startedAt.getTime())) &&
    (isNil(query.StartedDateEnd) || (!isNil(assignedParkingRight.startedAt) &&
      assignedParkingRight.startedAt.getTime() <= new Date(query.StartedDateEnd).getTime()))
  );
}

function reduceMyFleetVehiclesStateUpdate(
  state: VehicleAssignmentsMyFleetState,
  locatedParkingProducts: LocatedParkingProduct[],
  aggregateId: string,
  update: UpdateVehicleRequestModel
): VehicleAssignmentsMyFleetState {
  const createFakeProduct = (pmcId: number): VehicleAssignmentProduct => {
    const { name, location } = getProductInfo(locatedParkingProducts, pmcId);

    return {
      pmcId,
      locationName: location,
      pmcName: name,
      parkingRightId: `pr id for ${pmcId}`,
    };
  };

  let rowsAdded = 0;

  const newPages = mapValues(state.pages, (page) => {
    const rowIndex = page.data!.findIndex((r) => r.vehicleAssignmentId === aggregateId);

    if (rowIndex === -1) {
      const newFreshRow: VehicleAssignment = {
        vehicleAssignmentId: aggregateId,
        vehicle: {
          countryCode: update.numberPlate.countryCode,
          identifier: update.numberPlate.value,
          description: update.description,
        },
        products: update.pmcIds.map(createFakeProduct),
        assignedAt: new Date(),
      };

      if (vehicleAssignmentMatchesQuery(newFreshRow, page.query)) {
        rowsAdded++;
        return {
          ...page,
          data: [newFreshRow, ...page.data!],
        };
      }

      return page;
    }
    const row = page.data![rowIndex];
    const productsToAdd: VehicleAssignmentProduct[] = update.pmcIds
      .filter((updatePmc) => !row.products.some((rowProduct) => rowProduct.pmcId === updatePmc))
      .map(createFakeProduct);

    const updatedRow: VehicleAssignment = {
      ...row,
      vehicle: {
        ...row.vehicle,
        description: update.description,
      },
      products: row.products.filter((p) => update.pmcIds.includes(p.pmcId)).concat(productsToAdd),
    };

    if (!vehicleAssignmentMatchesQuery(updatedRow, page.query)) {
      return {
        ...page,
        data: page.data!.filter((_, index) => index !== rowIndex),
      };
    }

    return {
      ...page,
      data: page.data!.with(rowIndex, updatedRow),
    };
  });

  return {
    ...state,
    pages: newPages,
    totalRecords: state.totalRecords + rowsAdded
  };
}

function reduceMyProductsVehiclesState(
  vehicles: VehicleAssignmentMyProductsState,
  locatedParkingProducts: LocatedParkingProduct[],
  aggregateId: string,
  model: UpdateVehicleRequestModel,
  pmcToParkingRightStartDates?: Record<number, ParkingRightItemModel>
): VehicleAssignmentMyProductsState {
  const fakeRows: AssignedParkingRight[] = model.pmcIds.map((pmcId) => {
    const { name, location } = getProductInfo(locatedParkingProducts, pmcId);
    
    let startedAt = undefined as Date | undefined;
    if(pmcToParkingRightStartDates){
      startedAt = pmcToParkingRightStartDates[pmcId].parkingRightStartedDate
    }

    return createFakeAssignedParkingRight(aggregateId, model, pmcId, name, location, startedAt);
  });

  const newPagesWithAddedCount = mapValues(vehicles.pages, (page) => {
    const updatedRows = page
      .data!.filter(
        (p) => p.vehicleAssignmentId === aggregateId && model.pmcIds.includes(p.parkingRight.pmc)
      )
      .map((x) => ({
        ...x,
        vehicle: {
          ...x.vehicle,
          description: model.description,
        },
      }));

    const rowsToAdd = fakeRows.filter(
      (row) =>
        !updatedRows.some(
          (updateRow) =>
            updateRow.vehicleAssignmentId === row.vehicleAssignmentId &&
            row.parkingRight.pmc === updateRow.parkingRight.pmc
        ) &&
        assignedParkingRightMatchesQuery(
          row,
          page.query,
          (pmc) => getProductInfo(locatedParkingProducts, pmc).placeId
        )
    );

    const rowsToRemove = page.data!.filter(
      (x) => x.vehicleAssignmentId === aggregateId && !model.pmcIds.includes(x.parkingRight.pmc)
    );

    const data = [
      ...rowsToAdd,
      ...page.data!.flatMap((row) => {
        if (rowsToRemove.includes(row)) {
          return [];
        }

        const updatedRow = updatedRows.find(
          (ur) =>
            ur.vehicleAssignmentId === row.vehicleAssignmentId &&
            ur.parkingRight.pmc === row.parkingRight.pmc
        );

        if (updatedRow) {
          return [updatedRow];
        }

        return [row];
      }),
    ];

    const newPage: typeof page = { ...page, data };
    return { page: newPage, rowsAddedCount: data.length - page.data!.length };
  });

  const newPages = mapValues(newPagesWithAddedCount, (x) => x.page);

  const newTotalRecords = vehicles.totalRecords + sumBy(Object.values(newPagesWithAddedCount), x => x.rowsAddedCount);
  return { ...vehicles, totalRecords: newTotalRecords, pages: newPages };
}

function createFakeAssignedParkingRight(
  aggregateId: string,
  assign: UpdateVehicleRequestModel,
  pmcId: number,
  name: string,
  location: string,
  startDate?: Date
): AssignedParkingRight {
  return {
    vehicleAssignmentId: aggregateId,
    parkingRight: {
      id: `fake pr id for pmc=${pmcId}`,
      pmc: pmcId,
      name,
      location,
    },
    assigned: new Date(),
    vehicle: {
      countryCode: assign.numberPlate.countryCode,
      identifier: assign.numberPlate.value,
      description: assign.description,
    },
    startedAt: startDate ?? null
  };
}

function reduceAssign(
  state: ParkingProductState,
  model: AssignVehicleRequestModel,
  pmcToParkingRightStartDates: Record<number, ParkingRightItemModel>
): ParkingProductState {
  const newLocatedParkingProducts = incrementTotalAssignedCount(
    state.locatedParkingProducts.data!,
    Object.fromEntries(model.pmcIds.map((pmcId) => [pmcId, 1]))
  );

  const newMyProductsVehicles = reduceMyProductsVehiclesState(
    state.vehiclesMyProducts,
    state.locatedParkingProducts.data!,
    model.aggregateId,
    model,
    pmcToParkingRightStartDates
  );

  const newMyFleetVehicles = reduceMyFleetVehiclesStateUpdate(
    state.vehicleAssignmentsMyFleet,
    state.locatedParkingProducts.data!,
    model.aggregateId,
    model
  );

  return {
    ...state,
    locatedParkingProducts: {
      ...state.locatedParkingProducts,
      data: newLocatedParkingProducts,
    },
    vehiclesMyProducts: newMyProductsVehicles,
    vehicleAssignmentsMyFleet: newMyFleetVehicles,
  };
}

function getProductInfo(
  locations: LocatedParkingProduct[],
  pmcId: number
): { location: string; name: string; placeId: number } {
  for (const location of locations) {
    for (const product of location.parkingProducts) {
      if (product.pmcId === pmcId) {
        return {
          location: product.location,
          name: product.pmcName,
          placeId: location.placeId,
        };
      }
    }
  }

  throw new Error(`Can't find product info for pmc ${pmcId}`);
}

export function reduceVehicleAssignmentMutation(
  state: ParkingProductState,
  mutation: AssignedVehicleSuccessfulMutation
): ParkingProductState {
  switch (mutation.type) {
    case "update":
      return reduceUpdate(state, mutation.aggregateId, mutation.request, mutation.pmcToParkingRightStartDates);
    case "assign":
      return reduceAssign(state, mutation.request, mutation.pmcToParkingRightStartDates);
  }
}

export function reduceRevokeMyFleet(
  state: VehicleAssignmentsMyFleetState,
  revokeModel: RevokeVehicleAssignmentsModel
): VehicleAssignmentsMyFleetState {
  const newPages = mapValues(state.pages, (page) => {
    const newData = page.data!.map((vehicle) => {
      const productsToRemove = vehicle.products.filter((vp) =>
        revokeModel.vehicleAssignmentProducts.some(
          (vap) => vap.vehicleAssignmentId === vehicle.vehicleAssignmentId && vap.pmcId === vp.pmcId
        )
      );
      if (productsToRemove.length === 0) {
        return vehicle;
      }
      return {
        ...vehicle,
        products: vehicle.products.filter((x) => !productsToRemove.includes(x)),
      };
    });
    return { ...page, data: newData };
  });

  return {
    ...state,
    pages: newPages,
  };
}
