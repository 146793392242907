import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import languageDetector from "i18next-browser-languagedetector";
import resources, { languages } from "./translations";

export type i18nType = Readonly<typeof i18n>;

export default i18n
  .use(languageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      ...resources,
    },
    fallbackLng: languages.en,
    whitelist: [languages.en, languages.fr, languages.nl, languages.de],
    supportedLngs: [languages.en, languages.fr, languages.nl, languages.de],
    nonExplicitSupportedLngs: true,
    load: "languageOnly",
    interpolation: {
      escapeValue: false,
    },
  });
