import { ICheckParkingRight } from "../../../components/DelegeeForm/ParkingRightsGrid/ParkingRightsGrid";
import { importDelegationsTypes } from "./../actions/importDelegations.types";
export interface EnsureValidValue<T> {
  value: T;
  isValid: boolean;
  errorMessageKey?: string
}

export interface DelegeeData {
  name: EnsureValidValue<string>;
  emailAddress: EnsureValidValue<string>;
  lineNumber?: number;
}

export interface ImportDelegationsState {
  data: DelegeeData[];
  checkParkingRights: ICheckParkingRight[];
  processing: boolean;
}

export const initialState: ImportDelegationsState = {
  data: [],
  checkParkingRights: [],
  processing: false,
};

export type ImportDelegationsAction = {
  type: string;
  payload?: DelegeeData | DelegeeData[] | ICheckParkingRight[] | boolean;
};

export default (
  state: ImportDelegationsState = initialState,
  action: ImportDelegationsAction
): ImportDelegationsState => {
  switch (action.type) {
    case importDelegationsTypes.SET_DELEGEES_DATA:
      return {
        ...state,
        data: action.payload as DelegeeData[],
      };
    case importDelegationsTypes.SET_SELECTED_PARKING_RIGHTS:
      return {
        ...state,
        checkParkingRights: action.payload as ICheckParkingRight[],
      };
    case importDelegationsTypes.SET_PROCESSING:
      return {
        ...state,
        processing: action.payload as boolean,
      };
    case importDelegationsTypes.RESET:
      return initialState;
    default:
      return state;
  }
};
