import React from "react";
import { useTranslation } from "react-i18next";
import { ContentCard } from "../../../../../../../../../ui/ContentCard";
import Spacer from "../../../../../../../../../ui/Spacer/Spacer";
import { ParkingLocationForm } from "../../forms";
import { ParkingLocationProps } from "./ParkingLocation.types";

const ParkingLocation = (props: ParkingLocationProps) => {
  const { t } = useTranslation("visitors");
  const { values, errors, handleChange, setFieldValue, setFieldError, onPhysicalZoneChanged, parkingLocations, titlePrefix } = props;

  return (
    <ContentCard title={`${titlePrefix ?? ''}${t("parkingLocationForm.title")}`}>
      <Spacer size="xs" />
      <ParkingLocationForm
        parkingLocations={parkingLocations}
        onChange={handleChange}
        setFieldValue={setFieldValue}
        setFieldError={setFieldError}
        onPhysicalZoneChanged={onPhysicalZoneChanged}
        country={{
          value: values.country,
          error: errors.country,
          hasError: Boolean(errors.country),
        }}
        city={{
          value: values.city,
          error: errors.city,
          hasError: Boolean(errors.city),
        }}
        physicalZone={{
          value: values.physicalZone,
          error: errors.physicalZone,
          hasError: Boolean(errors.physicalZone),
        }}
      />
    </ContentCard>
  );
};

export default ParkingLocation;
