import axios from "../axios";
import environmentApi from "../api/environment.api";
import { AxiosResponse } from "axios";
import { FeatureFlagsAndDynamicConfiguration } from "../types/FeatureFlagsAndDynamicConfiguration";

type EnvironmentService = {
  getEnvironment: () => Promise<AxiosResponse<string>>;
  getVersion: () => Promise<AxiosResponse<string>>;
  getPortalStatus: () => Promise<AxiosResponse<boolean>>;
  getFeatureFlagsAndDynamicConfiguration: (
    loggedInCrmId: string
  ) => Promise<AxiosResponse<FeatureFlagsAndDynamicConfiguration>>;
  getAllowImpersonation: (
    loggedInCrmId: string
  ) => Promise<AxiosResponse<boolean>>;
};

const getEnvironment = (): Promise<AxiosResponse<string>> =>
  axios.get(environmentApi.getEnvironmentUrl());

const getVersion = (): Promise<AxiosResponse<string>> =>
  axios.get(environmentApi.getVersionUrl());

const getPortalStatus = (): Promise<AxiosResponse<boolean>> =>
  axios.get(environmentApi.getPortalStatusUrl());

const getFeatureFlagsAndDynamicConfiguration = (loggedInCrmId: string): Promise<AxiosResponse<FeatureFlagsAndDynamicConfiguration>> =>
  axios.get(environmentApi.getFeatureFlagsAndDynamicConfigurationUrl(loggedInCrmId));

const getAllowImpersonation = (
  loggedInCrmId: string
): Promise<AxiosResponse<boolean>> =>
  axios.get(environmentApi.getAllowImpersonationUrl(loggedInCrmId));

export default {
  getEnvironment,
  getVersion,
  getPortalStatus,
  getFeatureFlagsAndDynamicConfiguration,
  getAllowImpersonation,
} as EnvironmentService;
