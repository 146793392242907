import React from "react";
import { Grid } from "@material-ui/core";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import CustomButton from "../../ui/CustomButton/CustomButton";
import { useHistory } from "react-router";
import Spacer from "../../ui/Spacer/Spacer";
import WarningColumn from "../../ui/CenteredWarning/CenteredWarning";

const FullHeightRow = styled(Grid)`
  height: 100vh;
  padding: 0;
`;

const GeneralException = () => {
  const { t } = useTranslation("generalException");
  const history = useHistory();

  const goToIndex = () => history.push("/");

  const returnHomeButton = () => (
    <React.Fragment>
      <Spacer size="sm" />
      <CustomButton.Primary text={t("returnHome")} onClick={goToIndex} />
    </React.Fragment>
  );

  return (
    <React.Fragment>
      <FullHeightRow container item direction="row" alignItems="center" justifyContent="center">
        <WarningColumn
          title={t("somethingWentWrong")}
          details={t("details")}
          extraRows={[returnHomeButton()]}
        />
      </FullHeightRow>
    </React.Fragment>
  );
};

export default GeneralException;
