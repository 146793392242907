import { InputAdornment } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import React, { memo } from "react";
import "./qpark-date-picker.scss";
import useQParkDatePickerV2 from "./QParkDatePickerV2.controller";
import { DateRangeIcon } from "./QParkDatePickerV2.styledcomponents";
import { QParkDatePickerV2Props } from "./QParkDatePickerV2.types";

const QParkDatePickerV2 = memo((props: Partial<QParkDatePickerV2Props>) => {
  const { name, error, helperText } = props;

  const {
    inputRef,
    inputDateValue,
    onManualInput,
    validateAndSetManualInputDate,
    setManualInput,
  } = useQParkDatePickerV2(props);

  return (
    <TextField
      variant="outlined"
      type="text"
      className="date__picker__input"
      ref={inputRef}
      name={name}
      error={error}
      value={inputDateValue}
      onChange={onManualInput}
      onBlur={validateAndSetManualInputDate}
      onKeyDown={setManualInput}
      helperText={helperText}
      placeholder="dd/mm/yyyy"
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <DateRangeIcon />
          </InputAdornment>
        ),
      }}
    />
  );
});

export default QParkDatePickerV2;
