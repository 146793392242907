import { VisitorBookingsList, VisitorBookingStatus } from "../../../models/visitor-booking";
import { ParkingFacilityImageModel } from "../../../models/visitor-booking/ParkingFacilityImageModel";
import { ProductsSalesAvailability } from "../../../models/visitor-booking/ProductsSalesAvailability";
import { visitorBookingTypes } from "../../action-types";
import { request, success, failure } from "../../helpers/action.helper";
import { VisitorBookingAction } from "./../../actions/visitor-booking/visitorBookingActions.types";
import { VisitorBookingState } from "./visitorBookingReducer.types";

const initialState: VisitorBookingState = {
  productConfigurations: {
    loading: false,
    data: [],
    error: null,
  },
  parkingLocations: {
    loading: false,
    data: [],
    error: null,
  },
  createVisitorBooking: {
    loading: false,
    error: null,
  },
  createVisitorBookings: {
    loading: false,
    error: null,
  },
  visitorBookings: {
    loading: false,
    data: {} as VisitorBookingsList,
    error: null,
  },
  productSalesAvailability: {
    loading: false,
    data: {} as ProductsSalesAvailability,
    error: null,
  },
  facilityImages: {
    loading: false,
    data: [] as ParkingFacilityImageModel[],
    error: null,
  },
  loading: false,
};

export const visitorBookingReducer = (
  state: VisitorBookingState = initialState,
  action: VisitorBookingAction
): VisitorBookingState => {
  switch (action.type) {
    case request(visitorBookingTypes.GET_PRODUCT_CONFIGURATIONS):
      return {
        ...state,
        productConfigurations: {
          ...state.productConfigurations,
          loading: true,
          data: [],
        },
      };

    case request(visitorBookingTypes.GET_PARKING_LOCATIONS):
      return {
        ...state,
        parkingLocations: {
          ...state.parkingLocations,
          loading: true,
          data: [],
        },
      };

    case request(visitorBookingTypes.CREATE_VISITOR_BOOKING):
      return {
        ...state,
        createVisitorBooking: {
          ...state.createVisitorBooking,
          loading: true,
        },
      };

    case request(visitorBookingTypes.CREATE_VISITOR_BOOKINGS):
      return {
        ...state,
        createVisitorBookings: {
          ...state.createVisitorBookings,
          loading: true,
        },
      };

    case request(visitorBookingTypes.GET_VISITOR_BOOKINGS):
      return {
        ...state,
        visitorBookings: {
          ...state.visitorBookings,
          loading: true,
          data: {} as VisitorBookingsList,
        },
      };

    case request(visitorBookingTypes.GET_PRODUCT_SALES_AVAILABILITY):
      return {
        ...state,
        productSalesAvailability: {
          ...state.productSalesAvailability,
          loading: true,
          data: {} as ProductsSalesAvailability,
        },
      };

    case request(visitorBookingTypes.GET_PARKING_FACILITY_IMAGES):
      return {
        ...state,
        facilityImages: {
          ...state.facilityImages,
          loading: true,
          data: [] as ParkingFacilityImageModel[],
        },
      };

    case success(visitorBookingTypes.GET_PRODUCT_CONFIGURATIONS):
      return {
        ...state,
        productConfigurations: {
          ...state.productConfigurations,
          loading: false,
          data: action.productConfigurationsPayload,
        },
      };

    case success(visitorBookingTypes.GET_PARKING_LOCATIONS):
      return {
        ...state,
        parkingLocations: {
          ...state.parkingLocations,
          loading: false,
          data: action.parkingLocationsPayload,
        },
      };

    case success(visitorBookingTypes.CREATE_VISITOR_BOOKING):
      return {
        ...state,
        createVisitorBooking: {
          ...state.createVisitorBooking,
          loading: false,
        },
      };

    case success(visitorBookingTypes.CREATE_VISITOR_BOOKINGS):
      return {
        ...state,
        createVisitorBookings: {
          ...state.createVisitorBookings,
          loading: false,
        },
      };

    case success(visitorBookingTypes.GET_VISITOR_BOOKINGS):
      return {
        ...state,
        visitorBookings: {
          ...state.visitorBookings,
          loading: false,
          data: action.visitorBookingsPayload,
        },
      };

    case success(visitorBookingTypes.GET_PRODUCT_SALES_AVAILABILITY):
      return {
        ...state,
        productSalesAvailability: {
          ...state.productSalesAvailability,
          loading: false,
          data: action.productSalesAvailabilityPayload,
        },
      };

    case success(visitorBookingTypes.GET_PARKING_FACILITY_IMAGES):
      return {
        ...state,
        facilityImages: {
          ...state.facilityImages,
          loading: false,
          data: action.facilityImagesPayload,
        },
      };

    case failure(visitorBookingTypes.GET_PRODUCT_CONFIGURATIONS):
      return {
        ...state,
        productConfigurations: {
          ...state.productConfigurations,
          loading: false,
        },
      };

    case failure(visitorBookingTypes.GET_PARKING_LOCATIONS):
      return {
        ...state,
        parkingLocations: {
          ...state.parkingLocations,
          loading: false,
        },
      };

    case failure(visitorBookingTypes.CREATE_VISITOR_BOOKING):
      return {
        ...state,
        createVisitorBooking: {
          ...state.createVisitorBooking,
          loading: false,
        },
      };
    case failure(visitorBookingTypes.CREATE_VISITOR_BOOKINGS):
      return {
        ...state,
        createVisitorBookings: {
          ...state.createVisitorBookings,
          loading: false,
        },
      };

    case failure(visitorBookingTypes.GET_VISITOR_BOOKINGS):
    case failure(visitorBookingTypes.CANCEL_VISITOR_BOOKING):
      return {
        ...state,
        visitorBookings: {
          ...state.visitorBookings,
          loading: false,
        },
      };

    case failure(visitorBookingTypes.GET_PRODUCT_SALES_AVAILABILITY):
      return {
        ...state,
        productSalesAvailability: {
          ...state.productSalesAvailability,
          loading: false,
        },
      };

    case request(visitorBookingTypes.CANCEL_VISITOR_BOOKING):
      return {
        ...state,
        visitorBookings: {
          ...state.visitorBookings,
          loading: true,
        },
      };

    case failure(visitorBookingTypes.GET_PARKING_FACILITY_IMAGES):
      return {
        ...state,
        facilityImages: {
          ...state.facilityImages,
          loading: false,
        },
      };

    case success(visitorBookingTypes.CANCEL_VISITOR_BOOKING):
      const cancelledBooking = state.visitorBookings.data?.visitorBookings.find(
        (x) => x.referenceNumber === action.cancelledVisitorBookingReference
      );

      if (cancelledBooking) {
        cancelledBooking.status = VisitorBookingStatus.Cancelled;
      }

      return {
        ...state,
        visitorBookings: {
          ...state.visitorBookings,
          loading: false,
        },
      };

    case success(visitorBookingTypes.HANDLE_VISITOR_BOOKING_VALIDATION):
      const loading = action.loading ?? false;

      return {
        ...state,
        loading: loading,
      };

    default:
      return state;
  }
};
