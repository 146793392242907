import { UserManager } from "oidc-client";
import { createUserManager } from "redux-oidc";
import { OidcConfigurationResponseModel } from "../models/OidcConfigurationResponseModel";

let userManager: UserManager;

const getUserManager = function (oidcConfig: OidcConfigurationResponseModel) {
    if (userManager) {
        return userManager;
    }

    const currentHostName = `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ""}`;

    const userManagerConfig = {
        authority: oidcConfig.serverUrl,
        client_id: oidcConfig.clientId,
        client_secret: oidcConfig.clientSecret,
        redirect_uri: `${currentHostName}/signin-oidc`,
        response_type: "code",
        scope: "openid profile IdentityServerApi",
        monitorSession: false,
        loadUserInfo: false,
        post_logout_redirect_uri: currentHostName
    };

    userManager = createUserManager(userManagerConfig);

    return userManager;
};

export default getUserManager;
