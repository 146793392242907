import React, { createContext, useContext, useReducer, useRef } from "react";
import { findWhere, indexOf, isUndefined } from "underscore";
import { FilterType } from "../DataTableFilters/DataTableFilters";

const updateState = (state: DataTableContextState, newState: DataTableContextState) => ({
  ...state,
  ...newState,
});

const updateFilters = (state: DataTableContextState, payload: DataTableContextFilters) => {
  const found = findWhere(state.filters!, { key: payload.key });

  if (found && !isUndefined(state.filters)) {
    const index = indexOf(state.filters, found);

    found.value = payload.value;
    state.filters[index] = found;

    return updateState(state, { filters: [...state.filters] });
  }

  return updateState(state, {
    filters: [...state.filters!, payload],
  });
};

const actions = {
  SET_PAGE_NUMBER: "data_table/SET_PAGE_NUMBER",
  SET_PAGE_SIZE: "data_table/SET_PAGE_SIZE",
  TOGGLE_FILTERS: "data_table/TOGGLE_FILTERS",
  SET_FILTERS: "data_table/SET_FILTERS",
  RESET_FILTER: "data_table/RESET_FILTER",
  RESET_FILTERS: "data_table/RESET_FILTERS",
  RESET_PAGE_NUMBER: "data_table/RESET_PAGE_NUMBER",
  RESET_PAGE_SIZE: "data_table/RESET_PAGE_SIZE",
  RESET_CONTEXT: "data_table/RESET_CONTEXT",
};

declare type FiltersStatus = boolean;

export type DateFilter = {
  startDate: Date | null;
  endDate: Date | null;
  customKeys?: { startKey: string, endKey: string }
};

export type DataTableContextState = {
  pageNumber?: number;
  pageSize?: number;
  showFilters?: FiltersStatus;
  filters?: DataTableContextFilters[];
};

export type DataTableContextFilters = {
  key: string;
  type: FilterType;
  value?: string | string[] | number[] | DateFilter;
};

type DataTableActionPayload = {
  value?: number | DataTableContextFilters | DataTableContextFilters[] | boolean | DateFilter;
  type?: FilterType;
};

type DataTableActionType = {
  type: string;
  payload?: DataTableActionPayload;
};

const initialFilters: DataTableContextFilters[] = [];

const initialState: DataTableContextState = {
  pageNumber: 1,
  pageSize: 25,
  showFilters: false,
  filters: initialFilters,
};

const reducer = (
  state: DataTableContextState = initialState,
  action: DataTableActionType
): DataTableContextState => {
  switch (action.type) {
    case actions.SET_PAGE_NUMBER:
      return updateState(state, { pageNumber: action.payload?.value as number });
    case actions.SET_PAGE_SIZE:
      return updateState(state, { pageNumber: 1, pageSize: action.payload?.value as number });
    case actions.RESET_PAGE_NUMBER:
      return updateState(state, { pageNumber: 1 });
    case actions.RESET_PAGE_SIZE:
      return updateState(state, { pageSize: 25 });
    case actions.TOGGLE_FILTERS:
      return updateState(state, { showFilters: !state.showFilters });
    case actions.SET_FILTERS:
      return updateFilters(state, action.payload?.value as DataTableContextFilters);
    case actions.RESET_FILTER:
      return updateFilters(state, action.payload?.value as DataTableContextFilters);
    case actions.RESET_CONTEXT:
      return initialState;
    default:
      return state;
  }
};

type DataTableContextType = {
  state: DataTableContextState;
  setPageNumber: (pageNumber: number) => void;
  setPageSize: (pageSize: number) => void;
  setFilter: (
    key: string,
    type: FilterType,
    value: string | string[] | number[] | DateFilter
  ) => void;
  toggleFilters: () => void;
  resetPageNumber: () => void;
  resetPageSize: () => void;
  resetContext: () => void;
  resetFilters: () => void;
  resetFiltersAsync: () => Promise<boolean>;
  resetFilter: (key: string) => void;
};

export const DataTableContext = createContext<DataTableContextType>({} as DataTableContextType);

interface IDataTableContextProps {}

const DataTableContextProvider: React.FC<IDataTableContextProps> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const filtersRef = useRef<DataTableContextFilters[]>([]);

  const dispatchAction = (type: string, payload?: DataTableActionPayload): void =>
    dispatch({
      type,
      payload,
    });

  const storeAndDispatch = (
    key: string,
    value: string | string[] | number[] | DateFilter,
    type: FilterType
  ): void => {
    const found = findWhere(filtersRef.current, { key });
    if (isUndefined(found)) {
      filtersRef.current.push({ key, type, value });
    }

    const payload: DataTableActionPayload = {
      type,
      value: { key, type, value } as DataTableContextFilters,
    };

    dispatchAction(actions.SET_FILTERS, payload);
  };

  const setPageNumber = (pageNumber: number): void =>
    dispatchAction(actions.SET_PAGE_NUMBER, { value: pageNumber } as DataTableActionPayload);

  const setPageSize = (pageSize: number): void =>
    dispatchAction(actions.SET_PAGE_SIZE, { value: pageSize } as DataTableActionPayload);

  const toggleFilters = (): void => dispatchAction(actions.TOGGLE_FILTERS);

  const resetPageNumber = (): void => dispatchAction(actions.RESET_PAGE_NUMBER);

  const resetPageSize = (): void => dispatchAction(actions.RESET_PAGE_SIZE);

  const resetContext = (): void => {
    dispatchAction(actions.RESET_CONTEXT);
  };

  const setFilter = (
    key: string,
    type: FilterType,
    value: string | string[] | number[] | DateFilter
  ): void => storeAndDispatch(key, value, type);

  const resetFiltersAsync = (): Promise<boolean> => {
    return new Promise((resolve) =>
      filtersRef.current.forEach((filter, index) => {
        const payload: DataTableActionPayload = {
          type: filter.type,
          value: {
            key: filter.key,
            type: filter.type,
            value: filter.value,
          } as DataTableContextFilters,
        };

        dispatchAction(actions.SET_FILTERS, payload);

        if (index === filtersRef.current.length - 1) {
          resolve(true);
        }
      })
    );
  };

  const resetFilters = () =>
    filtersRef.current.forEach((filter) => {
      const payload: DataTableActionPayload = {
        type: filter.type,
        value: {
          key: filter.key,
          type: filter.type,
          value: filter.value,
        } as DataTableContextFilters,
      };

      dispatchAction(actions.SET_FILTERS, payload);
    });

  const resetFilter = (key: string) => {
    let defaultValue: any;

    const stored = findWhere(filtersRef.current, { key });
    if (stored) {
      defaultValue = stored.value;
    }

    setFilter(key, stored?.type as FilterType, defaultValue!);
  };

  var store: DataTableContextType = {
    state,
    setPageNumber,
    setPageSize,
    toggleFilters,
    resetPageNumber,
    resetPageSize,
    resetContext,
    setFilter,
    resetFilters,
    resetFiltersAsync,
    resetFilter,
  };

  return <DataTableContext.Provider value={store}>{children}</DataTableContext.Provider>;
};

export default DataTableContextProvider;

export const useDataTableContext = () => {
  const dataTableContext = useContext(DataTableContext);

  return dataTableContext;
};
