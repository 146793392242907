import { FormControl, MenuItem, Select } from "@material-ui/core";
import React, { memo, useMemo } from "react";
import { isUndefined } from "underscore";
import { MuiHelperText } from "../MuiHelperText";
import { ClockIcon } from "./QParkTimePicker.styledcomponents";
import { QParkTimePickerProps } from "./QParkTimePicker.types";
import { hoursGenerator } from "./utils";

const QParkTimePicker = memo((props: QParkTimePickerProps) => {
  const { minTime, value, onChange, disabled, error, helperText } = props;
  const hours = useMemo(
    () => hoursGenerator.createHalfHourIntervals(minTime),
    [minTime]
  );

  const handleHourChange = (
    event: React.ChangeEvent<{
      name?: string;
      value: unknown;
    }>
  ) => {
    if (isUndefined(onChange)) {
      return;
    }

    onChange(event.target.value as string);
  };

  return (
    <FormControl variant="outlined" disabled={disabled} error={error}>
      <Select
        value={value ?? ""}
        onChange={handleHourChange}
        displayEmpty
        IconComponent={(selectProps) => <ClockIcon {...selectProps} />}
      >
        <MenuItem value="" disabled>
          <span className="text-gray">hh:mm</span>
        </MenuItem>
        {hours.map((hour, index) => (
          <MenuItem key={`${hour}_${index}`} value={hour}>
            {hour}
          </MenuItem>
        ))}
      </Select>
      <MuiHelperText error={helperText} />
    </FormControl>
  );
});

export default QParkTimePicker;
