import React, { useEffect, Dispatch, useState, useLayoutEffect } from "react";
import Layout from "./ui/Layout/Layout";
import { Switch, Route, Redirect } from "react-router";
import routes from "./router/routes";
import { BrowserRouter } from "react-router-dom";
import CustomToast from "./ui/CustomToast/CustomToast";
import { Helmet } from "react-helmet";
import FmpAppRoutes from "./router/FmpAppRoutes";
import ErrorBoundary from "./ErrorBoundary";
import { connect } from "react-redux";
import environmentActions from "./store/actions/environment.actions";
import oidcActions from "./store/actions/oidc.actions";
import aiActions from "./store/actions/ai.actions";
import CustomOidcProvider from "./CustomOidcProvider";
import TelemetryProvider from "./TelemetryProvider";
import { RootReducer } from "./store/reducers";
import PortalDeactivated from "./components/PortalDeactivated/PortalDeactivated";
import MobileWarningScreen from "./components/MobileWarningScreen/MobileWarningScreen";

interface IProps extends StateProps, DispatchProps {}

const App = ({
  getEnvironment,
  getVersion,
  getOidcConfig,
  getAiInstrumentationKey,
  getPortalStatus,
  portalDeactivated,
  instrumentationKey,
}: IProps) => {
  const [oidcInfoRetrieved, setOidcInfoRetrieved] = useState(false);
  const [aiInstrumentationKeyRetrieved, setAiInstrumentationKeyRetrieved] =
    useState(false);

  const updateOidcInfoRetrieved = () => setOidcInfoRetrieved(true);
  const updateAiInstrumentationKeyRetrieved = () =>
    setAiInstrumentationKeyRetrieved(true);

  useEffect(() => {
    getOidcConfig(updateOidcInfoRetrieved);
    getAiInstrumentationKey(updateAiInstrumentationKeyRetrieved);
    getPortalStatus();
    // eslint-disable-next-line
  }, []);

  useLayoutEffect(() => {
    getEnvironment();
    getVersion();
  }, [getEnvironment, getVersion]);

  if (!oidcInfoRetrieved || !aiInstrumentationKeyRetrieved) {
    return null;
  }

  if (portalDeactivated) {
    return <PortalDeactivated />;
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>{`Q-Park | Business Portal`}</title>
      </Helmet>
      <CustomToast />
      <BrowserRouter>
        <TelemetryProvider instrumentationKey={instrumentationKey}>
          <CustomOidcProvider>
            <MobileWarningScreen>
              <Switch>
                <Route exact path="/">
                  <Redirect
                    to={routes.fleetManager.subRoutes![0].path as string}
                  />
                </Route>
                <Route exact path={routes.fleetManager.path}>
                  <Redirect
                    to={routes.fleetManager.subRoutes![0].path as string}
                  />
                </Route>
                <Route exact path={routes.visitors.path}>
                  <Redirect to={routes.visitors.subRoutes![0].path as string} />
                </Route>

                <ErrorBoundary>
                  <Layout>
                    <FmpAppRoutes />
                  </Layout>
                </ErrorBoundary>
              </Switch>
            </MobileWarningScreen>
          </CustomOidcProvider>
        </TelemetryProvider>
      </BrowserRouter>
    </React.Fragment>
  );
};

const mapStateToProps = (state: RootReducer) => {
  const { environment, ai } = state;

  return {
    portalDeactivated: environment.portalDeactivated,
    instrumentationKey: ai.instrumentationKey,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  getEnvironment: () => dispatch(environmentActions.getEnvironment()),
  getVersion: () => dispatch(environmentActions.getVersion()),
  getOidcConfig: (callback: Function) =>
    dispatch(oidcActions.getConfiguration(callback)),
  getAiInstrumentationKey: (callback: Function) =>
    dispatch(aiActions.getInstrumentationKey(callback)),
  getPortalStatus: (callback?: () => void) =>
    dispatch(environmentActions.getPortalStatus(callback))
});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(App);
