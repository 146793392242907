import React, { Dispatch } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import Warning from "./Warning";
import parkingProductsActions from "../../store/actions/parkingProducts.actions";
import useFleetManagerContext from "../../context/hooks/useFleetManagerContext";
import { RootReducer } from "../../store/reducers";
import { VehicleAssignmentProduct } from "../../models/vehicle-assignments/VehicleAssignmentProduct";
import { DeleteVehicleAssignmentsModel } from "../../models/vehicle-assignments/DeleteVehicleAssignmentsModel";
import { parseAssignedParkingRowKey, parseVehicleAssignmentId } from "../../helpers/vehicleAssignment.utils";

interface IFormProps extends StateProps, DispatchProps {
    selectedVehicleAssignmentKeys: string[];
    onRefresh: (vehiclesToExclude?: VehicleAssignmentProduct[], excludeByVehicleAssignmentId? : boolean) => void;
}

const DeleteVehicleAssignmentWarning = ({
    parkingProducts,
    deleteVehicleAssignments,
    selectedVehicleAssignmentKeys,
    onRefresh
}: IFormProps) => {
    const { t } = useTranslation(["deleteVehicleAssignmentWarning", "globals"]);
    const { toggleDeleteVehicleAssignmentsWarning, toggleEditVehicleAssignment, setSelectedParkingRightIds, fleetManagerState } = useFleetManagerContext();

    const distinctSelectedVehicleVehicleAssignmentKeys = selectedVehicleAssignmentKeys.filter(
        (key, index, list) => list.findIndex(item => parseVehicleAssignmentId(item) === parseVehicleAssignmentId(key)) === index
    );

    const vehicleAssignmentCount = distinctSelectedVehicleVehicleAssignmentKeys.length == 0 ? 1 : distinctSelectedVehicleVehicleAssignmentKeys.length;
    const warningText = t("warning", { count: vehicleAssignmentCount });
    const warningDetails = t("details", { count: vehicleAssignmentCount });

    const getSelectedVehicleAssignemntId = (): string => {
        const { vehicleAssignmentId } = parseAssignedParkingRowKey(fleetManagerState.selectedVehicleAssignment!.rowKey);
        return vehicleAssignmentId;
    }

    const handleDeleteCancellation = () => {
        toggleDeleteVehicleAssignmentsWarning();
    }

    const handleSuccessfulDelete = (model: VehicleAssignmentProduct[]) => {
        setSelectedParkingRightIds([]);
        toggleDeleteVehicleAssignmentsWarning();
        if (fleetManagerState.isEditVehicleAssignmentMode && fleetManagerState.showAssignVehicle) {
            toggleEditVehicleAssignment();
        }

        onRefresh(model, true);
    }

    const handleDeleteConfirmation = () => {
        const model: DeleteVehicleAssignmentsModel =
        {
            vehicleAssignmentIds: distinctSelectedVehicleVehicleAssignmentKeys.length > 0 ?
                distinctSelectedVehicleVehicleAssignmentKeys.map(vehicleAssignmentKey => parseVehicleAssignmentId(vehicleAssignmentKey)) :
                [getSelectedVehicleAssignemntId()]
        }; 

        const vehicleAssignments: VehicleAssignmentProduct[] = distinctSelectedVehicleVehicleAssignmentKeys.length > 0 ? 
            distinctSelectedVehicleVehicleAssignmentKeys.map(vehicleAssignmentKey => parseAssignedParkingRowKey(vehicleAssignmentKey)) :            
            [parseAssignedParkingRowKey(fleetManagerState.selectedVehicleAssignment!.rowKey)];

        deleteVehicleAssignments(model, vehicleAssignments, () => handleSuccessfulDelete(vehicleAssignments));
    }

    return (
        <Warning
            warning={warningText}
            details={warningDetails}
            onCancel={handleDeleteCancellation}
            onConfirm={handleDeleteConfirmation}
            disabled={false}
            confirmText={t("globals:delete")}
            loading={parkingProducts.loading} />
    );
};

const mapStateToProps = (state: RootReducer) => {
    const { parkingProducts } = state;

    return {
        parkingProducts,
    };
};

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
    deleteVehicleAssignments: (model: DeleteVehicleAssignmentsModel, vehicleAssignments: VehicleAssignmentProduct[], callback: (vehiclesToExclude?: VehicleAssignmentProduct[]) => void) =>
        dispatch(parkingProductsActions.deleteVehicleAssignments(model, () => callback(vehicleAssignments)))
});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(DeleteVehicleAssignmentWarning);
