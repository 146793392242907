import { Popper } from "@material-ui/core";
import { CancelRounded, DateRangeOutlined } from "@material-ui/icons";
import styled from "styled-components";
import { variables } from "../../../../../theme/variables";
import { IDatePickerProps } from "./DatePicker";

const { colors } = variables;

export const StyledPopper = styled(Popper)`
  margin-top: 5px;
`;

export const DatePickerContainer = styled.div`
  position: relative;
  width: 100%;
  z-index: 2050;
`;

export const DatePickerWrapper = styled.div.attrs((props: IDatePickerProps) => ({
  size: props.size || "medium",
}))`
  ${({ size }) => (size === "small" ? "top: 5px;" : "top: 55px;")}
  right: 3px;
  z-index: 2050;
`;

export const DatePickerFieldSet = styled.div`
  border: 2px solid transparent;
  border-radius: 5px;
  outline: none;
  padding: 0;
  margin: 0;
  position: relative;

  &:hover {
    cursor: default;
  }

  &:focus {
    border-color: ${colors.lime.hex};

    & > div {
      border-color: transparent;
    }
  }
`;

export const DatePickerInput = styled.div.attrs((props: IDatePickerProps) => ({
  size: props.size || "medium",
}))`
  outline: none;
  width: 100%;
  ${({ size }) =>
    size === "small" ? "padding: 7px 36px 7px 14px;" : "padding: 9px 36px 9px 14px;"}
  border-radius: 4px;
  border: 1px solid ${colors.lightGrayishBlue.hex};
  background-color: ${colors.white};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &:hover {
    border-color: ${colors.grayishBlue.hex};
  }
`;

export const ClearIcon = styled(CancelRounded).attrs((props: IDatePickerProps) => ({
  size: props.size || "medium",
}))`
  width: 14px;
  height: 14px;
  position: absolute;
  right: 10px;
  color: ${colors.darkGraySecondary};
  ${({ size }) => (size === "small" ? "top: 12px;" : "top: 13px;")}

  &:hover {
    cursor: pointer;
  }
`;

export const DateRangeIcon = styled(DateRangeOutlined).attrs((props: IDatePickerProps) => ({
  size: props.size || "medium",
}))`
  width: 18px;
  height: 18px;
  position: absolute;
  right: 12px;
  color: ${colors.lightGray};
  ${({ size }) => (size === "small" ? "top: 10px;" : "top: 13px;")}

  &:hover {
    cursor: pointer;
  }
`;

export const Placeholder = styled.span`
  color: ${colors.gray.hex};
  font-size: 15px;
`;

export const DateRange = styled.span`
  color: ${colors.black};
  font-size: 15px;
`;
