import React from "react";
import IChildren from "../../interfaces/IChildren";
import styled from "styled-components";
import { isEmpty } from "underscore";
import { Container } from "@material-ui/core";
import { variables } from "../../theme/variables";
import ContainerArrowUp from "../ContainerArrowUp/ContainerArrowUp";

const { colors } = variables;

interface ISectionProps extends IChildren {
  backgroundColor?: string;
  border?: boolean;
  boxShadow?: boolean;
  withArrow?: boolean;
  left?: number;
  right?: number;
}

const StyledContainer = styled(Container)`
  max-width: 100%;
  padding: 1.5rem;
`;

const StyledSection = styled.section`
  width: 100%;

  ${(props: ISectionProps) =>
    props.border &&
    `
border-top: 1px solid ${colors.veryLightGray};
border-bottom: 1px solid ${colors.veryLightGray};
`}

  ${(props: ISectionProps) =>
    props.boxShadow &&
    `
box-shadow: 0px 1px 3px ${colors.veryLightGraySecondary};
`}

background-color: ${(props: ISectionProps) =>
    !isEmpty(props.backgroundColor) ? props.backgroundColor : "unset"};
`;

const Section = (props: ISectionProps) => {
  const { children, withArrow, left, right } = props;

  if (!withArrow) {
    return (
      <StyledSection {...props}>
        <StyledContainer>
          <>{children}</>
        </StyledContainer>
      </StyledSection>
    );
  }

  return (
    <ContainerArrowUp left={left} right={right}>
      <StyledSection {...props}>
        <StyledContainer>
          <>{children}</>
        </StyledContainer>
      </StyledSection>
    </ContainerArrowUp>
  );
};

export default Section;
