import { TextField } from "@material-ui/core";
import { DateRangeOutlined } from "@material-ui/icons";
import styled from "styled-components";
import { variables } from "../../theme/variables";

const { colors } = variables;

export const DateRangeIcon = styled(DateRangeOutlined)`
  width: 18px;
  height: 18px;
  position: absolute;
  right: 12px;
  color: ${colors.lightGray};
  top: 10px;

  &:hover {
    cursor: pointer;
  }
`;

export const DatePickerTextField = styled(TextField)`
  &:hover {
    cursor: pointer;
  }

  & input:hover {
    cursor: pointer;
  }
`;
