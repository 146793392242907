import styled from "styled-components";
import CancelIcon from "@material-ui/icons/Cancel";
import { KeyboardArrowDownRounded, KeyboardArrowUpRounded, SearchOutlined } from "@material-ui/icons";
import { variables } from "../../../theme/variables";
import { FormControl } from "@material-ui/core";
import CustomInput from "../../../ui/CustomInput/CustomInput";
import { ReactComponent as DeleteIcon } from "../../../assets/images/delete.svg";

const { colors, typography } = variables;

export const FilterWrapper = styled.div`
  position: relative;
`;

export const ClearInputIcon = styled(CancelIcon)`
  height: 14px;
  cursor: pointer;
  color: ${colors.darkGraySecondary};
`;

export const SeachIcon = styled(SearchOutlined)`
  height: 18px;
  color: ${colors.lightGray};
`;


export const PaddingFormControl = styled(FormControl)`
  padding: 0 5px;
  position: relative;
  width: 100%;
`;

export const FilterNumberBubble = styled.div`
  border: 1px solid ${colors.grayishBlue.hex};
  background-color: ${colors.mostlyWhite};
  font-size: ${typography.fontSizeExtraSmall};
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0px 7px;
  border-radius: 500px;
  max-width: 70%;
  margin: 0 5px;
`;

export const AbsoluteClearInputIcon = styled(ClearInputIcon)`
  position: absolute;
  top: 13px;
  right: 12px;
`;

export const LightGraySpan = styled.span`
  color: ${colors.gray.hex};
`;

export const GrayDownArrow = styled(KeyboardArrowDownRounded)`
  color: ${colors.darkGraySecondary};
  width: 20px;
  height: 20px;
  margin-right: 12px;
`;

export const GrayUpArrow = styled(KeyboardArrowUpRounded)`
  color: ${colors.darkGraySecondary};
  width: 20px;
  height: 20px;
  margin-right: 12px;
`;

export const GrayFilterArrow = styled(KeyboardArrowUpRounded)`
  background-color: white;
  color: ${colors.darkGraySecondary};
  border: 1px solid ${colors.lightGray};
  border-radius: 20px;

  width: 38px;
  height: 38px;
  margin-right: 12px;
  position: absolute;

  transform: rotate(-90deg);
  flex-basis: 5%;
  z-index: 1;
  top: 2px;

  cursor: pointer;
  display: none;
`;

export const CenterdSpan = styled.span`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const PaddingCustomInput = styled(CustomInput)`
  padding: 0 5px;
`;

export const DeleteWrapper = styled.div`
  color: ${colors.red.hex};
  position: absolute;
  left: 24px;
  padding: 0;
  cursor: pointer;
  font-size: 1rem;
  box-sizing: border-box;
  font-family: FuturaT;
  font-weight: 400;
  max-width: 25%;
`;

export const DeleteText = styled.div`
  padding-left: 25px;
`;


export const DeleteDelegeeIcon = styled(DeleteIcon)`
  position: absolute;
  top: 25%;
  margin-right: 0.6em;
  margin-left: 0.2em;
`;
