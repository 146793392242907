import React from "react";
import { TableBody, TableRow, TableCell } from "@material-ui/core";
import CustomCheckbox from "../../../ui/CustomCheckbox/CustomCheckbox";
import LoadingDataBody from "../LoadingDataBody/LoadingDataBody";
import { isEmpty } from "underscore";
import NoDataTableBody from "../NoDataTableBody/NoDataTableBody";
import { ActionsIcon, ActionsIconButton } from "../DataTableStyledComponents";

export interface IDataTableRow {
  [rowKey: string]: string | number | [] | any;
  disabled?: boolean;
}

interface IDataTableBody<TRow extends IDataTableRow> {
  rows: TRow[];
  selected: string[];
  colSpan: number;
  loading?: boolean;
  hideActions?: boolean;
  actionBtn?: (focusedRow: TRow) => JSX.Element;
  handleRowClick: (event: React.MouseEvent<unknown>, name: string) => void;
  handleMenuClick: (
    event: React.MouseEvent<HTMLButtonElement>,
    row: TRow
  ) => void;
  translationOverrideKey: string;
  hideCheckboxes?: boolean;
  hiddenKeys?: string[];
}

const DataTableBody = <TRow extends IDataTableRow,>(props: IDataTableBody<TRow>) => {
  const {
    rows,
    selected,
    hideActions,
    actionBtn,
    handleRowClick,
    handleMenuClick,
    translationOverrideKey,
    hideCheckboxes,
    hiddenKeys
  } = props;

  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  if (props.loading) {
    return <LoadingDataBody colSpan={props.colSpan} />;
  }

  if (isEmpty(rows) && !props.loading) {
    return (
      <NoDataTableBody
        colSpan={props.colSpan}
        translationKey={translationOverrideKey}
      />
    );
  }

  const stopPropagation = (
    event: React.MouseEvent<HTMLTableCellElement, MouseEvent>
  ) => event.stopPropagation();

  const getActionClickHandler = (row: TRow) => {
    return (event: React.MouseEvent<HTMLButtonElement>) => {
      handleMenuClick(event, row);
    };
  };

  return (
    <TableBody>
      {rows.map((row, index: number) => {
        const keys = Object.keys(row);
        const isItemSelected = isSelected(row.rowKey as string);

        return (
          <TableRow
            hover
            onClick={(event: React.MouseEvent<unknown>) =>
              handleRowClick(event, row.rowKey as string)
            }
            role="checkbox"
            aria-checked={isItemSelected}
            tabIndex={-1}
            key={`table_row_${index}`}
            selected={isItemSelected}
          >
            <TableCell
              padding="checkbox"
              style={{ display: (hideCheckboxes ? "none" : "table-cell") }}
            >
              <CustomCheckbox
                checkboxProps={{
                  checked: isItemSelected,
                }}
              />
            </TableCell>

            {keys.map((key: string, index: number) => {
                if (key === "rowKey" || key == "disabled" || (hiddenKeys?.length && hiddenKeys.indexOf(key) > -1)) {
                    return null;
              }

              return (
                <TableCell
                  key={`table_cell_${index}`}
                  onClick={stopPropagation}
                  className={row.disabled ? "light-gray-important" : ""}
                >
                  {row[key]}
                </TableCell>
              );
            })}

            {!hideActions && (
              <TableCell
                onClick={stopPropagation}
                key={`actions_cell_${index}`}
                width={15}
                align="right"
              >
                {!actionBtn && (
                  <ActionsIconButton
                    disableRipple
                    onClick={getActionClickHandler(row)}
                  >
                    <ActionsIcon />
                  </ActionsIconButton>
                )}
                {actionBtn && actionBtn(row)}
              </TableCell>
            )}
          </TableRow>
        );
      })}
    </TableBody>
  );
};

export default DataTableBody;
