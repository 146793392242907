import React from "react";
import { Drawer } from "@material-ui/core";
import IChildren from "../../interfaces/IChildren";

interface IActionDrawerProps extends IChildren {
  open: boolean;
  onClose?: () => void;
  className?: string;
  hideBackdrop?: boolean;
}

const ActionDrawer = (props: IActionDrawerProps) => {
  return (
    <Drawer
      anchor="right"
      open={props.open}
      onClose={props.onClose}
      classes={{ paper: "drawer-paper" }}
      className = {props.className}
      BackdropProps={{invisible:props.hideBackdrop}}
    >
      {props.children}
    </Drawer>
  );
};

export default ActionDrawer;
