import React from "react";
import styled from "styled-components";
import { isEmpty, contains, first, without, min } from "underscore";
import { Statuses } from "../../../components/DelegeeStatus/DelegeeStatus";
import ProductsTooltip from "../../../components/LocationCard/ProductsTooltip/ProductsTooltip";
import { DelegatedParkingProductModel } from "../../../models/delegations/DelegatedParkingProductsModel";
import { ParkingProductModel } from "../../../models/ParkingProductModel";
import { v4 as uuidv4 } from "uuid";
import { QParkChip } from "../../../components/QParkChip";


export const getCreatedDate = (products: DelegatedParkingProductModel[]): Date | null => {
  const createDates = products.map((p) => new Date(p.created));

  if (isEmpty(createDates)) {
    return null;
  }

  return  min(createDates) as Date;
};

export const getStatus = (products: DelegatedParkingProductModel[]): number | null => {
  const statuses = products.map((p) => p.status);

  if (isEmpty(statuses)) {
    return null;
  }

  if (contains(statuses, Statuses.Accepted)) {
    return Statuses.Accepted;
  }

  return Statuses.Pending;
};

const TooltipWrapper = styled.div`
  display: flex;
  width: 100%;
`;

const Wrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
`;

export const toProductsJsx = (products: DelegatedParkingProductModel[]): JSX.Element | null => {
  if (isEmpty(products)) {
    return null;
  }

  const elements: JSX.Element[] = [];
  const firstProduct = first(products);

  elements.push(
    <QParkChip.Labeled
      key={"first-product"}
      label={`${firstProduct?.name as string} - ${firstProduct?.location as string}`}
    />
  );

  if (products.length > 1) {
    const parkingProducts: ParkingProductModel[] = without(products, firstProduct!).map(
      (pp: DelegatedParkingProductModel) => ({
        pmcName: pp.name,
        location: pp.location,
      })
    );

    elements.push(
      <ProductsTooltip
        key={uuidv4()}
        parkingProducts={parkingProducts}
        displayLocation
        displayAmount={false}
      >
        <TooltipWrapper>
          <QParkChip.Numbered
            key={"other product"}
            count={without(products, firstProduct!).length}
          />
        </TooltipWrapper>
      </ProductsTooltip>
    );
  }

  return <Wrapper>{elements}</Wrapper>;
};