import axios from "../axios";
import { AxiosResponse } from "axios";
import aiApi from "../api/ai.api";

type AiService = {
  getInstrumentationKey: () => Promise<AxiosResponse<string>>;
};

const getInstrumentationKey = (): Promise<AxiosResponse<string>> =>
  axios.get(aiApi.getInstrumentationKeyUrl());

export default {
  getInstrumentationKey,
} as AiService;
