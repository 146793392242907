import { StringMap, TOptions } from "i18next";
import { ToastType } from "../../ui/CustomToast/CustomToast";
import { toastTypes } from "../action-types/toast.types";

export type Translateable<T extends object> = {
  key: string;
  options?: string | TOptions<T>;
  formatArgs?: string[];
};

export type ToastState = {
  show: boolean;
  severity: ToastType;
  title: Translateable<StringMap>;
  content: Translateable<StringMap>;
};

export const initialState: ToastState = {
  show: false,
  severity: "success",
  title: {
    key: "",
    formatArgs: [],
    options: {},
  },
  content: {
    key: "",
    formatArgs: [],
    options: {},
  },
};

export type ToastTranslationModel = {
  title?: Translateable<StringMap>;
  content?: Translateable<StringMap>;
};

export type ToastActionType = {
  type: string;
  severity?: ToastType;
  translationModelPayload?: ToastTranslationModel;
};

export default (
  state: ToastState = initialState,
  action: ToastActionType
): ToastState => {
  switch (action.type) {
    case toastTypes.SHOW_TOAST:
      return {
        ...state,
        show: true,
        severity: action.severity as ToastType,
        title: {
          key: action.translationModelPayload?.title?.key || "",
          formatArgs: action.translationModelPayload?.title?.formatArgs || [],
          options: action.translationModelPayload?.title?.options || {},
        },
        content: {
          key: action.translationModelPayload?.content?.key || "",
          formatArgs: action.translationModelPayload?.content?.formatArgs || [],
          options: action.translationModelPayload?.content?.options || {},
        },
      };
    case toastTypes.HIDE_TOAST:
      return {
        ...state,
        show: false,
      };
    case toastTypes.DISPOSE_TOAST:
      return initialState;
    default:
      return state;
  }
};
