import {
  CreateVisitorBookingsDetailRequest,
  CreateVisitorBookingsRequest,
} from "../../../../../models/visitor-booking/CreateVisitorBookingsRequest";
import { v4 as uuidV4 } from "uuid";
import {
  VisitorBookingNewBookingBulkDetails,
  VisitorBookingNewBookingBulkRequest,
} from "../models/VisitorBookingNewBookingBulk";
import {
  VisitorDetailModel,
  VisitorDetailValidationModel,
} from "../../../../../models/visitor-booking/VisitorDetailModel";
import { isUndefined } from "underscore";

export const mapToCreateVisitorBookingsBulkRequest = (
  product: VisitorBookingNewBookingBulkDetails,
  visitors: VisitorDetailModel[]
): VisitorBookingNewBookingBulkRequest => ({
  ...product,
  visitors,
});

export const mapToCreateVisitorBookingsRequest = (
  data: VisitorBookingNewBookingBulkRequest,
  culture: string
): CreateVisitorBookingsRequest => ({
  countryCode: data.country,
  receptionistCountryCode: data.receptionistCountryCode,
  physicalZoneUid: data.physicalZone,
  productId: data.productId,
  productName: data.productName,
  productType: data.productType,
  parkingTime: data.parkingTime,
  startDate: data.startDate,
  startTime: data.startTime,
  endDate: data.endDate,
  endTime: data.endTime,
  culture: culture,
  visitorDetails: mapToCreateVisitorBookingsDetailRequest(data),
});

const mapToCreateVisitorBookingsDetailRequest = (
  data: VisitorBookingNewBookingBulkRequest
): CreateVisitorBookingsDetailRequest[] =>
  data.visitors.map((vd) => ({
    name: vd.name ?? "",
    referenceNumber: uuidV4(),
    emailAddress: vd.emailAddress,
    numberPlate: vd.numberPlate?.toString().toUpperCase() ?? "",
    numberPlateCountryPrefix: vd.numberPlatePrefix?.toUpperCase() ?? "",
  }));

export const mapToVisitorDetailModel = (
  visitor: VisitorDetailValidationModel,
  country?: string
): VisitorDetailModel => ({
  name: visitor.name.value,
  referenceNumber: uuidV4(),
  emailAddress: visitor.emailAddress.value,
  numberPlate: visitor.numberPlateNumber.value,
  numberPlatePrefix:
    visitor.numberPlateCountryCode.value ??
    (isUndefined(visitor.numberPlateNumber.value) && visitor.numberPlateNumber.isValid
      ? country?.toUpperCase()
      : ""),
});

export const mapToVisitorDetailModelArray = (
  data: VisitorDetailValidationModel[]
): VisitorDetailModel[] => data.map((visitor) => mapToVisitorDetailModel(visitor));
