import { useEffect, useRef } from "react";
import { useAppSelector } from "../store";
import { usePrevious } from "./usePrevious";

export function useOnBulkImportFinishedCallback(callback: () => void) {
  const bulkImportProgress = useAppSelector((s) => s.parkingProducts.bulkImportProgress.progress);
  const previousBulkImportProgress = usePrevious(bulkImportProgress);

  // want fresh callback, but don't want to rerun the effect if it changes...
  const callbackRef = useRef(callback);
  callbackRef.current = callback;

  useEffect(() => {
    if (bulkImportProgress === 100 && previousBulkImportProgress !== 100) {
      callbackRef.current();
    }
  }, [bulkImportProgress, previousBulkImportProgress, callbackRef]);
}
