import React, { Dispatch, useEffect } from "react";
import { Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import { RootReducer } from "../../store/reducers";
import { AnyAction } from "redux";
import { toastActions } from "../../store/actions/toast.actions";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { isEmpty, isNull, isUndefined } from "underscore";
import { stringUtils } from "../../utils";
import "./_toast-alert.scss";

const { format } = stringUtils;

export type ToastType = "success" | "error" | "warning" | "info";

const toastSeverity = {
  success: "success",
  error: "error",
  warning: "warning",
  info: "info",
};

interface ICustomToastProps extends StateProps, DispatchProps {}

const CustomToast = ({ toast, dispose }: ICustomToastProps) => {
  const { t } = useTranslation(["globals", "toast"]);

  useEffect(() => {
    let taskId: number;

    if (toast.show) {
      taskId = setTimeout(() => dispose(), 5000);
    }

    return () => clearTimeout(taskId);
  });

  const getTitle = (fallbackKey: string) => {
    const hasCustomTitle = !isNull(toast.title.key) && !isEmpty(toast.title.key);
    if (!hasCustomTitle) {
      return t(fallbackKey);
    }

    if (isEmpty(toast.title.formatArgs)) {
      return t(`toast:${toast.title.key}`, toast.title.options);
    }

    return format(
      t(`toast:${toast.title.key}`, toast.title.options),
      toast.title.formatArgs
    );
  };

  const getToastTitle = () => {
    switch (toast.severity) {
      case toastSeverity.success:
        return getTitle("success");
      case toastSeverity.error:
        return getTitle("error");
      case toastSeverity.warning:
        return getTitle("warning");
      case toastSeverity.info:
        return getTitle("info");
      default:
        return "";
    }
  };

  const getToastMessage = () => {
    if (toast.content.key == null || isEmpty(toast.content.key)) {
      return "";
    }

    if (isEmpty(toast.content.formatArgs)) {
      return t(`toast:${toast.content.key}`, toast.content.options);
    }

    return format(
      t(`toast:${toast.content.key}`, toast.content.options),
      toast.content.formatArgs
    );
  };

  return (
    <Snackbar
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={toast.show}
      onClose={dispose}
      autoHideDuration={3500}
      key={`alert_${toast.severity}`}
      className="toast-alert"
    >
      <MuiAlert
        elevation={6}
        variant="filled"
        severity={toast.severity}
        onClose={dispose}
      >
        <div className="toast-body">
          <strong className="title">{getToastTitle()}</strong>
          <span className="message">{getToastMessage()}</span>
        </div>
      </MuiAlert>
    </Snackbar>
  );
};

const mapStateToProps = (state: RootReducer) => {
  const { toast } = state;

  return {
    toast,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
  dispose: () => dispatch(toastActions.dispose() as any),
});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(CustomToast);
