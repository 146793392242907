export type PrebookingProductsApi = {
  getProductSalesAvailabilityUrl: (
    physicalZoneUid: string,
    startDate: string,
    endDate: string,
    customerId: string,
    productId: string
  ) => string;
};

export const prebookingProductsApi: PrebookingProductsApi = (() => {
  const baseUrl = "/api/PrebookingProducts";

  const getProductSalesAvailabilityUrl = (
    physicalZoneUid: string,
    startDate: string,
    endDate: string,
    customerId: string,
    productId: string
  ) =>
    `${baseUrl}/availability/${customerId}/${productId}?physicalZoneUid=${physicalZoneUid}&startDate=${startDate}&endDate=${endDate}`;
  return {
    getProductSalesAvailabilityUrl,
  };
})();
