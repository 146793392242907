import React from "react";
import { useTranslation } from "react-i18next";

const NoFacilitySelected = () => {
  const { t } = useTranslation(["visitors"]);

  return (
    <div className="no-facility-selected">
      <span>{t("productTimeframeForm.noFacilitySelected")}</span>
    </div>
  );
};

export default NoFacilitySelected;
