import { useFormik } from "formik";
import { TFunction } from "i18next";
import moment from "moment";
import * as Yup from "yup";
import { CountriesNumberPlates } from "../../../../../lib/countriesNumberPlates";
import { ProductType } from "../../../../../models/visitor-booking";
import { stringUtils } from "../../../../../utils";
import emailValidator from "../../../../../validators/email.validator";
import isValidnumberPlate from "../../../../../validators/numberplate.validator";
import { VisitorBookingNewBooking } from "../models/VisitorBookingNewBooking";
import { VisitorBookingNewBookingBulkDetails } from "../models/VisitorBookingNewBookingBulk";
import { isUndefined } from "underscore";
import { VisitorDetailModel } from "../../../../../models/visitor-booking/VisitorDetailModel";

const { format } = stringUtils;

type UseNewBookingFormikProps = {
  t: TFunction;
  onSubmit: (
    values: VisitorBookingNewBooking | VisitorBookingNewBookingBulkDetails | VisitorDetailModel
  ) => void;
  numberPlateCountryCode?: string;
  countryCode?: string;
  receptionistCountryCode?: string;
};

type UseNewBookingVisitorDetailFormikProps = {
  t: TFunction;
  numberPlateCountryCode?: string;
  initialValues?: VisitorDetailModel;
};

const undefinedHelper = (
  value: string | undefined,
  validation: (val: string) => boolean
): boolean => (!isUndefined(value) ? validation(value) : false);

export const useNewBookingFormik = (props: UseNewBookingFormikProps) => {
  const { t, onSubmit, countryCode, numberPlateCountryCode, receptionistCountryCode } = props;
  const countriesNumberPlates = new CountriesNumberPlates();
  const preselectedNumberPlatePrefix =
    countriesNumberPlates.findNumberPlatePrefix(numberPlateCountryCode);

  const formSchema = Yup.object().shape(
    {
      name: Yup.string().optional().default(""),
      emailAddress: Yup.string()
        .email(
          format(t("errors:format.field"), t("globals:email"), t("globals:email").toLowerCase())
        )
        .required(format(t("errors:required.field"), t("globals:email").toLowerCase()))
        .test("email-regex-validation", t("errors:format.email"), (value: string | undefined) =>
          undefinedHelper(value, emailValidator.isValidEmail)
        )
        .default(""),
      numberPlatePrefix: Yup.string().optional().default(preselectedNumberPlatePrefix),
      numberPlate: Yup.string()
        .optional()
        .test(
          "numberplate-regex-validation",
          t("errors:format.numberPlate"),
          (value: string | undefined, ctx) =>
            ctx.parent.numberPlatePrefix?.toUpperCase() === "D"
              ? undefinedHelper(value, isValidnumberPlate.isValidGermanNumberPlate)
              : undefinedHelper(value, isValidnumberPlate.isValidNumberPlate)
        )
        .default(""),
      country: Yup.string()
        .required(format(t("errors:required.field"), t("globals:country").toLowerCase()))
        .default(countryCode?.toLowerCase() ?? ""),
      city: Yup.string()
        .required(format(t("errors:required.field"), t("globals:city").toLowerCase()))
        .default(""),
      physicalZone: Yup.string()
        .required(format(t("errors:required.field"), t("globals:facility").toLowerCase()))
        .default(""),
      startDate: Yup.string()
        .required(format(t("errors:required.field"), t("globals:startDate").toLowerCase()))
        .default(""),
      startTime: Yup.string()
        .when("productType", {
          is: ProductType.HourlyRate,
          then: Yup.string().required(
            format(t("errors:required.field"), t("globals:startTime").toLowerCase())
          ),
        })
        .default(""),
      endDate: Yup.string()
        .when("productType", {
          is: ProductType.HourlyRate,
          then: Yup.string().required(
            format(t("errors:required.field"), t("globals:endDate").toLowerCase())
          ),
        })
        .when(
          ["startDate", "endDate"],
          /* @ts-ignore */ // Fixed in yup 1.0.0 https://github.com/jquense/yup/issues/1529
          (startDate: string, endDate: string, schema: Yup.BooleanSchema) => {
            const momentStartDate = moment(startDate, "DD/MM/YYYY");
            const momentEndDate = moment(endDate, "DD/MM/YYYY");

            return momentEndDate.isBefore(momentStartDate)
              ? schema.oneOf([false], t("visitors:productTimeframeForm.endDateNotBeforeStartDate"))
              : schema;
          }
        )
        .default(""),
      endTime: Yup.string()
        .when("productType", {
          is: ProductType.HourlyRate,
          then: Yup.string().required(
            format(t("errors:required.field"), t("globals:endTime").toLowerCase())
          ),
        })
        .default(""),
      productId: Yup.string().required().default(""),
      productName: Yup.string().required().default(""),
      productType: Yup.mixed()
        .oneOf([ProductType.HourlyRate, ProductType.FixedRate])
        .notRequired()
        .default(null),
      parkingTime: Yup.number()
        .when("productType", (productType: ProductType) => {
          if (productType === ProductType.FixedRate) {
            return Yup.number().moreThan(0).required().default(null);
          } else {
            return Yup.mixed().notRequired().default(null);
          }
        })
        .notRequired()
        .default(null),
    },
    [["endDate", "endDate"]]
  );

  const formik = useFormik<VisitorBookingNewBooking>({
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit,
    validationSchema: formSchema,
    enableReinitialize: true,
    initialValues: {
      name: "",
      emailAddress: "",
      numberPlatePrefix: preselectedNumberPlatePrefix ?? "",
      numberPlate: "",
      country: countryCode?.toLowerCase() ?? "",
      receptionistCountryCode: receptionistCountryCode?.toLowerCase() ?? "",
      city: "",
      physicalZone: "",
      parkingTime: null,
      startDate: "",
      startTime: "",
      endDate: "",
      endTime: "",
      productId: "",
      productName: "",
      productType: ProductType.Unknown,
    },
  });

  return { formik };
};

export const useNewBookingBulkStepOneFormik = (props: UseNewBookingFormikProps) => {
  const { t, onSubmit, countryCode, receptionistCountryCode } = props;

  const formSchema = Yup.object().shape(
    {
      country: Yup.string()
        .required(format(t("errors:required.field"), t("globals:country").toLowerCase()))
        .default(countryCode?.toLowerCase() ?? ""),
      city: Yup.string()
        .required(format(t("errors:required.field"), t("globals:city").toLowerCase()))
        .default(""),
      physicalZone: Yup.string()
        .required(format(t("errors:required.field"), t("globals:facility").toLowerCase()))
        .default(""),
      startDate: Yup.string()
        .required(format(t("errors:required.field"), t("globals:startDate").toLowerCase()))
        .default(""),
      startTime: Yup.string()
        .when("productType", {
          is: ProductType.HourlyRate,
          then: Yup.string().required(
            format(t("errors:required.field"), t("globals:startTime").toLowerCase())
          ),
        })
        .default(""),
      endDate: Yup.string()
        .when("productType", {
          is: ProductType.HourlyRate,
          then: Yup.string().required(
            format(t("errors:required.field"), t("globals:endDate").toLowerCase())
          ),
        })
        .when(
          ["startDate", "endDate"],
          /* @ts-ignore */ // Fixed in yup 1.0.0 https://github.com/jquense/yup/issues/1529
          (startDate: string, endDate: string, schema: Yup.BooleanSchema) => {
            const momentStartDate = moment(startDate, "DD/MM/YYYY");
            const momentEndDate = moment(endDate, "DD/MM/YYYY");

            return momentEndDate.isBefore(momentStartDate)
              ? schema.oneOf([false], t("visitors:productTimeframeForm.endDateNotBeforeStartDate"))
              : schema;
          }
        )
        .default(""),
      endTime: Yup.string()
        .when("productType", {
          is: ProductType.HourlyRate,
          then: Yup.string().required(
            format(t("errors:required.field"), t("globals:endTime").toLowerCase())
          ),
        })
        .default(""),
      productId: Yup.string().required().default(""),
      productName: Yup.string().required().default(""),
      productType: Yup.mixed()
        .oneOf([ProductType.HourlyRate, ProductType.FixedRate])
        .notRequired()
        .default(null),
      parkingTime: Yup.number()
        .when("productType", (productType: ProductType) => {
          if (productType === ProductType.FixedRate) {
            return Yup.number().moreThan(0).required().default(null);
          } else {
            return Yup.mixed().notRequired().default(null);
          }
        })
        .notRequired()
        .default(null),
    },
    [["endDate", "endDate"]]
  );

  const formikStepOne = useFormik<VisitorBookingNewBookingBulkDetails>({
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit,
    validationSchema: formSchema,
    enableReinitialize: true,
    initialValues: {
      country: countryCode?.toLowerCase() ?? "",
      receptionistCountryCode: receptionistCountryCode?.toLowerCase() ?? "",
      city: "",
      physicalZone: "",
      parkingTime: null,
      startDate: "",
      startTime: "",
      endDate: "",
      endTime: "",
      productId: "",
      productName: "",
      productType: ProductType.Unknown,
    },
  });

  return { formikStepOne };
};

export const useNewBookingBulkStepTwoFormik = (props: UseNewBookingVisitorDetailFormikProps) => {
  const { t, numberPlateCountryCode, initialValues } = props;
  const countriesNumberPlates = new CountriesNumberPlates();
  const preselectedNumberPlatePrefix =
    countriesNumberPlates.findNumberPlatePrefix(numberPlateCountryCode);
  const countryNumberPlates = countriesNumberPlates
    .getCountriesNumberPlates()
    .map((np) => np.numberPlateCode);

  const formSchema = Yup.object().shape(
    {
      name: Yup.string().optional().default(""),
      emailAddress: Yup.string()
        .email(
          format(t("errors:format.field"), t("globals:email"), t("globals:email").toLowerCase())
        )
        .required(format(t("errors:required.field"), t("globals:email").toLowerCase()))
        .test("email-regex-validation", t("errors:format.email"), (value: string | undefined) =>
          undefinedHelper(value, emailValidator.isValidEmail)
        )
        .default(""),
      numberPlatePrefix: Yup.string()
        .optional()
        .test(
          "numberplateprefix-regex-validation",
          t("errors:format.numberPlate"),
          (value: string | undefined) =>
            undefinedHelper(value, isValidnumberPlate.isValidNumberPlatePrefix)
        )
        .oneOf(countryNumberPlates, t("errors:format.numberPlate"))
        .default(""),
      numberPlate: Yup.string()
        .optional()
        .test(
          "numberplate-regex-validation",
          t("errors:format.numberPlate"),
          (value: string | undefined, ctx) =>
            ctx.parent.numberPlatePrefix?.toUpperCase() === "D"
              ? undefinedHelper(value, isValidnumberPlate.isValidGermanNumberPlate)
              : undefinedHelper(value, isValidnumberPlate.isValidNumberPlateWithoutDash)
        )
        .default(""),
    },
    [["endDate", "endDate"]]
  );

  const formikVisitorDetail = useFormik<VisitorDetailModel>({
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: () => undefined,
    validationSchema: formSchema,
    enableReinitialize: true,
    initialValues: initialValues ?? {
      referenceNumber: "",
      name: "",
      emailAddress: "",
      numberPlatePrefix: preselectedNumberPlatePrefix ?? "",
      numberPlate: "",
    },
  });

  return formikVisitorDetail;
};
