import React from "react";
import { SvgIcon } from "@material-ui/core";

const MyProductsIcon = () => (
  <SvgIcon>
    <g transform="translate(-16.26 -152.916)">
      <path
        className="a"
        d="M21.133,12.98V10.991l.545-.315-.545-.945V9.671H21.1l-.6-1.035c-.052,0-.106,0-.158,0a3.158,3.158,0,0,1-2.655-4.877L16.51,1.724,2.744,9.671H0a1.7,1.7,0,0,1,.727-.824L15.647.231A1.722,1.722,0,0,1,18,.861L19.868,4.1l-.248.143a1.436,1.436,0,1,0,1.435,2.487l.249-.143,1.867,3.233a1.726,1.726,0,0,1-.631,2.354l-1.405.811Zm-11.5-3.31H6.191l7.947-4.589a.861.861,0,0,1,1.176.316l2.466,4.272-1.989,0L14.253,7,9.636,9.671Z"
        transform="translate(17.545 152.916)"
      />
      <path
        className="a"
        d="M16.3,5.87V9.783H7.174V5.87Zm.978-1.957H6.2a.978.978,0,0,0-.978.978v5.87a.978.978,0,0,0,.978.978H17.283a.978.978,0,0,0,.978-.978V4.891A.978.978,0,0,0,17.283,3.913ZM23.153,6.2h.326V1.957A1.957,1.957,0,0,0,21.522,0H1.957A1.957,1.957,0,0,0,0,1.957V6.2H.326a1.63,1.63,0,0,1,0,3.261H0V13.7a1.957,1.957,0,0,0,1.957,1.957H21.522A1.957,1.957,0,0,0,23.479,13.7V9.457h-.326a1.63,1.63,0,0,1,0-3.261Zm-1.63-1.564a3.586,3.586,0,0,0,0,6.389V13.7H1.957V11.021a3.586,3.586,0,0,0,0-6.389V1.957H21.522Z"
        transform="translate(16.26 160.877)"
      />
    </g>
  </SvgIcon>
);

export default MyProductsIcon;
