import { Grid, makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import background from "../../assets/images/fm_mobile_bg.jpg";
import { ReactComponent as LogoWhite } from "../../assets/images/logo_white.svg";
import { variables } from "../../theme/variables";
import CustomButton from "../../ui/CustomButton/CustomButton";
import Spacer from "../../ui/Spacer/Spacer";

const { colors, typography } = variables;

const useStyles = makeStyles({
  root: {
    height: "100%",
    padding: "1.5em 2em",
  },
});

const StyledH1 = styled.h1`
  color: ${colors.white};
  font-size: ${typography.fontSizeLarger};
`;

const MessageWrapper = styled.div`
  color: ${colors.white};
  font-size: ${typography.fontSizeBase};
  text-align: center;
`;

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-image: url(${background});
  background-color: ${colors.veryLightGraySecondary};
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  z-index: 6050;

  #root {
    overflow: hidden !important;
  }
`;

interface IMobileWarningScreen {}

const MobileWarningScreen: React.FC<IMobileWarningScreen> = ({ children }) => {
  const classes = useStyles();

  const [width, setWidth] = useState(window.innerWidth);
  const [mobile, setMobile] = useState(false);
  const [accepted, setAccepted] = useState(false);

  const { t } = useTranslation("mobileOptimization");

  const setWindowWidth = () => setWidth(window.innerWidth);

  useEffect(() => {
    if (width <= 960) {
      setMobile(true);
    } else {
      setMobile(false);
    }
  }, [width]);

  useEffect(() => {
    window.addEventListener("resize", setWindowWidth);

    return () => window.removeEventListener("resize", setWindowWidth);
  }, []);

  if (mobile && !accepted) {
    return (
      <Wrapper>
        <Grid item container xs={12} direction="row" classes={classes} justifyContent="center">
          <Grid item container xs={12} direction="column" alignItems="center" justifyContent="center">
            <LogoWhite />
            <Spacer size="md" />
            <StyledH1>{t("welcome")}</StyledH1>
            <Spacer size="sm" />
            <MessageWrapper>{t("warningMessage")}</MessageWrapper>
            <Spacer size="sm" />
            <CustomButton.Primary text={t("continueAnyway")} onClick={() => setAccepted(true)} />
          </Grid>
        </Grid>
      </Wrapper>
    );
  }

  return <>{children}</>;
};

export default MobileWarningScreen;
