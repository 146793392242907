import { AxiosError, AxiosResponse } from "axios";
import { useTranslation } from "react-i18next";
import { DelegeeFileType } from "./DelegeeFileType.enum";

interface DownloadFileProps {
    readonly downloadValidationRequest: (seasonTicketOwnerCrmId : string, fileReference: string, language: string) => Promise<AxiosResponse<Blob>>;
    readonly downloadInvitationRequest: (seasonTicketOwnerCrmId : string, fileReference: string, language: string) => Promise<AxiosResponse<Blob>>;
    readonly onError: (error: string) => void;
    readonly getFileName: () => string;
    readonly getSeasonTicketOwnerCrmId: () => string;
    readonly getFileReference: () => string;
    readonly getLanguage: () => string | undefined;
    readonly delegeeFileType: DelegeeFileType;
}

interface DownloadedFileInfo {
    readonly download: () => Promise<void>;
}

export const useDownloadFile = ({
    downloadValidationRequest,
    downloadInvitationRequest,
    onError,
    getFileName,
    getSeasonTicketOwnerCrmId,
    getFileReference,
    getLanguage,
    delegeeFileType
}: DownloadFileProps): DownloadedFileInfo => {

    let downloadRequest = delegeeFileType === DelegeeFileType.Invitation 
        ? downloadInvitationRequest(getSeasonTicketOwnerCrmId(), getFileReference(), getLanguage()!) 
        : downloadValidationRequest(getSeasonTicketOwnerCrmId(), getFileReference(), getLanguage()!);
    

    const { t } = useTranslation(["downloadFile"]);
    const download = () => downloadRequest
        .then((response: AxiosResponse<Blob>) => {
            const data = response.data;
            if (data.size === 0) {
                onError(t("invalidFileReference"));
            }
            else {
                // Add encoding for deserializing punctuation marks correctly, ex: dÃ©jÃ -> déjà
                const url = URL.createObjectURL(new Blob([new Uint8Array([0xEF, 0xBB, 0xBF]),data],{ type: "text/plain;charset=utf-8" }));
                const link = document.createElement("a");
                link.href = url;
                link.download = getFileName();
                document.body.appendChild(link);

                link.click();

                document.body.removeChild(link);
                window.URL.revokeObjectURL(url);
            }            
        })
        .catch((error: AxiosError) => {
            if (error.response?.status === 400) {
                onError(t("invalidFileReference"));
            }
            else {
                onError(error.message);
            }            
        })

    return { download };
};