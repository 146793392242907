import { Grid } from "@material-ui/core";
import React, { Dispatch, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import styled from "styled-components";
import { isEmpty } from "underscore";
import CancelVisitorBookingWarning from "../../../components/Warnings/CancelVisitorBookingWarning";
import useAppContext from "../../../context/hooks/useAppContext";
import useVisitorBookingContext from "../../../context/hooks/useVisitorBookingContext";
import { WithFiltersAndPaging } from "../../../models/filters/WithFiltersAndPaging";
import { VisitorBookingsRequest } from "../../../models/visitor-booking/VisitorBookingsRequest";
import parkingProductsActions from "../../../store/actions/parkingProducts.actions";
import { visitorBookingActions } from "../../../store/actions/visitor-booking";
import { RootReducer } from "../../../store/reducers";
import { variables } from "../../../theme/variables";
import ActionDrawer from "../../../ui/ActionDrawer/ActionDrawer";
import Section from "../../../ui/Section/Section";
import VisitorBookingDataTable from "./VisitorBookingDataTable/VisitorBookingDataTable";

const { colors, typography } = variables;

const Span = styled.span`
  display: block;
  color: ${colors.darkGraySecondary};
  font-size: ${typography.fontSizeLarge};
  width: 100%;
  text-align: center;
`;

const BookingOverview = (props: BookingOverviewProps) => {
  const { appState } = useAppContext();

  const {
    productConfigurations,
    visitorBookingLoading,
    visitorBookingsList,
    getProductConfigurations,
    getParkingProducts,
    getParkingLocations,
  } = props;
  const {
    appState: { user },
  } = useAppContext();

  const { t } = useTranslation("visitors");

  const { visitorBookingContextState, toggleCancelVisitorBookingWarning } =
    useVisitorBookingContext();

  const requestProductConfigurations = useMemo(
    () => getProductConfigurations(user.seasonTicketOwnerCrmId as string),
    [user.seasonTicketOwnerCrmId]
  );

  const fetchParkingProducts = useMemo(
    () =>
      getParkingProducts(
        user.seasonTicketOwnerCrmId as string,
        appState.selectedLanguage
      ),
    [user.seasonTicketOwnerCrmId, appState.selectedLanguage]
  );

  const requestParkingLocations = useMemo(() => {
    const uniquePhysicalZonesUids = Array.from(
      new Set(
        productConfigurations.data?.flatMap((p) => p.allowedPhysicalZoneIds)
      )
    );
    if (uniquePhysicalZonesUids.length > 0) {
      getParkingLocations(uniquePhysicalZonesUids);
    }
  }, [productConfigurations.data]);

  useEffect(() => requestProductConfigurations, [requestProductConfigurations]);
  useEffect(() => fetchParkingProducts, [fetchParkingProducts]);
  useEffect(() => requestParkingLocations, [requestParkingLocations]);

  if (productConfigurations.loading) {
    return null;
  }

  return (
    <React.Fragment>
      <ActionDrawer
        open={visitorBookingContextState.showCancelBookingWarning}
        onClose={toggleCancelVisitorBookingWarning}
      >
        {visitorBookingContextState.selectedRowValues && (
          <CancelVisitorBookingWarning
            customerName={
              visitorBookingContextState.selectedRowValues.customerName
            }
            referenceNumber={
              visitorBookingContextState.selectedRowValues.referenceNumber
            }
          />
        )}
      </ActionDrawer>

      <Grid item container direction="row" alignItems="center">
        <Grid item container direction="column" alignItems="center" xs={12}>
          {!isEmpty(productConfigurations.data) && (
            <Section>
              <VisitorBookingDataTable
                hasProductsConfigured={!isEmpty(productConfigurations.data)}
                visitorBookingsList={visitorBookingsList}
                isLoading={visitorBookingLoading}
              />
            </Section>
          )}
        </Grid>
        <Grid
          item
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
          xs={12}
        >
          {isEmpty(productConfigurations.data) && (
            <Section>
              <Span>{t("noProductsAvailable")}</Span>
            </Section>
          )}
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

interface BookingOverviewProps extends StateProps, DispatchProps {}

const mapStateToProps = (state: RootReducer) => {
  const { productConfigurations, visitorBookings } = state.visitorBooking;

  return {
    productConfigurations,
    visitorBookingLoading: visitorBookings.loading,
    visitorBookingsList: visitorBookings.data ?? {
      visitorBookings: [],
      totalPages: 1,
      totalRecords: 0,
    },
  };
};

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  getProductConfigurations: (customerId: string) =>
    dispatch(visitorBookingActions.getProductConfigurations(customerId)),
  getVisitorBookings: (request: WithFiltersAndPaging<VisitorBookingsRequest>) =>
    dispatch(visitorBookingActions.getVisitorBookings(request)),
  getParkingProducts: (seasonTicketOwnerCrmId: string, location: string) =>
    dispatch(
      parkingProductsActions.getParkingProducts(
        seasonTicketOwnerCrmId,
        location
      )
    ),
  getParkingLocations: (uids: string[]) =>
    dispatch(
      visitorBookingActions.getParkingLocations({ physicalZoneUids: uids })
    ),
});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(BookingOverview);
