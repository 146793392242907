import React, { ReactNode, useEffect, useState } from "react";
import Table from "@material-ui/core/Table";
import DataTableHead, { Order } from "./DataTableHead/DataTableHead";
import DataTableBody, { IDataTableRow } from "./DataTableBody/DataTableBody";
import { Column } from "./DataTableHead/DataTableHead";
import DataTablePagination from "./DataTablePagination/DataTablePagination";
import Spacer from "../../ui/Spacer/Spacer";
import { Popper, Paper, MenuList, ClickAwayListener } from "@material-ui/core";
import DataTablePageSize from "./DataTablePageSize/DataTablePageSize";
import { useTranslation } from "react-i18next";
import {
  StyledTableContainer,
  NoPaddingBottomGrid,
  StyledDataTableWrapper,
} from "./DataTableStyledComponents";
import DataTableFilters, {
  FilterRef,
} from "./DataTableFilters/DataTableFilters";
import { isEmpty, isUndefined } from "underscore";
import DataTableHeading, {
  DataTableActions,
  DataTableFiltering,
  DataTableHeadingProps,
} from "./DataTableHeading/DataTableHeading";
import { useDataTableContext } from "./DataTableContext/DataTableContextProvider";
import "./_dataTable.scss";

interface IDataTableProps<TRow extends IDataTableRow > {
  heading?: DataTableHeadingProps;
  filtering?: DataTableFiltering;
  actions?: DataTableActions;
  columns: Column<TRow>[];
  rows: TRow[];
  orderBy: string;
  order: Order;
  pages: number;
  loading?: boolean;
  selectedRowKeys: string[];
  actionBtn?: (focusedRow: TRow) => JSX.Element;
  onCallback?: () => void;
  onFilterClicked?: () => void;
  onResetFiltersClicked?: () => void;
  onRequestSort?: (event: React.MouseEvent<unknown>, property: keyof TRow) => void;
  setSelectedRowKeys: (selected: string[]) => void;
  renderActionMenu: (
    focusedRow: TRow | null,
    onClose: () => void
  ) => ReactNode;
  filtersRef?: React.MutableRefObject<FilterRef>;
  hideWhenNoData?: boolean;
  hideActionsMenu?: boolean;
  translationOverrideKey: string;
  hideCheckBoxes?: boolean;
  hiddenKeys?: string[];
  subHeading?: ReactNode
}

const DataTable = <TRow extends IDataTableRow,>(props: IDataTableProps<TRow>) => {
  const { t } = useTranslation(["dataTable"]);
  const {
    heading,
    filtering,
    actions,
    columns,
    rows,
    order,
    orderBy,
    selectedRowKeys,
    actionBtn,
    onCallback,
    setSelectedRowKeys,
    onFilterClicked,
    onResetFiltersClicked,
    onRequestSort,
    filtersRef,
    hideWhenNoData,
    hideActionsMenu,
    translationOverrideKey,
    hideCheckBoxes,
    hiddenKeys,
    subHeading,
  } = props;

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [focusedRow, setFocusedRow] = useState<TRow | null>(null);
  const { state, resetFilters } = useDataTableContext();

  useEffect(() => {
    return () => resetFilters();
    // eslint-disable-next-line
  }, []);

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.rowKey);
      setSelectedRowKeys(newSelecteds);

      return;
    }

    setSelectedRowKeys([]);
  };

  const handleRowClick = (event: React.MouseEvent<unknown>, name: string) => {
    event.preventDefault();

    const selectedIndex = selectedRowKeys.indexOf(name);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedRowKeys, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedRowKeys.slice(1));
    } else if (selectedIndex === selectedRowKeys.length - 1) {
      newSelected = newSelected.concat(selectedRowKeys.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedRowKeys.slice(0, selectedIndex),
        selectedRowKeys.slice(selectedIndex + 1)
      );
    }

    setSelectedRowKeys(newSelected);
  };

  const handleMenuClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    row: TRow
  ) => {
    setSelectedRowKeys([]);
    setAnchorEl(event.currentTarget);
    setFocusedRow(row);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  useEffect(() => {
    if (!isUndefined(onCallback)) {
      onCallback();
    }
  }, [onCallback]);

  return (
    <StyledDataTableWrapper item container xs={12}>
      <DataTableHeading heading={heading!} filtering={filtering!} actions={actions!} />
      {subHeading && (
        <>
          <Spacer size="xs" />
          {subHeading}
        </>
      )}
      <Spacer size="xs" />
      {isEmpty(rows) && hideWhenNoData ? null : (
        <>
          <StyledTableContainer>
            {!isUndefined(filtering) && filtering.options?.useFilters && (
              <DataTableFilters
                filtersRef={filtersRef}
                columns={columns}
                showFilters={state.showFilters!}
                onFilterClicked={onFilterClicked!}
                onResetFiltersClicked={onResetFiltersClicked}
              />
            )}
            <Table
              stickyHeader
              aria-labelledby="tableTitle"
              size="small"
              aria-label="enhanced table"
            >
              <DataTableHead
                columns={columns}
                numSelected={selectedRowKeys.length}
                orderBy={orderBy}
                order={order}
                rowCount={rows?.length || 0}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={onRequestSort}
                hideActions={hideActionsMenu}
                hideCheckboxes={hideCheckBoxes}
              />
              <DataTableBody
                rows={rows}
                selected={selectedRowKeys}
                loading={props.loading}
                handleRowClick={handleRowClick}
                handleMenuClick={handleMenuClick}
                colSpan={columns.length}
                hideActions={hideActionsMenu}
                translationOverrideKey={translationOverrideKey}
                actionBtn={actionBtn}
                hideCheckboxes={hideCheckBoxes}
                hiddenKeys={hiddenKeys}
              />
            </Table>
            {!hideActionsMenu && (
              <Popper
                open={open}
                className="context-menu-wrapper"
                anchorEl={anchorEl}
                placement="bottom-end"
              >
                <Paper>
                  <ClickAwayListener
                    onClickAway={handleMenuClose}
                    mouseEvent="onMouseDown"
                    touchEvent="onTouchStart"
                  >
                    <MenuList>{props.renderActionMenu(focusedRow, handleMenuClose)}</MenuList>
                  </ClickAwayListener>
                </Paper>
              </Popper>
            )}
          </StyledTableContainer>
          <Spacer size="xs" />
          <NoPaddingBottomGrid
            container
            item
            direction="row"
            xs={12}
            alignItems="center"
            justifyContent="space-between"
          >
            <NoPaddingBottomGrid
              container
              item
              direction="column"
              xs={3}
              md={3}
              lg={6}
              alignItems="flex-start"
            >
              <DataTablePageSize defaultPageSize={25} disabled={props.loading} />
            </NoPaddingBottomGrid>
            <NoPaddingBottomGrid
              container
              item
              direction="column"
              xs={9}
              md={9}
              lg={6}
              alignItems="flex-end"
            >
              <DataTablePagination
                count={props.pages}
                disabled={props.loading}
                nextlabel={t("next")}
                previouslabel={t("previous")}
              />
            </NoPaddingBottomGrid>
          </NoPaddingBottomGrid>
        </>
      )}
    </StyledDataTableWrapper>
  );
};

export default DataTable;
