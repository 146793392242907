import { Grid } from "@material-ui/core";
import React, { forwardRef, useImperativeHandle } from "react";
import { isEmpty, isUndefined } from "underscore";
import { useNewBookingBulkStepTwoFormik } from "../../../../../hooks/useNewBookingFormik";
import { useTranslation } from "react-i18next";
import VisitorDetailsBulk from "./VisitorDetailsBulk";
import "../../../../../_newBooking.scss";
import { VisitorDetailModel } from "../../../../../../../../../models/visitor-booking/VisitorDetailModel";

export type VisitorDetailsWrapperProps = {
  numberPlateCountryCode: string | undefined;
  index: number;
  initialValues?: VisitorDetailModel;
  handleDelete?: () => void;
  setIsDirty?: () => void;
};

export type VisitorDetailsWrapperHandle = {
  handleSubmit: () => Promise<{
    isValid: boolean;
    data: VisitorDetailModel | null;
  }>;
  getValue: () => VisitorDetailModel;
  isDirty: () => boolean;
};

const VisitorDetailsWrapper = forwardRef<VisitorDetailsWrapperHandle, VisitorDetailsWrapperProps>(
  (
    {
      numberPlateCountryCode,
      index,
      initialValues,
      handleDelete,
      setIsDirty,
    }: VisitorDetailsWrapperProps,
    ref
  ) => {
    const { t } = useTranslation(["visitors"]);

    const formik = useNewBookingBulkStepTwoFormik({
      t,
      numberPlateCountryCode: numberPlateCountryCode,
      initialValues: initialValues,
    });

    const onInputChange = (field: string, e?: React.ChangeEvent<any>) => {
      if (!isUndefined(formik.errors[field as keyof typeof formik.errors])) {
        formik.setFieldError(field, undefined);
      }

      if (e) {
        formik.handleChange(e);
      }

      if (setIsDirty) {
        setIsDirty();
      }
    };

    useImperativeHandle(ref, () => ({
      async handleSubmit() {
        const errors = await formik.validateForm();
        const isValid = !Object.keys(errors).length;

        return {
          isValid: isValid,
          data: isValid ? formik.values : null,
        };
      },

      getValue() {
        return formik.values;
      },

      isDirty() {
        return (
          !isEmpty(formik.values.emailAddress) ||
          !isEmpty(formik.values.name) ||
          !isEmpty(formik.values.numberPlate)
        );
      },
    }));

    return (
      <React.Fragment>
        <Grid
          item
          container
          direction="row"
          xs={12}
          className={`visitor-detail-${index} padding-top-6`}
        >
          <VisitorDetailsBulk
            values={formik.values}
            errors={formik.errors}
            handleChange={onInputChange}
            setFieldValue={formik.setFieldValue}
            setFieldError={formik.setFieldError}
            handleDelete={handleDelete}
          />
        </Grid>
      </React.Fragment>
    );
  }
);

export default VisitorDetailsWrapper;
