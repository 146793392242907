import React, { useRef } from "react";
import { connect } from "react-redux";
import useAppContext from "../../context/hooks/useAppContext";
import { RootReducer } from "../../store/reducers";
import useOutsideClick from "../../utils/hooks/useOutsideClick";
import getUserManager from "../../utils/userManager";
import userCardBusiness from "./userCard.business";
import UserCardDetails from "./UserCardDetails";
import {
  NoPaddingGrid,
  StyledFullName,
  ExpandMore,
  UserCardWrapper,
  ExpandLess,
  PointerContainerGrid,
} from "./UserCardStyledComponents";
import useUserCardState from "./useUserCardState";

interface IUserCardProps extends StateProps {
  email: string;
}

const UserCard: React.FC<IUserCardProps> = ({ email, oidcConfig }) => {
  const { state, toggleDetails, hideDetails } = useUserCardState();
  const { disposeUser } = useAppContext();
  const { signOut } = userCardBusiness;

  const callback = () => {
    disposeUser();
    getUserManager(oidcConfig.oidcConfig).signoutRedirect();
  };

  const onSignOut = () => signOut(callback);

  const instance = useRef<any>(null);
  useOutsideClick(instance, hideDetails);

  return (
    <UserCardWrapper ref={instance}>
      <PointerContainerGrid container item direction="row" xs={12} onClick={toggleDetails}>
        <NoPaddingGrid
          container
          item
          direction="column"
          xs={10}
          alignItems="center"
          justifyContent="center"
        >
          <StyledFullName>{email}</StyledFullName>
        </NoPaddingGrid>
        <NoPaddingGrid
          container
          item
          direction="column"
          xs={2}
          alignItems="flex-start"
          justifyContent="center"
        >
          {state.showDetails ? <ExpandLess /> : <ExpandMore />}
        </NoPaddingGrid>
      </PointerContainerGrid>
      <UserCardDetails email={email} show={state.showDetails} onSignOut={onSignOut} />
    </UserCardWrapper>
  );
};

const mapStateToProps = (state: RootReducer) => {
  const { oidcConfig } = state;

  return {
    oidcConfig,
  };
};

type StateProps = ReturnType<typeof mapStateToProps>;

export default connect(mapStateToProps)(UserCard);
