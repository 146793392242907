import React from "react";
import { ContentCard } from "../../../../../../../../../ui/ContentCard";
import { VisitorDetailsForm } from "../../forms";
import { VisitorDetailsProps } from "./VisitorDetails.types";

const VisitorDetailsBulk = (props: VisitorDetailsProps) => {
  const { values, errors, handleChange, setFieldValue, setFieldError, handleDelete } = props;

  return (
    <ContentCard handleDelete={handleDelete}>
      <VisitorDetailsForm
        onChange={handleChange}
        setFieldValue={setFieldValue}
        setFieldError={setFieldError}
        name={{
          value: values.name,
        }}
        emailAddress={{
          value: values.emailAddress,
          hasError: Boolean(errors.emailAddress),
          error: errors.emailAddress,
        }}
        numberPlatePrefix={{
          value: values.numberPlatePrefix,
          hasError: Boolean(errors.numberPlatePrefix),
          error: errors.numberPlatePrefix,
        }}
        numberPlate={{
          value: values.numberPlate,
          hasError: Boolean(errors.numberPlate),
          error: errors.numberPlate,
        }}
      />
    </ContentCard>
  );
};

export default VisitorDetailsBulk;
