import React, { Dispatch, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import Warning from "./Warning";
import parkingProductsActions from "../../store/actions/parkingProducts.actions";
import useFleetManagerContext from "../../context/hooks/useFleetManagerContext";
import { RootReducer } from "../../store/reducers";
import { RevokeVehicleAssignmentsModel } from "../../models/vehicle-assignments/RevokeVehicleAssignmentsModel";
import { VehicleAssignmentProduct } from "../../models/vehicle-assignments/VehicleAssignmentProduct";
import { parseDVARowKey } from "../../helpers/vehicleAssignment.utils";

interface IFormProps extends StateProps, DispatchProps {
  selectedVehicleAssignmentKeys: string[];
  onRefresh: (vehiclesToExclude?: VehicleAssignmentProduct[]) => void;
}

const BatchRevokeVehicleAssignmentWarning = ({
  parkingProducts,
  revokeVehicleAssignmentsParkingRights,
  selectedVehicleAssignmentKeys,
  onRefresh,
}: IFormProps) => {
  const { t } = useTranslation(["revokeVehicleAssignmentWarning", "globals"]);
  const { toggleBatchRevokeVehicleAssignmentsWarning, setSelectedParkingRightIds } =
    useFleetManagerContext();

  const vaSelectedProducts: VehicleAssignmentProduct[] = useMemo(
    () =>
      selectedVehicleAssignmentKeys.flatMap((vehicleAssignmentKey) => {
        const parsed = parseDVARowKey(vehicleAssignmentKey);

        return parsed.pmcIds.map((pmcId) => ({
          vehicleAssignmentId: parsed.vehicleAssignmentId,
          pmcId,
        }));
      }),
    [selectedVehicleAssignmentKeys]
  );

  const handleRevokeCancellation = () => {
    toggleBatchRevokeVehicleAssignmentsWarning();
  };

  const handleSuccessfulRevoke = (model: VehicleAssignmentProduct[]) => {
    setSelectedParkingRightIds([]);
    toggleBatchRevokeVehicleAssignmentsWarning();

    onRefresh(model);
  };

  const handleRevokeConfirmation = () => {
    const model: RevokeVehicleAssignmentsModel = {
      vehicleAssignmentProducts: vaSelectedProducts,
    };
    revokeVehicleAssignmentsParkingRights(model, () =>
      handleSuccessfulRevoke(model.vehicleAssignmentProducts)
    );
  };

  return (
    <Warning
      warning={ t("warning", { count: vaSelectedProducts.length })}
      details={t("details", { count: vaSelectedProducts.length })}
      onCancel={handleRevokeCancellation}
      onConfirm={handleRevokeConfirmation}
      disabled={false}
      confirmText={t("globals:revoke")}
      loading={parkingProducts.loading}
    />
  );
};

const mapStateToProps = (state: RootReducer) => {
  const { parkingProducts } = state;

  return {
    parkingProducts,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  revokeVehicleAssignmentsParkingRights: (
    model: RevokeVehicleAssignmentsModel,
    callback: (vehiclesToExclude?: VehicleAssignmentProduct[]) => void
  ) =>
    dispatch(
      parkingProductsActions.revokeVehicleAssignmentProducts(model, () =>
        callback(model.vehicleAssignmentProducts)
      )
    ),
});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(BatchRevokeVehicleAssignmentWarning);
