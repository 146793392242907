import React from "react";
import { Container, Grid } from "@material-ui/core";
import styled from "styled-components";
import { variables } from "../../theme/variables";
import { useTranslation } from "react-i18next";
import CustomButton from "../../ui/CustomButton/CustomButton";
import WarningColumn from "../../ui/CenteredWarning/CenteredWarning";

const { colors } = variables;



const ActionFooter = styled(Container)`
  background-color: ${colors.white};
  border-top: 1px solid ${colors.veryLightGray};
  padding-top: 1rem;
  padding-bottom: 1rem;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  position: absolute;
  bottom: 0;
`;

interface IProps {
  warning: string;
  details: string;
  disabled?: boolean;
  onCancel: () => void;
  onConfirm: () => void;
  confirmText: string;
  loading?: boolean;
  useOrangeWarning?: boolean;
  width?: number
}

const Warning = (props: IProps) => {
  const { t } = useTranslation("globals");

  const FullHeightRow = styled(Grid)`
    height: 100vh;
    padding: 0;
    width: ${props.width ?? 500}px;
    position: relative;
  `;

  const warning = !props.disabled ? props.warning : "";
  const details = !props.disabled ? props.details : "";

  return (
    <FullHeightRow container item direction="row" alignItems="center" justifyContent="center">
      <WarningColumn title={warning} details={details} orangeWarning={props.useOrangeWarning}/>
      <ActionFooter>
        <React.Fragment>
          <CustomButton.Light text={t("cancel")} onClick={props.onCancel} />
          {!props.useOrangeWarning && (<CustomButton.Danger
            text={props.confirmText}
            onClick={props.onConfirm}
            disabled={props.disabled}
            loading={props.loading}
          />)}
          {props.useOrangeWarning && (<CustomButton.Primary
            text={props.confirmText}
            onClick={props.onConfirm}
            disabled={props.disabled}
            loading={props.loading}
          />)}
        </React.Fragment>
      </ActionFooter>
    </FullHeightRow>
  );
};

export default Warning;
