import React, { PropsWithChildren } from "react";
import Button from "@material-ui/core/Button";
import styled from "styled-components";
import { variables } from "../../theme/variables";
import CustomSpinner from "../CustomSpinner/CustomSpinner";
import { IconButton } from "@material-ui/core";

const { colors, shared } = variables;

interface ColouredTextProps {
  color?: string;
}

const ColouredText = styled.span`
  color: ${(props: ColouredTextProps) => props.color || colors.black};
`;

const AbsoluteCustomSpinner = styled.div`
  position: absolute;
  z-index: 105;
  display: flex;
  align-items: center;
`;

interface IButtonProps {
  text?: string;
  className?: string;
  disabled?: boolean;
  onClick?: (event?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  textColor?: string;
  loading?: boolean;
  id?: string
}

const CustomButton = () => null;

const Primary = ({
  text,
  className,
  disabled,
  loading,
  startIcon,
  endIcon,
  onClick,
  id,
}: IButtonProps) => (
  <Button
    variant="contained"
    color="primary"
    onClick={onClick}
    disableRipple
    disabled={disabled || loading}
    startIcon={startIcon}
    endIcon={endIcon}
    className={className}
    id={id}
  >
    <span className={loading ? "hidden" : "visible"}>{text}</span>
    {loading && (
      <AbsoluteCustomSpinner>
        <CustomSpinner.DotPulse />
      </AbsoluteCustomSpinner>
    )}
  </Button>
);

const Light = ({
  text,
  className,
  disabled,
  startIcon,
  loading,
  textColor,
  onClick,
  id,
}: IButtonProps) => (
  <Button
    variant="contained"
    color="default"
    onClick={onClick}
    disableRipple
    disabled={disabled || loading}
    startIcon={startIcon}
    className={className}
    id={id}
  >
    <ColouredText color={textColor} className={loading ? "hidden" : "visible"}>
      {text}
    </ColouredText>
    {loading && (
      <AbsoluteCustomSpinner>
        <CustomSpinner.DotPulse />
      </AbsoluteCustomSpinner>
    )}
  </Button>
);

const Danger = ({ 
  text,
  className,
  disabled,
  loading,
  onClick, 
  id 
}: IButtonProps) => (
  <Button
    variant="contained"
    color="secondary"
    onClick={onClick}
    disableRipple
    disabled={disabled || loading}
    className={className}
    id={id}
  >
    <span className={loading ? "hidden" : "visible"}>{text}</span>
    {loading && (
      <AbsoluteCustomSpinner>
        <CustomSpinner.DotPulse />
      </AbsoluteCustomSpinner>
    )}
  </Button>
);

const StyledIconButton = styled(IconButton)`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${colors.white};
  border: 1px solid ${colors.veryLightGraySecondary};
  color: ${colors.black};
  padding: 10px;
  box-shadow: ${shared.boxShadow};

  svg {
    font-size: 18px;
  }
`;

const Icon = ({ children, onClick }: PropsWithChildren<IButtonProps>) => (
  <StyledIconButton onClick={onClick}>{children}</StyledIconButton>
);

CustomButton.Primary = Primary;
CustomButton.Light = Light;
CustomButton.Danger = Danger;
CustomButton.Icon = Icon;

export default CustomButton;
