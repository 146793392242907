import React, { Dispatch } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import Warning from "./Warning";
import parkingProductsActions from "../../store/actions/parkingProducts.actions";
import { RevokeParkingRightsModel } from "../../models/RevokeParkingRightsModel";
import useFleetManagerContext from "../../context/hooks/useFleetManagerContext";
import { RootReducer } from "../../store/reducers";

interface IFormProps extends StateProps, DispatchProps {
    selectedParkingRightIds: string[];
    onRefresh: () => void;
}

const BatchRevokeParkingRightsWarning = ({
    parkingProducts,
    revokeParkingRights,
    selectedParkingRightIds,
    onRefresh
}: IFormProps) => {
    const { t } = useTranslation(["revokeDelegeeWarning", "globals"]);
    const { toggleBatchRevokeParkingRightsWarning, setSelectedParkingRightIds } = useFleetManagerContext();

    const parkingRightsCount = selectedParkingRightIds.length;
    const warningText = t("warning", {count:parkingRightsCount});
    const warningDetails = t("details", {count:parkingRightsCount});

    const handleRevokeCancellation = () => {
        toggleBatchRevokeParkingRightsWarning();
    }

    const handleSuccessfulRevoke = () => {
        setSelectedParkingRightIds([]);
        toggleBatchRevokeParkingRightsWarning();

        onRefresh();
    }

    const handleRevokeConfirmation = () => {
        revokeParkingRights(selectedParkingRightIds, handleSuccessfulRevoke);
    }
    
    return (
        <Warning
            warning={warningText}
            details={warningDetails}
            onCancel={handleRevokeCancellation}
            onConfirm={handleRevokeConfirmation}
            disabled={false}
            confirmText={t("globals:revoke")}
            loading={parkingProducts.loading}
        />
    );
};

const mapStateToProps = (state: RootReducer) => {
  const { parkingProducts } = state;

  return {
    parkingProducts,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
    revokeParkingRights: (parkingRightIds: string[], callback?: () => void) => {
        const model: RevokeParkingRightsModel = {
            parkingRightIds
        };
        dispatch(parkingProductsActions.revokeParkingRights(model, callback))
    },
});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(BatchRevokeParkingRightsWarning);
