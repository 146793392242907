import React, { memo, PropsWithChildren, useCallback } from "react";
import { QParkSelectCardItemProps } from "./QParkSelectCardItem.types";
import { DoneRounded } from "@material-ui/icons";
import { isUndefined } from "underscore";
import "./_qpark-select-card-item.scss";

const QParkSelectCardItem = memo(
  (props: PropsWithChildren<QParkSelectCardItemProps>) => {
    const { selected, children, value, onClick } = props;

    const isSelected = selected === value;
    const onCardItemClicked = useCallback(
      (value?: string | number) => (_: React.MouseEvent<HTMLLIElement>) => {
        if (isUndefined(onClick)) {
          return;
        }

        onClick(value);
      },
      [value]
    );

    return (
      <li
        className={`qp-select-card ${isSelected ? "active" : ""}`.trim()}
        value={value}
        onClick={onCardItemClicked(value)}
      >
        <div className="qp-select-card-check">
          <div className="check-icon-wrapper">
            <DoneRounded className="check-icon" />
          </div>
        </div>
        <span className="qp-select-card-label">
          <span>{children}</span>
        </span>
      </li>
    );
  }
);

export default QParkSelectCardItem;
