import React from "react";
import { useLocation } from "react-router";
import routes from "../../../router/routes";
import { isEmpty, isNull, isUndefined } from "underscore";
import { ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import { NavLink } from "react-router-dom";
import { Translation } from "react-i18next";
import { IRouteProps } from "../../../router/AppRoute";

const FleetManagerSidebar = () => {
  const { pathname } = useLocation();
  let subRoutes: IRouteProps[] | undefined = [];

  const isFleetPage = pathname.includes(routes.fleetManager.path as string);
  const fleetHasSubroutes = !isUndefined(routes.fleetManager.subRoutes);
  if (isFleetPage && fleetHasSubroutes) {
    subRoutes = routes.fleetManager.subRoutes;
  }

  const isVisitorsPage = pathname.includes(routes.visitors.path as string);
  const visitorsHasSubroutes = !isUndefined(routes.visitors.subRoutes);
  if (isVisitorsPage && visitorsHasSubroutes) {
    subRoutes = routes.visitors.subRoutes;
  }

  if (isEmpty(subRoutes) || isUndefined(subRoutes)) {
    return null;
  }

  return (
    <Translation ns="fleetManagerSidebar">
      {(t: Function) => (
        <React.Fragment>
          {subRoutes?.map((route: IRouteProps, index: number) => {
            if (route.hidden) {
              return false;
            }

            return (
              <ListItem
                key={`${route.path}_${index}`}
                component={NavLink}
                to={route.path as string}
                classes={{ root: "list-item" }}
              >
                {!isNull(route.Icon) && !isUndefined(route.Icon) && (
                  <ListItemIcon classes={{ root: "list-item-icon" }}>
                    <route.Icon />
                  </ListItemIcon>
                )}

                <ListItemText
                  primary={t(route.name)}
                  classes={{ root: "list-item-text" }}
                />
              </ListItem>
            );
          })}
        </React.Fragment>
      )}
    </Translation>
  );
};

export default FleetManagerSidebar;
