import thunkMiddleware from "redux-thunk";
import loggerMiddleware from "redux-logger";
import { Middleware } from "redux";
import { routerMiddleware } from "connected-react-router";
import { createBrowserHistory } from "history";

export const history = createBrowserHistory();

const getMiddlewares = () => {
  let middlewares: Middleware[] = [routerMiddleware(history), thunkMiddleware];

  const isProdBuild = process.env.NODE_ENV === "production";
  const isTestBuild = process.env.NODE_ENV === "test";

  if (!isProdBuild && !isTestBuild) {
    middlewares = [...middlewares, loggerMiddleware];
  }

  return middlewares;
};

export const middlewares = getMiddlewares();