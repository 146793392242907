import { useEffect } from "react";

const useOutsideClick = (instance: React.MutableRefObject<any>, action: () => void) => {
  const _handleClickOutside = (
    instance: React.MutableRefObject<any>,
    action: () => void,
    event: MouseEvent
  ) => {
    if (instance.current && !instance.current.contains(event.target)) {
      action();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", (event) => _handleClickOutside(instance, action, event));

    return () =>
      document.removeEventListener("mousedown", (event) =>
        _handleClickOutside(instance, action, event)
      );
    // eslint-disable-next-line
  }, [instance]);
};

export default useOutsideClick;
