import React from "react";
import { Route } from "react-router";
import { appRoutesArray } from "./routes";
import { IRouteProps } from "./AppRoute";
import { RootReducer } from "../store/reducers";
import { connect } from "react-redux";

interface IAppRoutesProps extends StateProps {}

const FmpAppRoutes = ({ environment }: IAppRoutesProps) => (
  <>
    {appRoutesArray.map((route: IRouteProps, index: number) => {
      return (
        <React.Fragment key={`route_${index}`}>
          <Route
            exact={route.exact}
            path={route.path}
            component={route.component}
          />
          {route.subRoutes?.length! > 0 &&
            route.subRoutes?.map((subroute: IRouteProps, sIndex: number) => (
              <Route
                key={`subroute_${sIndex}`}
                exact={subroute.exact}
                path={subroute.path}
                component={subroute.component}
              />
            ))}
        </React.Fragment>
      );
    })}
  </>
);

const mapStateToProps = (state: RootReducer) => {
  const { environment } = state;

  return {
    environment,
  };
};

type StateProps = ReturnType<typeof mapStateToProps>;

export default connect(mapStateToProps)(FmpAppRoutes);
