import storageService from "../../services/storage.service";
import { isUndefined } from "underscore";

type UserCardBusiness = {
  signOut: (callback?: Function) => void;
};

const userCardBusiness: UserCardBusiness = (() => {
  const signOut = (callback?: Function): void => {
    storageService.removeTokenFromStorage();

    if (!isUndefined(callback)) {
      callback();
    }
  };

  return {
    signOut,
  };
})();

export default userCardBusiness;
