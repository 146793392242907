import { useTranslation } from "react-i18next";
import React from "react";
import "./_stickySidebar.scss";
import ProductIconSrc from "../../../../../../../../../assets/images/product-ticket.svg";
import ClockIconSrc from "../../../../../../../../../assets/images/clock-filled.svg";
import AvailableSpotsIconSrc from "../../../../../../../../../assets/images/available-spots.svg";
import AvailableSpotsRedIconSrc from "../../../../../../../../../assets/images/available-spots-red.svg";
import { SidebarInformation } from "./SidebarInformation";
import { StickySidebarProps } from "./StickySidebar.types";

export default function StickySidebar(props: Readonly<StickySidebarProps>) {
  const { showSidebar, availableSpots, values, parkingLocations, parkingFacilityImages } = props;
  const { t } = useTranslation("visitors");

  const imageUrl = parkingFacilityImages?.find(
    (facility) => facility.physicalZoneUid === values.physicalZone.split('.')[1]
  )?.imageUrl;

  // Pre-load the images to avoid showing the sticky sidebar without them
  new Image().src = ProductIconSrc;
  new Image().src = ClockIconSrc;
  new Image().src = AvailableSpotsIconSrc;
  new Image().src = AvailableSpotsRedIconSrc;

  const formatDatesForSidebar = () => {
    const hasStartDateAndTime = values.startDate && values.startTime;
    const hasEndDateAndTime = values.endDate && values.endTime;

    return hasStartDateAndTime && hasEndDateAndTime
      ? `${values.startDate} ${values.startTime} → ${values.endDate} ${values.endTime}`
      : values.startDate;
  };

  const getFacilityNameForSidebar = () => {
    const citiesInTheCountry = parkingLocations?.find(
      (location) => location.countryCode === values.country
    );
    const city = citiesInTheCountry?.cities.find((city) => city.name === values.city);
    const facility = city?.physicalZones.find((pz) => pz.physicalZoneId === values.physicalZone);

    return facility?.name ?? "";
  };

  return showSidebar ? (
    <div className="sticky-sidebar">
      <div className="sidebar-top">
        {imageUrl ? (
          <img src={imageUrl} alt="sidebar-image" className="sidebar-image" />
        ) : (
          <div className="sidebar-image"></div>
        )}
      </div>
      <div className="sidebar-info-wrapper">
        <h4>{getFacilityNameForSidebar()}</h4>
        <SidebarInformation
          imageSrc={ProductIconSrc}
          text={values.productName}
        ></SidebarInformation>
        <SidebarInformation
          imageSrc={ClockIconSrc}
          text={formatDatesForSidebar()}
        ></SidebarInformation>
        <SidebarInformation
          imageSrc={availableSpots === 0 ? AvailableSpotsRedIconSrc : AvailableSpotsIconSrc}
          text={t("availableSpots", { availableSpots: availableSpots })}
          useWarningColor = {availableSpots === 0}
        ></SidebarInformation>
      </div>
    </div>
  ) : (
    <></>
  );
}
