import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import AppThemeProvider from "./theme/AppThemeProvider";
import { Provider } from "react-redux";
import store from "./store";
import ContextProvider from "./context/ContextProvider";
import "./i18n";

import "./index.scss";

// Yup always relies on the Promise global object to handle asynchronous
// values as well as Set and Map. For browsers that do not support these,
// you'll need to include a polyfill, such as core-js:
import "core-js/es/promise";
import "core-js/es/set";
import "core-js/es/map";

const rootElement = document.getElementById("root");

ReactDOM.render(
  <Provider store={store}>
    <AppThemeProvider>
      <ContextProvider>
        <App />
      </ContextProvider>
    </AppThemeProvider>
  </Provider>,
  rootElement
);
