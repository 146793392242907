import { appContextTypes } from "../actions/appContext.types";

export type UserType = {
  seasonTicketOwnerCrmId?: string;
  email?: string;
  country?: string;
};

export type AppContextState = {
  selectedLanguage: string;
  user: UserType;
};

export type AppContextAction = {
  type: string;
  payload?: string | UserType;
};

export const initialState: AppContextState = {
  selectedLanguage: "",
  user: {
    seasonTicketOwnerCrmId: "",
    email: "",
  },
};

export default (
  state: AppContextState = initialState,
  action: AppContextAction
): AppContextState => {
  switch (action.type) {
    case appContextTypes.SET_APP_LANGUAGE:
      return {
        ...state,
        selectedLanguage: action.payload as string,
      };
    case appContextTypes.SET_APP_USER:
      return {
        ...state,
        user: {
          ...state.user,
          ...(action.payload as UserType),
        },
      };
    default:
      return state;
  }
};
