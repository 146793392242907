import en from "./en.json";
import nl from "./nl.json";
import fr from "./fr.json";
import de from "./de.json";

export const languages = {
  en: "en",
  nl: "nl",
  fr: "fr",
  de: "de",
};

export default {
  [languages.en]: en,
  [languages.nl]: nl,
  [languages.fr]: fr,
  [languages.de]: de,
};
