import { ParkingLocationCountry } from "../../../../../../../../models/visitor-booking/ParkingLocationCountry";
import { Required } from "../../../../NewBooking.types";

export type ParkingLocationFormProps = {
  country: Required<string>;
  city: Required<string>;
  physicalZone: Required<string | null>;
  onChange: (field: string, e: React.ChangeEvent<any>) => void;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => any;
  setFieldError: (field: string, value: string | undefined) => void;
  onPhysicalZoneChanged?: () => void;
  parkingLocations?: ParkingLocationCountry[] | null;
};

export enum Fields {
  Country = "country",
  City = "city",
  PhysicalZone = "physicalZone",
}
